import React, { useEffect, useMemo, useState } from 'react'
import DashboardLayout from '../../components/layout/DashboardLayout'
import { useNavigate, useParams } from 'react-router-dom';
import GetSpinner from '../../helpers/shared/GetSpinner';
import { useGetSingleUserQuery, usePutSingleUserMutation } from '../../app/services/authUser';
import { SchemaUpdateSchema } from '../../helpers/validation/SchemaUpdateSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
function UnionSingleUser() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [Loading, setLoading] = useState(false)
    const { data: SingleUserData, isLoading } = useGetSingleUserQuery(id)
    const [userUpdate, { isLoading: userUpdateLoding, isSuccess, isError }] = usePutSingleUserMutation()
    const { register, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(SchemaUpdateSchema) });
    const OfficeType = [
        {
            _id: 1,
            bn_name: 'ইউনিয়ন অন্তর্ভুক্ত',
            en_name: 'Union included'
        },
        {
            _id: 2,
            bn_name: 'পৌরসভা অন্তর্ভুক্ত',
            en_name: 'Municipality included'
        }
    ]
    useEffect(() => {
        if (isLoading) {
            setLoading(true)

        } else {
            setLoading(false)

        }
        if (isSuccess) {
            toast.success('User Update Success')
        }
        if (isError) {
            toast.error('User Not Update ')
        }
    }, [isLoading, isSuccess, isError])

    /* ==================   user update  ================  */
    const onSubmit = async (data) => {
        const sentInfo = { data, id }
        await userUpdate(sentInfo)
        navigate('/user/union-secretary')
    }

    return (
        <DashboardLayout>
            {Loading && <GetSpinner />}
            {
                SingleUserData && <>
                    <div >
                        <section >
                            <div className="row">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="col-lg-12">
                                        <div className="card mb-4">
                                            <div className="card-body">
                                                <h3 className="content-title">চেয়ারম্যানের তথ্য</h3>
                                                <div className="row gx-2">
                                                    <div className="col-4 mb-3">
                                                        <label className="form-label">নাম (বাংলায়)</label>
                                                        <input className="form-control" type="text" placeholder="chairmanBanglaName"
                                                            {...register("chairmanBanglaName")}
                                                            defaultValue={SingleUserData?.user?.chairmanBanglaName}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <label className="form-label">নাম (ইংরেজিতে)</label>
                                                        <input className="form-control" type="text" placeholder="chairmanEnglishName"
                                                            {...register("chairmanEnglishName")}
                                                            defaultValue={SingleUserData?.user?.chairmanEnglishName}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <label className="form-label">জাতীয় পরিচয়পত্র নম্বর</label>
                                                        <input className="form-control" type="Number" placeholder="chairmanNID"
                                                            {...register("chairmanNID")}
                                                            defaultValue={SingleUserData?.user?.chairmanNID}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <label className="form-label">মোবাইল নম্বর</label>
                                                        <input className="form-control" type="number" placeholder="chairmanMobileNumber"
                                                            {...register("chairmanMobileNumber")}
                                                            defaultValue={SingleUserData?.user?.chairmanMobileNumber}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <label className="form-label">ইউনিয়ন নাম (বাংলায়)</label>
                                                        <input className="form-control" type="text" placeholder="union_bn_name"
                                                            {...register("union_bn_name")}
                                                            defaultValue={SingleUserData?.user?.union_bn_name}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <label className="form-label">ইউনিয়ন নাম (ইংরেজিতে)</label>
                                                        <input className="form-control" type="text" placeholder="union_en_name"
                                                            {...register("union_en_name")}
                                                            defaultValue={SingleUserData?.user?.union_en_name}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card mb-4">
                                            <div className="card-body">
                                                <h3 className="content-title">ইউনিয়ন পরিষদ সচিবের তথ্য </h3>
                                                <div className="row gx-2">
                                                    <div className="col-4 mb-3">
                                                        <label className="form-label">নাম (বাংলায়)</label>
                                                        <input className="form-control" type="text" placeholder="secretaryBanglaName"
                                                            {...register("secretaryBanglaName")}
                                                            defaultValue={SingleUserData?.user?.secretaryBanglaName}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <label className="form-label">নাম (ইংরেজিতে)</label>
                                                        <input className="form-control" type="text" placeholder="secretaryEnglishName"
                                                            {...register("secretaryEnglishName")}
                                                            defaultValue={SingleUserData?.user?.secretaryEnglishName}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <label className="form-label">জাতীয় পরিচয়পত্র নম্বর</label>
                                                        <input className="form-control" type="Number" placeholder="secretaryNID"
                                                            {...register("secretaryNID")}
                                                            defaultValue={SingleUserData?.user?.secretaryNID}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <label className="form-label">মোবাইল নম্বর</label>
                                                        <input className="form-control" type="number" placeholder="secretaryMobileNumber"
                                                            {...register("secretaryMobileNumber")}
                                                            defaultValue={SingleUserData?.user?.secretaryMobileNumber}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <label className="form-label">ই-মেইল</label>
                                                        <input className="form-control" type="text" placeholder="secretaryEmail"
                                                            {...register("secretaryEmail")}
                                                            defaultValue={SingleUserData?.user?.secretaryEmail}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <label className="form-label">পাসওয়ার্ড</label>
                                                        <input className="form-control" type="text" placeholder="secretaryPassword"
                                                            {...register("secretaryPassword")}
                                                            defaultValue={SingleUserData?.user?.secretaryPassword}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card mb-4">
                                            <div className="card-body">
                                                <h3 className="content-title">উদ্যোক্তার তথ্য </h3>
                                                <div className="row gx-2">
                                                    <div className="col-4 mb-3">
                                                        <label className="form-label">নাম (বাংলায়)</label>
                                                        <input className="form-control" type="text" placeholder="secretaryBanglaName"
                                                            {...register("entrepreneurBanglaName")}
                                                            defaultValue={SingleUserData?.user?.entrepreneurBanglaName}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <label className="form-label">নাম (ইংরেজিতে)</label>
                                                        <input className="form-control" type="text" placeholder="secretaryEnglishName"
                                                            {...register("entrepreneurEnglishName")}
                                                            defaultValue={SingleUserData?.user?.entrepreneurEnglishName}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <label className="form-label">জাতীয় পরিচয়পত্র নম্বর</label>
                                                        <input className="form-control" type="Number" placeholder="secretaryNID"
                                                            {...register("entrepreneurNID")}
                                                            defaultValue={SingleUserData?.user?.entrepreneurNID}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <label className="form-label">মোবাইল নম্বর</label>
                                                        <input className="form-control" type="number" placeholder="secretaryMobileNumber"
                                                            {...register("entrepreneurMobileNumber")}
                                                            defaultValue={SingleUserData?.user?.entrepreneurMobileNumber}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <label className="form-label">ই-মেইল</label>
                                                        <input className="form-control" type="text" placeholder="entrepreneurEmail"
                                                            {...register("entrepreneurEmail")}
                                                            defaultValue={SingleUserData?.user?.entrepreneurEmail}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <label className="form-label">পাসওয়ার্ড</label>
                                                        <input className="form-control" type="text" placeholder="secretaryPassword"
                                                            {...register("entrepreneurPassword")}
                                                            defaultValue={SingleUserData?.user?.entrepreneurPassword}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="card mb-4">
                                            <div className="card-body">
                                                <h3 className="content-title">নিয়োগ এলাকা তথ্য</h3>
                                                <div className="row gx-2">
                                                    <div className="col-3 mb-3">
                                                        <label className="form-label">বিভাগ</label>
                                                        <input className="form-control" type="text" placeholder="secretaryBanglaName"
                                                            value={SingleUserData?.user?.division?.bn_name}
                                                        />
                                                    </div>
                                                    <div className="col-lg-3 mb-3">
                                                        <label className="form-label">জেলা</label>
                                                        <input className="form-control" type="text" placeholder="secretaryEnglishName"
                                                            value={SingleUserData?.user?.district?.bn_name}
                                                        />
                                                    </div>
                                                    <div className="col-lg-3 mb-3">
                                                        <label className="form-label">থানা / উপজেলা</label>
                                                        <input className="form-control" type="text" placeholder="secretaryNID"
                                                            value={SingleUserData?.user?.upazila?.bn_name}
                                                        />
                                                    </div>
                                                    <div className="col-lg-3 mb-3">
                                                        <label className="form-label">ইউনিয়ন</label>
                                                        <input className="form-control" type="text" placeholder="secretaryNID"
                                                            value={SingleUserData?.user?.union?.bn_name}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                      <button style={{ cursor: userUpdateLoding ? 'no-drop' : 'pointer' }} className="btn btn-md rounded font-sm hover-up">সাবমিট</button>
                                </form>

                            </div>
                        </section>
                    </div>
                </>
            }
        </DashboardLayout>
    )
}

export default UnionSingleUser