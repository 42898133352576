import React, { useMemo, useState } from "react";
import DashboardLayout from "../../components/layout/DashboardLayout";
import Pagination from "../../components/elements/Pagination";
import { FaSearch } from "react-icons/fa";
import GetSpinner from "../../helpers/shared/GetSpinner";
import EditHoldingTaxModal from "../../components/elements/holdingTax/modal/EditHoldingTaxModal";
import CretaeBrithCertificate from "./CretaeBrithCertificate";
import SearchInputModal from "./SearchInputModal";
import AddNibondhonBulk from "../../components/elements/holdingTax/modal/AddNibondhonBulk";
import { useGetbirthregistrationQuery } from "../../app/services/birthregistration";
import JonmoNibondhonSingle from "../../components/elements/jonmoNibondhon/JonmoNibondhonSingle";
import Search from "../../utils/Search";

function JonmoNibondhon() {
  const contects = { title: "নতুন নিবন্ধন" };
  const fromtitle = { title: "অনুসন্ধান করুন" };
  const [status, setStatus] = useState({ type: false, data: null });
  const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10, });
  const [singleUser, setSingleUser] = useState();
  const [bulkUpload, setBulkUpload] = useState(false)
  const [holdingTaxModal, setHoldingTaxModal] = useState({ type: false, data: null, });
  const [searchModal, setSearchModal] = useState({ type: false, data: null });
  const [search, setSearchValue] = useState(``);
  const pathname = `page=${pageIndex}&limit=${pageSize}&${search}`;

  const { data, isLoading: loading } = useGetbirthregistrationQuery(pathname);
  const JonmoNibondhonData = useMemo(() => data?.result ? data?.result : [], [loading, data, search])

  return (
    <DashboardLayout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">জন্ম নিবন্ধন</h2>
          </div>
        </div>
        <div className="card mb-4">
          <header className="card-header">
            <div className="row align-items-center">
              <div style={{ display: 'flex' }} className="  col-md-3 col-12 me-auto mb-md-0 mb-3">
                {/* <Search setSearchValue={setSearchValue} /> */}
                <p
                  onClick={() =>
                    setSearchModal({ type: !searchModal.type })
                  }
                  className=" btn btn-primary  rounded"
                >
                  অনুসন্ধান করুন
                  <span style={{
                    "paddingLeft": '10px'
                  }}> <FaSearch></FaSearch></span>
                </p>
                
              </div>
              <div className="text-end col-md-5 col-12 ms-auto mb-md-0 mb-3">
                <div className="d-flex justify-content-end  align-items-end">
                  <p style={{ marginRight: '10px', marginTop: '5px' }}
                    onClick={() =>
                      setBulkUpload(!bulkUpload)
                    }
                    className=" btn btn-primary btn-sm rounded"
                  >
                    আপলোড করুন
                  </p>
                  {/*  <p
                    onClick={() =>
                      setHoldingTaxModal({ type: !holdingTaxModal.type })
                    }
                    className=" btn btn-primary btn-sm rounded"
                  >
                    নতুন তৈরি করুন
                  </p> */}
                </div>
              </div>
            </div>
          </header>
          <div className="col-md-12 px-4 ">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr className="">
                    <th>রেজিস্টার তারিখ</th>
                    <th>নাম</th>
                    <th>বাবার নাম</th>
                    <th>মায়ের নাম</th>
                    <th>জন্ম নিবন্ধন নং</th>
                    <th>জন্ম তারিখ</th>
                    <th>গ্রাম</th>
                    <th>বই নং</th>
                    <th>পাতার নং</th>
                    {/*    <th className="text-end">পদক্ষেপ</th> */}
                  </tr>
                </thead>
                <tbody>
                  {loading && <GetSpinner />}
                  {JonmoNibondhonData?.map((jonmoNibondhon) => (
                    <JonmoNibondhonSingle
                      jonmoNibondhon={jonmoNibondhon}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Pagination
          totalPages={data?.totalPages}
          setPagination={setPagination}
          pageIndex={pageIndex}
          pageSize={pageSize}
        />
      </section >

      <EditHoldingTaxModal
        singleUser={singleUser}
        modal={status}
        setOpen={setStatus}
      />
      <CretaeBrithCertificate
        modal={holdingTaxModal}
        setOpen={setHoldingTaxModal}
        contects={contects}
      />
      <SearchInputModal
        modal={searchModal}
        setOpen={setSearchModal}
        contects={fromtitle}
        setSearchValue={setSearchValue}
        isLoading={loading}
      />


      {
        bulkUpload && <AddNibondhonBulk
          bulkUpload={bulkUpload}
          contects={contects}
          setBulkUpload={setBulkUpload}
        />
      }
    </DashboardLayout >
  );
}

export default JonmoNibondhon;









