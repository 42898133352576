import QRCode from 'react-qr-code';
import styles from './CertificateFooter.module.css';
function CertificateFooterBn({ certificate, accountType, marginHandel, sigbature, sigbatureConecnt }) {
    let linkOpen;
    if (accountType === 'municipality') {
        linkOpen = `https://smartunion.com.bd/municipality/verifySonod/${certificate?.sonodId}`
    } else {
        linkOpen = `https://smartunion.com.bd/verifySonod/${certificate?.sonodId}`
    }
    return (
        <>
            <footer>
                <div className={marginHandel ? styles.qrAndUpSignMargin_top : styles.qrAndUpSign}>
                    <div className={styles.qrCode}>
                        <QRCode
                            size={100}
                            style={{
                                height: '85px',
                                paddingBottom: "5px"
                            }}
                            value={linkOpen}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                    {accountType === 'municipality' && <div style={{
                        marginTop: "10px"
                    }}>
                        <h4>লাইসেন্স পরিদর্শক</h4>
                    </div>
                    }
                    {
                        sigbature && <div style={{
                            marginTop: "10px"
                        }}>
                            <h4>{sigbatureConecnt}</h4>
                        </div>
                    }

                    <div className={styles.upSign}>
                        <h4 className={styles.upName}
                            style={{
                                fontSize: "12px"
                            }}
                        >
                            {certificate?.union_or_municipality?.chairman_mayor_bn_name}
                        </h4>
                        <h4
                            style={{
                                fontSize: "13px"
                            }}
                        > {accountType === 'municipality' ? "প্রশাসক" : "চেয়ারম্যান"}</h4>
                        <h4
                            style={{
                                fontSize: "13px"
                            }}
                        >{certificate?.union_or_municipality?.municipality_union_bn_name}</h4>
                        <h4
                            style={{
                                fontSize: "13px"
                            }}
                        >
                            {certificate?.union_or_municipality?.upazila?.bn_name},{' '}
                            {certificate?.union_or_municipality?.district?.bn_name}{'।'}
                        </h4>
                    </div>
                </div>
            </footer >
        </>
    )
}

function CertificateFooterEn({ certificate, accountType, marginHandel, sigbature, sigbatureConecnt }) {
    let linkOpen;
    if (accountType === 'municipality') {
        linkOpen = `https://smartunion.com.bd/municipality/verifySonod/${certificate?.sonodId}`
    } else {
        linkOpen = `https://smartunion.com.bd/verifySonod/${certificate?.sonodId}`
    }
    return (
        <>
            <footer>
                <div className={marginHandel ? styles.qrAndUpSignMargin_top : styles.qrAndUpSign}>
                    <div className={styles.qrCode}>
                        <QRCode
                            size={100}
                            style={{
                                height: '85px',
                                paddingBottom: "5px"
                            }}
                            value={linkOpen}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                    {
                        sigbature && <div
                            style={{
                                marginTop: "10px"
                            }}>
                            <h5>{sigbatureConecnt}</h5>
                        </div>
                    }

                    <div className={styles.upSign}>
                        <h5 style={{
                            fontSize: "13px"
                        }} className={styles.upName}>
                            {certificate?.union_or_municipality?.chairman_mayor_en_name}
                        </h5>
                        <h5 style={{
                            fontSize: "13px"
                        }}> {accountType === 'municipality' ? "Meyor" : "Chairman"}</h5>
                        <h5 style={{
                            fontSize: "13px"
                        }}>{certificate?.union_or_municipality?.municipality_union_en_name}</h5>
                        <h5 style={{
                            fontSize: "13px"
                        }}>
                            {certificate?.union_or_municipality?.upazila?.name},{' '}
                            {certificate?.union_or_municipality?.district?.name}{'.'}
                        </h5>
                    </div>
                </div>
            </footer>
        </>
    )
}
export { CertificateFooterBn, CertificateFooterEn };