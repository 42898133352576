import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import EnglishToBanglaNumber from '../../../../utils/EnglishToBanglaNumber';
import { useSelector } from 'react-redux';
import AccountRoles from '../../../../utils/AccountRoles';

const CertificatePrintModal = ({ modal, setOpen, title }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { type, data } = modal;
  const [lang, SetLang] = useState('');
  const handlePrint = () => {
    if (!lang?.length) {
      return toast.error('Please Select a language!!!', { id: 'print' });
    }
    if (data?.sonod_type)
      if (userInfo?.users?.account === AccountRoles.MUNICIPALITY) {
        return navigate(`/certificate/${userInfo?.users?.account}/${data?.sonod_type}/${lang}/${data?._id}`);
      } else {
        return navigate(`/certificate/${data?.sonod_type}/${lang}/${data?._id}`);
      }
  };
  return (
    <>
      {type && (
        <div className="modal_product">
          <section className="content-main">
            <div className="row">
              <div className="col-12">
                <div className="content-header">
                  <h3 className="capitalize text-center text-primary">
                    {title}
                  </h3>
                  <button onClick={() => setOpen({ type: false })}>X</button>
                </div>
              </div>

              <div>
                <div className="col-lg-12">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div
                        className="d-flex justify-content-between "
                        style={{ marginBottom: '30px' }}
                      >
                        <div>
                          <p>নাগরিকের নাম</p>
                          <h4 className="capitalize ">{data?.citizen_id?.name_bangla}</h4>
                        </div>
                        <div>
                          <p>নাগরিকের এনআইডি</p>
                          <h4 className="capitalize ">{EnglishToBanglaNumber(`${data?.citizen_id?.nid}`)}</h4>
                        </div>
                        <div>
                          <p>নাগরিক নম্বর</p>
                          <h4 className="capitalize ">{EnglishToBanglaNumber(`${data?.citizen_id?.number}`)}</h4>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center  gap-4 mt-5">
                        <div
                          type='button'
                          onClick={() => SetLang('bn')}
                          style={{ width: '100px', height: '40px', fontSize: "16px" }}
                          className={`d-flex justify-content-center align-items-center rounded text-white   ${lang === 'bn' ? 'bg-primary ' : 'bg-info'}`}
                        >
                          বাংলা সনদ
                        </div>
                        <div
                          type='button'
                          onClick={() => SetLang('en')}
                          style={{ width: '100px', height: '40px', fontSize: "16px" }}
                          className={`d-flex justify-content-center align-items-center rounded text-white  ${lang === 'en' ? 'bg-primary ' : 'bg-info'}`}
                        >
                          ইংরেজি সনদ
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      onClick={handlePrint}
                      className="btn btn-md rounded  font-sm"
                    >
                      ডাউনলোড
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default CertificatePrintModal;
