import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
export const handelClick = async (id, deleteContent) => {
  Swal.fire({
    title: 'আপনি কি নিশ্চিত ?',
    text: "ডিলিট করার জন্য অনুমোদন দেন",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText: 'বাতিল করুন',
    confirmButtonText: 'হ্যাঁ',
  }).then(async (result) => {
    if (result.isConfirmed) {
      const result = await deleteContent(id);
      if(result?.data){
        toast.success('সঠিকভাবে ডিলেট করা হয়েছ')
      }
      if (result) {
        Swal.fire('Deleted!', `Your file has been deleted`, 'success');
      }
    }
  });
};
