import React, { useState, useEffect, useMemo } from 'react';
import DashboardLayout from '../../layout/DashboardLayout';
import Pagination from '../Pagination';
import GetSpinner from '../../../helpers/shared/GetSpinner';
import { toast } from 'react-toastify';
import {
  useGetRoleAllSonodQuery,
  usePostCertificateMutation,
} from '../../../app/services/certificate';
import SingleSonod from './singleSonod/SingleSonod';
import { useSelector } from 'react-redux';
import ApproveSonod from './modal/ApproveSonod';
import CertificatePrintModal from './modal/CertificatePrintModal';
import { RefetchData } from '../RefetchData';
import CreateMonthlyIncomeCertificate from './modal/CreateMonthlyIncomeCertificate';
import Search from '../../../utils/Search';

function MonthlyIncomeCertificateApplication() {

  const maxRetries = 3;
  const contects = {
    title: 'মাসিক আয়ের সনদ',
    sonod_type: 'monthlyincome'
  };

  const { userInfo } = useSelector((state) => state.auth);

  const [retryCount, setRetryCount] = useState(0);
  const [search, setSearchValue] = useState('');
  const [printModalOpen, setPrintModalOpen] = useState({
    type: false,
    data: null,
  });
  const [status, setStatus] = useState({ type: false, data: null });
  const [sonodCreateModal, setSonodCreateModal] = useState({
    type: false,
    data: null,
  });
  const [sonodEditModal, setSonodEditModal] = useState({
    type: false,
    data: null,
  });
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const pathname = `role=monthlyincome&page=${pageIndex}&limit=${pageSize}&search=${search}`;

  const { data, isLoading, isError, refetch } = useGetRoleAllSonodQuery(pathname);

  const [
    addCertificate,
    { isLoading: addisLoading, isSuccess: addisSuccess, reset },
  ] = usePostCertificateMutation();

  const certificate = useMemo(
    () => (data ? data?.certificate : []),
    [data, search]
  );

  const CreateonSubmit = async (data) => {
    await addCertificate(data);
  };

  useEffect(() => {
    if (addisSuccess) {
      toast.success('Certificate Add');
      reset();
    }
    RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
  }, [isLoading, addisSuccess, reset, isError, refetch]);


  return (
    <DashboardLayout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">{contects?.title}</h2>
          </div>
          <div>
            <p
              onClick={() =>
                setSonodCreateModal({ type: !sonodCreateModal.type })
              }
              className="btn btn-primary btn-sm rounded"
            >
              নতুন তৈরি করুন
            </p>
          </div>
        </div>
        {isLoading ? <GetSpinner /> : <>
          <div className="card mb-4">
            <header className="card-header">
              <div className="row align-items-center">
                <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                  <Search setSearchValue={setSearchValue} placeholder="সনদ নাম্বার" />
                </div>
              </div>
            </header>

            <div className="col-md-12 px-4 ">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr className="">
                      <th>নাম</th>
                      <th>জাতীয় পরিচয়পত্র</th>
                      <th>মোবাইল নম্বর</th>
                      <th>ইউনিয়ন</th>
                      <th>সনদপত্র প্রকার</th>
                      <th>অবস্থা</th>
                      <th className="text-end">পদক্ষেপ</th>
                    </tr>
                  </thead>
                  <tbody>

                    {certificate?.map((data) => (
                      <SingleSonod
                        setSonodCreateModal={setSonodCreateModal}
                        setSonodEditModal={setSonodEditModal}
                        data={data}
                        key={data._id}
                        setStatus={setStatus}
                        setPrintModalOpen={setPrintModalOpen}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Pagination
            totalPages={data?.totalPages}
            setPagination={setPagination}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />
        </>
        }

      </section>
      {userInfo?.users?.role === 'secretary' ? (
        <ApproveSonod modal={status} setOpen={setStatus} />
      ) : (
        ''
      )}

      <CertificatePrintModal
        title={contects?.title}
        modal={printModalOpen}
        setOpen={setPrintModalOpen}
      />

      <CreateMonthlyIncomeCertificate
        contects={contects}
        CreateonSubmit={CreateonSubmit}
        modal={sonodCreateModal}
        setOpen={setSonodCreateModal}
        addisLoading={addisLoading}
      />



    </DashboardLayout>
  );
}

export default MonthlyIncomeCertificateApplication;
