import { useForm } from "react-hook-form";
import { usePutCertificateRoleReportMutation } from "../../app/services/certificate";
import EnglishToBanglaNumber from "../../utils/EnglishToBanglaNumber";
import { useMemo, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import CertificatedownloadFormat from "./downloadFormat";
import AccountRoles from "../../utils/AccountRoles";
import { usePutrolerepostmunicipalitycertificateMutation } from "../../app/services/certificatemunicipality";

const CertificateReport = ({ handleCloseModal, showModal, userInfo }) => {
    const [dateIssue, setDateIssue] = useState(null)
    const componentRef = useRef();
    const [getReport, { data: unionData, isLoading }] = usePutCertificateRoleReportMutation()
    const [getReportmunicipality, { data: MunicipalityData, isLoading: isLoadingmunicipality }] = usePutrolerepostmunicipalitycertificateMutation()
    const { register, handleSubmit, reset, formState: { errors }, } = useForm();
    const data = useMemo(
        () => (unionData ? unionData : MunicipalityData ? MunicipalityData : []),
        [unionData, MunicipalityData, isLoadingmunicipality, isLoading]
    );
    const submitFromDate = async (value) => {
        let data = {
            ...value,
            sonod_type: "tradelicense"
        }
        setDateIssue(value)
        if (userInfo?.users?.account === AccountRoles.MUNICIPALITY) {
            getReportmunicipality(data);
        } else {
            await getReport(data)
        }





    }
    return (
        <section>
            {
                showModal && <div>
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">

                                    <button type="button" className="btn-close" onClick={handleCloseModal}></button>
                                </div>
                                <form onSubmit={handleSubmit(submitFromDate)}>
                                    <div className="col-lg-12">
                                        <div className="card mb-4">
                                            <div className="card-body">
                                                <div style={{}} className=" gx-2">
                                                    <div className="col-12  mb-3">
                                                        <label className="form-label">
                                                            আজকের তারিখ হইতে{" "}
                                                            <span className="from_required">*</span>{" "}
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="date"
                                                            placeholder=" আজকের তারিখ হইতে                                                            "
                                                            {...register('start_date', {
                                                                required: 'খালি রাখা যাবে না',
                                                            })}
                                                        />
                                                        {errors?.start_date && (
                                                            <span className="form__error">
                                                                {errors?.start_date.message}
                                                            </span>
                                                        )}



                                                    </div>
                                                    <div className="col-lg-12 mb-3">
                                                        <label className="form-label">
                                                            এত তারিখ পর্যন্ত{" "}
                                                            <span className="from_required">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="date"
                                                            placeholder="এত তারিখ পর্যন্ত                                                            "
                                                            {...register('end_date', {
                                                                required: 'খালি রাখা যাবে না',
                                                            })}
                                                        />
                                                        {errors?.end_date && (
                                                            <span className="form__error">
                                                                {errors?.end_date.message}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div style={{ display: 'flex', justifyContent: 'center', }}>

                                                    <button
                                                        style={{ cursor: isLoading || isLoadingmunicipality ? "no-drop" : "pointer", }}
                                                        className="btn btn-md rounded font-sm  col-12 "
                                                    >
                                                        <span style={{ marginLeft: '125px' }}>সাবমিট </span>
                                                    </button></div>

                                            </div>

                                        </div>

                                    </div>
                                </form>
                                {
                                    data?.certificate?.length > 0 ? <div>
                                        <p>{EnglishToBanglaNumber(`${data?.certificate?.length}`)} জন ব্যক্তির ডাটা পাওয়া গিয়েছে দয়া করে প্রিন্ট বাটনে ক্লিক করে প্রিন্ট করুন</p>

                                        <ReactToPrint
                                            trigger={() => (
                                                <button className="btn btn-md rounded font-sm  ">
                                                    প্রিন্ট করুন
                                                </button>
                                            )}
                                            content={() => componentRef.current}
                                        />
                                    </div> : <p>
                                        কোনো ডাটা পাওয়া  যায় নেই
                                    </p>
                                }

                            </div>
                        </div>

                    </div>
                    <div className="modal-backdrop fade show"></div>
                </div >
            }
            <section ref={componentRef} className={`holdingTaxdownloadMain_styles`}>
                <CertificatedownloadFormat data={data?.certificate} area_information={data?.area_information} dateIssue={dateIssue} />
            </section>
        </section >
    )
}
export default CertificateReport;