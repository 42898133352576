import React, { useMemo, useState } from "react";
import DashboardLayout from "../../components/layout/DashboardLayout";

import {
  useGetHoldingTaxByNidOrNumberQuery,
} from "../../app/services/holdingTax";
import BanglaToEnglishNumber from "../../utils/BanglaToEnglishNumber";

import AcceptHoldingEdit from "../../components/elements/holdingTax/AcceptHoldingEdit";

function AcceptHoldingTax() {
  const [search, setSearchValue] = useState("");
  const { data: getData, isLoading } = useGetHoldingTaxByNidOrNumberQuery(search);
  const data = useMemo(() => getData ? getData : [], [getData, isLoading])


  return (
    <DashboardLayout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">হোল্ডিং ট্যাক্স আদায়</h2>
          </div>
        </div>
        {/*  */}
        <div className="card mb-4">
          <header className="card-header">
            <div className="row mb-0 align-items-center">
              <div className="col-md-5 row col-12  me-auto mb-md-0">
                <div className="  d-flex align-items-center">
                  <input
                    value={search}
                    onChange={(e) =>
                      setSearchValue(BanglaToEnglishNumber(e.target.value))
                    }
                    type="text"
                    placeholder="এন আইডি /মোবাইল নম্বর দিয়ে সার্চ করুন"
                    className="form-control"
                  />
                </div>
                <div className="">
                  {data?.user ? (
                    <label className="fs-5">
                      <span className="ms-1"></span>
                    </label>
                  ) : (
                    <span className="text-danger">
                      কোন তথ্য খুজে পাওয়া যায় নি
                    </span>
                  )}
                </div>
              </div>

            </div>
          </header>
        </div>
        {/* ============ from information ============  */}

        {
          (data?.holding_no == search) && <>
            <AcceptHoldingEdit data={data} _id={data?._id} setSearchValue={setSearchValue} />
          </>
        }
      </section>
    </DashboardLayout>
  );
}

export default AcceptHoldingTax;

