import { useMemo, useState } from "react";
import DashboardLayout from "../../components/layout/DashboardLayout";
import Search from "../../utils/Search";
import RechargeModal from "../../components/Recharge/RechargeModal";
import { useLocation } from "react-router-dom";
import { useGetPyamentGatewayQuery } from "../../app/services/recharge";
import GetSpinner from "../../helpers/shared/GetSpinner";
import RechargeTable from "../../components/Recharge/RechargeTable";

function MoneyRecharge() {
    let content;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const message = searchParams.get('message');
    const contects = {
        title: "নতুন রিচার্জ করুন",
    };
    const [open, setOpen] = useState(false)
    const [search, setSearchValue] = useState("");
    const [{ pageIndex, pageSize }, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const pathname = `v1/payment-gateway?page=${pageIndex}&limit=${pageSize}&search=${search}`
    const { data, isLoading, error } = useGetPyamentGatewayQuery(pathname)

    const paymentData = useMemo(() => data ? data?.result : [], [data, isLoading])
    if (isLoading) {
        content = <GetSpinner />
    } else if (paymentData?.length >= 1) {
        content = <RechargeTable data={paymentData}/>
    } else {
        content = <p>কোন ডাটা খুঁজে পাওয়া যায়নি</p>
    }
    return (
        <DashboardLayout>
            <section className="content-main">
                <div className="content-header">
                    <div>
                        <h2 className="content-title card-title">রিচার্জ পরিচালনা  করুন</h2>
                        <p>সর্বনিম্ন আপনাকে ১০০ রিচার্জ টাকা করতে হবে,</p>
                        {
                            message && <>
                                {
                                    message == "success" ? <p className="success_color">সঠিকভাবে রিচার্জ করা হয়েছে </p> : <p className="error_color">
                                        আপনার রিচার্জ টি বাতিল করা হয়েছে
                                    </p>
                                }

                            </>
                        }
                    </div>
                </div>
                <div className="card mb-4">
                    <header className="card-header">
                        <div className="row align-items-center">
                            <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                                <Search setSearchValue={setSearchValue}  placeholder={"অনুসন্ধান করুন"}/>

                            </div>
                            <div className="text-end col-md-5 col-12 ms-auto mb-md-0 mb-3 text-end">
                                <p
                                    onClick={() =>
                                        setOpen(!open)
                                    }
                                    className=" btn btn-primary btn-sm rounded"
                                >
                                    নতুন রিচার্জ করুন
                                </p>
                            </div>
                        </div>
                    </header>
                    <div className="col-md-12 px-4 ">
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr className="">
                                        <th>নাম</th>
                                        <th>তারিখ</th>
                                        <th>ট্রানজেকশন আইডি</th>
                                        <th>মোট পরিমাণ </th>
                                        <th>পাঠানোর নাম্বার </th>
                                        <th>পদক্ষেপ</th>
                                    </tr>
                                </thead>
                                {
                                    content
                                }
                                
                            </table>
                        </div>
                    </div>
                </div>
                {/*  <Pagination
                    totalPages={data?.totalPages}
                    setPagination={setPagination}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                /> */}
            </section>
            <RechargeModal
                contects={contects}
                setOpen={setOpen}
                open={open}
            />


        </DashboardLayout>
    );
}

export default MoneyRecharge;
