import React from 'react';
import logo from '../../assets/logo/logo.png';
import { FaFacebookF, FaTwitter, FaYoutube } from 'react-icons/fa';

function Footer() {
    return (
        <footer className="footer">
            <div className="items-center">
                <a className="brand-wrap">
                    <img style={{
                        width: '72px'
                    }} src={logo} alt="Logo" />
                </a>

                <p >Copyright © 2023 - All right reserved</p>
            </div>
            <div>
                <a href="/" className="text-2xl">
                    <FaTwitter />
                </a>
                <a href="/" className="text-2xl">
                    <FaYoutube />
                </a>
                <a href="https://www.facebook.com/groups/722535906491506" className="text-2xl">
                    <FaFacebookF />
                </a>
            </div>
        </footer>
    );
}

export default Footer;
