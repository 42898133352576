function CertificatePagesLogo({ certificate,toValue }) {
    return (
        <div
            style={{
                content: "''",
                display: 'block',
                width: '500px',
                height: '500px',
                backgroundImage: `url(${certificate?.pageLogo})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                position: 'absolute',
                top: toValue,
                left: 0,
                right: 0,
                bottom: 0,
                margin: 'auto',
                zIndex: '-1',
                opacity: '0.1',
            }}
        ></div>
    )
}
export default CertificatePagesLogo;

/*  transform: 'translate(-50%, -50%)', */
