import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQueryHandel";
// users service
export const AuthApi = createApi({
  reducerPath: "User",
  tagTypes: ["User"],
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    /* auth info */
    getauthInfo: builder.mutation({
      query: (email) => ({
        url: `v1/auth_info?email=${email}`,
        method: "PUT",
      }),
      invalidatesTags: ["User"],
    }),
    /* admin handel api */
    allUserGet: builder.query({
      query: (pathname) => ({
        url: `v1/all-user?${pathname}`,
        method: "GET",
      }),
      providesTags: ["User"],
    }),
    userDelete: builder.mutation({
      query: (id) => ({
        url: `v1/user/delete?_id=${id}`,
        method: "POST",
      }),
      invalidatesTags: ["User"],
    }),
    putSingleUser: builder.mutation({
      query: ({ data, id }) => ({
        url: `v1/singleUser?_id=${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
    putUserAccountHandel: builder.mutation({
      query: ({ value, id }) => ({
        url: `v1/account?_id=${id}`,
        method: "PUT",
        body: value,
      }),
      invalidatesTags: ["User"],
    }),
    /* user and admin  */
    getSingleUser: builder.query({
      query: (id) => ({
        url: `v1/singleUser?_id=${id}`,
        method: "GET",
      }),
      providesTags: ["User"],
    }),
    /* getProfileUser */
    getProfileUser: builder.query({
      query: () => ({
        url: `v1/getProfileUser`,
        method: "GET",
      }),
      providesTags: ["User"],
    }),
    updateLogo: builder.mutation({
      query: ({ value, id }) => {
        return {
          url: `v1/logo?_id=${id}`,
          method: "PUT",
          body: value,
        };
      },
      invalidatesTags: ["User"],
    }),
    porichoyCitizanPost: builder.mutation({
      query: (data) => ({
        url: `v1/porichoy`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
    porichoyCitizanVerified: builder.mutation({
      query: (data) => ({
        url: `v1/porichoy/Verify`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
    updateEmalOnlyAdmin: builder.mutation({
      query: (data) => ({
        url: `v1/email_update`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),

  }),
});
export const {
  useAllUserGetQuery,
  useUserDeleteMutation,
  useGetSingleUserQuery,
  usePutSingleUserMutation,
  usePutUserAccountHandelMutation,
  useGetProfileUserQuery,
  useUpdateLogoMutation,
  useGetauthInfoMutation,
  usePorichoyCitizanPostMutation,
  usePorichoyCitizanVerifiedMutation,
  useUpdateEmalOnlyAdminMutation
} = AuthApi;
