const BanglaToEnglishNumber = (banglaNumber) => {
  const banglaToEnglishMap = {
    "০": "0",
    "১": "1",
    "২": "2",
    "৩": "3",
    "৪": "4",
    "৫": "5",
    "৬": "6",
    "৭": "7",
    "৮": "8",
    "৯": "9",
  };
  let englishNumber = "";
  for (let i = 0; i < banglaNumber?.length; i++) {
    const banglaDigit = banglaNumber[i];
    if (banglaToEnglishMap.hasOwnProperty(banglaDigit)) {
      englishNumber += banglaToEnglishMap[banglaDigit];
    } else {
      englishNumber += banglaDigit;
    }
  }
  return englishNumber;
};

export default BanglaToEnglishNumber;
