import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQueryHandel";
export const CitizenApi = createApi({
  reducerPath: "Citizen",
  tagTypes: ["Citizen"],
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getAllCitizen: builder.query({
      query: (pathname) => ({
        url: `/v1/citizen?${pathname}`,
        method: "GET",
      }),
      providesTags: ["Citizen"],
    }),
    getViewsCitizen: builder.query({
      query: (id) => ({
        url: `/v1/citizen/singleViews?_id=${id}`,
        method: "GET",
      }),
      providesTags: ["Citizen"],
    }),

    getSingleCitizen: builder.mutation({
      query: (data) => ({
        url: `/v1/citizen/`,
        method: "PATCH",
        body: data,
      }),
      providesTags: ["Citizen"],
    }),

    creteCitizen: builder.mutation({
      query: (data) => ({
        url: `/v1/citizen/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Citizen"],
    }),
    updateCitizen: builder.mutation({
      query: ({ id, data }) => ({
        url: `/v1/citizen/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Citizen"],
    }),
    deleteCitizen: builder.mutation({
      query: (id) => ({
        url: `/v1/citizen/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Citizen"],
    }),

    updateCitizenProfilePicture: builder.mutation({
      query: ({ data, id }) => ({
        url: `v1/citizen/update_image?id=${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Citizen"],
    }),
  }),
});
export const {
  useCreteCitizenMutation,
  useGetAllCitizenQuery,
  useUpdateCitizenMutation,
  useDeleteCitizenMutation,
  useGetSingleCitizenMutation,
  useGetViewsCitizenQuery,
  useUpdateCitizenProfilePictureMutation,
} = CitizenApi;
