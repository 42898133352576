import { Swiper, SwiperSlide } from 'swiper/react';
import video1 from '../../../assets/video/video1.webm'
import video2 from '../../../assets/video/video2.webm'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function HeroSlider() {
    return (
        <div style={{ width: "100%", maxWidth: "1200px", margin: "auto", height: "auto" }}>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 8000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}

                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className="Sliderhero ">
                        <div className="hero-content ">
                            <div className="w-100 h-100">
                                <video autoPlay muted loop>
                                    <source src={video2} type="video/mp4" alt="sonod_video"/>
                                </video>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="Sliderhero ">
                        <div className="hero-content ">
                            <div className="w-100">
                                <video autoPlay muted loop>
                                    <source src={video1} type="video/mp4" alt="sonod_video"/>
                                </video>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
        </div>
    )
}
