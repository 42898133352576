import React from 'react';
import sonod1 from '../../../assets/sonod/01.webp'
import sonod2 from '../../../assets/sonod/02.webp'
import sonod3 from '../../../assets/sonod/03.webp'
import sonod5 from '../../../assets/sonod/05.webp'
import sonod7 from '../../../assets/sonod/07.webp'
import sonod8 from '../../../assets/sonod/08.webp'
import sonod9 from '../../../assets/sonod/09.webp'
import sonod10 from '../../../assets/sonod/10.webp'
import sonod13 from '../../../assets/sonod/13.webp'
import sonod17 from '../../../assets/sonod/17.webp'
import sonod18 from '../../../assets/sonod/18.webp'
import sonod25 from '../../../assets/sonod/25.webp'
import sonod28 from '../../../assets/sonod/28.webp'
import sonod30 from '../../../assets/sonod/30.webp'
import sonod31 from '../../../assets/sonod/31.webp'
import sonod32 from '../../../assets/sonod/32.webp'
import sonod35 from '../../../assets/sonod/35.webp'
import { Link } from 'react-router-dom';


const Sebas = () => {
    return (
        <>
            <div className='allsonod'>
                <div className='sonodCard'>
                    <Link to='/application-certificate/nationality'> <img className='w-[140px] mb-3' src={sonod1} alt="sonod_images" /></Link>
                </div>

                <div className='sonodCard'>
                    <Link to="/application-certificate/family">
                        <img className='w-[140px] mb-3' src={sonod5} alt="sonod_images" />
                    </Link>

                </div>

                <div className='sonodCard'>
                    <Link to="/application-certificate/warish">
                        <img className='w-[140px] mb-3' src={sonod3} alt="sonod_images" />
                    </Link>
                </div>

                <div className='sonodCard'>
                    <Link to="application-certificate/death">
                        <img className='w-[140px] mb-3' src={sonod2} alt="sonod_images" />
                    </Link>
                </div>

                <div className='sonodCard'>
                    <Link to="/application-certificate/citizenship">
                        <img className='w-[140px] mb-3' src={sonod10} alt="sonod_images" />
                    </Link>
                </div>

                <div className='sonodCard'>
                    <Link to="/application-certificate/new-voter-attestation"> <img className='w-[140px] mb-3' src={sonod7} alt="sonod_images" /></Link>
                </div>

                <div className='sonodCard'>
                    <Link to="/application-certificate/remarriage"> <img className='w-[140px] mb-3' src={sonod9} alt="sonod_images" /></Link>
                </div>

                <div className='sonodCard'>
                    <Link to="/application-certificate/agricultural"><img className='w-[140px] mb-3' src={sonod13} alt="sonod_images" /></Link>
                </div>

                <div className='sonodCard'>
                    <Link to=""><img className='w-[140px] mb-3' src={sonod8} alt="sonod_images" /></Link>
                </div>
                <div className='sonodCard'>
                    <Link to="/application-certificate/monthly-income"><img className='w-[140px] mb-3' src={sonod18} alt="sonod_images" /></Link>
                </div>
                <div className='sonodCard'>
                    <Link to="/application-certificate/characteristic"> <img className='w-[140px] mb-3' src={sonod17} alt="sonod_images" /></Link>
                </div>
                <div className='sonodCard'>
                    <Link to="/application-certificate/amendment-of-national-identity-information"> <img className='w-[140px] mb-3' src={sonod25} alt="sonod_images" /></Link>
                </div>
                <div className='sonodCard'>
                    <Link to="/application-certificate/transfer-of-constituency"> <img className='w-[140px] mb-3' src={sonod30} alt="sonod_images" /></Link>
                </div>
                <div className='sonodCard'>
                    <Link to="/application-certificate/attestation-of-the-same-person"> <img className='w-[140px] mb-3' src={sonod28} alt="sonod_images" /></Link>
                </div>
                <div className='sonodCard'>
                    <Link to="/application-certificate/trade-license"> <img className='w-[140px] mb-3' src={sonod35} alt="sonod_images" /></Link>

                </div>
                <div className='sonodCard'>
                    <Link to="/application-certificate/unmarried"><img className='w-[140px] mb-3' src={sonod32} alt="sonod_images" /></Link>

                </div>
                <div className='sonodCard'>
                    <Link to=""><img className='w-[140px] mb-3' src={sonod31} alt="sonod_images" />
                    </Link>
                </div>

            </div>
        </>
    );
};

export default Sebas;