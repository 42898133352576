import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateCertificateMutation } from "../../../../app/services/certificate";
import toast from "react-hot-toast";
import { WarishSonodUpdateCreateSchema } from "../../../../helpers/validation/AllSonodCertificateSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
const WarishSonodEdit = ({ certificate }) => {
    const navigate = useNavigate()
    const [familyDetails, setFamilyDetails] = useState(certificate?.warish_sonod_information[0]?.family_information)
    const [updateCertificate, { isLoading, isError, isSuccess, error }] = useUpdateCertificateMutation()

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({ resolver: yupResolver(WarishSonodUpdateCreateSchema) });
    const [familyDtError, setFamilyDtError] = useState(null)
    const memberInitialData = {
        memberNameBn: "",
        memberNameEn: "",
        memberDate: "",
        memberNidDeath: "",
        memberRelation: ""
    }
    const [memberData, setMemberData] = useState(memberInitialData)



    const handleInputChange = (e) => {
        setFamilyDtError(null)
        const { name, value } = e.target;
        setMemberData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleAddFamilyDetails = () => {
        if (
            memberData.memberNameBn === "" ||
            memberData.memberNameEn === "" ||
            memberData.memberDate === "" ||
            memberData.memberRelation === ""
        ) {
            setFamilyDtError("খালি রাখা যাবে ন");
        } else {
            const updatedFamilyDetails = [...familyDetails, memberData];
            // Sort the updated array based on memberDate in descending order
            const sortedFamilyDetails = updatedFamilyDetails.slice().sort((a, b) => new Date(a.memberDate) - new Date(b.memberDate));
            setFamilyDetails(sortedFamilyDetails);
            // Reset the memberData state
            setMemberData(memberInitialData);
        }
    };


    const handleDeleteFamilyMember = (index) => {
        let updatedFamilyDetails = [...familyDetails];
        updatedFamilyDetails.splice(index, 1);
        setFamilyDetails(updatedFamilyDetails);
    };

    const createOnSubmit = async (certificateData) => {
        const certificateDataInfo = {
            id: certificate?._id,
            data: {
                warish_sonod_information: {
                    living_person_name_bn: certificateData?.living_person_name_bn,
                    living_person_name_en: certificateData?.living_person_name_en,
                    living_person_father_husband_bn: certificateData?.living_person_father_husband_bn,
                    living_person_father_husband_en: certificateData?.living_person_father_husband_en,
                    living_person_nid_no: certificateData?.living_person_nid_no,
                    living_person_date: certificateData?.living_person_date,
                    living_person_mother_bn: certificateData?.living_person_mother_bn,
                    living_person_mother_en: certificateData?.living_person_mother_en,
                    family_information: familyDetails
                }
            }
        };
        await updateCertificate(certificateDataInfo);

    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('আপডেট করা হয়েছে')
            navigate('/application-certificate/warish')
            reset()
        }
        if (error) {
            toast.error('আপডেট করা নেই')
        }

    }, [error, isError, isSuccess]);
    console.log(familyDetails)







    return (
        <>
            <form onSubmit={handleSubmit(createOnSubmit)}>
                <div className="col-lg-12">
                    <h3>{certificate?.citizen_id?.name_bangla}</h3>
                    <div className="card mb-4">
                        <div className="card-body">
                            <h5> মৃত ব্যক্তির বিবরণ :-</h5>
                            <div className="row gx-3">
                                <div className="col-4 mb-3">
                                    <label className="form-label">
                                        মৃত ব্যক্তির নাম (বাংলা){' '}
                                        <span className="from_required">*</span>{' '}
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="নাম বাংলা"
                                        defaultValue={certificate?.warish_sonod_information[0]?.living_person_name_bn}
                                        {...register('living_person_name_bn')}
                                    />
                                    {errors?.living_person_name_bn && (
                                        <span className="form__error">
                                            {errors?.living_person_name_bn.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">
                                        মৃত ব্যক্তির নাম (ইংরেজিতে){' '}
                                        <span className="from_required">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="নাম ইংরেজিতে"
                                        defaultValue={certificate?.warish_sonod_information[0]?.living_person_name_en}
                                        {...register('living_person_name_en')}
                                    />
                                    {errors?.living_person_name_en && (
                                        <span className="form__error">
                                            {errors?.living_person_name_en.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">
                                        মৃত ব্যক্তির পিতা / স্বামীর নাম  (বাংলা){' '}
                                        <span className="from_required">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="পিতা / স্বামীর নাম বাংলা"
                                        defaultValue={certificate?.warish_sonod_information[0]?.living_person_father_husband_bn}
                                        {...register('living_person_father_husband_bn')}
                                    />
                                    {errors?.living_person_father_husband_bn && (
                                        <span className="form__error">
                                            {errors?.living_person_father_husband_bn.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">
                                        মৃত ব্যক্তির পিতা / স্বামীর নাম (ইংরেজিতে){' '}
                                        <span className="from_required">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="পিতা / স্বামীর নাম ইংরেজিতে"
                                        defaultValue={certificate?.warish_sonod_information[0]?.living_person_father_husband_en}
                                        {...register('living_person_father_husband_en')}
                                    />
                                    {errors?.living_person_father_husband_en && (
                                        <span className="form__error">
                                            {errors?.living_person_father_husband_en.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">
                                        মৃত ব্যক্তির মাতার নাম (বাংলা){' '}
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="মাতার নাম বাংলা"
                                        defaultValue={certificate?.warish_sonod_information[0]?.living_person_mother_bn}
                                        {...register('living_person_mother_bn')}
                                    />
                                  
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">
                                        মৃত ব্যক্তির মাতার নাম (ইংরেজিতে){' '}
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="মাতার নাম ইংরেজিতে"
                                        defaultValue={certificate?.warish_sonod_information[0]?.living_person_mother_en}
                                        {...register('living_person_mother_en')}
                                    />
                                   
                                </div>








                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">
                                        মৃত্যু রেজিস্টারের নিবন্ধন নাম্বার {' '}
                                        <span className="from_required">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        defaultValue={certificate?.warish_sonod_information[0]?.living_person_nid_no}
                                        {...register('living_person_nid_no')}
                                    />
                                    {errors?.living_person_nid_no && (
                                        <span className="form__error">
                                            {errors?.living_person_nid_no.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label className="form-label">
                                        মৃত্যু তারিখ {' '}
                                        <span className="from_required">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        placeholder=" মৃত্যু  তারিখ"
                                        defaultValue={certificate?.warish_sonod_information[0]?.living_person_date}
                                        {...register('living_person_date')}
                                    />
                                    {errors?.living_person_date && (
                                        <span className="form__error">
                                            {errors?.living_person_date.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center mb-3' >
                                <h5> ওয়ারিশ  সদস্যদের তথ্য :-</h5>

                                <button onClick={handleAddFamilyDetails} type='button' className=' btn-sm btn-primary '>
                                যোগ করুন +
                                </button>
                            </div>
                            <div>
                                <div className="row gx-3 mb-2" style={{
                                    border: '1px solid #3bb77e',
                                    padding: '2px',
                                    borderRadius: '6px'
                                }}>
                                    <div className="col-3 mb-3">
                                        <label className="form-label">
                                            নাম: (বাংলা){' '}
                                            <span className="from_required">*</span>{' '}
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name='memberNameBn'
                                            placeholder="নাম বাংলা"
                                            value={memberData.memberNameBn}
                                            onChange={handleInputChange}
                                        />

                                    </div>
                                    <div className="col-3 mb-3">
                                        <label className="form-label">
                                            নাম: (ইংরেজিতে){' '}
                                            <span className="from_required">*</span>{' '}
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name='memberNameEn'
                                            placeholder="নাম ইংরেজিতে"
                                            value={memberData.memberNameEn}
                                            onChange={handleInputChange}
                                        />

                                    </div>
                                    <div className="col-2 mb-3">
                                        <label className="form-label">
                                            এনআইডি / জন্ম নিবন্ধন {' '}
                                        </label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name='memberNidDeath'
                                            placeholder=" এনআইডি বা  জন্ম নিবন্ধন "
                                            value={memberData.memberNidDeath}
                                            onChange={handleInputChange}
                                        />

                                    </div>
                                    <div className="col-2 mb-3">
                                        <label className="form-label">
                                            তারিখ {' '}
                                            <span className="from_required">*</span>{' '}
                                        </label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            name='memberDate'
                                            value={memberData.memberDate}
                                            onChange={handleInputChange}
                                        />

                                    </div>

                                    <div className="col-md-2 mb-3">
                                        <label htmlFor="Promo_amount" className="form-label">
                                            সম্পর্ক <span className="from_required">*</span>
                                        </label>
                                        <select
                                            className="form-select"
                                            name="memberRelation"
                                            value={memberData.memberRelation}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">
                                                সম্পর্ক নির্বাচন করুন
                                            </option>
                                            <option value="swami">স্বামী</option>
                                            <option value="wife">স্ত্রী</option>
                                            <option value="son">পুত্র</option>
                                            <option value="daughter">কন্যা</option>
                                            <option value="the-mother">মাতা</option>
                                            <option value="grandfather">দাদা</option>
                                            <option value="father ">বাবা </option>
                                            <option value="grandmother">দাদী</option>
                                            <option value="grandmother">নানি</option>
                                            <option value="dead-son">মৃত-পুত্র</option>
                                            <option value="dead-daughter">মৃত-কন্যা</option>
                                            <option value="dead-grandfather">মৃত-দাদা</option>
                                            <option value="dead-grandfather">মৃত-নানি</option>
                                            <option value="grandson">নাতি</option>
                                            <option value="granddaughter">নাতনির</option>
                                            <option value="brother">ভাই</option>
                                            <option value="sister">বোন</option>
                                            <option value="brother">সহোদর ভাই</option>

                                        </select>
                                    </div>
                                </div>
                                <p style={{ color: "red" }}>{familyDtError}</p>
                                <div className=' mt-4' style={{ paddingTop: "25px" }} >
                                    {
                                        familyDetails?.map((details, index) => <div key={index} className="row gx-3 mb-4 shadow-sm" >
                                            <div className="col-3 mb-3">
                                                <label className="form-label">
                                                    নাম: (বাংলা){' '}
                                                    <span className="from_required">*</span>{' '}
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name='memberNameBn'
                                                    value={details?.memberNameBn}
                                                    onChange={(e) => handleInputChange(index, 'memberNameBn', e.target.value)}
                                                />

                                            </div>
                                            <div className="col-2 mb-3">
                                                <label className="form-label">
                                                    নাম: (ইংরেজিতে){' '}
                                                    <span className="from_required">*</span>{' '}
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name='memberNameEn'
                                                    value={details?.memberNameEn}
                                                    onChange={(e) => handleInputChange(index, 'memberNameEn', e.target.value)}


                                                />
                                            </div>
                                            <div className="col-2 mb-3">
                                                <label className="form-label">
                                                    এনআইডি / জন্ম নিবন্ধন {' '}
                                                </label>
                                                <input
                                                    className="form-control"
                                                    name='memberNidDeath'
                                                    placeholder=" এনআইডি বা  জন্ম নিবন্ধন "
                                                    value={details.memberNidDeath}
                                                    onChange={(e) => handleInputChange(index, 'memberNidDeath', e.target.value)}


                                                />

                                            </div>

                                            <div className="col-2 mb-3">
                                                <label className="form-label">
                                                    তারিখ {' '}<span className="from_required">*</span>{' '}
                                                </label>
                                                <input
                                                    className="form-control"
                                                    name='memberDate'
                                                    value={details?.memberDate}
                                                    onChange={(e) => handleInputChange(index, 'memberDate', e.target.value)}

                                                />

                                            </div>
                                            <div className="col-2 mb-3">
                                                <label className="form-label">
                                                    সম্পর্ক
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={details?.memberRelation}
                                                    onChange={(e) => handleInputChange(index, 'memberRelation', e.target.value)}

                                                />
                                            </div>

                                            <div className=' col-1 d-flex align-items-center justify-content-end '>
                                                <button type='button' onClick={() => handleDeleteFamilyMember(index)} className='btn btn-danger '>Delete</button>
                                            </div>

                                        </div>)
                                    }
                                </div>
                            </div>
                            <button
                                style={{ cursor: isLoading ? 'no-drop' : 'pointer' }}
                                className="btn btn-md rounded font-sm "
                            >
                                সংশোধন করুন
                            </button>
                        </div>
                    </div>

                </div>

            </form>

        </>
    )
}
export default WarishSonodEdit;