import ReactToPrint from 'react-to-print';
import govtLogo from '../../../../../assets/imgs/logo/govtLogo.png';
import { useEffect, useRef, useState } from 'react';
import QRCode from 'react-qr-code';
import DashboardLayout from '../../../../layout/DashboardLayout';
import { useParams } from 'react-router-dom';
import { useGetSingleCertificateQuery } from '../../../../../app/services/certificate';
import { enFormatDate } from '../../../../../utils/formatDate';

const BnMiscellaneous = () => {
  const [issueDate, setIssueDate] = useState();
  const componentRef = useRef();
  const { id } = useParams();
  const { data } = useGetSingleCertificateQuery(id);

  const certificate = data?.certificate;
  useEffect(() => {
    if (certificate) {
      const date = enFormatDate(certificate?.createdAt);
      setIssueDate(date);
    }
  }, [certificate]);
  
  return (
    <DashboardLayout>
     
    </DashboardLayout>
  );
};

export default BnMiscellaneous;
