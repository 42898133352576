import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useCreteCitizenMutation, useUpdateCitizenMutation } from '../../../../app/services/citizen';
import { toast } from 'react-toastify';
import { fetchBloodData } from '../../../../utils/Fetch';
import { fetchGender } from '../../../../utils/Fetch';

function EditCitizenModal({ contects, modal, setOpen }) {
  const { type, data } = modal;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [UpdateAccountHandel, { isLoading, isSuccess, isError, error }] =
    useUpdateCitizenMutation();
  const createOnSubmit = async (updateData) => {
    const userHandel = { data: { ...updateData }, id: data?._id };
    await UpdateAccountHandel(userHandel);
  };
  useEffect(() => {
    if (isSuccess) {
      toast('Success');
    }
    if (isError) {
      toast('error');
    }
  }, [isLoading, isSuccess, isError]);

  return (
    <>
      {type && (
        <div className="modal_product">
          <section className="content-main">
            <div className="row">
              <div className="col-12">
                <div className="content-header">
                  <h2 className="content-title">{contects?.title}</h2>
                  <button onClick={() => setOpen({ type: false }, reset())}>
                    X
                  </button>
                </div>
              </div>
              <form onSubmit={handleSubmit(createOnSubmit)}>
                <div className="col-lg-12">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row gx-2">
                        <div className="col-6 mb-3">
                          <label className="form-label">
                            নাম: (বাংলা){' '}
                            <span className="from_required">*</span>{' '}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="নাম বাংলা"
                            defaultValue={data?.name_bangla}
                            {...register('name_bangla', {
                              required: 'Bangla name is required',
                            })}

                          />
                          {errors?.name_bangla && (
                            <span className="form__error">
                              {errors?.name_bangla.message}
                            </span>
                          )}
                        </div>

                        <div className="col-lg-6 mb-3">
                          <label className="form-label">
                            নাম: (ইংরেজিতে){' '}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="নাম ইংরেজিতে"
                            defaultValue={data?.name_english}
                            {...register('name_english')}

                          />
                          {errors?.name_english && (
                            <span className="form__error">
                              {errors?.name_english.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="row gx-2">
                        <div className="col-lg-6 mb-3">
                          <label className="form-label">
                            পিতা / স্বামীর নাম: (বাংলা){' '}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="পিতা / স্বামীর নাম বাংলা"
                            defaultValue={data?.father_husband_bangla}
                            {...register('father_husband_bangla')}
                          />
                          {errors?.father_husband_bangla && (
                            <span className="form__error">
                              {errors?.father_husband_bangla.message}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label className="form-label">
                            পিতা / স্বামীর নাম: (ইংরেজিতে){' '}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="পিতা / স্বামীর নাম ইংরেজিতে"
                            defaultValue={data?.father_husband_english}
                            {...register('father_husband_english')}
                          />
                          {errors?.father_husband_english && (
                            <span className="form__error">
                              {errors?.father_husband_english.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="row gx-2">
                        <div className="col-lg-3 mb-3">
                          <label className="form-label">
                            মাতার নাম: (বাংলা){' '}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="মাতার নাম বাংলা"
                            defaultValue={data?.mother_name_bangla}
                            {...register('mother_name_bangla')}
                          />
                          {errors?.mother_name_bangla && (
                            <span className="form__error">
                              {errors?.mother_name_bangla.message}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3 mb-3">
                          <label className="form-label">
                            মাতার নাম: (ইংরেজিতে){' '}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="মাতার নাম ইংরেজিতে"
                            defaultValue={data?.mother_name_english}
                            {...register('mother_name_english')}
                          />
                          {errors?.mother_name_english && (
                            <span className="form__error">
                              {errors?.mother_name_english.message}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3 mb-3">
                          <label className="form-label">
                            মোবাইল নম্বর{' '}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            placeholder="মোবাইল নম্বর"
                            defaultValue={data?.number}
                            {...register('number')}
                          />
                          {errors?.number && (
                            <span className="form__error">
                              {errors?.number.message}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3 mb-3">
                          <label className="form-label">
                            জাতীয় পরিচয়পত্র নং{' '}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            defaultValue={data?.nid}
                            placeholder="জাতীয় পরিচয়পত্র নং"
                            {...register('nid')}
                          />
                          {errors?.nid && (
                            <span className="form__error">
                              {errors?.nid.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="row gx-2">
                        <div className="col-lg-3 mb-3">
                          <label className="form-label">
                            জন্ম তারিখ <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="date"
                            placeholder="জন্ম তারিখ"
                            defaultValue={data?.date_of_birth}
                            {...register('date_of_birth')}
                          />
                          {errors?.date_of_birth && (
                            <span className="form__error">
                              {errors?.date_of_birth.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-3 mb-3">
                          <label htmlFor="Promo_amount" className="form-label">
                            লিঙ্গ <span className="from_required">*</span>
                          </label>
                          <select
                            className="form-select"
                            {...register('gender')}
                          >
                            {
                              fetchGender?.map(gender => <option key={gender?.id} value={gender?.en}
                                selected={data?.gender == gender?.en}
                              >{gender?.bd}</option>)
                            }
                          </select>
                          {errors?.gender && (
                            <span className="form__error">
                              {errors?.gender.message}
                            </span>
                          )}
                        </div>
                        {errors?.zip_code && (
                          <span className="form__error">
                            {errors?.zip_code.message}
                          </span>
                        )}
                        <div className="col-lg-3 mb-3">
                          <label className="form-label">রক্তের গ্রুপ</label>
                          <select
                            className="form-select"
                            {...register('blood_group')}
                          >

                            {
                              fetchBloodData?.map(blood => <option key={blood?.id} value={blood?.en}
                                selected={data?.blood_group === blood?.en}
                              >{blood?.bd}</option>)
                            }


                          </select>
                        </div>
                        <div className="col-lg-3 mb-3">
                          <label className="form-label">
                            ডাকঘর <span className="from_required">*</span>{' '}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Type here"
                            defaultValue={data?.post_office}
                            {...register('post_office')}
                          />
                          {errors?.post_office && (
                            <span className="form__error">
                              {errors?.post_office.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="row gx-2">
                        <div className="col-lg-3 mb-3">
                          <label className="form-label">
                            ওয়ার্ড নং <span className="from_required">*</span>{' '}
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            placeholder="Zip code "
                            defaultValue={data?.ward_no}
                            {...register('ward_no')}
                          />
                          {errors?.ward_no && (
                            <span className="form__error">
                              {errors?.ward_no.message}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3 mb-3">
                          <label className="form-label">
                            রোড / ব্লক / সেক্টর{' '}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Zip code "
                            defaultValue={data?.road}
                            {...register('road')}
                          />
                          {errors?.road && (
                            <span className="form__error">
                              {errors?.road.message}
                            </span>
                          )}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <input
                  style={{ cursor: isLoading ? 'no-drop' : 'pointer' }}
                  type="submit"
                  className="btn btn-md rounded font-sm hover-up"
                  value="সংরক্ষণ করুন"
                />
              </form>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default EditCitizenModal;
