import React from "react";
import HomeLayout from "../../components/Home/HomeLayout";
import "../../assets/css/Home.css";
import "../../assets/css/holdintax_download.css";
import "../../assets/css/verifySonod.css";
import "../../assets/css/warishAndNationality.css"
import ServicesNav from "../../components/Home/HomeComponents/ServicesNav";
import SonodServices from "../../components/Home/HomeComponents/SonodServices";

export default function Home() {
  return (
    <div>
      <HomeLayout>
        <ServicesNav />
        <SonodServices />
      </HomeLayout>
    </div>
  );
}
