import DashboardLayout from "../../components/layout/DashboardLayout";
import { useGetUpazilasQuery, useUnionCreateMutation } from "../../app/services/location";
import { CreateUnionLocationSchema } from "../../helpers/validation/locationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Button from "../../components/button/Button";
import Spinner from "../../helpers/shared/Spinner";
import { useEffect } from "react";
import { toast } from "react-toastify";

const UnionLocationAddPage = () => {
    /* trk */
    const [creactUnion, { isLoading: unionLoading, data, isSuccess: unionSuccess, error, isError }] = useUnionCreateMutation()
    const { data: upazilas, isLoading, isSuccess } = useGetUpazilasQuery()

    const { register, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(CreateUnionLocationSchema) });

    const onSubmit = async (value) => {
        const addValue = { ...value, url: 'subilup.comilla.gov.bd' }
        await creactUnion(addValue)
    }
    useEffect(() => {
        if (unionSuccess) {
            reset()
            toast.success(data?.message)
        }
        if (isError) {
            toast.error(error?.data?.error)
        }
    }, [data,unionSuccess,error,isError])



    return (
        <DashboardLayout>
            <div className='Signcontainer px-5 mx-auto'>
                <div className="hero min-h-screen bg-base-100">
                    <div className='row'>
                        <div className="col-lg-12 my-5">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {
                                    (!isLoading && isSuccess) && <div className='card flex-shrink-0 w-full max-w-3xl shadow-2xl bg-base-100'>
                                        <div className="card-body ml-2">
                                            <h3 className='title' >ইউনিয়ন  তথ্য</h3>

                                            <div className='row'>
                                                <div className="col-lg-6 col-md-6">
                                                    <label className="Slabel">
                                                        থানা / উপজেলা  <span className='reqStart'>*</span>
                                                    </label>
                                                    <select className="Sinput"
                                                        {...register("upazilla_id")}
                                                    >
                                                        <option value=''>পছন্দ করুন থানা / উপজেলা</option>
                                                        {
                                                            upazilas?.upazila?.map(upazila => <option key={upazila?._id} value={upazila?.id}>{upazila?.name} / {upazila?.bn_name}</option>
                                                            )
                                                        }
                                                    </select>
                                                    {errors.upazilla_id && <span className='warning'>{errors.upazilla_id.message}</span>}

                                                </div>
                                                <div className="col-lg-6 col-md-6 mb-3 mb-md-0">
                                                    <label className="Slabel">
                                                        ইউনিয়ন নাম (বাংলায়) <span className='reqStart'>*</span>
                                                    </label>
                                                    <input type="text" {...register("bn_name")} className="Sinput" />
                                                    {errors.bn_name && <span className='warning'>{errors.bn_name.message}</span>}
                                                </div>
                                            </div>
                                            <div className='row'>

                                                <div className="col-lg-6 col-md-6 mb-3 mb-md-0">
                                                    <label className="Slabel">
                                                        ইউনিয়ন নাম (ইংরেজিতে) <span className='reqStart'>*</span>
                                                    </label>
                                                    <input type="text" {...register("name")} className="Sinput" />
                                                    {errors.name && <span className='warning'>{errors.name.message}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    (isLoading && !isSuccess) && <Spinner />
                                }
                                <Button
                                    isLoading={unionLoading}
                                    text="নিবন্ধন করুন"
                                />
                            </form>
                        </div>

                    </div>
                </div>
            </div>


        </DashboardLayout>
    )
}
export default UnionLocationAddPage;