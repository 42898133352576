import EnglishToBanglaNumber from "../../utils/EnglishToBanglaNumber";
import RelationshipBangla from "../../utils/RelationshipBangla";
import SonodTypeEnToBn from "../../utils/SonodTypeEnToBn";

const FamilySonod = ({ certificate }) => {
  const createdAt = new Date(certificate?.createdAt).toISOString().split('T')[0];
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', flexDirection: 'column' }}>

        <div style={{ width: '' }}>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p className="pragraph">
            আবেদন আইডি নং / সনদ নং {EnglishToBanglaNumber(certificate?.citizen_id?.nid)}  এর প্রেক্ষিতে <br></br> উত্তরাধিকার সনদটি {certificate.citizen_id?.name_bangla}  এর <br></br> উত্তরাধিকারীগণ কে {EnglishToBanglaNumber(createdAt)} তারিখে  {certificate?.union_bn_name} <br></br> চেয়ারম্যান {certificate?.chairmanBanglaName} কর্তৃক প্রদান করা হয়েছে। <br></br> {SonodTypeEnToBn(`${certificate?.sonod_type}`)} তালিকা  নিম্নরুপঃ   
          </p>

          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <table>
            <tr style={{ height: "32pt" }}>
              <td className="tableData">
                <p className="pragraph1">নামঃ</p>
              </td>

              <td className="tableData3">
                <p
                  className="s3"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "15pt",
                    textAlign: "left",
                  }}
                >
                  সম্পর্কঃ
                </p>
              </td>
            </tr>

            {certificate?.family_information?.map((x) => (
              <tr style={{ height: "32pt" }}>
                <td className="tableData">
                  <p className="pragraph1">{x.memberNameBn}</p>
                </td>

                <td className="tableData3">
                  <p
                    className="s3"
                    style={{
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      lineHeight: "15pt",
                      textAlign: "left",
                    }}
                  >
                    {RelationshipBangla(`${x.memberRelation}`)}
                  </p>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default FamilySonod;
