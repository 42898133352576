import Button from "../button/Button";

const FindcitizensSearch = ({ onChangeGetValue, onClickSubmitButton, isLoading,setInputFildData,data}) => {
    return (
        <div>
            <h3 className="mt-0">নাগরিক খুজুন</h3>
            <div className="d-flex justify-content-between gap-5 mb-2">
                <div className="w-100">
                    <span className="content-title">
                        এনআইডি  বা মোবাইল নম্বর
                    </span>
                    <input
                        onChange={(e) => onChangeGetValue(e.target.value)}
                        type="text"
                        className="form-control"
                    />
                </div>
                <Button
                    btnClass='w-100'
                    clickFuntion={onClickSubmitButton}
                    isLoading={isLoading}
                    text="অনুসন্ধান"
                />

                <div
                    className="w-100 bg-white"
                    style={{ marginTop: '22px' }}
                >
                    {data ? (
                        <div className="d-flex justify-content-between">
                            <p className="text-success fs-5 border rounded  px-3 py-1">
                                {data?.name_bangla}
                            </p>
                            <button
                                onClick={() => setInputFildData(data)}
                                className="btn btn-md   rounded font-sm"
                            >
                                তথ্য যোগ করুন{' '}
                            </button>
                        </div>
                    ) : isLoading ? (
                        <p className=" p-2 fs-6 border rounded  px-3 py-1">তথ্য খোঁজা হচ্ছে ...</p>
                    ) : (
                        <p className=" p-2 fs-6 border rounded  px-3 py-1">কিছুই পাওয়া যায়নি</p>
                    )}
                </div>
            </div>
        </div>
    )
}
export default FindcitizensSearch;