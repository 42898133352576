/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaQuestionCircle } from "react-icons/fa";
import "../../assets/css/SignUp.css"
import { SingUpUnionSchema } from '../../helpers/auth/SignupSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetDistrictsQuery, useGetDivisionsQuery, useGetUnionsQuery, useGetUpazilasQuery } from '../../app/services/location';
import { useEmailVerifyMutation } from '../../app/services/register';
import SentEmailCode from './SentEmailCode';

const UnionSingUp = () => {
    const [showModal, setShowModal] = useState({
        open: false,
        data: null,
    });
    const [selectStoreDistricts, setSelectStoreDistricts] = useState([])
    const [selectStoreUpazilas, setSelectStoreUpazilas] = useState([])
    const [selectStoreUnions, setSelectStoreUnions] = useState([])
    const { data: divisions } = useGetDivisionsQuery()
    const { data: districts } = useGetDistrictsQuery()
    const { data: upazilas } = useGetUpazilasQuery()
    const { data: unions } = useGetUnionsQuery()

    const selectDistrict = async (e) => {
        e.preventDefault()
        const id = e.target.value;
        const result = await districts?.district?.filter(district => id === district?.division_id)
        setSelectStoreDistricts(result)
    }
    const selectUpdzilas = async (e) => {
        e.preventDefault()
        const id = e.target.value;
        const result = await upazilas?.upazila?.filter(upazila => id === upazila?.district_id)
        setSelectStoreUpazilas(result)
    }
    const selectUnion = async (e) => {
        e.preventDefault()
        const id = e.target.value;
        const result = await unions?.union?.filter(union => id === union?.upazilla_id)
        setSelectStoreUnions(result)
    }
    /* rtk  */
    const [sentEmailCode, { data: sentEmailCodeInfo }] = useEmailVerifyMutation()
    /* user from hook */
    const { register, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(SingUpUnionSchema) });

    const onSubmit = async (data) => {
        const division = await divisions?.division?.find(value => value?.id === data?.division)
        const district = await districts?.district?.find(value => value?.id === data?.district)
        const upazila = await upazilas?.upazila?.find(value => value?.id === data?.upazila)
        const union = await unions?.union?.find(value => value?.id === data?.union)
        const sentResult = {
            ...data, division: division?._id,
            district: district?._id,
            upazila: upazila?._id,
            union: union?._id,
            role: 'union'
        }
        await sentEmailCode(sentResult)
        setShowModal({
            open: true,
            data: sentResult,
        })
    }

    return (
        <>
            <div className='Signcontainer px-5 mx-auto'>
                <div className="hero min-h-screen bg-base-100">
                    <div className='row'>
                        <div className="col-lg-8 my-5">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='card mb-4'>
                                    <div className="card-body">
                                        <h3 className='title'>চেয়ারম্যানের তথ্য</h3>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 mb-3 mb-md-0">
                                                <label className="Slabel">
                                                    নাম (বাংলায়)  <span className='reqStart'>*</span>
                                                </label>
                                                <input type="text" {...register("chairmanBanglaName")} className="Sinput" />
                                                {errors.chairmanBanglaName && <span className='warning '>{errors.chairmanBanglaName.message}</span>}
                                            </div>

                                            <div className="col-lg-6 col-md-6 ">
                                                <label className="Slabel">নাম (ইংরেজিতে)  <span className='reqStart'>*</span>
                                                </label>
                                                <input type="text" {...register("chairmanEnglishName")} className="Sinput" />
                                                {errors.chairmanEnglishName && <span className='warning'>{errors.chairmanEnglishName.message}</span>}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-6 mb-3 mb-md-0">
                                                <label className="Slabel">
                                                    জাতীয় পরিচয়পত্র নম্বর  <span className='reqStart'>*</span>
                                                </label>
                                                <input type="number" {...register("chairmanNID")} className="Sinput" />
                                                {errors.chairmanNID && <span className='warning'>{errors.chairmanNID.message}</span>}
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <label className="Slabel">
                                                    মোবাইল নম্বর  <span className='reqStart'>*</span>
                                                </label>
                                                <input type="number" {...register("chairmanMobileNumber")} className="Sinput" />
                                                {errors.chairmanMobileNumber && <span className='warning'>{errors.chairmanMobileNumber.message}</span>}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-6 mb-3 mb-md-0">
                                                <label className="Slabel">
                                                    ইউনিয়ন নাম (বাংলায়)  <span className='reqStart'>*</span>
                                                </label>
                                                <input type="text" {...register("union_bn_name")} className="Sinput" />
                                                {errors.union_bn_name && <span className='warning'>{errors.union_bn_name.message}</span>}
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <label className="Slabel">
                                                    ইউনিয়ন নাম (ইংরেজিতে)  <span className='reqStart'>*</span>
                                                </label>
                                                <input type="text" {...register("union_en_name")} className="Sinput" />
                                                {errors.union_en_name && <span className='warning'>{errors.union_en_name.message}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card flex-shrink-0 w-full max-w-3xl shadow-2xl bg-base-100 mb-4'>
                                    <div className="card-body ml-2">
                                        <h3 className='title'>ইউনিয়ন পরিষদ সচিবের তথ্য </h3>
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-6 mb-3 mb-md-0">
                                                <label className="Slabel">
                                                    নাম (বাংলায়)  <span className='reqStart'>*</span>
                                                </label>
                                                <input type="text" {...register("secretaryBanglaName")} className="Sinput" />
                                                {errors.secretaryBanglaName && <span className='warning'>{errors.secretaryBanglaName.message}</span>}
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <label className="Slabel">
                                                    নাম (ইংরেজিতে)  <span className='reqStart'>*</span>
                                                </label>
                                                <input type="text" {...register("secretaryEnglishName")} className="Sinput" />
                                                {errors.secretaryEnglishName && <span className='warning'>{errors.secretaryEnglishName.message}</span>}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-6 mb-3 mb-md-0">
                                                <label className="Slabel">
                                                    জাতীয় পরিচয়পত্র নম্বর  <span className='reqStart'>*</span>
                                                </label>
                                                <input type="number" {...register("secretaryNID")} className="Sinput" />
                                                {errors.secretaryNID && <span className='warning'>{errors.secretaryNID.message}</span>}
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <label className="Slabel">
                                                    মোবাইল নম্বর  <span className='reqStart'>*</span>
                                                </label>
                                                <input type="number" {...register("secretaryMobileNumber")} className="Sinput" />
                                                {errors.secretaryMobileNumber && <span className='warning'>{errors.secretaryMobileNumber.message}</span>}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-6 mb-3 mb-md-0">
                                                <label className="Slabel">
                                                    ই-মেইল  <span className='reqStart'>*</span>
                                                </label>
                                                <input id='Sochibemail' type="email" {...register("secretaryEmail")} className="Sinput" />
                                                {errors.secretaryEmail && <span className='warning'>{errors.secretaryEmail.message}</span>}
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <label className="Slabel">
                                                    পাসওয়ার্ড  <span className='reqStart'>*</span>
                                                </label>
                                                <input type="password" {...register("secretaryPassword")} className="Sinput" />
                                                {errors.secretaryPassword && <span className='warning'> {errors.secretaryPassword.message}</span>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='card flex-shrink-0 w-full max-w-3xl shadow-2xl bg-base-100 mb-4'>
                                    <div className="card-body ml-2">
                                        <h3 className='title' >উদ্যোক্তার তথ্য</h3>
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-6 mb-3 mb-md-0">
                                                <label className="Slabel">
                                                    নাম (বাংলায়)  <span className='reqStart'>*</span>
                                                </label>
                                                <input type="text" {...register("entrepreneurBanglaName")} className="Sinput" />
                                                {errors.entrepreneurBanglaName && <span className='warning'>{errors.entrepreneurBanglaName.message}</span>}
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <label className="Slabel">
                                                    নাম (ইংরেজিতে)  <span className='reqStart'>*</span>
                                                </label>
                                                <input type="text" {...register("entrepreneurEnglishName")} className="Sinput" />
                                                {errors.entrepreneurEnglishName && <span className='warning'>{errors.entrepreneurEnglishName.message}</span>}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-6 mb-3 mb-md-0">
                                                <label className="Slabel">
                                                    জাতীয় পরিচয়পত্র নম্বর  <span className='reqStart'>*</span>
                                                </label>
                                                <input type="number" {...register("entrepreneurNID")} className="Sinput" />
                                                {errors.entrepreneurNID && <span className='warning'>{errors.entrepreneurNID.message}</span>}
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <label className="Slabel">
                                                    মোবাইল নম্বর  <span className='reqStart'>*</span>
                                                </label>
                                                <input type="number" {...register("entrepreneurMobileNumber")} className="Sinput" />
                                                {errors.entrepreneurMobileNumber && <span className='warning'>{errors.entrepreneurMobileNumber.message}</span>}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-6 mb-3 mb-md-0">
                                                <label className="Slabel">
                                                    ই-মেইল  <span className='reqStart'>*</span>
                                                </label>
                                                <input id='udokktaEmail' type="email" {...register("entrepreneurEmail")} className="Sinput" />
                                                {errors.entrepreneurEmail && <span className='warning'>{errors.entrepreneurEmail.message}</span>}
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <label className="Slabel">
                                                    পাসওয়ার্ড  <span className='reqStart'>*</span>
                                                </label>
                                                <input type="password" {...register("entrepreneurPassword")} className="Sinput" />
                                                {errors.entrepreneurPassword && <span className='warning'> {errors.entrepreneurPassword.message}</span>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='card flex-shrink-0 w-full max-w-3xl shadow-2xl bg-base-100'>
                                    <div className="card-body ml-2">
                                        <h3 className='title' >নিয়োগ এলাকা তথ্য</h3>
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-6">
                                                <label className="Slabel">
                                                    বিভাগ  <span className='reqStart'>*</span>
                                                </label>
                                                <select className="Sinput"
                                                    {...register("division")}
                                                    onChange={(e) => selectDistrict(e)}
                                                >
                                                    <option value=''>পছন্দ করুন বিভাগ</option>
                                                    {
                                                        divisions?.division?.map(division => <option key={division?._id} value={division?.id}>{division?.name} / {division?.bn_name}</option>
                                                        )
                                                    }
                                                </select>
                                                {errors.division && <span className='warning'>{errors.division.message}</span>}
                                            </div>
                                            <div className="col-lg-6 col-md-6 mb-3 mb-md-0">
                                                <label className="Slabel">
                                                    জেলা  <span className='reqStart'>*</span>
                                                </label>
                                                <select className="Sinput"
                                                    {...register("district")}
                                                    onChange={(e) => selectUpdzilas(e)}

                                                >
                                                    <option value=''>পছন্দ করুন জেলা</option>
                                                    {
                                                        selectStoreDistricts?.map(district => <option key={district?._id} value={district?.id}>{district?.name} / {district?.bn_name}</option>
                                                        )
                                                    }
                                                </select>
                                                {errors.district && <span className='warning'>{errors.district.message}</span>}

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-6">
                                                <label className="Slabel">
                                                    থানা / উপজেলা  <span className='reqStart'>*</span>
                                                </label>
                                                <select className="Sinput"
                                                    {...register("upazila")}
                                                    onChange={(e) => selectUnion(e)}
                                                >
                                                    <option value=''>পছন্দ করুন থানা / উপজেলা</option>
                                                    {
                                                        selectStoreUpazilas?.map(upazila => <option key={upazila?._id} value={upazila?.id}>{upazila?.name} / {upazila?.bn_name}</option>
                                                        )
                                                    }
                                                </select>
                                                {errors.upazila && <span className='warning'>{errors.upazila.message}</span>}

                                            </div>
                                            <div className="col-lg-6 col-md-6 mb-3 mb-md-0">
                                                <label className="Slabel">
                                                    ইউনিয়ন  <span className='reqStart'>*</span>
                                                </label>
                                                <select className="Sinput"
                                                    {...register("union")}
                                                >
                                                    <option value=''>পছন্দ করুন ইউনিয়ন</option>
                                                    {
                                                        selectStoreUnions?.map(union => <option key={union?._id} value={union?.id}>{union?.name} / {union?.bn_name}</option>
                                                        )
                                                    }
                                                </select>
                                                {errors.union && <span className='warning'>{errors.union.message}</span>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <input 
                                    className="btn btn-primary bg-fuchsia-700 text-white mt-4" type="submit" value=" নিবন্ধন করুন"
                                />
                            </form>
                        </div>
                        <div className='col-lg-4 my-5 '>
                            <h4 className="rightTitle" ><FaQuestionCircle /><span>নির্দেশনা</span></h4>
                            <div className='px-2 rightItems'>
                                <p><span className='list'>.</span> (*) চিহ্নিত ক্ষেত্রগুলি পূরণ করা বাধ্যতামূলক। অন্যগুলো ঐচ্ছিক।</p>
                                <p><span className='list'>.</span> আপনার মোবাইল ফোন নম্বর এর মোট সংখ্যা হবে ১১ ডিজিট।</p>
                                <p><span className='list'>.</span> আপনার জাতীয় পরিচয়পত্রের মোট সংখ্যা হবে ১০ অথবা ১৭ ডিজিট।।</p>

                                <p><span className='list'>.</span> আপনার সঠিক একাউন্ট ধরন নির্বাচন করুন।</p>
                                <p><span className='list'>.</span> তথ্য জমা দেয়ার পর একটি যাচাইকরণ কোড আপনার মোবাইলে পাঠানো হবে।</p>
                                <p><span className='list'>.</span> তথ্য জমা দেওয়ার পর আপনার মোবাইলে একটি যাচাইকরণ কোড প্রেরণ করা হবে।</p>
                                <p><span className='list'>.</span> আপনার প্রেরিত তথ্য যাচাই করার পর আপনার অ্যাকাউন্ট দিয়ে আপনি কার্যক্রম পরিচালনা করতে পারবেন।</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SentEmailCode showModal={showModal} setShowModal={setShowModal}
                sentEmailCodeInfo={sentEmailCodeInfo}
            />
        </>


    );
};

export default UnionSingUp;


