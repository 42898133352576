import * as yup from "yup";
export const CreateUnionLocationSchema = yup.object().shape({
    upazilla_id: yup.string().required("খালি রাখা যাবে না"),
    bn_name: yup
        .string()
        .required("খালি রাখা যাবে না.")
        .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),

    name: yup
        .string()
        .required("English Name Field Can Not Be Empty.")
        .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),
})