import React, { useState } from "react";
import DashboardLayout from "../../components/layout/DashboardLayout";
import { roleNameBangla } from "../../utils/roleNameBangla";
import { useSelector } from "react-redux";
import { useGetProfileUserQuery } from "../../app/services/authUser";
import EnglishToBanglaNumber from "../../utils/EnglishToBanglaNumber";

import { FaPencilAlt } from "react-icons/fa";
import EditSecretaryModal from "../../components/elements/profile/modal/EditSecretaryModal";
import EditLogoModal from "../../components/elements/profile/modal/EditLogoModal";
export default function Profile() {
  const [secretaryEditModal, setSecretaryEditModal] = useState({
    type: false,
    data: null,
  });
  const [logoModal, setLogoModal] = useState({
    type: false,
    data: null,
    field: "",
  });
  const { userInfo } = useSelector((state) => state.auth);
  const { data } = useGetProfileUserQuery();
  const result = {};

  return (
    <DashboardLayout>
      <section className="content-main">
        <div className="content-header">
          <div className="d-flex justify-content-between w-100">
            <h2 className="content-title card-title">
              {roleNameBangla(userInfo?.users?.role)} প্রোফাইল
            </h2>
            <button
              onClick={() =>
                setSecretaryEditModal({ type: !secretaryEditModal.type, data })
              }
              className="btn btn-md  d-flex align-items-center "
            >
              <FaPencilAlt className="me-2 text-sm" /> তথ্য আপডেট করুন
            </button>
          </div>
        </div>
        {userInfo?.users?.role === "secretary" && (
          <div className="card p-4">
            <h3 className="mb-1"> সচিবের তথ্য</h3>

            <div className=" row  gy-4">
              <div className="col-md-3">
                <p className="mb-0">নাম (বাংলায়)</p>
                <h6>{data?.secretaryBanglaName}</h6>
              </div>
              <div className="col-md-3">
                <p className="mb-0">ইমেইল</p>
                <h6>{data?.secretaryEmail}</h6>
              </div>

              <div className="col-md-3">
                <p className="mb-0">মোবাইল নম্বর</p>
                <h6>
                  {EnglishToBanglaNumber(
                    "0" + data?.secretaryMobileNumber.toString()
                  )}
                </h6>
              </div>

              <div className="col-md-3">
                <p className="mb-0"> এনআইডি নং</p>
                <h6>{EnglishToBanglaNumber(data?.secretaryNID.toString())}</h6>
              </div>
            </div>
          </div>
        )}
        <div className="card  p-4">
          <h3 className="mb-1"> উদ্যোক্তার তথ্য</h3>

          <div className=" row gy-4  ">
            <>
              <div className="col-md-3">
                <p className="mb-0"> নাম </p>
                <h6>{data?.secretaryBanglaName}</h6>
              </div>

              <div className="col-md-3">
                <p className="mb-0">ইমেইল</p>
                <p>{data?.entrepreneurEmail}</p>
              </div>
              <div className="col-md-3">
                <p className="mb-0">মোবাইল নম্বর</p>
                <h6>
                  {EnglishToBanglaNumber(
                    "0" + data?.secretaryMobileNumber.toString()
                  )}
                </h6>
              </div>
              <div className="col-md-3">
                <p className="mb-0"> এনআইডি নং</p>
                <h6>{EnglishToBanglaNumber(data?.secretaryNID.toString())}</h6>
              </div>
            </>
          </div>
        </div>
        <div className="card  p-4 ">
          <h3 className="mb-1">ইউনিয়নের তথ্য </h3>

          <div className=" row gy-4 ">
            <>
              <div className="col-md-3">
                <p className="mb-0">ইউনিয়ন </p>
                <h6>{data?.union_bn_name}</h6>
              </div>

              <div className="col-md-3">
                <p className="mb-0">উপজেলা </p>
                <h6>{data?.upazila.bn_name}</h6>
              </div>
              <div className="col-md-3">
                <p className="mb-0"> জেলা</p>
                <h6>{data?.district.bn_name}</h6>
              </div>
              <div className="col-md-3">
                <p className="mb-0">বিভাগ</p>
                <h6>{data?.division.bn_name}</h6>
              </div>
              <div className="col-md-3">
                <p className="mb-0"> চেয়ারম্যান </p>
                <h6>{data?.chairmanBanglaName}</h6>
              </div>
              <div className="col-md-3">
                <p className="mb-0"> চেয়ারম্যান মোবা </p>
                <h6>
                  {EnglishToBanglaNumber(
                    "0" + data?.chairmanMobileNumber.toString()
                  )}
                </h6>
              </div>
            </>
          </div>
        </div>
        <div className="card  p-4">
          <h3 className="mb-1">সার্টিফিকেট লোগো </h3>

          <div className=" row gy-4  ">
            <>
              <div className="col-md-3">
                <div className="d-flex mb-1 justify-content-between">
                  <h5 className="mb-0">হেড লোগো </h5>
                  <button
                    onClick={() =>
                      setLogoModal({
                        type: !logoModal?.type,
                        data,
                        field: "headLogo",
                      })
                    }
                    className="border-0 rounded "
                  >
                    <FaPencilAlt />{" "}
                  </button>
                </div>
                <img src={data?.headLogo} className="border p-1" />
              </div>

              <div className="col-md-3">
                <div className="d-flex mb-1 justify-content-between">
                  <h5 className="mb-0">পেজ লোগো</h5>
                  <button
                    onClick={() =>
                      setLogoModal({
                        type: !logoModal?.type,
                        data,
                        field: "pageLogo",
                      })
                    }
                    className="border-0 rounded "
                  >
                    <FaPencilAlt />{" "}
                  </button>
                </div>

                <img src={data?.pageLogo} className="border p-1" />
              </div>
            </>
          </div>
        </div>
      </section>

      <EditSecretaryModal
        modal={secretaryEditModal}
        setModal={setSecretaryEditModal}
      />
      <EditLogoModal modal={logoModal} setModal={setLogoModal} />
    </DashboardLayout>
  );
}
