import React, { useEffect, useMemo, useState } from 'react';
import DashboardLayout from '../../../components/layout/DashboardLayout';
import GetSpinner from '../../../helpers/shared/GetSpinner';
import Pagination from '../../../components/elements/Pagination';
import { useAllUserGetQuery } from '../../../app/services/authUser';
import CreateCitizenModal from './modal/CreateCitizenModal';
import { useGetAllCitizenQuery } from '../../../app/services/citizen';
import SingleCitizen from './SingleCitizen';
import ApproveCitizenModal from './modal/ApproveCitizenModal';
import { useSelector } from 'react-redux';
import EditCitizenModal from './modal/EditCitizenModal';
import Search from '../../../utils/Search';
function Citizen() {
  const { userInfo } = useSelector((state) => state.auth);
  const contects = {
    title: 'নতুন নাগরিক',
  };
  const [citizenCreateModal, setCitizenCreateModal] = useState({
    type: false,
    data: null,
  });
  const [search, setSearchValue] = useState('');
  const [status, setStatus] = useState({ type: false, data: null });
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const pathname = `approve=${true}&page=${pageIndex}&limit=${pageSize}&search=${search}`;
  const { data, isLoading } = useAllUserGetQuery(pathname);
  const users = useMemo(() => (data ? data?.users : []), [data, search]);
  const { data: citizens, isLoading: loading } = useGetAllCitizenQuery(pathname);

  const accessFunction = ['entrepreneur', 'secretary']


  return (
    <DashboardLayout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">নাগরিক</h2>
          </div>
        </div>
        <div className="card mb-4">
          <header className="card-header">
            <div className="row align-items-center">
              <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                <Search setSearchValue={setSearchValue}  placeholder={"অনুসন্ধান করুন"}/>
              </div>
              {
                userInfo?.users?.role !== 'admin' && <div className="col-md-3 col-12 ms-auto mb-md-0 mb-3">
                  <p
                    onClick={() =>
                      setCitizenCreateModal({ type: !citizenCreateModal.type })
                    }
                    className="btn btn-primary btn-sm rounded"
                  >
                    নতুন তৈরি করুন
                  </p>
                </div>
              }
            </div>
          </header>
          <div className="col-md-12 px-4 ">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr className="">
                    <th>নাম</th>
                    <th>আইডি</th>
                    <th>মোবাইল নাম্বার</th>
                    <th>লিঙ্গ</th>
                    <th>অবস্থা</th>
                    {
                      accessFunction?.includes(userInfo?.users?.role) && <th className="text-end">পদক্ষেপ</th>
                    }

                  </tr>
                </thead>
                <tbody>
                  {loading && <GetSpinner />}
                  {citizens?.data?.map((user) => (
                    <SingleCitizen user={user} setStatus={setStatus} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Pagination
          totalPages={citizens?.totalPages}
          setPagination={setPagination}
          pageIndex={pageIndex}
          pageSize={pageSize}
        />
      </section>
      {userInfo?.users?.role === 'secretary' ? (
        <ApproveCitizenModal modal={status} setOpen={setStatus} />
      ) : userInfo?.users?.role === 'entrepreneur' ? (
        <EditCitizenModal modal={status} setOpen={setStatus} />
      ) : (
        ''
      )}
      <CreateCitizenModal
        modal={citizenCreateModal}
        setOpen={setCitizenCreateModal}
        contects={contects}
      />
    </DashboardLayout>
  );
}

export default Citizen;
