import ReactToPrint from 'react-to-print';
import styles from './BnParibarikSonod.module.css';
import { useRef } from 'react';
import DashboardLayout from '../../../../layout/DashboardLayout';
import { useParams } from 'react-router-dom';
import { useGetSingleCertificateQuery } from '../../../../../app/services/certificate';
import EnglishToBanglaNumber from '../../../../../utils/EnglishToBanglaNumber';
import { connect } from 'react-redux';
import RelationshipBangla from '../../../../../utils/RelationshipBangla';
import { CertificateHeaderBn } from '../../CertificateHeader/CertificateHeader';
import { CertificateFooterBn } from '../../CertificateFooter/CertificateFooter';
import { useState } from 'react';
import { useEffect } from 'react';
import { RefetchData } from '../../../RefetchData';
import GetSpinner from '../../../../../helpers/shared/GetSpinner';
import CertificatePagesLogo from '../../CertificatePagesLogo/CertificatePagesLogo';
const BnParibarikSonod = ({ userInfo }) => {
  const maxRetries = 3;
  const { id } = useParams();
  const componentRef = useRef();
  const [retryCount, setRetryCount] = useState(0);
  const { data, isLoading, isError, refetch } = useGetSingleCertificateQuery(id);
  const accountType = userInfo?.account
  const certificate = data?.certificate;
  useEffect(() => {
    RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
  }, [isError, retryCount, refetch]);

  return (
    <DashboardLayout>
      {
        isLoading ? <GetSpinner /> : <div>
          <div className="download_pdf d-flex justify-content-end ">
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-md justify-content-center">
                  প্রিন্ট করুন
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div className={styles.body} ref={componentRef}>
            <div className={styles.container} >
              <div className={styles.content_position}>
                <CertificateHeaderBn certificate={certificate} sonodName={'পারিবারিক সনদ'} />
                <div className={styles.main}>
                  <p className={styles.mainText}>
                    এই মর্মে প্রত্যয়ন করা যাইতেছে যে,{' '}
                    <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.name_bangla}</b>,{' '}  পিতা/স্বামীঃ {' '}
                    <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.father_husband_bangla}</b>, মাতাঃ {' '}
                    <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.mother_name_bangla}</b>, অত্র এলাকার একজন স্থায়ী
                    বাসিন্দা। তাহার গ্রাম/বাড়ীঃ- {' '}{certificate?.citizen_id?.permanent_address[0]?.village_bn_name}, হোল্ডিং নং {certificate?.citizen_id?.permanent_address[0]?.village_bn_name}, ডাকঘরঃ{' '}
                    {certificate?.citizen_id?.permanent_address[0]?.post_office_bn}, ওয়ার্ড নম্বরঃ {EnglishToBanglaNumber(`${certificate?.citizen_id?.permanent_address[0]?.ward_no}`)},

                    থানা/উপজেলাঃ {certificate?.citizen_id?.permanent_address[0]?.upazila?.bn_name}, জেলাঃ{' '}
                    {certificate?.citizen_id?.permanent_address[0]?.district?.bn_name}। উল্লেখিত ব্যক্তির পরিবারের
                    সদস্যগণের তালিকা নিম্নরূপঃ
                  </p>
                  <div className={styles.infoTable}>
                    <div className={styles.grid_container}>
                      <h5>ক্রমিক নং </h5>
                      <h5 className={styles.span_2}>নাম </h5>
                      <h5 className={styles.span_2}>এনআইডি / জন্ম নিবন্ধন</h5>
                      <h5>জন্ম তারিখ</h5>
                      <h5>সম্পর্ক</h5>
                    </div>
                    {
                      certificate?.family_information.map((familyDdata, index) => <div
                        key={data._id}
                        className={styles.grid_container}>
                        <p>{EnglishToBanglaNumber(`${index + 1}`)}</p>
                        <p className={styles.span_2}>{familyDdata?.memberNameBn}</p>
                        <p className={`${styles.span_2} mb-0`}>
                          {
                            familyDdata?.memberNidDeath ? EnglishToBanglaNumber(familyDdata?.memberNidDeath) : ""
                          }
                        </p>
                        <p >{EnglishToBanglaNumber(`${familyDdata?.memberDate}`)}</p>


                        <p>{RelationshipBangla(familyDdata?.memberRelation)}</p>
                      </div>)
                    }
                  </div>

                  <div className={styles.mainText}>
                    <p>
                    উল্লেখিত ব্যক্তি / ব্যক্তিগণ ছাড়া আর কোনো ওয়ারিশান নাই। সকল
                      প্রকার দেনাপাওনা / সম্পত্তি / বিষয় নিস্পত্তি করার ব্যাপারে
                      কেবলমাত্র উল্লেখিত ব্যক্তি / ব্যক্তিগণই ওয়ারিশান হিসেবে
                      বিবেচিত হইবে।
                    </p>
                    <p>আমি সকল ওয়ারিশগণের মঙ্গল কামনা করিতেছি। </p>
                    <p className={styles.specialNote}>
                      <b> বিঃদ্রঃ </b> : আবেদনকারী ‍যদি ইচ্ছাকৃতভাবে কোন ওয়ারিশান
                      বাদ দিয়ে থাকেন বা কোন ভুল তথ্য প্রদান করিয়া থাকেন তাহলে তিনি
                      দ্বায়ী থাকিবেন। তাহার জন্য চেয়ারম্যান কার্যালয় কোন ভাবেই
                      দ্বায়ী থাকিবেন না এবং উক্ত সনদ বাতিল বলিয়া গণ্য করা হইবে ।
                    </p>
                  </div>
                </div>
                <CertificateFooterBn certificate={certificate} accountType={accountType} marginHandel={false}
                  sigbature={true}
                  sigbatureConecnt="সনাক্তকারী"
                />
              </div>

              <CertificatePagesLogo
                certificate={certificate}
                toValue={'100px'}
              />
            </div>
          </div>
        </div>
      }

    </DashboardLayout>
  );
};


function mapStateToProps(state) {
  return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(BnParibarikSonod)