import EnglishToBanglaNumber from "../../../utils/EnglishToBanglaNumber";

const HoldingTaxdownloadTable = ({ data }) => {
    const totalAmountSum = data.reduce((accumulator, currentValue) => accumulator + (currentValue.total_amount || 0), 0);
    return (
        <table id="customers">
            <tr>
                <th>ক্রমিক নং</th>
                <th>নাম</th>
                <th>পিতা/স্বামীর নাম</th>
                <th>গ্রাম</th>
                <th>ওয়ার্ড নং</th>
                <th>হোল্ডিং নং</th>
                <th>সম্পদের বিবরণ (গৃহের)</th>
                <th>বার্ষিক মূল্যায়ন</th>
                <th>কর নির্ধারণের হার %</th>
                <th>বার্ষিক করের পরিমান</th>
            </tr>
            {
                data?.map((dt, index) => <tr>
                    <td>{EnglishToBanglaNumber(`${index + 1}`)}</td>
                    <td>{dt?.name_bangla}</td>
                    <td>{dt?.father_husband_bangla}</td>
                    <td>{dt?.village_bn_name}</td>
                    <td>{EnglishToBanglaNumber(`${dt?.ward_no}`)}</td>
                    <td>{EnglishToBanglaNumber(`${dt?.holding_no}`)}</td>
                    <td>{dt?.home_type}</td>
                    <td>{EnglishToBanglaNumber(`${dt?.amount_as_schedule}`)}</td>
                    <td>{EnglishToBanglaNumber(`${dt?.actual_tax_amount ? dt?.actual_tax_amount : '0'}`)}</td>
                    <td>{EnglishToBanglaNumber(`${dt?.total_amount}`)}</td>
                </tr>)
            }
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>মোট টাকা</td>
            <td>{EnglishToBanglaNumber(`${totalAmountSum}`)}</td>
           
        </table>
    )

}
export default HoldingTaxdownloadTable;