import EnglishToBanglaNumber from "../../../utils/EnglishToBanglaNumber";

const HoldingTaxdownloadHeader = () => {
    const d = new Date();
    let year = d.getFullYear();


    return (
        <header className="holdintax_header">
            <p>ইউনিয়ন ফরম-১১</p>
            <p>ইউনিয়ন কর ধার্য্য রেজিষ্টার</p>
            <p>আইয়ূ্বপুর ইউনিয়ন পরিষদ</p>
            <p>বাঞ্ছারামপুর, ব্রাহ্মণবাড়িয়া</p>
            <p>আদায়কৃত হোল্ডিং ট্যাক্সের তালিকা</p>
            <p>অর্থ বছর-{EnglishToBanglaNumber(`${(year- 1)}-${year}`)}</p>
        </header>
    )

}
export default HoldingTaxdownloadHeader;