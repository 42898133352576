export default function EnFormatDate() {
  const d =  new Date();
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  return new Intl.DateTimeFormat("en-US", options).format(d);
}

