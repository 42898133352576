import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { allSonodCertificateSchema } from '../../../../helpers/validation/AllSonodCertificateSchema';
import { useGetSingleCitizenMutation } from '../../../../app/services/citizen';
import { useEffect } from 'react';
import { usePostCertificateMutation } from '../../../../app/services/certificate';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import FindcitizensSearch from '../../../findcitizens/FindcitizensSearch';
import Button from '../../../button/Button';
/*  */
function ALlSonodCreate({
  contects,
  modal,
  setOpen,
  userInfo,
  addisLoading,
}) {
  const { type } = modal;
  const [addressName, setAddressName] = useState(true)
  const [userData, setUserData] = useState(false);
  const [nidOrNumber, setNidOrNumber] = useState('');
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(allSonodCertificateSchema) });

  const [getCitizen, { data, isLoading, isSuccess, isError, error }] =
    useGetSingleCitizenMutation();

  const handleSearchCitizen = async () => {
    if (nidOrNumber) {
      const searchData = { nidOrNumber };
      await getCitizen(searchData);
    }
  };
  const [
    createCertificate,
    { isSuccess: isSucc, isLoading: loading, isError: isErr, error: err },
  ] = usePostCertificateMutation();





  const createOnSubmit = async (certificateData) => {
    const certificateDataInfo = {
      ...certificateData,
      citizen_id: userData?._id,
      sonod_type: contects?.sonod_type,
      present_address: userData?.present_address[0],
      permanent_address: userData?.permanent_address[0]

    }
    await createCertificate(certificateDataInfo);
  };

  useEffect(() => {
    if (userData) {
      setValue('sonod_type', userData?.sonod_type);
      setValue('nid', userData?.nid);
      setValue('number', userData?.number);
      setValue('name_bangla', userData?.name_bangla);
      setValue('name_english', userData?.name_english);
      setValue('father_husband_bangla', userData?.father_husband_bangla);
      setValue('father_husband_english', userData?.father_husband_english);
      setValue('mother_name_bangla', userData?.mother_name_bangla);
      setValue('mother_name_english', userData?.mother_name_english);
      setValue('date_of_birth', userData?.date_of_birth);
      setValue('gender', userData?.gender);
      setValue('blood_group', userData?.blood_group);
      setValue('district', userData?.permanent_address[0]?.district?.bn_name);
      setValue('division', userData?.permanent_address[0]?.division?.bn_name);
      setValue('upazila', userData?.permanent_address[0]?.upazila?.bn_name);
      setValue('union', userData?.permanent_address[0]?.union?.bn_name);
      setValue('municipality_bn_name', userData?.permanent_address[0]?.municipality_bn_name);
      setValue('municipality_en_name', userData?.permanent_address[0]?.municipality_en_name);
      setValue('post_office_bn', userData?.permanent_address[0]?.post_office_bn);
      setValue('post_office_en', userData?.permanent_address[0]?.post_office_en);
      setValue('village_bn_name', userData?.permanent_address[0]?.village_bn_name);
      setValue('village_en_name', userData?.permanent_address[0]?.village_en_name);
      setValue('holding_no', userData?.permanent_address[0]?.holding_no);
      setValue('ward_no', userData?.permanent_address[0]?.ward_no);
      setValue('road', userData?.permanent_address[0]?.road);
      setValue('pdistrict', userData?.present_address[0]?.district?.bn_name);
      setValue('pdivision', userData?.present_address[0]?.division?.bn_name);
      setValue('pupazila', userData?.present_address[0]?.upazila?.bn_name);
      setValue('punion', userData?.present_address[0]?.union?.bn_name);
      setValue('pmunicipality_bn_name', userData?.present_address[0]?.municipality_bn_name);
      setValue('pmunicipality_en_name', userData?.present_address[0]?.municipality_en_name);
      setValue('ppost_office_bn', userData?.present_address[0]?.post_office_bn);
      setValue('ppost_office_en', userData?.present_address[0]?.post_office_en);
      setValue('pvillage_bn_name', userData?.present_address[0]?.village_bn_name);
      setValue('pvillage_en_name', userData?.present_address[0]?.village_en_name);
      setValue('pholding_no', userData?.present_address[0]?.holding_no);
      setValue('pward_no', userData?.present_address[0]?.ward_no);
      setValue('proad', userData?.present_address[0]?.road);
    }
  }, [userData, setValue]);


  useEffect(() => {
    if (isSucc) {
      toast.success('Success', { id: 'create' });
      setOpen({ type: false },
        reset())
    }
    if (isErr) {
      toast.error('errror', { id: 'create' });
    }
  }, [isSucc, isErr, err]);
  return (
    <>
      {type && (
        <div className="modal_product">
          <section className="content-mainDash">
            <div className="row">
              <div className="col-12">
                <div className="content-header" >
                  <h2 className="content-title">{contects?.title}</h2>
                  <button onClick={() => setOpen({ type: false }, reset())}>
                    X
                  </button>
                </div>
                <FindcitizensSearch
                  onChangeGetValue={setNidOrNumber}
                  onClickSubmitButton={handleSearchCitizen}
                  isLoading={isLoading}
                  setInputFildData={setUserData}
                  data={data?.data}
                />
              </div>
              <form onSubmit={handleSubmit(createOnSubmit)}>
                <div className="col-lg-12">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row gx-2">
                        <div className="col-6 mb-3">
                          <label className="form-label">
                            নাম: (বাংলা){' '}
                            <span className="from_required">*</span>{' '}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="নাম বাংলা"
                            {...register('name_bangla', { required: true })}
                          />
                          {errors?.name_bangla && (
                            <span className="form__error">
                              {errors?.name_bangla.message}
                            </span>
                          )}
                        </div>

                        <div className="col-lg-6 mb-3">
                          <label className="form-label">
                            নাম: (ইংরেজিতে){' '}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="নাম ইংরেজিতে"
                            {...register('name_english', { required: true })}
                          />
                          {errors?.name_english && (
                            <span className="form__error">
                              {errors?.name_english.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="row gx-2">
                        <div className="col-lg-6 mb-3">
                          <label className="form-label">
                            পিতা / স্বামীর নাম: (বাংলা){' '}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="পিতা / স্বামীর নাম বাংলা"
                            {...register('father_husband_bangla', {
                              required: true,
                            })}
                          />
                          {errors?.father_husband_bangla && (
                            <span className="form__error">
                              {errors?.father_husband_bangla.message}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label className="form-label">
                            পিতা / স্বামীর নাম: (ইংরেজিতে){' '}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="পিতা / স্বামীর নাম ইংরেজিতে"
                            {...register('father_husband_english', {
                              required: true,
                            })}
                          />
                          {errors?.father_husband_english && (
                            <span className="form__error">
                              {errors?.father_husband_english.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="row gx-2">
                        <div className="col-lg-3 mb-3">
                          <label className="form-label">
                            মাতার নাম: (বাংলা){' '}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="মাতার নাম বাংলা"
                            {...register('mother_name_bangla', {
                              required: true,
                            })}
                          />
                          {errors?.mother_name_bangla && (
                            <span className="form__error">
                              {errors?.mother_name_bangla.message}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3 mb-3">
                          <label className="form-label">
                            মাতার নাম: (ইংরেজিতে){' '}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="মাতার নাম ইংরেজিতে"
                            {...register('mother_name_english', {
                              required: true,
                            })}
                          />
                          {errors?.mother_name_english && (
                            <span className="form__error">
                              {errors?.mother_name_english.message}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3 mb-3">
                          <label className="form-label">
                            মোবাইল নম্বর{' '}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            placeholder="মোবাইল নম্বর"
                            {...register('number', { required: true })}
                          />
                          {errors?.number && (
                            <span className="form__error">
                              {errors?.number.message}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3 mb-3">
                          <label className="form-label">
                            জাতীয় পরিচয়পত্র নং{' '}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            placeholder="জাতীয় পরিচয়পত্র নং"
                            {...register('nid', { required: true })}
                          />
                          {errors?.nid && (
                            <span className="form__error">
                              {errors?.nid.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="row gx-2">
                        <div className="col-lg-3 mb-3">
                          <label className="form-label">
                            জন্ম তারিখ <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="date"
                            placeholder="জন্ম তারিখ"
                            {...register('date_of_birth', { required: true })}
                          />
                          {errors?.date_of_birth && (
                            <span className="form__error">
                              {errors?.date_of_birth.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-3 mb-3">
                          <label htmlFor="Promo_amount" className="form-label">
                            লিঙ্গ <span className="from_required">*</span>
                          </label>
                          <select
                            className="form-select"
                            {...register('gender', { required: true })}
                          >
                            <option selected value="">
                              লিঙ্গ নির্বাচন করুন
                            </option>

                            <option value="male">পুরুষ</option>
                            <option value="woman">মহিলা</option>
                            <option value="ananya">অনন্যা</option>
                          </select>
                          {errors?.gender && (
                            <span className="form__error">
                              {errors?.gender.message}
                            </span>
                          )}
                        </div>
                        {errors?.zip_code && (
                          <span className="form__error">
                            {errors?.zip_code.message}
                          </span>
                        )}
                        <div className="col-lg-3 mb-3">
                          <label className="form-label">রক্তের গ্রুপ</label>
                          <select
                            className="form-select"
                            {...register('blood_group', { required: true })}
                          >
                            <option selected value="">
                              রক্তের গ্রুপ নির্বাচন করুন
                            </option>

                            <option value={'O+'}>ও+</option>
                            <option value={'O-'}>ও−</option>
                            <option value={'A-'}>এ−</option>
                            <option value={'A+'}>এ+</option>
                            <option value={'B+_'}>বি−</option>
                            <option value={'B+'}>বি+</option>
                            <option value={'AB-'}>এবি−</option>
                            <option value={'AB+'}>এবি+</option>
                          </select>
                        </div>


                      </div>

                      <div className='d-flex gap-4'>
                        <div className='mb-2 d-flex gap-2'>
                          <input id='sthayiAddress' name='sthayiAddress' onClick={() => setAddressName(true)} type="checkbox" checked={addressName} />
                          <label htmlFor='sthayiAddress'>স্থায়ী ঠিকানা</label>
                        </div>

                        <div className='mb-2 d-flex gap-2'>
                          <input id="bortomanAddress" name='bortomanAddress' onClick={() => setAddressName(false)} type="checkbox" checked={!addressName} />
                          <label htmlFor='bortomanAddress'>বর্তমান ঠিকানা</label>
                        </div>
                      </div>

                      {
                        addressName ? <div className="row gx-3">
                          <div className="col-lg-3">
                            <label className="form-label">
                              বিভাগ
                              <span className="from_required">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              {...register('division')}
                            />
                          </div>
                          <div className="col-lg-3">
                            <label htmlFor='prDistrict' className="form-label">
                              জেলা
                              <span className="from_required">*</span>
                            </label>
                            <input
                              id='prDistrict'
                              name="prDistrict"
                              className="form-control"
                              type="text"
                              {...register('district')}
                            />
                          </div>
                          <div className="col-lg-3">
                            <label htmlFor='prUpazila' className="form-label">
                              থানা / উপজেলা
                              <span className="from_required">*</span>
                            </label>
                            <input
                              id='prUpazila'
                              name='prUpazila'
                              className="form-control"
                              type="text"
                              {...register('upazila')}
                            />
                          </div>

                          {
                            userInfo?.account === 'municipality' ? <>
                              <div className="col-lg-3 mb-2">
                                <label className="form-label">
                                  পৌরসভা (বাংলা)
                                  <span className="from_required">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  {...register('municipality_bn_name')}
                                />
                              </div>
                              <div className="col-lg-3 mb-2">
                                <label className="form-label">
                                  পৌরসভা (ইংরেজিতে)
                                  <span className="from_required">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  {...register('municipality_en_name')}
                                />
                              </div>
                            </> : <>
                              <div className="col-lg-3 mb-2">
                                <label className="form-label">
                                  ইউনিয়ন
                                  <span className="from_required">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  {...register('union')}
                                />
                              </div>
                            </>
                          }


                          <div className="col-lg-3 mb-2">
                            <label htmlFor='prPost_office_bn' className="form-label">
                              ডাকঘর (বাংলা)
                              <span className="from_required">*</span>{' '}
                            </label>
                            <input
                              id='prPost_office_bn'
                              name='prPost_office_bn'
                              className="form-control"
                              type="text"
                              {...register('post_office_bn')}

                            />
                            {errors?.post_office && (
                              <span className="form__error">
                                {errors?.post_office.message}
                              </span>
                            )}
                          </div>
                          <div className="col-lg-3 mb-2">
                            <label htmlFor='prPost_office_en' className="form-label">
                              ডাকঘর (ইংরেজিতে){' '}
                              <span className="from_required">*</span>{' '}
                            </label>
                            <input
                              id='prPost_office_en'
                              name='prPost_office_en'
                              className="form-control"
                              type="text"
                              {...register('post_office_en')}

                            />
                            {errors?.post_office && (
                              <span className="form__error">
                                {errors?.post_office.message}
                              </span>
                            )}
                          </div>

                          <div className="col-lg-3 mb-3">
                            <label className="form-label">
                              ওয়ার্ড নং <span className="from_required">*</span>{' '}
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Zip code "
                              {...register('ward_no')}
                            />
                            {errors?.ward_no && (
                              <span className="form__error">
                                {errors?.ward_no.message}
                              </span>
                            )}
                          </div>
                          <div className="col-lg-3 mb-2">
                            <label htmlFor='prVillage_bn_name' className="form-label">
                              গ্রাম (বাংলা)
                              <span className="from_required">*</span>{' '}
                            </label>
                            <input
                              id='prVillage_bn_name'
                              name='prVillage_bn_name'
                              className="form-control"
                              type="text"
                              {...register('village_bn_name')}
                            />
                            {errors?.road && (
                              <span className="form__error">
                                {errors?.village_bn_name.message}
                              </span>
                            )}
                          </div>
                          <div className="col-lg-3 mb-2">
                            <label htmlFor='prVillage_en_name' className="form-label">
                              গ্রাম (ইংরেজিতে){' '}
                              <span className="from_required">*</span>{' '}
                            </label>

                            <input
                              id='prVillage_en_name'
                              name='prVillage_en_name'
                              className="form-control"
                              type="text"
                              {...register('village_en_name')}
                            />
                            {errors?.road && (
                              <span className="form__error">
                                {errors?.village_en_name.message}
                              </span>
                            )}
                          </div>
                          <div className="col-lg-3 mb-2">
                            <label htmlFor='prRoad' className="form-label">
                              রোড / ব্লক / সেক্টর{' '}
                            </label>
                            <input
                              id='prRoad'
                              name='prRoad'
                              className="form-control"
                              type="text"
                              {...register('road')}

                            />
                            {errors?.road && (
                              <span className="form__error">
                                {errors?.road.message}
                              </span>
                            )}
                          </div>
                        </div> : <div className="row gx-3">
                          <div className="col-lg-3">
                            <label className="form-label">
                              বিভাগ
                              <span className="from_required">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              {...register('pdivision')}
                            />
                          </div>
                          <div className="col-lg-3">
                            <label htmlFor='pDistrict' className="form-label">
                              জেলা
                              <span className="from_required">*</span>
                            </label>
                            <input
                              id='pDistrict'
                              name="pDistrict"
                              className="form-control"
                              type="text"
                              {...register('pdistrict')}
                            />
                          </div>
                          <div className="col-lg-3">
                            <label htmlFor='pUpazila' className="form-label">
                              থানা / উপজেলা
                              <span className="from_required">*</span>
                            </label>
                            <input
                              id='pUpazila'
                              name='pUpazila'
                              className="form-control"
                              type="text"
                              {...register('pupazila')}
                            />
                          </div>
                          {
                            userInfo?.account === 'municipality' ? <>
                              <div className="col-lg-3 mb-2">
                                <label className="form-label">
                                  পৌরসভা (বাংলা)
                                  <span className="from_required">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  {...register('pmunicipality_bn_name')}
                                />
                              </div>
                              <div className="col-lg-3 mb-2">
                                <label className="form-label">
                                  পৌরসভা (ইংরেজিতে)
                                  <span className="from_required">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  {...register('pmunicipality_en_name')}
                                />
                              </div>
                            </> : <>
                              <div className="col-lg-3 mb-2">
                                <label className="form-label">
                                  ইউনিয়ন
                                  <span className="from_required">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  {...register('punion')}
                                />
                              </div>
                            </>
                          }


                          <div className="col-lg-3 mb-2">
                            <label htmlFor='pPost_office_bn' className="form-label">
                              ডাকঘর (বাংলা)
                              <span className="from_required">*</span>{' '}
                            </label>
                            <input
                              id='pPost_office_bn'
                              name='pPost_office_bn'
                              className="form-control"
                              type="text"
                              {...register('ppost_office_bn')}

                            />
                            {errors?.post_office && (
                              <span className="form__error">
                                {errors?.post_office.message}
                              </span>
                            )}
                          </div>
                          <div className="col-lg-3 mb-2">
                            <label htmlFor='pPost_office_en' className="form-label">
                              ডাকঘর (ইংরেজিতে){' '}
                              <span className="from_required">*</span>{' '}
                            </label>
                            <input
                              id='pPost_office_en'
                              name='pPost_office_en'
                              className="form-control"
                              type="text"
                              {...register('ppost_office_en')}

                            />
                            {errors?.post_office && (
                              <span className="form__error">
                                {errors?.post_office.message}
                              </span>
                            )}
                          </div>

                          <div className="col-lg-3 mb-3">
                            <label className="form-label">
                              ওয়ার্ড নং <span className="from_required">*</span>{' '}
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Zip code "
                              {...register('pward_no')}
                            />
                            {errors?.ward_no && (
                              <span className="form__error">
                                {errors?.ward_no.message}
                              </span>
                            )}
                          </div>
                          <div className="col-lg-3 mb-2">
                            <label htmlFor='pVillage_bn_name' className="form-label">
                              গ্রাম (বাংলা)
                              <span className="from_required">*</span>{' '}
                            </label>
                            <input
                              id='pVillage_bn_name'
                              name='pVillage_bn_name'
                              className="form-control"
                              type="text"
                              {...register('pvillage_bn_name')}
                            />
                            {errors?.road && (
                              <span className="form__error">
                                {errors?.village_bn_name.message}
                              </span>
                            )}
                          </div>
                          <div className="col-lg-3 mb-2">
                            <label htmlFor='pVillage_en_name' className="form-label">
                              গ্রাম (ইংরেজিতে){' '}
                              <span className="from_required">*</span>{' '}
                            </label>

                            <input
                              id='pVillage_en_name'
                              name='pVillage_en_name'
                              className="form-control"
                              type="text"
                              {...register('pvillage_en_name')}
                            />
                            {errors?.road && (
                              <span className="form__error">
                                {errors?.village_en_name.message}
                              </span>
                            )}
                          </div>
                          <div className="col-lg-3 mb-2">
                            <label htmlFor='pRoad' className="form-label">
                              রোড / ব্লক / সেক্টর{' '}
                            </label>
                            <input
                              id='pRoad'
                              name='pRoad'
                              className="form-control"
                              type="text"
                              {...register('proad')}

                            />
                            {errors?.road && (
                              <span className="form__error">
                                {errors?.road.message}
                              </span>
                            )}
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <Button
                  isLoading={loading}
                  text="জমা করুন"
                />
                
              </form>
            </div>
          </section>
        </div>
      )}
    </>
  );
}


function mapStateToProps(state) {
  return { userInfo: state.auth?.userInfo?.users }
}



export default connect(mapStateToProps, null)(ALlSonodCreate)
/* 
https://zava.server.zavawholesale.com/product?data=

*/