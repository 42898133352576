import { useEffect, useRef, useState } from 'react';
import DashboardLayout from '../../../../layout/DashboardLayout';
import { useParams } from 'react-router-dom';
import { useGetSingleCertificateQuery } from '../../../../../app/services/certificate';
import { connect } from 'react-redux';
import { RefetchData } from '../../../RefetchData';

const Enamendmentofnationalidentityinformation = ({ userInfo }) => {
    const maxRetries = 3;
    const { id } = useParams();
    const componentRef = useRef();
    const [retryCount, setRetryCount] = useState(0);
    const { data, isLoading, isError, refetch } = useGetSingleCertificateQuery(id);
    const accountType = userInfo?.account
    const certificate = data?.certificate;
    useEffect(() => {
        RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
    }, [isError, retryCount, refetch]);

    return (
        <DashboardLayout>

        </DashboardLayout>
    );
};



function mapStateToProps(state) {
    return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(Enamendmentofnationalidentityinformation)
