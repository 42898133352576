import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useGetSingleCitizenMutation } from "../../../../app/services/citizen";
import { useCreatemunicipalitycertificateMutation } from "../../../../app/services/certificatemunicipality";
import { toast } from "react-toastify";
import FindcitizensSearch from "../../../findcitizens/FindcitizensSearch";
import { connect } from "react-redux";
import { CreateMunicipalityTradeLicenseSchema } from "../../../../helpers/validation/MunicipalitySchema";
import Button from "../../../button/Button";

function CreateTradeLicenseMunicipality({ contects, modal, setOpen, addisLoading }) {
  const { type } = modal;
  const [institutionNature, setInstitutionNature] = useState(null)
  const [userData, setUserData] = useState(false);
  const [nidOrNumber, setNidOrNumber] = useState("");
  /* total ammount handel   */
  const [renewalfee, setRenewalfee] = useState(0);
  const [vat, setVat] = useState(0);
  const [aboutincometax, setAboutincometax] = useState(0);
  const [signboard, setSignboard] = useState(0);
  const [misc, setMisc] = useState(0);
  const [due, setDue] = useState(0);
  const [service_charge, setService_charge] = useState(0);
  let totalServicePrices =
    Number(renewalfee) + Number(aboutincometax) +
    Number(signboard) + Number(misc) +
    Number(due) + Number(vat) +
    Number(service_charge);
  let totalPrices = Number(totalServicePrices)


  const { register, handleSubmit, reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(CreateMunicipalityTradeLicenseSchema) });

  const [getCitizen, { data, isLoading, isSuccess, isError, error }] = useGetSingleCitizenMutation();

  const handleSearchCitizen = async () => {
    if (nidOrNumber) {
      const searchData = { nidOrNumber };
      await getCitizen(searchData);
    }
  };
  const [createCertificate, { isSuccess: isSucc, isLoading: loading, isError: isErr, error: err }] = useCreatemunicipalitycertificateMutation();

  const createOnSubmit = async (certificateData) => {
    if (!isValid) {
      return;
    }
    const institutionNature = document.querySelector(
      'select[name="institution_nature"] option:checked'
    );
    const tradeLicensesTypes = document.querySelector(
      'select[name="trade_licenses_types"] option:checked'
    );
    const trade_license_infomation = [
      {
        license_id: certificateData?.license_id,
        institution_bn_name: certificateData?.institution_bn_name,
        institution_en_name: certificateData?.institution_en_name,
        institution_bn_type: certificateData?.institution_bn_type,
        institution_en_type: certificateData?.institution_en_type,
        circle_street_mohalla_bn_name: certificateData?.circle_street_mohalla_bn_name,
        circle_street_mohalla_en_name: certificateData?.circle_street_mohalla_en_name,
        mobile_number: certificateData?.mobile_number,
        institution_nature: {
          bn_name: institutionNature.getAttribute("data-bn_name"),
          en_name: institutionNature.getAttribute("data-en_name"),
          joint_name_bn: certificateData?.joint_name_bn,
          joint_name_en: certificateData?.joint_name_en,
          joint_nid: certificateData?.joint_nid,
        },
        trade_licenses_types: {
          bn_name: tradeLicensesTypes.getAttribute("data-bn_name"),
          en_name: tradeLicensesTypes.getAttribute("data-en_name"),
        },
        license_fee_infomation: {
          renewal_fee: certificateData?.renewal_fee,
          signboard: certificateData?.signboard,
          misc: certificateData?.misc,
          due: certificateData?.due,
          service_charge: certificateData?.service_charge,
          about_income_tax: certificateData?.about_income_tax,
          vat: certificateData?.vat,
          totalAmmount: totalPrices,
        },
        tin: certificateData?.tin,
        bin: certificateData?.bin,
        institution_address_en: certificateData?.institution_address_en,
        institution_address_bn: certificateData?.institution_address_bn,
        institution_bn_name: certificateData?.institution_bn_name,
        institution_en_name: certificateData?.institution_en_name,
      },
    ];
    const certificateDataInfo = {
      citizen_id: userData?._id,
      ...certificateData,
      present_address: userData?.present_address[0],
      permanent_address: userData?.permanent_address[0],
      trade_license_infomation: trade_license_infomation,
      sonod_type: "tradelicense"
    };
    await createCertificate(certificateDataInfo);
  };



  useEffect(() => {
    if (userData) {
      setValue("sonod_type", userData?.sonod_type);
      setValue("nid", userData?.nid);
      setValue("profilePicture", userData?.profilePicture);
      setValue("number", userData?.number);
      setValue("name_bangla", userData?.name_bangla);
      setValue("name_english", userData?.name_english);
      setValue("father_husband_bangla", userData?.father_husband_bangla);
      setValue("father_husband_english", userData?.father_husband_english);
      setValue("mother_name_bangla", userData?.mother_name_bangla);
      setValue("mother_name_english", userData?.mother_name_english);
      setValue("date_of_birth", userData?.date_of_birth);
      setValue("gender", userData?.gender);
      setValue("blood_group", userData?.blood_group);
      setValue("district", userData?.permanent_address[0]?.district?.bn_name);
      setValue("division", userData?.permanent_address[0]?.division?.bn_name);
      setValue("upazila", userData?.permanent_address[0]?.upazila?.bn_name);
      setValue("union", userData?.permanent_address[0]?.union?.bn_name);
      setValue(
        "municipality_bn_name",
        userData?.permanent_address[0]?.municipality_bn_name
      );
      setValue(
        "municipality_en_name",
        userData?.permanent_address[0]?.municipality_en_name
      );
      setValue(
        "post_office_bn",
        userData?.permanent_address[0]?.post_office_bn
      );
      setValue(
        "post_office_en",
        userData?.permanent_address[0]?.post_office_en
      );
      setValue(
        "village_bn_name",
        userData?.permanent_address[0]?.village_bn_name
      );
      setValue(
        "village_en_name",
        userData?.permanent_address[0]?.village_en_name
      );
      setValue("holding_no", userData?.permanent_address[0]?.holding_no);
      setValue("ward_no", userData?.permanent_address[0]?.ward_no);
      setValue("road", userData?.permanent_address[0]?.road);
      setValue("pdistrict", userData?.present_address[0]?.district?.bn_name);
      setValue("pdivision", userData?.present_address[0]?.division?.bn_name);
      setValue("pupazila", userData?.present_address[0]?.upazila?.bn_name);
      setValue("punion", userData?.present_address[0]?.union?.bn_name);
      setValue(
        "pmunicipality_bn_name",
        userData?.present_address[0]?.municipality_bn_name
      );
      setValue(
        "pmunicipality_en_name",
        userData?.present_address[0]?.municipality_en_name
      );
      setValue("ppost_office_bn", userData?.present_address[0]?.post_office_bn);
      setValue("ppost_office_en", userData?.present_address[0]?.post_office_en);
      setValue(
        "pvillage_bn_name",
        userData?.present_address[0]?.village_bn_name
      );
      setValue(
        "pvillage_en_name",
        userData?.present_address[0]?.village_en_name
      );
      setValue("pholding_no", userData?.present_address[0]?.holding_no);
      setValue("pward_no", userData?.present_address[0]?.ward_no);
      setValue("proad", userData?.present_address[0]?.road);
    }
  }, [userData, setValue]);

  useEffect(() => {
    if (isSucc) {
      reset()
      toast.success('সঠিকভাবে  এড করা হয়েছ');
      setOpen({ type: false })
    }
    if (isErr) {
      reset()
      toast.error("errror", { id: "create" });
    }
  }, [isSucc, isErr, err]);

  return (
    <>
      {type && (
        <div className="modal_product">
          <section className="content-mainDash">
            <div className="row">
              <div className="col-12">
                <div className="content-header">
                  <h2 className="content-title">{contects?.title}</h2>
                  <button onClick={() => setOpen({ type: false }, reset())}>
                    X
                  </button>
                </div>
                <FindcitizensSearch
                  onChangeGetValue={setNidOrNumber}
                  onClickSubmitButton={handleSearchCitizen}
                  isLoading={isLoading}
                  setInputFildData={setUserData}
                  data={data?.data}
                />
              </div>
              <form onSubmit={handleSubmit(createOnSubmit)}>
                <div className="col-lg-12">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row gx-2">
                        <div className="col-6 mb-3">
                          <label className="form-label">
                            নাম: (বাংলা){" "}
                            <span className="from_required">*</span>{" "}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="নাম বাংলা"
                            value={userData?.name_bangla}
                            disabled
                          />
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label className="form-label">
                            নাম: (ইংরেজিতে){" "}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="নাম ইংরেজিতে"
                            value={userData?.name_english}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row gx-2">
                        <div className="col-lg-6 mb-3">
                          <label className="form-label">
                            মোবাইল নম্বর{" "}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            placeholder="মোবাইল নম্বর"
                            value={userData?.number}
                            disabled
                          />
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label className="form-label">
                            জাতীয় পরিচয়পত্র নং{" "}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            placeholder="জাতীয় পরিচয়পত্র নং"
                            value={userData?.nid}
                            disabled
                          />
                        </div>
                      </div>
                      <div>
                        <h5>প্রতিষ্ঠানের তথ্য : </h5>
                        <div className="row gx-2">
                          <div className="col-3 mb-3">
                            <label className="form-label">
                              লাইসেন্স আইডি{" "}
                              <span className="from_required">*</span>{" "}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="লাইসেন্স আইডি"
                              {...register("license_id")}
                            />
                            {errors?.license_id && (
                              <span className="form__error">
                                {errors?.license_id.message}
                              </span>
                            )}
                          </div>
                          <div className="col-3 mb-3">
                            <label className="form-label">
                              প্রতিষ্ঠানের নাম (বাংলায়){" "}
                              <span className="from_required">*</span>{" "}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="নাম বাংলা"
                              {...register("institution_bn_name")}
                            />
                            {errors?.institution_bn_name && (
                              <span className="form__error">
                                {errors?.institution_bn_name.message}
                              </span>
                            )}
                          </div>

                          <div className="col-lg-3 mb-3">
                            <label className="form-label">
                              প্রতিষ্ঠানের নাম: (ইংরেজিতে){" "}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="নাম ইংরেজিতে"
                              {...register("institution_en_name")}
                            />
                            {errors?.institution_en_name && (
                              <span className="form__error">
                                {errors?.institution_en_name.message}
                              </span>
                            )}
                          </div>
                          <div className="col-lg-3 mb-3">
                            <label className="form-label">
                              সার্কেল / রাস্তা / মহল্লা নাম: (বাংলা){" "}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="নাম বাংলা"
                              {...register("circle_street_mohalla_bn_name")}
                            />
                            {errors?.circle_street_mohalla_bn_name && (
                              <span className="form__error">
                                {errors?.circle_street_mohalla_bn_name.message}
                              </span>
                            )}
                          </div>
                          <div className="col-lg-3 mb-3">
                            <label className="form-label">
                              সার্কেল / রাস্তা / মহল্লা  নাম: (ইংরেজিতে){" "}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="নাম ইংরেজিতে"
                              {...register("circle_street_mohalla_en_name")}
                            />
                            {errors?.circle_street_mohalla_en_name && (
                              <span className="form__error">
                                {errors?.circle_street_mohalla_en_name.message}
                              </span>
                            )}
                          </div>
                          <div className="col-lg-3 mb-3">
                            <label className="form-label">
                              ফোন / মোবাইল নং:
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="মোবাইল নাম্বার"
                              {...register("mobile_number")}
                            />
                            {errors?.mobile_number && (
                              <span className="form__error">
                                {errors?.mobile_number.message}
                              </span>
                            )}
                          </div>


                          <div className="col-lg-3 mb-3">
                            <label className="form-label">
                              প্রতিষ্ঠানের ধরণ (বাংলায়){" "}
                              <span className="from_required">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="নাম বাংলায়"
                              {...register("institution_bn_type")}
                            />
                            {errors?.institution_bn_type && (
                              <span className="form__error">
                                {errors?.institution_bn_type.message}
                              </span>
                            )}
                          </div>
                          <div className="col-lg-3 mb-3">
                            <label className="form-label">
                              প্রতিষ্ঠানের ধরণ (ইংরেজিতে){" "}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="নাম ইংরেজিতে"
                              {...register("institution_en_type")}
                            />
                            {errors?.institution_en_type && (
                              <span className="form__error">
                                {errors?.institution_en_type.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-3 mb-3">
                            <label
                              htmlFor="Promo_amount"
                              className="form-label"
                            >
                              প্রতিষ্ঠানের প্রকৃতি{" "}
                              <span className="from_required">*</span>
                            </label>
                            <select
                              className="form-select"
                              {...register("institution_nature")}
                              onChange={(e) => setInstitutionNature(e.target.value)}
                            >
                              <option value="">
                                প্রতিষ্ঠানের প্রকৃতি নির্বাচন করুন।
                              </option>
                              <option
                                value="individual"
                                data-bn_name="একক"
                                data-en_name="Individual"
                                selected
                              >
                                একক(Individual)
                              </option>
                              <option
                                value="joint"
                                data-bn_name="যৌথ"
                                data-en_name="Joint"
                              >
                                যৌথ (Joint)
                              </option>
                              <option
                                value="others"
                                data-bn_name="অন্যান্য"
                                data-en_name="Others"
                              >
                                অন্যান্য (Others)
                              </option>
                            </select>
                            {errors?.institution_nature && (
                              <span className="form__error">
                                {errors?.institution_nature.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-3 mb-3">
                            <label
                              htmlFor="Promo_amount"
                              className="form-label"
                            >
                              ট্রেড লাইসেন্স এর ধরণ
                              <span className="from_required">*</span>
                            </label>
                            <select
                              className="form-select"
                              {...register("trade_licenses_types")}
                            >
                              <option value="">
                                ট্রেড লাইসেন্স এর ধরণ নির্বাচন করুন।
                              </option>

                              <option
                                value="new"
                                data-bn_name="সংশোধন"
                                data-en_name="New"
                                selected
                              >
                                নতুন(New)
                              </option>
                              <option
                                value="renewal"
                                data-bn_name="নবায়ন"
                                data-en_name="Renewal"
                              >
                                নবায়ন (Renewal)
                              </option>
                              <option
                                value="amendment"
                                data-bn_name="সংশোধন"
                                data-en_name="Amendment"
                              >
                                সংশোধন (Amendment)
                              </option>
                            </select>
                            {errors?.trade_licenses_types && (
                              <span className="form__error">
                                {errors?.trade_licenses_types.message}
                              </span>
                            )}
                          </div>
                          <div className="col-lg-3 mb-3">
                            <label className="form-label">
                              TIN নম্বর (ইংরেজিতে){" "}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="নাম ইংরেজিতে"
                              {...register("tin")}
                            />
                          </div>
                          <div className="col-lg-3 mb-3">
                            <label className="form-label">
                              BIN নম্বর (ইংরেজিতে){" "}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="নাম ইংরেজিতে"
                              {...register("bin")}
                            />
                            {errors?.bin && (
                              <span className="form__error">
                                {errors?.bin.message}
                              </span>
                            )}
                          </div>

                          <div className="col-lg-6 mb-3">
                            <label className="form-label">
                              প্রতিষ্ঠানের ঠিকানার (বাংলায়) {" "}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="নাম বাংলায়"
                              {...register("institution_address_bn")}
                            />
                          </div>
                          <div className="col-lg-6 mb-3">
                            <label className="form-label">
                              প্রতিষ্ঠানের ঠিকানার (ইংরেজিতে) {" "}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="নাম ইংরেজিতে"
                              {...register("institution_address_en")}
                            />
                            {errors?.institution_address_en && (
                              <span className="form__error">
                                {errors?.institution_address_en.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      {
                        institutionNature === "joint" && <section className="row gx-2">

                          <div className="col-lg-6 mb-3">
                            <label className="form-label">
                              বাকী স্বত্ত্বাধিকারীগণের নাম (বাংলায়) {" "}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="নাম বাংলায়"
                              {...register("joint_name_bn")}
                            />
                          </div>
                          <div className="col-lg-6 mb-3">
                            <label className="form-label">
                              বাকী স্বত্ত্বাধিকারীগণের নাম (ইংরেজীতে) {" "}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="নাম ইংরেজিতে"
                              {...register("joint_name_en")}
                            />
                          </div>
                          <div className="col-lg-12 mb-3">
                            <label className="form-label">
                              বাকী স্বত্ত্বাধিকারীগণের জাতীয় পরিচয় পত্র/জন্মনিবন্ধন নং
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="প্রতিষ্ঠানের যৌথ প্রকৃতি জাতীয় পরিচয়পত্র নং "
                              {...register("joint_nid")}
                            />
                          </div>
                        </section>
                      }

                      <div>
                        <h5>লাইসেন্স তথ্য : </h5>
                        <div className="row gx-2">
                          <div className="col-3 mb-3">
                            <label className="form-label">
                              ট্রেড লাইসেন্স/নবায়ন ফি {" "}
                              <span className="from_required">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="0.00"
                              {...register("renewal_fee")}
                              onChange={(e) => setRenewalfee(e.target.value)}
                            />
                          </div>
                          <div className="col-3 mb-3">
                            <label className="form-label">
                              সাইনবোর্ড/বিজ্ঞাপন কর
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="0.00"
                              defaultValue={0}
                              {...register("signboard")}
                              onChange={(e) => setSignboard(e.target.value)}
                            />
                          </div>
                          <div className="col-3 mb-3">
                            <label className="form-label">
                              বিবিধ
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="0.00"
                              defaultValue={0}
                              {...register("misc")}
                              onChange={(e) => setMisc(e.target.value)}
                            />
                          </div>

                          <div className="col-3 mb-3">
                            <label className="form-label">বকেয়া</label>
                            <input
                              className="form-control"
                              type="number"
                              defaultValue={0}
                              placeholder="0.00"
                              {...register("due")}
                              onChange={(e) => setDue(e.target.value)}
                            />
                          </div>
                          <div className="col-3 mb-3">
                            <label className="form-label">সার চার্জ/জরিমানা </label>
                            <input
                              className="form-control"
                              type="number"
                              defaultValue={0}
                              placeholder="0.00"
                              {...register("service_charge")}
                              onChange={(e) =>
                                setService_charge(e.target.value)
                              }
                            />
                          </div>
                          <div className="col-3 mb-3">
                            <label className="form-label">আয়কর বাবদ </label>
                            <input
                              className="form-control"
                              type="number"
                              defaultValue={0}
                              placeholder="0.00"
                              {...register("about_income_tax")}
                              onChange={(e) =>
                                setAboutincometax(e.target.value)
                              }
                            />
                          </div>
                          <div className="col-3 mb-3">
                            <label className="form-label">
                              ভ্যাট
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="0.00"
                              {...register("vat")}
                              onChange={(e) => setVat(e.target.value)}
                            />
                          </div>
                          <div className="col-3 mb-3">
                            <label className="form-label">মোট</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="0.00"
                              value={totalPrices}
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <Button
                    btnClass='w-100'
                    isLoading={loading}
                    text=" জমা করুন"
                />


              </form>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return { userInfo: state.auth?.userInfo?.users };
}

export default connect(mapStateToProps, null)(CreateTradeLicenseMunicipality);
