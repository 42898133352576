import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQueryHandel";
export const RechargeApi = createApi({
    reducerPath: "payment",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getPyamentGateway: builder.query({
            query: (pathname) => ({
                url: `${pathname}`,
                method: "GET",
            }),
            providesTags: ["payment"],
        }),

        bkashCreate: builder.mutation({
            query: (data) => ({
                url: "v1/payment-gateway/bKash/tokenized/checkout/create",
                method: "POST",
                body: data,
            }),
        }),
        ekpayCreate: builder.mutation({
            query: (data) => ({
                url: "v1/payment-gateway/ekpay/create",
                method: "POST",
                body: data,
            }),
        }),
        ekpayTransId: builder.query({
            query: (id) => ({
                url: `v1/payment-gateway/ekpay/transId?id=${id}`,
                method: "GET",
            }),
            invalidatesTags: ['User','Citizen'],

        }),

    }),
});
export const { useBkashCreateMutation, useGetPyamentGatewayQuery, useEkpayCreateMutation,useEkpayTransIdQuery} = RechargeApi;

