import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { toast } from "react-toastify";
import { usePostHoldingTaxMutation } from "../../../../app/services/holdingTax";

function CreateHoldingTaxModal({ contects, modal, setOpen }) {
  const { type } = modal;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [amountPerSchedule, setAmountPerSchedule] = useState(0);
  const [actualTaxAmount, setActualTaxAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [postData, { isSuccess,isLoading, isError, error }] = usePostHoldingTaxMutation();
  const createOnSubmit = async (data) => {
    const { actual_tax_amount, amount_as_schedule, ...others } = data;
    const holdingTaxData = {
      actual_tax_amount: actualTaxAmount,
      amount_as_schedule: amountPerSchedule,
      total_amount: total,
      ...others,
    };
    await postData(holdingTaxData);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("সঠিকভাবে হোল্ডিং ট্যাক্স নিবন্ধন করা হয়েছে");
      setOpen({ type: false }, reset(), setTotal(0))
    }
    if (error) {
      toast.error(error?.data?.error);
    }
  }, [isSuccess, error, reset]);

  useEffect(() => {
    if (actualTaxAmount) {
      setTotal(actualTaxAmount);
    }
  }, [actualTaxAmount]);
  return (
    <>
      {type && (
        <div className="modal_product p-6">
          <section className="content-mainDash ">
            <div className="row">
              <div className="col-12">
                <div className="content-header">
                  <h2 className="content-title">{contects?.title}</h2>
                  <button
                    onClick={() =>
                      setOpen({ type: false }, reset(), setTotal(0))
                    }
                  >
                    X
                  </button>
                </div>
              </div>
              <form onSubmit={handleSubmit(createOnSubmit)}>
                <div className="card ">
                  <div className="card-body">
                    <div className="row g-3">
                      <div className="col-lg-4">
                        <label className="form-label">
                          নাম: (বাংলা) <span className="from_required">*</span>{" "}
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          {...register("name_bangla", {
                            required: "খালি রাখা যাবে না",
                            pattern: {
                              value: /^[ঀ-৾\s:-]+$/i,
                              message: "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত",
                            },
                          })}
                        />
                        {errors?.name_bangla && (
                          <span className="form__error">
                            {errors?.name_bangla.message}
                          </span>
                        )}
                      </div>

                      <div className="col-lg-4 ">
                        <label className="form-label">
                          পিতা / স্বামীর নাম: (বাংলা){" "}
                          <span className="from_required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          {...register("father_husband_bangla", {
                            required: "খালি রাখা যাবে না",
                            pattern: {
                              value: /^[ঀ-৾\s:-]+$/i,
                              message: "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত",
                            },
                          })}
                        />
                        {errors?.father_husband_bangla && (
                          <span className="form__error">
                            {errors?.father_husband_bangla.message}
                          </span>
                        )}
                      </div>

                      <div className="col-lg-4 ">
                        <label className="form-label">
                          মাতার নাম: (বাংলা){" "}
                          <span className="from_required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          {...register("mother_name_bangla", {
                            pattern: {
                              value: /^[ঀ-৾\s:-]+$/i,
                              message: "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত",
                            },
                          })}
                        />
                        {errors?.mother_name_bangla && (
                          <span className="form__error">
                            {errors?.mother_name_bangla.message}
                          </span>
                        )}
                      </div>

                      <div className="col-lg-4">
                        <label className="form-label">
                          মোবাইল নম্বর <span className="from_required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          {...register("number", {
                            pattern: {
                              value: /^[0-9]{11}$/,
                              message: "মোবাইল নম্বরটি ১১ টি সংখ্যা হতে হবে",
                            },
                          })}
                        />
                        {errors?.number && (
                          <span className="form__error">
                            {errors?.number.message}
                          </span>
                        )}
                      </div>
                      <div className="col-lg-4 ">
                        <label className="form-label">
                          জাতীয় পরিচয়পত্র নং / জন্মনিবন্ধন নং{" "}
                          <span className="from_required">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          {...register("nid", {
                            pattern: {
                              value: /^[0-9]{10}$|^[0-9]{17}$/,
                              message:
                                "জাতীয় পরিচয়পত্র নম্বরটি ১০ বা ১৭ টি সংখ্যা হতে হবে",
                            },
                          })}
                        />
                        {errors?.nid && (
                          <span className="form__error">
                            {errors?.nid.message}
                          </span>
                        )}
                      </div>

                      <div className="col-lg-4 ">
                        <label htmlFor="ward_no" className="form-label">
                          ওয়ার্ড নং <span className="from_required">*</span>{" "}
                        </label>
                        <input
                          id="ward_no"
                          name="ward_no"
                          className="form-control"
                          type="number"
                          {...register("ward_no", {
                          })}
                          onChange={(e) => {
                            const value = e.target.value;
                          }}
                        />
                        {errors?.ward_no && (
                          <span className="form__error">
                            {errors?.ward_no.message}
                          </span>
                        )}
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label htmlFor="village_bn_name" className="form-label">
                          গ্রাম (বাংলা)
                          <span className="from_required">*</span>{" "}
                        </label>
                        <input
                          id="village_bn_name"
                          name="village_bn_name"
                          className="form-control"
                          type="text"
                          {...register("village_bn_name", {
                            required: "খালি রাখা যাবে না",
                            pattern: {
                              value: /^[ঀ-৾\s:-]+$/i,
                              message: "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত",
                            },
                          })}
                          onChange={(e) => {
                            const value = e.target.value;
                          }}
                        />
                        {errors?.village_bn_name && (
                          <span className="form__error">
                            {errors?.village_bn_name.message}
                          </span>
                        )}
                      </div>

                      <div className="col-lg-4 mb-2">
                        <label htmlFor="prRoad" className="form-label">
                          রোড / ব্লক / সেক্টর{" "}
                        </label>
                        <input
                          id="road"
                          name="road"
                          className="form-control"
                          type="text"
                          {...register("road", {
                          })}
                          onChange={(e) => {
                            const value = e.target.value;
                          }}
                        />
                        {errors?.road && (
                          <span className="form__error">
                            {errors?.road.message}
                          </span>
                        )}
                      </div>
                      <div className="col-lg-4 mb-2">
                        <label htmlFor="home_type" className="form-label">
                          বাড়ীর ধরন
                        </label>
                        <input
                          id="home_type"
                          name="home_type"
                          className="form-control"
                          type="text"
                          {...register("home_type", {
                            required: "খালি রাখা যাবে না",
                            pattern: {
                              value: /^[ঀ-৾\s:-]+$/i,
                              message: "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত",
                            },
                          })}
                          onChange={(e) => {
                            const value = e.target.value;
                          }}
                        />
                        {errors?.home_type && (
                          <span className="form__error">
                            {errors?.home_type.message}
                          </span>
                        )}
                      </div>
                      <div className="col-lg-4 mb-2">
                        <label htmlFor="prHolding_no" className="form-label">
                          তফসিল অনুযায়ী পরিমাণ
                        </label>
                        <input
                          id="amount_as_schedule"
                          name="amount_as_schedule"
                          className="form-control"
                          type="number"
                          {...register("amount_as_schedule", {
                          })}
                          onChange={(e) => {
                            const value = Number(e.target.value);
                            setAmountPerSchedule(value);
                          }}
                        />
                        {errors?.amount_as_schedule && (
                          <span className="form__error">
                            {errors?.amount_as_schedule.message}
                          </span>
                        )}
                      </div>
                      <div className="col-lg-4 mb-2">
                        <label htmlFor="prHolding_no" className="form-label">
                          প্রকৃত ধার্যকৃত করের পরিমাণ
                        </label>
                        <input
                          id="actual_tax_amount"
                          name="actual_tax_amount"
                          className="form-control"
                          type="number"
                          {...register("actual_tax_amount", {
                            required: "খালি রাখা যাবে না",
                          })}
                          onChange={(e) => {
                            const value = Number(e.target.value);
                            setActualTaxAmount(value);
                          }}
                        />
                        {errors?.holding_no && (
                          <span className="form__error">
                            {errors?.actual_tax_amount.message}
                          </span>
                        )}
                      </div>

                      <div className="col-lg-12 mb-2 me-0">
                        <p className="fs-5 text-end ">
                          {" "}
                          মোট মূল্য : {total} টাকা{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end gap-3">
                  <p
                    onClick={() =>
                      setOpen({ type: false }, reset(), setTotal(0))
                    }
                    className="btn btn-md rounded btn-warning bg-warning font-sm hover-up"
                  >
                    বাতিল করুন
                  </p>
                  <>
                    <input
                      style={{ cursor: isLoading ? "no-drop" : "pointer" }}
                      type="submit"
                      className="btn btn-md rounded font-sm hover-up h-25"
                      value="সংরক্ষণ করুন"
                    />
                  </>
                </div>
              </form>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default CreateHoldingTaxModal;

export const home_type = [
  "মাটির ঘর",
  "টিনের ঘর",
  "আধা পাকা ঘর",
  "আধা পাকা ইমারত",
  "পাকা ইমারত",
];
