import DateDisplay from "../../../../utils/DateDisplay/DateDisplay";
import EnglishToBanglaNumber from "../../../../utils/EnglishToBanglaNumber";
import styles from "./CertificateHeader.module.css";
function CertificateHeaderBn({ certificate, sonodName }) {


  return (
    <>
      <header className={styles.pdfHeader}>
        <img src={certificate?.headLogo} alt="gov_images" className={styles.gotLogo} />
        <h2>
          {certificate?.union_or_municipality?.municipality_union_bn_name}{" "}
        </h2>
        <h4>
          {certificate?.union_or_municipality?.upazila?.bn_name},{" "}
          {certificate?.union_or_municipality ?.district?.bn_name}
          {"।"}
        </h4>
        <h1>{sonodName}</h1>
      </header>
      <div className={styles.timeline}>
        <h4>সনদ নং : {EnglishToBanglaNumber(`${certificate?.sonodId}`)} </h4>
        <div className="d-flex align-items-center gap-1">
          <h4>ইস্যুর তারিখ :</h4>
          <h4>
            <DateDisplay lang="bangla" />
          </h4>
        </div>
      </div>
    </>
  );
}
function CertificateHeaderEn({ certificate, sonodName }) {
  return (
    <>
      <header className={styles.pdfHeader}>
        <img src={certificate?.headLogo} alt="sonod_images" className={styles.gotLogo} />
        <h2>
          {certificate?.union_or_municipality?.municipality_union_en_name}{" "}
        </h2>
        <h4>
          {certificate?.union_or_municipality?.upazila?.name},{" "}
          {certificate?.union_or_municipality?.district?.name}
          {"."}
        </h4>

        <h2>{sonodName}</h2>
      </header>
      <div className={styles.timeline}>
        <h4>Certificate No : {certificate?.sonodId}</h4>
        <div className="d-flex align-items-center gap-1">
          <h4>Date of Issue:</h4>
          <h4>
            <DateDisplay />
          </h4>
        </div>
      </div>
    </>
  );
}
export { CertificateHeaderBn, CertificateHeaderEn };
