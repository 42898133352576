import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQueryHandel";
export const LocationApi = createApi({
    reducerPath: "Location",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        /* ===========  add department ===========  */
        getDivisions: builder.query({
            query: () => ({
                url: "v1/bangladesh-geo/divisions",
                method: "GET",
            }),
        }),
        getDistricts: builder.query({
            query: () => ({
                url: "v1/bangladesh-geo/districts",
                method: "GET",
            }),
        }),
        getUpazilas: builder.query({
            query: () => ({
                url: "v1/bangladesh-geo/upazilas",
                method: "GET",
            }),
        }),
        getUnions: builder.query({
            query: () => ({
                url: "v1/bangladesh-geo/unions",
                method: "GET",
            }),
            providesTags: ["union"],
        }),
        unionCreate: builder.mutation({
            query: (data) => ({
                url: "v1/bangladesh-geo/union/create",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["union"],
        }),




    }),
});
export const { useGetDivisionsQuery, useGetDistrictsQuery, useGetUpazilasQuery, useGetUnionsQuery,useUnionCreateMutation } = LocationApi;

