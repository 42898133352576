import React, { useState, useEffect, useMemo } from "react";
import { useGetrolemunicipalitycertificateQuery } from "../../../../app/services/certificatemunicipality";
import DashboardLayout from "../../../../components/layout/DashboardLayout";
import CertificateReport from "../../../../components/certificateReport/CertificateReport";
import Search from "../../../../utils/Search";
import TradeLicenseSingleSonod from "../../../../components/elements/applicationCertificate/singleSonod/TradeLicenseSingleSonod";
import Pagination from "../../../../components/elements/Pagination";
import ApproveSonod from "../../../../components/elements/applicationCertificate/modal/ApproveSonod";
import CertificatePrintModal from "../../../../components/elements/applicationCertificate/modal/CertificatePrintModal";
import { useSelector } from "react-redux";
import GetSpinner from "../../../../helpers/shared/GetSpinner";
import Error from "../../../../helpers/shared/Error";
import CreateTradeLicenseMunicipality from "../../../../components/allCertificates/municipality/trade-license/CreateTradeLicenseMunicipality";

function TradeLicenseCertificateMunicipalityPage() {
    const contects = { title: "ট্রেড লাইসেন্স" };
    let content;
    const { userInfo } = useSelector((state) => state.auth);
    const [search, setSearchValue] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [printModalOpen, setPrintModalOpen] = useState({
        type: false,
        data: null,
    });
    const [status, setStatus] = useState({ type: false, data: null });

    const [sonodCreateModal, setSonodCreateModal] = useState({
        type: false,
        data: null,
    });

    const [{ pageIndex, pageSize }, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const pathname = `role=tradelicense&page=${pageIndex}&limit=${pageSize}&search=${search}`;

    const { data, isLoading, isError, refetch } =
        useGetrolemunicipalitycertificateQuery(pathname);

    const certificate = useMemo(
        () => (data ? data?.certificate : []),
        [data, search]
    );
    if (isLoading) content = <Error message={"অপেক্ষা করুন..."} />;
    if (!isLoading && isError) {
        content = <GetSpinner />;
    }
    if (!isLoading && !isError && certificate?.length > 0) {
        content = <div>
            <div className="card mb-4">
                <header className="card-header">
                    <div className="row align-items-center">
                        <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                            <Search setSearchValue={setSearchValue}  placeholder={"সনদ নাম্বার"}/>
                        </div>
                    </div>
                </header>
                <div className="col-md-12 px-4 ">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr className="">
                                    <th>প্রতিষ্ঠানের নাম</th>
                                    <th>মালিকের নাম</th>
                                    <th>জাতীয় পরিচয়পত্র</th>
                                    <th>মোবাইল নম্বর</th>
                                    <th>অবস্থা</th>
                                    <th className="text-end">পদক্ষেপ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {certificate?.map((data) => (
                                    <TradeLicenseSingleSonod
                                        setSonodCreateModal={setSonodCreateModal}
                                        data={data}
                                        key={data._id}
                                        setStatus={setStatus}
                                        setPrintModalOpen={setPrintModalOpen}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Pagination
                totalPages={data?.totalPages}
                setPagination={setPagination}
                pageIndex={pageIndex}
                pageSize={pageSize}
            />
        </div>
    }
    if (!isLoading && !isError && certificate?.length === 0) {
        content = <Error message={"কোন ডাটা পাওয়া যায়নি"} />;
    }

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    useEffect(() => {

    }, [isLoading, isError, refetch]);

    return (
        <DashboardLayout>
            <section className="content-main">
                <div className="content-header ">
                    <div>
                        <h2 className="content-title card-title">{contects?.title}</h2>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="">

                        <p style={{ marginRight: '20px ', }}
                            onClick={() =>
                                setSonodCreateModal({ type: !sonodCreateModal.type })
                            }
                            className="btn btn-primary btn-sm rounded"
                        >
                            নতুন তৈরি করুন
                        </p>

                        <p style={{ marginRight: '20px ', }}
                            onClick={handleOpenModal}
                            className="btn btn-primary btn-sm rounded"
                        >
                            রিপোর্ট ডাউনলোড
                        </p>
                        <div>
                            <CertificateReport handleCloseModal={handleCloseModal} showModal={showModal} userInfo={userInfo}/>
                        </div>


                    </div>
                </div>
                {content}

            </section>


            {userInfo?.users?.role === "secretary" ? (
                <ApproveSonod modal={status} setOpen={setStatus} userInfo={userInfo}/>
            ) : (
                ""
            )}
            <CertificatePrintModal
                title={contects?.title}
                modal={printModalOpen}
                setOpen={setPrintModalOpen}
            />
            <CreateTradeLicenseMunicipality
                contects={contects}
                modal={sonodCreateModal}
                setOpen={setSonodCreateModal}
            />
        </DashboardLayout>
    );
}

export default TradeLicenseCertificateMunicipalityPage;
