import { useDebouncedCallback } from 'use-debounce';
const Search = ({ setSearchValue, placeholder }) => {
    const debounced = useDebouncedCallback(
        (value) => {
            setSearchValue(value);
        }, 1000
    );
    return (
        <input
            onChange={(e) => debounced(e.target.value)}
            type="text"
            placeholder={placeholder}
            className="form-control"
        />
    )
}

export default Search;