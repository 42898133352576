import * as yup from "yup";

export const allSonodCertificateSchema = yup.object().shape({
  name_bangla: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),

  name_english: yup
    .string()
    .required("English Name Field Can Not Be Empty.")
    .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),

  father_husband_bangla: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),

  father_husband_english: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),

  mother_name_bangla: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),

  mother_name_english: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),
  number: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[0-9]{11}$/, "মোবাইল নম্বরটি ১১ টি সংখ্যা হতে হবে"),

  nid: yup
    .string()
    .required("এন আইডি কমপক্ষে ১০  এবং ১৭ অক্ষরের হতে হবে")
    .matches(
      /^[0-9]{10}$|^[0-9]{17}$/,
      "জাতীয় পরিচয়পত্র নম্বরটি ১০ বা ১৭ টি সংখ্যা হতে হবে"
    ),
  date_of_birth: yup.string().required("খালি রাখা যাবে না"),
  gender: yup.string().required("খালি রাখা যাবে না"),
  blood_group: yup.string(),
  post_office_bn: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),
  post_office_en: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),
  ward_no: yup.string().required("খালি রাখা যাবে না"),
  road: yup.string(),
});

export const AnnualAndMonthIncomeCertificateSchema = yup.object().shape({
  name_bangla: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),

  name_english: yup
    .string()
    .required("English Name Field Can Not Be Empty.")
    .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),

  father_husband_bangla: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),

  father_husband_english: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),

  mother_name_bangla: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),

  mother_name_english: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),
  number: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[0-9]{11}$/, "মোবাইল নম্বরটি ১১ টি সংখ্যা হতে হবে"),

  nid: yup
    .string()
    .required("এন আইডি কমপক্ষে ১০  এবং ১৭ অক্ষরের হতে হবে")
    .matches(
      /^[0-9]{10}$|^[0-9]{17}$/,
      "জাতীয় পরিচয়পত্র নম্বরটি ১০ বা ১৭ টি সংখ্যা হতে হবে"
    ),
  date_of_birth: yup.string().required("খালি রাখা যাবে না"),
  income: yup.string().required("খালি রাখা যাবে না"),
  gender: yup.string().required("খালি রাখা যাবে না"),
  blood_group: yup.string(),
  post_office_bn: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),
  post_office_en: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),
  ward_no: yup.string().required("খালি রাখা যাবে না"),
  road: yup.string(),
});
export const SamePersonCertificateSchema = yup.object().shape({
  name_bangla: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),

  name_english: yup
    .string()
    .required("English Name Field Can Not Be Empty.")
    .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),

  father_husband_bangla: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),

  father_husband_english: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),

  mother_name_bangla: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),
  mother_name_english: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),
  samePersonBn: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),

  samePersonEn: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),
  number: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[0-9]{11}$/, "মোবাইল নম্বরটি ১১ টি সংখ্যা হতে হবে"),

  nid: yup
    .string()
    .required("এন আইডি কমপক্ষে ১০  এবং ১৭ অক্ষরের হতে হবে")
    .matches(
      /^[0-9]{10}$|^[0-9]{17}$/,
      "জাতীয় পরিচয়পত্র নম্বরটি ১০ বা ১৭ টি সংখ্যা হতে হবে"
    ),
  date_of_birth: yup.string().required("খালি রাখা যাবে না"),
  gender: yup.string().required("খালি রাখা যাবে না"),
  blood_group: yup.string(),
  post_office_bn: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),
  post_office_en: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),
  ward_no: yup.string().required("খালি রাখা যাবে না"),
  road: yup.string(),
});


export const CreateTradeLicenseSchema = yup.object().shape({
  institution_bn_name: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[ঀ-৾\s,-:।]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),
  institution_en_name: yup.string(),
  institution_bn_type: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[ঀ-৾\s,-:।]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),
  institution_en_type: yup.string(),
  institution_nature: yup.string().required("খালি রাখা যাবে না"),
  trade_licenses_types: yup.string().required("খালি রাখা যাবে না"),
  tin: yup.string(),
  bin: yup.string(),
  institution_address_en: yup.string(),
  institution_address_bn: yup.string(),

  joint_name_bn: yup.string(),
  joint_name_en: yup.string(),
  joint_nid: yup.string(),

  license_fee: yup.string().required("খালি রাখা যাবে না"),
  vat: yup.string(),
  taxes_on_profession_trades: yup.string().required("খালি রাখা যাবে না"),
  signboard: yup.string(),
  due: yup.string(),
  service_charge: yup.string(),
});
export const CreateTradeLicenseEditSchema = yup.object().shape({
  institution_bn_name: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[ঀ-৾\s,-:।]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),
  institution_en_name: yup.string(),
  institution_bn_type: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[ঀ-৾\s,-:।]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),
  institution_en_type: yup.string(),
  institution_nature: yup.string().required("খালি রাখা যাবে না"),
  trade_licenses_types: yup.string().required("খালি রাখা যাবে না"),
  tin: yup.string(),
  bin: yup.string(),
  institution_address_en: yup.string(),
  institution_address_bn: yup.string(),

  joint_name_bn: yup.string(),
  joint_name_en: yup.string(),
  joint_nid: yup.string(),

  license_fee: yup.string().required("খালি রাখা যাবে না"),
  vat: yup.string(),
  taxes_on_profession_trades: yup.string().required("খালি রাখা যাবে না"),
  signboard: yup.string(),
  due: yup.string(),
  service_charge: yup.string(),
});

export const CreateDeathSchema = yup.object().shape({
  death_person_bn: yup.string().required("খালি রাখা যাবে না"),
  death_person_en: yup.string().required("খালি রাখা যাবে না"),
  death_father_husband_bn: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),
  death_father_husband_en: yup.string(),
  date_mother_bn: yup.string(),
  date_mother_en: yup.string(),
  date_of_birth: yup.string().required("খালি রাখা যাবে না"),
  death_register_no: yup.string().required("খালি রাখা যাবে না"),
});

export const FimilyCertificateSchema = yup.object().shape({
  name_bangla: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),

  name_english: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),

  father_husband_bangla: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),

  father_husband_english: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),

  mother_name_bangla: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),

  mother_name_english: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),
  number: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[0-9]{11}$/, "মোবাইল নম্বরটি ১১ টি সংখ্যা হতে হবে"),

  nid: yup
    .string()
    .required("এন আইডি কমপক্ষে ১০  এবং ১৭ অক্ষরের হতে হবে")
    .matches(
      /^[0-9]{10}$|^[0-9]{17}$/,
      "জাতীয় পরিচয়পত্র নম্বরটি ১০ বা ১৭ টি সংখ্যা হতে হবে"
    ),
  date_of_birth: yup.string().required("খালি রাখা যাবে না"),
});

export const WarishSonodCreateSchema = yup.object().shape({
  name_bangla: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),

  name_english: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),

  father_husband_bangla: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),

  father_husband_english: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),

  mother_name_bangla: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),

  mother_name_english: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[A-Za-z\s:.,-]+$/, "কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত"),
  number: yup
    .string()
    .required("খালি রাখা যাবে না")
    .matches(/^[0-9]{11}$/, "মোবাইল নম্বরটি ১১ টি সংখ্যা হতে হবে"),

  nid: yup
    .string()
    .required("এন আইডি কমপক্ষে  ১০  এবং ১৭ অক্ষরের হতে হবে")
    .matches(
      /^[0-9]{10}$|^[0-9]{17}$/,
      "জাতীয় পরিচয়পত্র নম্বরটি ১০ বা ১৭ টি সংখ্যা হতে হবে"
    ),
  date_of_birth: yup.string().required("খালি রাখা যাবে না"),
  living_person_name_bn: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),
  living_person_name_en: yup.string(),
  living_person_father_husband_bn: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),
  living_person_father_husband_en: yup.string(),
  living_person_mother_bn: yup.string(),
  living_person_mother_en: yup.string(),
  living_person_nid_no: yup.string().required("খালি রাখা যাবে না."),
  living_person_date: yup.string().required("খালি রাখা যাবে না."),
});
export const WarishSonodUpdateCreateSchema = yup.object().shape({
  living_person_name_bn: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),
  living_person_name_en: yup.string(),
  living_person_father_husband_bn: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),
  living_person_father_husband_en: yup.string(),
  living_person_mother_bn: yup.string(),
  living_person_mother_en: yup.string(),
  living_person_nid_no: yup.string().required("খালি রাখা যাবে না."),
  living_person_date: yup.string().required("খালি রাখা যাবে না."),
  /*  living_person_name_bn: yup.string().required("খালি রাখা যাবে না.")
     .matches(/^[ঀ-৾\s,:-]+$/i, 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত'),
   living_person_name_en: yup.string().required("খালি রাখা যাবে না.").matches(/^[A-Za-z\s:.,-]+$/, 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত'),
   living_person_father_husband_bn: yup.string().required("খালি রাখা যাবে না.")
     .matches(/^[ঀ-৾\s,:-]+$/i, 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত'),
   living_person_father_husband_en: yup.string().required("খালি রাখা যাবে না.").matches(/^[A-Za-z\s:.,-]+$/, 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত'),
   living_person_nid_no: yup.string().required("খালি রাখা যাবে না."),
   living_person_date: yup.string().required("খালি রাখা যাবে না."), */
});

export const OtherCertificateSchema = yup.object().shape({
  title_bn: yup
    .string()
    .required("খালি রাখা যাবে না.")
    .matches(/^[ঀ-৾\s,:-]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),
  title_en: yup
    .string(),

});
