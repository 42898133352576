import EnglishToBanglaNumber from "./EnglishToBanglaNumber";

const SubscriptionEndDate = (subDate, ammount) => {
    let content;
    var givenDate = new Date(subDate);
    var currentDate = new Date();
    var timeDifference = givenDate - currentDate;
    var daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    if (daysDifference > 0) {
        daysDifference = daysDifference;
        content = <span className="px-3">
            আপনার সাবস্ক্রিপশন টি চালু  আছে <span style={{
                color: 'red'
            }}>
                {EnglishToBanglaNumber(`${daysDifference}`)}
            </span> দিন। <br />
            {
                ammount? <span className="px-3"

                >আপনার একাউন্টে টাকার পরিমাণ <span
                    style={{
                        color: 'red'
                    }}
                >
                        {EnglishToBanglaNumber(`${ammount} `)} {''}
                    </span>৳</span>:''
            }


        </span>
    } else {
        content = <span>আপনার সাবস্ক্রিপশনটি এখন বন্ধ আছে</span>
    }
    return content;
}
export default SubscriptionEndDate;
