import { useLocation } from "react-router-dom";
import DashboardLayout from "../../components/layout/DashboardLayout";
import { useEkpayTransIdQuery } from "../../app/services/recharge";
import Spinner from "../../helpers/shared/Spinner";

const Rechargesuccess = () => {
    let content;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const transId = searchParams.get('transId');
    const { data, isLoading, isSuccess } = useEkpayTransIdQuery(transId)
    if (isLoading) {
        content = <Spinner />
    }
    if (isSuccess && !isLoading) {
        if (data?.status) {
            content = <div style={{ width: "100%", height: '80vh', display: 'flex', justifyContent: "center", alignItems: "center" }}>
                <div>
                    <h1 className="success_color">সঠিকভাবে রিচার্জ করা হয়েছে</h1>
                </div>
            </div>
        } else {
            content = <div style={{ width: "100%", height: '80vh', display: 'flex', justifyContent: "center", alignItems: "center" }}>
                <h1 className="error_color"> আপনার রিচার্জ টি বাতিল করা হয়েছে</h1>
            </div>
        }

    }


    return (
        <DashboardLayout>
            {
                content
            }
        </DashboardLayout>
    )
}
export default Rechargesuccess;