import React, { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { UserApproveSchema } from '../../../helpers/validation/UserApproveSchema';
import { usePutUserAccountHandelMutation } from '../../../app/services/authUser';

function UserApprove({ modal, setOpen }) {
    const { type, data } = modal;
    const [UpdateAccountHandel, { isSuccess, isLoading, error, isError, data: accountData }] = usePutUserAccountHandelMutation()
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(UserApproveSchema) });
    const onSubmit = async (value) => {
        const userHandel = { value, id: data?._id }
        await UpdateAccountHandel(userHandel)
    }

    const accountHandel = [
        {
            value: 'true',
            type: true,
        },
        {
            value: 'false',
            type: false,
        }
    ]
    const statusHandel = [
        {
            value: 'pending',
            type: 'pending',
        },
        {
            value: 'approved',
            type: 'approved',
        }
    ]

    useEffect(() => {
        if (isSuccess) {
            toast.success('একাউন্টটি গ্রহণ করা হয়েছ')
            reset()
            setOpen({ type: false })
        }
        if (error) {
            toast.error(error?.data?.error)
            setOpen({ type: false })
        }
    }, [isSuccess, error])

    return (
        <>{
            type &&
            <div className='modal_product'>
                <section className="content-main">
                    <div className="row">
                        <div className="col-12">
                            <div className="content-header">
                                <h2 className="content-title">ইউনিয়ন সচিব</h2>
                                <button onClick={() => setOpen({ type: false }, reset())}>X</button>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="col-lg-12">
                                <div className="card mb-4">
                                    <div className="card-body">

                                        <div className="row gx-2">
                                            <div className="col-12 mb-3">
                                                <label className="form-label">Account Approve</label>
                                                <select className="form-control"
                                                    {...register("approve")}
                                                >
                                                    {
                                                        accountHandel?.map(account => <option value={account?.type} selected={data?.approve == account?.type}>{account?.value}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-12 mb-3">
                                                <label className="form-label">Status</label>
                                                <select className="form-control"  {...register("status")} >

                                                    {
                                                        statusHandel?.map(statu => <option key={statu?.value} value={statu?.type}
                                                            selected={data?.status == statu?.type}
                                                        >{statu?.value}</option>)
                                                    }

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} className="btn btn-md rounded font-sm hover-up">Submit</button>
                            </div>
                        </form>

                    </div>

                </section>

            </div>
        }
        </>
    )
}

export default UserApprove