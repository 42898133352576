import React, { useEffect, useMemo, useState } from 'react'
import DashboardLayout from '../../../components/layout/DashboardLayout'
import GetSpinner from '../../../helpers/shared/GetSpinner';
import SingleEmployee from '../../../components/elements/user/SingleEmployee';
import Pagination from '../../../components/elements/Pagination';
import UserApprove from '../../../components/elements/modal/EmployeeEditModal';
import { useAllUserGetQuery } from '../../../app/services/authUser';
import { useGetSubscriptionQuery, useUpdateSubscriptionMutation } from '../../../app/services/subscription';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import SingleDonation from './SingleDonation';
import Search from '../../../utils/Search';
function DonationRequst({ userInfo }) {
    const [Loading, setLoading] = useState(false)
    const [search, setSearchValue] = useState('')
    const [status, setStatus] = useState({ type: false, data: null })
    const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10, });
    const pathname = `page=${pageIndex}&limit=${pageSize}`;
    const { data, isLoading } = useGetSubscriptionQuery(pathname)
    const [updateSubscriptionMutation, { isLoading: updateLoading, isSuccess: updateisSuccess }] = useUpdateSubscriptionMutation()

    const subscriptions = useMemo(() => (data ? data?.result
        : []), [
        data
    ]);
    useEffect(() => {
        if (updateisSuccess) {
            toast('আবেদন গ্রহণ করা হয়েছ')
        }
        if (isLoading) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [isLoading])

    const subscriptionsHandelstatus = async (subscription) => {
        if (userInfo?.role === 'admin') {
            const updatedData = { data: { status: true, month: subscription?.month, uid: subscription?.uid }, id: subscription?._id };
            await updateSubscriptionMutation(updatedData)
        }
    }



    return (
        <DashboardLayout>
            <section className="content-main">
                <div className="content-header">
                    <div>
                        <h2 className="content-title card-title">সাবস্ক্রিপশন হিসাব</h2>
                    </div>
                </div>
                <div className="card mb-4">
                    <header className="card-header">
                        <div className="row align-items-center">
                            <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                                <Search setSearchValue={setSearchValue} placeholder="অনুসন্ধান করুন"/>

                            </div>
                        </div>
                    </header>
                    <div className="col-md-12 px-4 ">
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr className=''>
                                        <th>তারিখ</th>
                                        <th>মাস</th>
                                        <th>টাকার পরিমান</th>
                                        <th>নাম্বার</th>
                                        <th>লেনদেন আইডি</th>
                                        <th>পাঠানোর মাধ্যম</th>
                                        <th>স্ট্যাটাস</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Loading && <GetSpinner />}
                                    {subscriptions.map(subscription =><SingleDonation updateLoading={updateLoading} subscription={subscription} subscriptionsHandelstatus={subscriptionsHandelstatus}/>)}

                                </tbody>
                    </table>
                </div>
            </div>

        </div>
        <Pagination totalPages={data?.totalPages} setPagination={setPagination} pageIndex={pageIndex} pageSize={pageSize} />
            </section >
        <UserApprove
            modal={status}
            setOpen={setStatus}
        />
        </DashboardLayout >
    )
}


function mapStateToProps(state) {
    return { userInfo: state.auth?.userInfo?.users }
}



export default connect(mapStateToProps, null)(DonationRequst) 