import React from 'react'
import { Link } from 'react-router-dom'
import Sebas from './Sebas'
import ImportantLink from './ImportantLink'

export default function SonodServices() {
    return (
        <div className='sonodContainer'>
            <div className='row'>
                <div className='col-lg-8'>
                    <div className=' sonodLeft shadow-md pb-5'>
                        <div >
                            <h4 className='sonodBtn'>সনদের সেবাসমূহ</h4>
                            <hr />
                        </div>
                        <div>
                            <Sebas></Sebas>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className='  sonodRight'>
                        <h4 className='sonodBtn '>কার্যালয় আওতাভুক্তকরনের আবেদন</h4>
                        <hr className='' />
                        <div className='grid grid-cols-1 gap-2 p-5 pt-0'>

                            <Link to='/' className=" rightBtn bg-primary">সিটি কর্পোরেশন আন্তর্ভুক্ত</Link>
                            <Link to='/municipality-signUp' className=" rightBtn bg-info">পৌরসভা আন্তর্ভুক্ত</Link>
                            <Link to='/union-signUp' className=" rightBtn bg-success">ইউনিয়ন আন্তর্ভুক্ত</Link>
                        </div>
                    </div>
                    <div className='sonodRight'>
                        <h4 className='sonodBtn '>গুরুত্বপূর্ণ লিংক</h4>
                        <hr className='mb-3' />
                        <ImportantLink></ImportantLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
