import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import DashboardLayout from "../../layout/DashboardLayout";
import { useParams } from "react-router-dom";
import GetSpinner from "../../../helpers/shared/GetSpinner";
import { useGetViewsCitizenQuery, useUpdateCitizenMutation, useUpdateCitizenProfilePictureMutation, } from "../../../app/services/citizen";
import profileImages from "../../../assets/imgs/people/avatar-3.png";
import { fetchBloodData, fetchGender } from "../../../utils/Fetch";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useGetDistrictsQuery, useGetDivisionsQuery, useGetUnionsQuery, useGetUpazilasQuery } from "../../../app/services/location";

function ViewsCitizan() {
  /* react hook state*/
  const { userInfo } = useSelector((state) => state.auth);
  const { id } = useParams();
  const inputFileRef = useRef(null);
  const [fileObject, setFileObject] = useState();
  const [image, setImage] = useState();
  const [selectStoreDistricts, setSelectStoreDistricts] = useState(null)
  const [selectStoreUpazilas, setSelectStoreUpazilas] = useState(null)
  const [selectStoreUnions, setSelectStoreUnions] = useState(null)
  const [selectPermanentStoreDistricts, setSelectPermanentStoreDistricts] = useState(null)
  const [selectPermanentStoreUpazilas, setSelectPermanentStoreUpazilas] = useState(null)
  const [selectPermanentStoreUnions, setSelectPermanentStoreUnions] = useState(null)


  /* rtk query */
  const { data: divisions } = useGetDivisionsQuery()
  const { data: districts } = useGetDistrictsQuery()
  const { data: upazilas } = useGetUpazilasQuery()
  const { data: unions } = useGetUnionsQuery()
  const { data: UserData, isLoading: Loding } = useGetViewsCitizenQuery(id);
  const [UpdateAccountHandel, { isLoading, isSuccess, isError, error }] = useUpdateCitizenMutation();
  const [updateImage, { isSuccess: success, isError: isErr, error: err }] = useUpdateCitizenProfilePictureMutation();

  /* handel data  */
  const filterData = useMemo(() => (UserData ? UserData : []), [UserData, Loding]);
  const accountType = userInfo?.users?.account;
  const data = filterData?.data;
  /* userhookform */
  const { register, handleSubmit, formState: { errors } } = useForm();



  const selectDistrict = async (e) => {
    e.preventDefault()
    const id = e.target.value;
    const result = await districts?.district?.filter(district => id === district?.division_id)
    setSelectStoreDistricts(result)
  }
  const selectUpdzilas = async (e) => {
    e.preventDefault()
    const id = e.target.value;
    const result = await upazilas?.upazila?.filter(upazila => id === upazila?.district_id)
    setSelectStoreUpazilas(result)
  }
  const selectUnion = async (e) => {
    e.preventDefault()
    const id = e.target.value;
    const result = await unions?.union?.filter(union => id === union?.upazilla_id)
    setSelectStoreUnions(result)
  }
  /* permanent address  */
  const selectPermanentDistrict = async (e) => {
    e.preventDefault()
    const id = e.target.value;
    const result = await districts?.district?.filter(district => id === district?.division_id)
    setSelectPermanentStoreDistricts(result)
  }
  const selectPermanentUpdzilas = async (e) => {
    e.preventDefault()
    const id = e.target.value;
    const result = await upazilas?.upazila?.filter(upazila => id === upazila?.district_id)
    setSelectPermanentStoreUpazilas(result)
  }
  const selectPermanentUnion = async (e) => {
    e.preventDefault()
    const id = e.target.value;
    console.log(id)
    const result = await unions?.union?.filter(union => id === union?.upazilla_id)
    console.log(unions?.union)
    setSelectPermanentStoreUnions(result)
  }

  /* submit data */
  const createOnSubmit = async (updateData) => {

    const division = await divisions?.division?.find(value => value?.id === updateData?.present_division)
    const district = await districts?.district?.find(value => value?.id === updateData?.present_district)
    const upazila = await upazilas?.upazila?.find(value => value?.id === updateData?.present_upazila)
    const union = await unions?.union?.find(value => value?.id === updateData?.present_union);
    /* permanent */
    const division_permanent = await divisions?.division?.find(value => value?.id === updateData?.permanent_division)
    const district_permanent = await districts?.district?.find(value => value?.id === updateData?.permanent_district)
    const upazila_permanent = await upazilas?.upazila?.find(value => value?.id === updateData?.permanent_upazila)
    const union_permanent = await unions?.union?.find(value => value?.id === updateData?.permanent_union);

    const updatetosentData = {
      ...updateData,
      permanent_address: [
        {
          division: division_permanent?._id,
          district: district_permanent?._id,
          upazila: upazila_permanent?._id,
          union: union_permanent?._id,
          municipality_bn_name: updateData?.municipality_bn_name,
          municipality_en_name: updateData?.municipality_en_name,
          post_office_bn: updateData?.post_office_bn,
          post_office_en: updateData?.post_office_en,
          ward_no: updateData?.ward_no,
          village_bn_name: updateData?.village_bn_name,
          village_en_name: updateData?.village_en_name,
          holding_no: updateData?.holding_no,
          road: updateData?.road,
        }
      ],
      present_address: [
        {
          division: division?._id,
          district: district?._id,
          upazila: upazila?._id,
          union: union?._id,
          municipality_bn_name: updateData?.municipality_bn_name,
          municipality_en_name: updateData?.municipality_en_name,
          post_office_bn: updateData?.presentPost_office_bn,
          post_office_en: updateData?.presentPost_office_en,
          ward_no: updateData?.presentWard_no,
          village_bn_name: updateData?.presentVillage_bn_name,
          village_en_name: updateData?.presentVillage_en_name,
          holding_no: updateData?.presentHolding_no,
          road: updateData?.presentRoad,
        }
      ],
    }
    console.log(updatetosentData)
    const userHandel = { data: { ...updatetosentData }, id: data?._id };
    await UpdateAccountHandel(userHandel);
  };

  /* ========= photo upload function =========  */
  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (fileObj) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setFileObject(fileObj);
      };
      reader.readAsDataURL(fileObj);
    }
  };

  const handleShowModal = () => {
    inputFileRef.current.click();
  };

  const updateProfilePicture = () => {
    if (fileObject && id) {
      const data = new FormData();
      data.append("image", fileObject);
      updateImage({ data, id });
    } else {
    }
  };

  /*  useEffect */
  useEffect(() => {
    if (data?.profilePicture) {
      setImage(data?.profilePicture);
    }
    if (isSuccess || success) {
      toast.success("সঠিকভাবে নাগরিক এড করা হয়েছ");
    }
    if (isErr) {
      toast.error(err?.data?.error);
    }
    if (isError) {
      toast.error(error?.data?.error);
    }
  }, [data, success, isLoading, isSuccess, isError]);




  return (
    <DashboardLayout>
      {Loding ? (
        <GetSpinner />
      ) : (
        <>
          <section className="content-main">
            <div className="content-header">
              <h2 className="content-title">নাগরিক প্রোফাইল</h2>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row gx-5">
                  <div className="col-lg-12">
                    <section className="content-body p-xl-4">
                      <form onSubmit={handleSubmit(createOnSubmit)}>
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="card mb-4">
                              <div className="card-body">
                                <div className="row gx-2">
                                  <div className="col-6 mb-3">
                                    <label className="form-label">
                                      নাম: (বাংলা){" "}
                                      <span className="from_required">*</span>{" "}
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="নাম বাংলা"
                                      defaultValue={data?.name_bangla}
                                      {...register('name_bangla', {
                                        required: 'খালি রাখা যাবে না',
                                        pattern: {
                                          value: /^[ঀ-৾\s:-]+$/i,
                                          message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                        },
                                      })}
                                    />
                                    {errors?.name_bangla && (
                                      <span className="form__error">
                                        {errors?.name_bangla.message}
                                      </span>
                                    )}
                                  </div>

                                  <div className="col-lg-6 mb-3">
                                    <label className="form-label">
                                      নাম: (ইংরেজিতে){" "}
                                      <span className="from_required">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="নাম ইংরেজিতে"
                                      defaultValue={data?.name_english}
                                      {...register('name_english', {
                                        required: 'খালি রাখা যাবে না',
                                        pattern: {
                                          value: /^[A-Za-z\s]+$/,
                                          message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                        },
                                      })}
                                    />
                                    {errors?.name_english && (
                                      <span className="form__error">
                                        {errors?.name_english.message}
                                      </span>
                                    )}
                                  </div>

                                </div>
                                <div className="row gx-2">
                                  <div className="col-lg-6 mb-3">
                                    <label className="form-label">
                                      পিতা / স্বামীর নাম: (বাংলা){" "}
                                      <span className="from_required">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="পিতা / স্বামীর নাম বাংলা"
                                      defaultValue={data?.father_husband_bangla}
                                      {...register('father_husband_bangla', {
                                        required: 'খালি রাখা যাবে না',
                                        pattern: {
                                          value: /^[ঀ-৾\s:-]+$/i,
                                          message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                        },
                                      })}
                                    />
                                    {errors?.father_husband_bangla && (
                                      <span className="form__error">
                                        {errors?.father_husband_bangla.message}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-lg-6 mb-3">
                                    <label className="form-label">
                                      পিতা / স্বামীর নাম: (ইংরেজিতে){" "}
                                      <span className="from_required">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="পিতা / স্বামীর নাম ইংরেজিতে"
                                      defaultValue={
                                        data?.father_husband_english
                                      }
                                      {...register('father_husband_english', {
                                        required: 'খালি রাখা যাবে না',
                                        pattern: {
                                          value: /^[A-Za-z\s]+$/,
                                          message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                        },
                                      })}
                                    />
                                    {errors?.father_husband_english && (
                                      <span className="form__error">
                                        {errors?.father_husband_english.message}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="row gx-2">
                                  <div className="col-lg-3 mb-3">
                                    <label className="form-label">
                                      মাতার নাম: (বাংলা){" "}
                                      <span className="from_required">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="মাতার নাম বাংলা"
                                      defaultValue={data?.mother_name_bangla}
                                      {...register('mother_name_bangla', {
                                        required: 'খালি রাখা যাবে না',
                                        pattern: {
                                          value: /^[ঀ-৾\s:-]+$/i,
                                          message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                        },
                                      })}



                                    />
                                    {errors?.mother_name_bangla && (
                                      <span className="form__error">
                                        {errors?.mother_name_bangla.message}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-lg-3 mb-3">
                                    <label className="form-label">
                                      মাতার নাম: (ইংরেজিতে){" "}
                                      <span className="from_required">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="মাতার নাম ইংরেজিতে"
                                      defaultValue={data?.mother_name_english}
                                      {...register('mother_name_english', {
                                        required: 'খালি রাখা যাবে না',
                                        pattern: {
                                          value: /^[A-Za-z\s]+$/,
                                          message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                        },
                                      })}
                                    />
                                    {errors?.mother_name_english && (
                                      <span className="form__error">
                                        {errors?.mother_name_english.message}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-lg-3 mb-3">
                                    <label className="form-label">
                                      মোবাইল নম্বর{" "}
                                      <span className="from_required">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="number"
                                      placeholder="মোবাইল নম্বর"
                                      defaultValue={data?.number}
                                      {...register("number")}
                                    />
                                    {errors?.number && (
                                      <span className="form__error">
                                        {errors?.number.message}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-lg-3 mb-3">
                                    <label className="form-label">
                                      জাতীয় পরিচয়পত্র নং{" "}
                                      <span className="from_required">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="number"
                                      defaultValue={data?.nid}
                                      placeholder="জাতীয় পরিচয়পত্র নং"
                                      {...register("nid")}
                                    />
                                    {errors?.nid && (
                                      <span className="form__error">
                                        {errors?.nid.message}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="row gx-2">
                                  <div className="col-lg-3 mb-3">
                                    <label className="form-label">
                                      জন্ম তারিখ{" "}
                                      <span className="from_required">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="date"
                                      placeholder="জন্ম তারিখ"
                                      defaultValue={data?.date_of_birth}
                                      {...register("date_of_birth")}
                                    />
                                    {errors?.date_of_birth && (
                                      <span className="form__error">
                                        {errors?.date_of_birth.message}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-md-3 mb-3">
                                    <label
                                      htmlFor="Promo_amount"
                                      className="form-label"
                                    >
                                      লিঙ্গ{" "}
                                      <span className="from_required">*</span>
                                    </label>
                                    <select
                                      className="form-select"
                                      {...register("gender")}
                                    >
                                      {fetchGender?.map((gender) => (
                                        <option
                                          key={gender?.id}
                                          value={gender?.en}
                                          selected={data?.gender === gender?.en}
                                        >
                                          {gender?.bd}
                                        </option>
                                      ))}
                                    </select>
                                    {errors?.gender && (
                                      <span className="form__error">
                                        {errors?.gender.message}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-lg-3 mb-3">
                                    <label className="form-label">
                                      রক্তের গ্রুপ
                                    </label>
                                    <select
                                      className="form-select"
                                      {...register("blood_group")}
                                    >
                                      {fetchBloodData?.map((blood) => (
                                        <option
                                          key={blood?.id}
                                          value={blood?.en}
                                          selected={
                                            data?.blood_group === blood?.en
                                          }
                                        >
                                          {blood?.bd}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                <div className="mb-2">
                                  <h6>স্থায়ী ঠিকানা</h6>
                                </div>

                                {/* permanent address */}

                                <div className="row gx-3">

                                  <div className="col-lg-3">
                                    <label className="form-label">
                                      বিভাগ
                                      <span className="from_required">*</span>
                                    </label>
                                    <select className="form-control" {...register('permanent_division')}
                                      onChange={(e) => selectPermanentDistrict(e)}
                                    >
                                      {
                                        divisions?.division?.map(divisio => <option
                                          className="form-control" key={divisio?._id} value={divisio?.id} selected={divisio?._id === data?.permanent_address[0]?.division?._id}>{divisio?.bn_name}</option>)
                                      }
                                    </select>
                                  </div>
                                  <div className="col-lg-3">
                                    <label htmlFor='prDistrict' className="form-label">
                                      জেলা
                                      <span className="from_required">*</span>
                                    </label>
                                    <select className="form-control"
                                      {...register('permanent_district')} onChange={(e) => selectPermanentUpdzilas(e)}
                                    >
                                      {
                                        selectPermanentStoreDistricts ? selectPermanentStoreDistricts?.map(district => <option
                                          className="form-control" key={district?._id} value={district?.id} selected={district?._id === data?.permanent_address[0]?.district?._id}
                                        >{district?.bn_name}</option>) : districts?.district?.map(district => <option
                                          className="form-control" key={district?._id}
                                          value={district?.id}
                                          selected={district?._id === data?.permanent_address[0]?.district?._id}
                                        >{district?.bn_name}</option>)
                                      }
                                    </select>

                                  </div>
                                  <div className="col-lg-3">
                                    <label htmlFor='prUpazila' className="form-label">
                                      থানা / উপজেলা
                                      <span className="from_required">*</span>
                                    </label>
                                    <select className="form-control"
                                      {...register('permanent_upazila')}
                                      onChange={(e) => selectPermanentUnion(e)}
                                    >
                                      {
                                        selectPermanentStoreUpazilas ? selectPermanentStoreUpazilas?.map(upazila => <option
                                          className="form-control"
                                          key={upazila?._id} value={upazila?.id} selected={upazila?._id === data?.permanent_address[0]?.upazila?._id}>{upazila?.bn_name}</option>) : upazilas?.upazila?.map(upazila => <option
                                            className="form-control" key={upazila?._id}
                                            value={upazila?.id}
                                            selected={upazila?._id === data?.permanent_address[0]?.upazila?._id}
                                          >{upazila?.bn_name}</option>)
                                      }
                                    </select>

                                  </div>

                                  {accountType === "municipality" ? (
                                    <>
                                      <div className="col-lg-3 ">
                                        <label className="form-label">
                                          পৌরসভা (বাংলা)
                                          <span className="from_required">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          className="form-control"
                                          type="text"
                                          defaultValue={
                                            data?.permanent_address[0]
                                              ?.municipality_bn_name
                                          }
                                        />
                                      </div>
                                      <div className="col-lg-3 mt-3">
                                        <label className="form-label">
                                          পৌরসভা (ইংরেজিতে)
                                          <span className="from_required">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          className="form-control"
                                          type="text"
                                          defaultValue={
                                            data?.permanent_address[0]
                                              ?.municipality_en_name
                                          }
                                        />
                                      </div>``
                                    </>
                                  ) : (
                                    <div className="col-lg-3">
                                      <label className="form-label">
                                        ইউনিয়ন
                                        <span className="from_required">*</span>
                                      </label>
                                      <select className="form-control" {...register('permanent_union')}>
                                        {
                                          selectPermanentStoreUnions ? selectPermanentStoreUnions?.map(union => <option
                                            className="form-control"
                                            key={union?._id} value={union?.id} selected={union?._id == data?.permanent_address[0]?.union?._id}>{union?.bn_name}</option>) : unions?.union?.map(union => <option
                                              className="form-control" key={union?._id}
                                              value={union?.id}
                                              selected={union?._id === data?.permanent_address[0]?.union?._id}
                                            >{union?.bn_name}</option>)
                                        }

                                      </select>


                                    </div>
                                  )}
                                  <div className="col-lg-3 mb-2 mt-3">
                                    <label
                                      htmlFor="prPost_office_bn"
                                      className="form-label"
                                    >
                                      ডাকঘর (বাংলা)
                                      <span className="from_required">
                                        *
                                      </span>{" "}
                                    </label>
                                    <input
                                      id="prPost_office_bn"
                                      name="prPost_office_bn"
                                      className="form-control"
                                      type="text"
                                      defaultValue={
                                        data?.permanent_address[0]
                                          ?.post_office_bn
                                      }
                                      {...register('post_office_bn', {
                                        required: 'খালি রাখা যাবে না',
                                        pattern: {
                                          value: /^[ঀ-৾\s:-]+$/i,
                                          message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                        },
                                      })}
                                    />
                                    {errors?.post_office && (
                                      <span className="form__error">
                                        {errors?.post_office.message}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-lg-3 mb-2 mt-3">
                                    <label
                                      htmlFor="prPost_office_en"
                                      className="form-label"
                                    >
                                      ডাকঘর (ইংরেজিতে){" "}
                                      <span className="from_required">*</span>{" "}
                                    </label>
                                    <input
                                      id="prPost_office_en"
                                      name="prPost_office_en"
                                      className="form-control"
                                      type="text"

                                      {...register('post_office_en', {
                                        required: 'খালি রাখা যাবে না',
                                        pattern: {
                                          value: /^[A-Za-z\s]+$/,
                                          message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                        },
                                      })}

                                      defaultValue={
                                        data?.permanent_address[0]
                                          ?.post_office_en
                                      }
                                    />
                                    {errors?.post_office && (
                                      <span className="form__error">
                                        {errors?.post_office.message}
                                      </span>
                                    )}
                                  </div>

                                  <div className="col-lg-3 my-3">
                                    <label className="form-label">
                                      ওয়ার্ড নং{" "}
                                      <span className="from_required">*</span>{" "}
                                    </label>
                                    <input
                                      className="form-control"
                                      type="number"
                                      placeholder="Zip code "
                                      defaultValue={
                                        data?.permanent_address[0]?.ward_no
                                      }
                                      {...register("ward_no")}
                                    />
                                    {errors?.ward_no && (
                                      <span className="form__error">
                                        {errors?.ward_no.message}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-lg-3 mt-3">
                                    <label
                                      htmlFor="prVillage_bn_name"
                                      className="form-label"
                                    >
                                      গ্রাম (বাংলা)
                                      <span className="from_required">
                                        *
                                      </span>{" "}
                                    </label>
                                    <input
                                      id="prVillage_bn_name"
                                      name="prVillage_bn_name"
                                      className="form-control"
                                      type="text"
                                      defaultValue={
                                        data?.permanent_address[0]
                                          ?.village_bn_name
                                      }
                                      {...register('village_bn_name', {
                                        required: 'খালি রাখা যাবে না',
                                        pattern: {
                                          value: /^[ঀ-৾\s:-]+$/i,
                                          message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                        },
                                      })}
                                    />
                                  </div>
                                  <div className="col-lg-3 mb-2">
                                    <label
                                      htmlFor="prVillage_en_name"
                                      className="form-label"
                                    >
                                      গ্রাম (ইংরেজিতে){" "}
                                      <span className="from_required">*</span>{" "}
                                    </label>

                                    <input
                                      id="prVillage_en_name"
                                      name="prVillage_en_name"
                                      className="form-control"
                                      type="text"
                                      {...register('village_en_name', {
                                        required: 'খালি রাখা যাবে না',
                                        pattern: {
                                          value: /^[A-Za-z\s]+$/,
                                          message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                        },
                                      })}
                                      defaultValue={
                                        data?.permanent_address[0]
                                          ?.village_en_name
                                      }
                                    />
                                  </div>
                                  <div className="col-lg-3 ">
                                    <label
                                      htmlFor="prRoad"
                                      className="form-label"
                                    >
                                      রোড / ব্লক / সেক্টর{" "}
                                    </label>
                                    <input
                                      id="prRoad"
                                      name="prRoad"
                                      className="form-control"
                                      type="text"
                                      defaultValue={
                                        data?.permanent_address[0]?.road
                                      }
                                      {...register("road")}
                                    />
                                    {errors?.road && (
                                      <span className="form__error">
                                        {errors?.road.message}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-lg-3 ">
                                    <label htmlFor='holding_no' className="form-label">হোল্ডিং নং</label>
                                    <input
                                      id='holding_no'
                                      className="form-control"
                                      type="text"
                                      defaultValue={
                                        data?.permanent_address[0]?.holding_no
                                      }
                                      {...register('holding_no')}
                                    />
                                  </div>
                                </div>

                                {/* Present Address */}
                                <div className="mb-2">
                                  <h6>বর্তমান ঠিকানা</h6>
                                </div>

                                <div className="row gx-3">
                                  <div className="col-lg-3">
                                    <label className="form-label">
                                      বিভাগ
                                      <span className="from_required">*</span>
                                    </label>
                                    <select className="form-control" {...register('present_division')}
                                      onChange={(e) => selectDistrict(e)}
                                    >
                                      {
                                        divisions?.division?.map(divisio => <option
                                          className="form-control" key={divisio?._id} value={divisio?.id} selected={divisio?._id === data?.present_address[0]?.division?._id}>{divisio?.bn_name}</option>)
                                      }
                                    </select>
                                  </div>
                                  <div className="col-lg-3">
                                    <label htmlFor='prDistrict' className="form-label">
                                      জেলা
                                      <span className="from_required">*</span>
                                    </label>
                                    <select className="form-control"
                                      {...register('present_district')} onChange={(e) => selectUpdzilas(e)}
                                    >
                                      {
                                        selectStoreDistricts ? selectStoreDistricts?.map(district => <option
                                          className="form-control" key={district?._id} value={district?.id} selected={district?._id === data?.present_address[0]?.district?._id}
                                        >{district?.bn_name}</option>) : districts?.district?.map(district => <option
                                          className="form-control" key={district?._id}
                                          value={district?.id}
                                          selected={district?._id === data?.present_address[0]?.district?._id}
                                        >{district?.bn_name}</option>)
                                      }
                                    </select>

                                  </div>
                                  <div className="col-lg-3">
                                    <label htmlFor='prUpazila' className="form-label">
                                      থানা / উপজেলা
                                      <span className="from_required">*</span>
                                    </label>
                                    <select className="form-control" {...register('present_upazila')}
                                      onChange={(e) => selectUnion(e)}
                                    >
                                      {
                                        selectStoreUpazilas ? selectStoreUpazilas?.map(upazila => <option
                                          className="form-control"
                                          key={upazila?._id} value={upazila?.id} selected={upazila?._id === data?.present_address[0]?.upazila?._id}>{upazila?.bn_name}</option>) : upazilas?.upazila?.map(upazila => <option
                                            className="form-control" key={upazila?._id}
                                            value={upazila?.id}
                                            selected={upazila?._id === data?.present_address[0]?.upazila?._id}
                                          >{upazila?.bn_name}</option>)
                                      }
                                    </select>

                                  </div>
                                  {accountType === "municipality" ? (
                                    <>
                                      <div className="col-lg-3 ">
                                        <label className="form-label">
                                          পৌরসভা (বাংলা)
                                          <span className="from_required">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          className="form-control"
                                          type="text"
                                          defaultValue={
                                            data?.present_address[0]
                                              ?.municipality_bn_name
                                          }
                                        />
                                      </div>
                                      <div className="col-lg-3 mt-3">
                                        <label className="form-label">
                                          পৌরসভা (ইংরেজিতে)
                                          <span className="from_required">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          className="form-control"
                                          type="text"
                                          defaultValue={
                                            data?.present_address[0]
                                              ?.municipality_en_name
                                          }
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <div className="col-lg-3">
                                      <label className="form-label">
                                        ইউনিয়ন
                                        <span className="from_required">*</span>
                                      </label>
                                      <select className="form-control" {...register('present_union')}>
                                        {
                                          selectStoreUnions ? selectStoreUnions?.map(union => <option
                                            className="form-control"
                                            key={union?._id} value={union?.id} selected={union?._id == data?.present_address[0]?.union?._id}>{union?.bn_name}</option>) : unions?.union?.map(union => <option
                                              className="form-control" key={union?._id}
                                              value={union?.id}
                                              selected={union?._id === data?.present_address[0]?.union?._id}
                                            >{union?.bn_name}</option>)
                                        }

                                      </select>
                                    </div>
                                  )}
                                  <div className="col-lg-3 my-3">
                                    <label
                                      htmlFor="prPost_office_bn"
                                      className="form-label"
                                    >
                                      ডাকঘর (বাংলা)
                                      <span className="from_required">
                                        *
                                      </span>{" "}
                                    </label>
                                    <input
                                      id="prPost_office_bn"
                                      name="prPost_office_bn"
                                      className="form-control"
                                      type="text"
                                      defaultValue={
                                        data?.present_address[0]?.post_office_bn
                                      }
                                      {...register('presentPost_office_bn', {
                                        required: 'খালি রাখা যাবে না',
                                        pattern: {
                                          value: /^[ঀ-৾\s:-]+$/i,
                                          message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                        },
                                      })}


                                    />
                                    {errors?.post_office && (
                                      <span className="form__error">
                                        {errors?.post_office.message}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-lg-3 my-3">
                                    <label
                                      htmlFor="prPost_office_en"
                                      className="form-label"
                                    >
                                      ডাকঘর (ইংরেজিতে){" "}
                                      <span className="from_required">*</span>{" "}
                                    </label>
                                    <input
                                      id="prPost_office_en"
                                      name="prPost_office_en"
                                      className="form-control"
                                      type="text"
                                      {...register('presentPost_office_en', {
                                        required: 'খালি রাখা যাবে না',
                                        pattern: {
                                          value: /^[A-Za-z\s]+$/,
                                          message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                        },
                                      })}

                                      defaultValue={
                                        data?.present_address[0]?.post_office_en
                                      }
                                    />
                                    {errors?.post_office && (
                                      <span className="form__error">
                                        {errors?.post_office.message}
                                      </span>
                                    )}
                                  </div>

                                  <div className="col-lg-3 my-3">
                                    <label className="form-label">
                                      ওয়ার্ড নং{" "}
                                      <span className="from_required">*</span>{" "}
                                    </label>
                                    <input
                                      className="form-control"
                                      type="number"
                                      placeholder="Zip code "
                                      defaultValue={
                                        data?.present_address[0]?.ward_no
                                      }
                                      {...register("presentWard_no")}
                                    />
                                    {errors?.ward_no && (
                                      <span className="form__error">
                                        {errors?.ward_no.message}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-lg-3 mt-3">
                                    <label
                                      htmlFor="prVillage_bn_name"
                                      className="form-label"
                                    >
                                      গ্রাম (বাংলা)
                                      <span className="from_required">
                                        *
                                      </span>{" "}
                                    </label>
                                    <input
                                      id="prVillage_bn_name"
                                      name="prVillage_bn_name"
                                      className="form-control"
                                      type="text"
                                      defaultValue={
                                        data?.present_address[0]
                                          ?.village_bn_name
                                      }
                                      {...register('presentVillage_bn_name', {
                                        required: 'খালি রাখা যাবে না',
                                        pattern: {
                                          value: /^[ঀ-৾\s:-]+$/i,
                                          message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                        },
                                      })}
                                    />
                                    {errors?.road && (
                                      <span className="form__error">
                                        {errors?.village_bn_name.message}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-lg-3 mb-2">
                                    <label
                                      htmlFor="prVillage_en_name"
                                      className="form-label"
                                    >
                                      গ্রাম (ইংরেজিতে){" "}
                                      <span className="from_required">*</span>{" "}
                                    </label>

                                    <input
                                      id="prVillage_en_name"
                                      name="prVillage_en_name"
                                      className="form-control"
                                      type="text"
                                      {...register('presentVillage_en_name', {
                                        required: 'খালি রাখা যাবে না',
                                        pattern: {
                                          value: /^[A-Za-z\s]+$/,
                                          message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                        },
                                      })}
                                      defaultValue={
                                        data?.present_address[0]
                                          ?.village_en_name
                                      }
                                    />

                                    {errors?.road && (
                                      <span className="form__error">
                                        {errors?.village_en_name.message}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-lg-3 mb-2">
                                    <label
                                      htmlFor="prRoad"
                                      className="form-label"
                                    >
                                      রোড / ব্লক / সেক্টর{" "}
                                    </label>
                                    <input
                                      id="prRoad"
                                      name="prRoad"
                                      className="form-control"
                                      type="text"
                                      defaultValue={
                                        data?.present_address[0]?.road
                                      }
                                      {...register("presentRoad")}
                                    />
                                    {errors?.road && (
                                      <span className="form__error">
                                        {errors?.road.message}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-lg-3 ">
                                    <label htmlFor='presentHolding_no' className="form-label">হোল্ডিং নং</label>
                                    <input
                                      id='presentHolding_no'
                                      className="form-control"
                                      type="text"
                                      defaultValue={
                                        data?.present_address[0]?.holding_no
                                      }
                                      {...register('presentHolding_no')}
                                    />
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>

                          <aside className="col-lg-3">
                            <figure className="text-lg-center">
                              <img
                                className="img-lg mb-3 img-avatar border"
                                src={image ? image : profileImages}
                                alt="User Photo"
                              />

                              <figcaption className="">
                                <div
                                  style={{
                                    marginTop: "-70px",
                                    marginLeft: "120px",
                                    display: "absolute",
                                  }}
                                  className=" rounded font-md      "
                                >
                                  <input
                                    type="file"
                                    id="file"
                                    onChange={handleFileChange}
                                    ref={inputFileRef}
                                    style={{ display: "none" }}
                                  />
                                  <div onClick={handleShowModal}>
                                    <i className="icons material-icons border  md-photo_camera font-md mt-1 fs-4 btn btn-circle text-dark bg-white rounded-circle p-1  "></i>{" "}
                                    {/* <span className="pl-2"> Upload </span> */}
                                  </div>
                                </div>
                              </figcaption>

                              <div className="mt-4">
                                <span
                                  onClick={updateProfilePicture}
                                  className=" btn btn-light btn-sm text-dark border"
                                >
                                  {isLoading ? "অপেক্ষা করুন..." : "ফটো আপডেট করুন"}
                                </span>
                              </div>
                            </figure>
                          </aside>
                        </div>

                        <br />
                        <button
                          style={{ cursor: isLoading ? "no-drop" : "pointer" }}
                          className="btn btn-primary"
                          type="submit"
                        >
                          সাবমিট করুন
                        </button>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </DashboardLayout>
  );
}

export default ViewsCitizan;
