import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useGetSonodFreeCertificateMutation } from "../../app/services/certificate";
import VerifySonodTable from "./VerifySonodTable";
import WarishAndNationality from "./WarishAndNationality";
import GetSpinner from "../../helpers/shared/GetSpinner";
const VerifySonod = () => {
  let content;
  const { id } = useParams();
  const [inputValue, setInputValue] = useState(id ? id : null);
  const [getFreeSonod, { isError, isSuccess, isLoading, error, data }] =
    useGetSonodFreeCertificateMutation();
  const certificate = useMemo(
    () => (data?.certificate ? data?.certificate : []),
    [isError, isSuccess, error, data, isLoading]
  );

  const formSubmit = async () => {
    if (inputValue) {
      const certificateDataInfo = {
        id: inputValue,
        data: {},
      };
      await getFreeSonod(certificateDataInfo);
    } else {
      toast.error("আপনার এনআইডি নম্বরটি সঠিক নয়।");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const certificateDataInfo = {
          id: inputValue,
          data: {},
        };
        await getFreeSonod(certificateDataInfo);
      }
    };

    fetchData();
  }, [id, inputValue, getFreeSonod]);

  if (isLoading) {
    content = <GetSpinner />;
  } else if (certificate) {
    const sonodChaker = ["family", "theheir"];
    const sonodResult = sonodChaker.includes(certificate?.sonod_type);
    content = sonodResult ? (
      <WarishAndNationality isLoading={isLoading} certificate={certificate} />
    ) : (
      <VerifySonodTable certificate={certificate} />
    );
  }

  return (
    <div className="container">
      <div>
        <div>
          <div className=" sonodLeft shadow-md pb-5">
            <div>
              <h4 className="sonodBtn">সনদ যাচাই</h4>
              <hr />
            </div>
            <label className="Slabel">
              সনদ নম্বর <span className="reqStart">*</span>
            </label>
            <div className="row align-items-center">
              <div className="col-lg-10">
                <input
                  type="number"
                  className="Sinput"
                  onChange={(e) => setInputValue(e.target.value)}
                  defaultValue={inputValue}
                />
              </div>
              <div
                className="col-lg-2 text-center"
                style={{
                  backgroundColor: "#29A56C",
                  cursor: "pointer",
                  borderRadius: "3px",
                }}
              >
                {
                  isLoading?<h6
                  style={{
                    color: "white",
                    padding: "10px",
                  }}
                >
                  অপেক্ষা করুন
                </h6>:<h6
                    style={{
                      color: "white",
                      padding: "10px",
                    }}
                    onClick={() => formSubmit()}
                  >
                     সংরক্ষণ করুন
                  </h6>
                }

              </div>
            </div>
          </div>
        </div>
        {certificate.length === 0 ? (
          <div>
            <p>কোন তথ্য পাওয়া যায় নেই।</p>
          </div>
        ) : (
          <div>{content}</div>
        )}
      </div>
    </div>
  );
};

export default VerifySonod;
