import React, { useState } from 'react';
import { forgotSchema } from '../../helpers/validation/LoginSchema';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { toast } from 'react-toastify';
export default function ForgotPassword() {
    const [loading,setLoading]=useState(false)
    const auth = getAuth();
    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(forgotSchema) });
    const onSubmit = async (data) => {
        setLoading(true)
        const email = data?.email;
        await sendPasswordResetEmail(auth, email).then(() => {
            toast.success(`দয়া করে আপনি ইমেইল চেক করুন ${email}`)
            setLoading(false)
        })
            .catch((error) => {
                toast.error('দয়া করে সঠিক ই-মেইল ব্যবহার করুন')
                setLoading(false)
            });

    };


    return (
        <div>
            <section
                style={{
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'

                }}
            >
                <div className="card" style={{ width: '300px' }}>
                    <div className="card-body">
                        <h4 className="card-title mb-4"> পাসওয়ার্ড ভুলে গেছেন</h4>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-3">
                                <label >ইমেইল <span style={{ color: 'red' }}>*</span></label>
                                <input
                                    className="form-control"
                                    placeholder="ইমেইল"
                                    type="email"
                                    {...register('email')}
                                />
                                {errors?.email && (
                                    <span className="form__error">{errors?.email?.message}</span>
                                )}
                            </div>
                            <div className="mb-3">
                                <Link to="/login" className="float-end font-sm text-muted"> প্রবেশ করুন</Link>
                                <span> অ্যাকাউন্ট নেই? <Link to="/union-signUp"> নিবন্ধন করুন</Link></span>

                            </div>
                            <div className="mb-4">
                                <button type="submit" className="btn-primary w-100">
                                      {
                                        loading ? 'অপেক্ষা করুন...' : 'সাবমিট করুন'
                                    }
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </section>
        </div>
    );
}
