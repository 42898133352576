import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import { useCreatebirthregistrationMutation, useSavebirthregistrationMutation } from "../../../../app/services/birthregistration";

const AddNibondhonBulk = ({ bulkUpload, contects, setBulkUpload }) => {
    const [loading, setLoading] = useState(false)
    const [files, setFiles] = useState(null);
    const handleChange = (file) => {
        setFiles(file);
    };
    const [createBulk] = useCreatebirthregistrationMutation();
    const [saveData, { isLoading, isError, error, isSuccess }] = useSavebirthregistrationMutation();
    const submitFromDate = async (event) => {
        setLoading(!loading)
        event.preventDefault();
        const formData = new FormData();
        if (!files) return toast.error('আপনি ফাইলটি সিলেক্ট করুন')
        formData.append("file", files);
        const result = await createBulk(formData)
        if (result?.data) {
            await saveData(result?.data)
        }
    }


    useEffect(() => {
        if (isSuccess) {
            toast.success("তথ্য আপডেট সফল হয়েছে");
            setBulkUpload(false)
            setLoading(false)
        }
        if (isError) {
            toast.error(error.data.error);
            setBulkUpload(false)
            setLoading(false)
        }

    }, [isLoading, isError, error])


    return (
        <>
            {bulkUpload && (
                <div className="modal_product">
                    <section style={{ background: 'white', padding: '20px', borderRadius: "10px" }}>
                        <div className="row">
                            <div className="col-12">
                                <div className="content-header">
                                    <h2 className="content-title">{contects?.title}</h2>
                                    <button
                                        onClick={() =>
                                            setBulkUpload(!bulkUpload)
                                        }
                                    >
                                        X
                                    </button>
                                </div>
                            </div>

                            <form onSubmit={submitFromDate}>
                                <div >
                                    <FileUploader
                                        handleChange={handleChange}
                                        name="nibondhon"
                                        multiple={false}
                                        types={["json"]}
                                    />
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-10">
                                    {
                                        loading || isLoading ? <button
                                            style={{
                                                cursor: "not-allowed"
                                            }}
                                            className="btn btn-primary btn-sm rounded" >
                                            অপেক্ষা করুন
                                        </button> :
                                            <button type="submit" className=" btn btn-primary btn-sm rounded" >
                                                সাবমিট  করুন
                                            </button>
                                    }
                                </div>
                            </form>

                        </div>
                    </section>
                </div>
            )}
        </>
    )
}
export default AddNibondhonBulk;