import HomeLayout from "../../components/Home/HomeLayout";

const TermsConditions = () => {
    return (
        <HomeLayout>
            <h3 className="header text-center">
                ব্যবহারের শর্তাবলি
            </h3>
            <div className='terms_container'>
                <p className="terms_text">
                    এই ডাটা সিকিউরিটি পলিসি আমাদের ষ্মার্ট ইউনিয়ন পরিষদ ম্যানেজমেন্ট সিস্টেমের মধ্যে ডাটার নিরাপত্তা ও সুরক্ষা নিশ্চিত করতে https://smartunion.com.bd/ দ্বারা বাস্তবায়িত ব্যবস্থা ও অনুশীলনের রূপরেখা দেয় (" পরিষেবা")।  ব্যবহারকারীর তথ্য সুরক্ষিত রাখতে ডাটা নিরাপত্তার সর্বোচ্চ মান বজায় রাখতে প্রতিশ্রুতিবদ্ধ।
                </p>
                <p className="terms_title">ডাটা শ্রেণীবিভাগ</p>
                <p className="terms_text">ষ্মার্ট ইউনিয়ন পরিষদ ম্যানেজমেন্ট সিস্টেমে  আমাদের পরিষেবার মধ্যে ডাটাকে নিম্নলিখিত শ্রেণীতে শ্রেণীবদ্ধ করে:</p>
                <div>
                    <span className="terms_title">  ক. সংবেদনশীল ডাটা:  </span>
                    <span className="terms_text">
                        এর মধ্যে রয়েছে শংসাপত্র ইস্যু করার জন্য ব্যবহারকারীদের দ্বারা প্রদত্ত ব্যক্তিগত তথ্য, অর্থপ্রদানের বিশদ, এবং অন্য কোনও ডাটা যা প্রকাশ করা হলে, ক্ষতি বা পরিচয় চুরি হতে পারে৷
                    </span>
                </div>

                <div>
                    <span className="terms_title"> খ. অ-সংবেদনশীল ডাটা: </span>
                    <span className="terms_text">
                        এই বিভাগে অ-ব্যক্তিগত এবং অ-সমালোচনামূলক ডাটা রয়েছে যা অপারেশনাল উদ্দেশ্যে সংগ্রহ করা হয়, যেমন ব্যবহারকারীর আচরণ বিশ্লেষণ।
                    </span>
                </div>


                <p className="terms_title">তথ্য সংগ্রহ এবং স্টোরেজ</p>
                <div>
                    <span className="terms_title">ক. সংগ্রহ: </span>
                    <span className="terms_text">
                        ষ্মার্ট ইউনিয়ন পরিষদ ম্যানেজমেন্ট সিস্টেম শুধুমাত্র পরিষেবার পরিচালনার জন্য প্রয়োজনীয় ডাটা সংগ্রহ করে। সার্টিফিকেট প্রদান প্রক্রিয়া চলাকালীন ব্যবহারকারীর ডাটা সংগ্রহ করা হয় এবং প্রযোজ্য হলে অর্থপ্রদান প্রক্রিয়াকরণের জন্য লেনদেনের তথ্য সংগ্রহ করা হয়।
                    </span>
                </div>
                <div>
                    <span className="terms_title">খ. স্টোরেজঃ  </span>
                    <span className="terms_text">
                        ব্যবহারকারীর ডাটা জাতীয় ডাটা সেন্টার সার্ভারে নিরাপদে সংরক্ষণ করা হয় এবং অ্যাক্সেস শুধুমাত্র অনুমোদিত কর্মীদের জন্য সীমাবদ্ধ। ডাটা এনক্রিপশন, অ্যাক্সেস কন্ট্রোল এবং ডাটা ধরে রাখার জন্য আমরা ইন্ডাস্ট্রি এবং বাংলাদেশ সরকারের সেরা অনুশীলন নীতি অনুসরণ করি।
                    </span>
                </div>



                <p className="terms_title">অ্যাক্সেস কন্ট্রোল</p>
                <div>
                    <span className="terms_title">ক. অ্যাক্সেসের অনুমতি: </span>
                    <span className="terms_text">
                        সংবেদনশীল ডাটাতে অ্যাক্সেস শুধুমাত্র অনুমোদিত কর্মীদের জন্য সীমাবদ্ধ, এবং অনুমতিগুলি জানার প্রয়োজনের ভিত্তিতে দেওয়া হয়।
                    </span>
                </div>
                <div>
                    <span className="terms_title">খ. ইউজার অথেনটিকেশন </span>
                    <span className="terms_text">
                        ষ্মার্ট ইউনিয়ন পরিষদ ম্যানেজমেন্ট সিস্টেমের  শুধুমাত্র অনুমোদিত ব্যবহারকারীরা পরিষেবাটি অ্যাক্সেস করতে পারে তা নিশ্চিত করতে শক্তিশালী একাধিক কারণের প্রমাণীকরণ পদ্ধতি ব্যবহার করে এবং আমরা ব্যবহারকারীদের তাদের ব্যক্তিগত মোবাইল নম্বর, ইমেল ব্যবহার করতে এবং শক্তিশালী, অনন্য পাসওয়ার্ড বজায় রাখতে উত্সাহিত করি।
                    </span>
                </div>



                <p className="terms_title">ডাটা এনক্রিপশন</p>
                <div>
                    <span className="terms_title">ক. ইন-ট্রানজিট এনক্রিপশন: </span>
                    <span className="terms_text">
                        ব্যবহারকারীর ব্রাউজার এবং আমাদের সার্ভারের মধ্যে প্রেরিত সমস্ত ডাটা শিল্প-মান এনক্রিপশন প্রোটোকল (যেমন, HTTPS) ব্যবহার করে এনক্রিপ্ট করা হয়।

                    </span>
                </div>
                <div>
                    <span className="terms_title">খ. অ্যাট-রেস্ট এনক্রিপশন: </span>
                    <span className="terms_text">
                        আমাদের সার্ভারে সংরক্ষিত ডাটা অননুমোদিত অ্যাক্সেস থেকে রক্ষা করার জন্য এনক্রিপ্ট করা হয়।
                    </span>
                </div>


                <p className="terms_title"> নিয়মিত অডিট এবং নিরীক্ষা</p>
                <p className="terms_text" >ষ্মার্ট ইউনিয়ন পরিষদ ম্যানেজমেন্ট সিস্টেম আমাদের সিস্টেম এবং অবকাঠামোতে সম্ভাব্য নিরাপত্তা দুর্বলতা চিহ্নিত করতে এবং মোকাবেলা করার জন্য নিয়মিত নিরাপত্তা অডিট এবং দুর্বলতা মূল্যায়ন পরিচালনা করে।
                </p>

                <p className="terms_title">ইঞ্চিডেন্ট রেস্পন্স </p>
                <p className="terms_text">ডাটা লঙ্ঘন বা নিরাপত্তার ঘটনা ঘটলে, আইনের প্রয়োজন অনুযায়ী আমরা তাৎক্ষণিকভাবে তদন্ত, প্রশমিত এবং ক্ষতিগ্রস্ত পক্ষগুলিকে অবহিত করার জন্য একটি ঘটনা প্রতিক্রিয়া পরিকল্পনা প্রতিষ্ঠা করেছি।
                </p>

                <p className="terms_title">তৃতীয় পক্ষের নিরাপত্তা
                </p>
                <p className="terms_text">
                    ষ্মার্ট ইউনিয়ন পরিষদ ম্যানেজমেন্ট সিস্টেমের  সতর্কতার সাথে তৃতীয় পক্ষের পরিষেবা প্রদানকারীদের নির্বাচন করে এবং তাদের পরীক্ষা করে নিশ্চিত করে যে তারা আমাদের সাথে সারিবদ্ধ নিরাপত্তা এবং গোপনীয়তার মান পূরণ করে। যে কোনো তৃতীয় পক্ষের ব্যবহারকারীর ডাটা হ্যান্ডলিং চুক্তিগতভাবে এটি রক্ষা করতে বাধ্য।
                </p>

                <p className="terms_title">প্রশিক্ষণ এবং সচেতনতা </p>
                <p className="terms_text"> আমাদের দল ডাটা নিরাপত্তা এবং গোপনীয়তার সর্বোত্তম অনুশীলন সম্পর্কে প্রশিক্ষণ গ্রহণ করে যাতে তারা আমাদের ডাটা সুরক্ষা নীতিগুলি সম্পর্কে সচেতন এবং মেনে চলে।
                </p>

                <p className="terms_title">ডাটা ধারণ এবং ডিস্পোসাল
                </p>
                <p className="terms_text"> আমরা আমাদের পরিষেবা এবং আইনি প্রয়োজনীয়তা পূরণের প্রয়োজনীয়তার ভিত্তিতে ডাটা ধরে রাখি। যখন ডাটা আর প্রয়োজন হয় না, তখন অননুমোদিত অ্যাক্সেস রোধ করতে এটি নিরাপদে নিষ্পত্তি করা হবে।
                </p>

                <p className="terms_title">কমপ্লাইয়েন্স ও বিধিনিয়ম
                </p>
                <p className="terms_text">
                    আমরা প্রযোজ্য ডাটা সুরক্ষা এবং গোপনীয়তা আইন এবং প্রবিধানগুলি মেনে চলি, যার মধ্যে জিডিপিআর, সিসিপিএ, এবং বাংলাদেশ সরকার অনুসারে এবং ব্যবহারকারীর অবস্থানের উপর ভিত্তি করে অন্যান্য প্রাসঙ্গিক আইন সহ কিন্তু সীমাবদ্ধ নয়।
                </p>

                <p className="terms_title">  ক্রমাগত উন্নতি
                </p>
                <p className="terms_text">আমরা আমাদের ডাটা সুরক্ষা অনুশীলনগুলিকে ক্রমাগত উন্নত করতে এবং উদীয়মান হুমকি এবং প্রযুক্তিগুলির সাথে খাপ খাইয়ে নিতে প্রতিশ্রুতিবদ্ধ।
                </p>
                <p className="terms_title"> যোগাযোগ </p>
                <p className="terms_text"> আপনার যদি ডাটা নিরাপত্তা সংক্রান্ত কোনো প্রশ্ন বা উদ্বেগ থাকে বা কোনো নিরাপত্তা সমস্যা প্রতিবেদন করতে চান তাহলে অনুগ্রহ করে আমাদের সাথে জানাবেন।
                </p>
                <p className="terms_text"> ষ্মার্ট ইউনিয়ন পরিষদ ম্যানেজমেন্ট সিস্টেমের  ডাটা নিরাপত্তাকে গুরুত্ব সহকারে নেয় এবং আমাদের ব্যবহারকারীদের জন্য নিরাপদ পরিবেশ বজায় রাখার জন্য নিবেদিত। এই নীতিটি পর্যায়ক্রমিক পর্যালোচনা এবং বিকশিত সুরক্ষা মান এবং সর্বোত্তম অনুশীলনের সাথে সামঞ্জস্য করার জন্য আপডেটের বিষয়
                </p>

            </div>

        </HomeLayout>

    )
}
export default TermsConditions;
/* 




























*/