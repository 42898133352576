
import BnFormatDate from "./bnFormatDate";
import EnFormatDate from "./enFormatDate";

function DateDisplay({ lang }) {
    let formattedDate;

    if (lang === 'bangla') {
        // Display Bangla date
        formattedDate = BnFormatDate(EnFormatDate());
    } else {
        // Display English date
        formattedDate = EnFormatDate();
    }

    return <div>{formattedDate}</div>;
}

export default DateDisplay;