import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQueryHandel";
export const HoldingTaxApi = createApi({
  reducerPath: "HoldingTax",
  tagTypes: ["HoldingTax"],
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getAllHoldingTaxes: builder.query({
      query: (pathname) => {
        return {
          url: `v1/holding-tax?${pathname}`,
          method: "GET",
        };
      },
      providesTags: ["HoldingTax"],
    }),
    getHoldingTaxByNidOrNumber: builder.query({
      query: (nidOrNumber) => {
        return {
          url: `v1/holding-tax/${nidOrNumber}`,
          method: "GET",
        };
      },
      providesTags: ["HoldingTax"],
    }),
    postHoldingTax: builder.mutation({
      query: (data) => ({
        url: `v1/holding-tax`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["HoldingTax"],
    }),
    updateHoldingTax: builder.mutation({
      query: ({ id, data }) => ({
        url: `/v1/holding-tax/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["HoldingTax"],
    }),
    downloadHoldingTax: builder.mutation({
      query: (data) => ({
        url: `/v1/holding-tax/download`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["HoldingTax"],
    }),
    bulkPostHoldingTax: builder.mutation({
      query: (data) => ({
        url: "/v1/holding-tax/bulk",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["HoldingTax"],
    }),
  }),
});
export const {
  usePostHoldingTaxMutation,
  useGetAllHoldingTaxesQuery,
  useUpdateHoldingTaxMutation,
  useGetHoldingTaxByNidOrNumberQuery,
  useBulkPostHoldingTaxMutation,
  useDownloadHoldingTaxMutation
} = HoldingTaxApi;
