import React, { } from 'react'
import { Navigate, Outlet,useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Spinner from '../shared/Spinner';
import AccountRoles from '../../utils/AccountRoles';

function MunicipalityProtectedRoutes({ isLoading }) {
    const { userInfo } = useSelector(state => state.auth)
    const roleCheck = userInfo?.users?.approve ? userInfo?.users?.account == AccountRoles.MUNICIPALITY : false;
    const location = useLocation();
    if (isLoading || userInfo == null) {
        return <Spinner />;
    }
    return roleCheck ? (
        <Outlet/>
    ) : <Navigate to="404" state={{ from: location }} replace />

}

export default MunicipalityProtectedRoutes;
