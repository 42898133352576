import * as yup from "yup";
export const CreateMunicipalityTradeLicenseSchema = yup.object().shape({
    license_id: yup.string().required("খালি রাখা যাবে না"),

    institution_bn_name: yup
        .string()
        .required("খালি রাখা যাবে না")
        .matches(/^[ঀ-৾\s,-:।]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),
    institution_en_name: yup.string(),

    circle_street_mohalla_bn_name: yup.string()
        .required("খালি রাখা যাবে না")
        .matches(/^[ঀ-৾\s,-:।]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),
    circle_street_mohalla_en_name: yup.string(),


    institution_bn_type: yup
        .string()
        .required("খালি রাখা যাবে না")
        .matches(/^[ঀ-৾\s,-:।]+$/i, "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত"),
    institution_en_type: yup.string(),
    institution_nature: yup.string().required("খালি রাখা যাবে না"),
    trade_licenses_types: yup.string().required("খালি রাখা যাবে না"),
    tin: yup.string(),

    mobile_number: yup.string(),

    bin: yup.string(),
    institution_address_en: yup.string(),
    institution_address_bn: yup.string(),

    joint_name_bn: yup.string(),
    joint_name_en: yup.string(),
    joint_nid: yup.string(),


    renewal_fee: yup.string(),
    signboard: yup.string(),
    misc: yup.string(),
    due: yup.string(),
    service_charge: yup.string(),
    about_income_tax: yup.string(),
    vat: yup.string(),


});