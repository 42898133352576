const GenderHandel = (value) => {
    let ganderName;
    if (value == 'male') {
        ganderName = 'He'
    }
    else if (value == 'famale') {
        ganderName = 'She'
    }
    return ganderName;
};


export default GenderHandel;
