import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import styles from "./BnMrittoSonod.module.css";
import DashboardLayout from "../../../../layout/DashboardLayout";
import { useParams } from "react-router-dom";
import { useGetSingleCertificateQuery } from "../../../../../app/services/certificate";
import EnglishToBanglaNumber from "../../../../../utils/EnglishToBanglaNumber";
import BnFormatDate from "../../../../../utils/DateDisplay/bnFormatDate";
import { CertificateFooterBn } from "../../CertificateFooter/CertificateFooter";
import { connect } from "react-redux";
import { CertificateHeaderBn } from "../../CertificateHeader/CertificateHeader";
import { useEffect } from "react";
import { useState } from "react";
import { RefetchData } from "../../../RefetchData";
import GetSpinner from "../../../../../helpers/shared/GetSpinner";
import CertificatePagesLogo from "../../CertificatePagesLogo/CertificatePagesLogo";
const BnMrittoSonod = ({ userInfo }) => {
  const maxRetries = 3;
  const { id } = useParams();
  const componentRef = useRef();
  const [retryCount, setRetryCount] = useState(0);
  const { data, isLoading, isError, refetch } = useGetSingleCertificateQuery(id);
  const certificate = data?.certificate;
  useEffect(() => {
    RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
  }, [isError, retryCount, refetch]);
  return (
    <DashboardLayout>
      {
        isLoading ? <GetSpinner /> : <div>
          <div className="download_pdf d-flex justify-content-end">
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-md justify-content-center">
                  প্রিন্ট করুন
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div className={styles.body} ref={componentRef}>
            <div className={styles.container} >
              <div className={styles.content_position}>
                <div>
                  <CertificateHeaderBn certificate={certificate} sonodName={'মৃত্যু সনদ'} />
                  <div>
                    <p className={styles.mainText}>
                      এই মর্মে প্রত্যয়ন করা যাইতেছে যে,{" "}
                      {certificate?.death_information[0]?.death_person_bn}, পিতা /
                      স্বামীঃ {certificate?.death_information[0]?.death_father_husband_bn},{" "}মাতার নামঃ {certificate?.death_information[0]?.date_mother_bn}, তাহার গ্রামঃ{" "}
                      {certificate?.citizen_id?.permanent_address[0]?.village_bn_name},{" "}হোল্ডিং
                      নংঃ-{" "}
                      {EnglishToBanglaNumber(
                        certificate?.citizen_id?.permanent_address[0]?.holding_no
                      )}
                      , ডাকঘরঃ {certificate?.citizen_id?.permanent_address[0]?.post_office_bn},
                      ওয়ার্ড নংঃ-{" "}
                      {EnglishToBanglaNumber(
                        certificate?.citizen_id?.permanent_address[0]?.ward_no
                      )}, ইউনিয়নঃ-{" "}
                      {certificate?.union_or_municipality?.municipality_union_bn_name}
                      ,{" "}থানা / উপজেলাঃ{" "}
                      {certificate?.citizen_id?.permanent_address[0]?.upazila?.bn_name}, জেলাঃ{" "}
                      {certificate?.citizen_id?.permanent_address[0]?.district?.bn_name} । অত্র এলাকার
                      অধিবাসী ছিলেন। আবেদনকারী {certificate?.citizen_id?.name_bangla} তাহার  গ্রামঃ{" "}
                      {certificate?.citizen_id?.permanent_address[0]?.village_bn_name}, হোল্ডিং
                      নংঃ-{" "}
                      {EnglishToBanglaNumber(
                        certificate?.citizen_id?.permanent_address[0]?.holding_no
                      )}
                      , ডাকঘরঃ {certificate?.citizen_id?.permanent_address[0]?.post_office_bn},
                      ওয়ার্ড নংঃ-{" "}
                      {EnglishToBanglaNumber(
                        certificate?.citizen_id?.permanent_address[0]?.ward_no
                      )}{" "}
                      , ইউনিয়নঃ-{" "}
                      {certificate?.union_or_municipality?.municipality_union_bn_name}
                      , থানা / উপজেলাঃ{" "}
                      {certificate?.citizen_id?.permanent_address[0]?.upazila?.bn_name}, জেলাঃ{" "}
                      {certificate?.citizen_id?.permanent_address[0]?.district?.bn_name}।
                      আবেদনকারীর তথ্য এবং আমার জানা মতে, তিনি গত{" "}
                      {BnFormatDate(certificate?.death_information[0]?.date_of_birth)}{" "}
                      ইং তারিখে মৃত্যুবরণ করেন এবং মৃত্যু রেজিস্টারের নিবন্ধন নাম্বার{" "}
                      {EnglishToBanglaNumber(certificate?.death_information[0]?.death_register_no)}।

                    </p>
                    <br />

                    <div className={styles.mainText}>
                      <p className={styles.mainText}>
                        আমি মরহুমের বিদেহী আত্নার মাগফেরাত কামনা করছি।{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <CertificateFooterBn certificate={certificate} accountType={userInfo?.account} marginHandel={true} />
              </div>
              <CertificatePagesLogo
                certificate={certificate}
                toValue={'200px'}
              />
            </div>
          </div>


        </div>
      }

    </DashboardLayout>
  );
};

function mapStateToProps(state) {
  return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(BnMrittoSonod)

