import React, { useEffect, useMemo, useState } from 'react'
import DashboardLayout from '../../../components/layout/DashboardLayout'
import GetSpinner from '../../../helpers/shared/GetSpinner';
import SingleEmployee from '../../../components/elements/user/SingleEmployee';
import Pagination from '../../../components/elements/Pagination';
import UserApprove from '../../../components/elements/modal/EmployeeEditModal';
import { useAllUserGetQuery } from '../../../app/services/authUser';
import Search from '../../../utils/Search';
function DonationReport() {
    const [Loading, setLoading] = useState(false)
    const [search, setSearchValue] = useState('')
    const [status, setStatus] = useState({ type: false, data: null })
    const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10, });
    const pathname = `approve=${true}&page=${pageIndex}&limit=${pageSize}&search=${search}`;
    const { data, isLoading } = useAllUserGetQuery(pathname)

    const users = useMemo(() => (data ? data?.users : []), [
        data,
        search
    ]);
    useEffect(() => {
        if (isLoading) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [isLoading])

    return (
        <DashboardLayout>
            <section className="content-main">
                <div className="content-header">
                    <div>
                        <h2 className="content-title card-title">ইউনিয়ন সচিব</h2>
                    </div>
                </div>
                <div className="card mb-4">
                    <header className="card-header">
                        <div className="row align-items-center">
                            <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                                <Search setSearchValue={setSearchValue} placeholder="অনুসন্ধান করুন" />
                            </div>
                        </div>
                    </header>
                    <div className="col-md-12 px-4 ">
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr className=''>
                                        <th>ইউনিয়ন/পৌরসভা</th>
                                        <th>বিভাগ</th>
                                        <th>জেলা</th>
                                        <th>থানা / উপজেলা</th>
                                        <th>Country</th>
                                        <th>স্ট্যাটাস</th>
                                        <th className="text-end">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Loading && <GetSpinner />}
                                    {users.map(user => <SingleEmployee user={user}
                                        setStatus={setStatus}
                                    />
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <Pagination totalPages={users?.totalPages} setPagination={setPagination} pageIndex={pageIndex} pageSize={pageSize} />
            </section>
            <UserApprove
                modal={status}
                setOpen={setStatus}
            />
        </DashboardLayout>
    )
}

export default DonationReport