import HoldingTaxdownloadHeader from "./HoldingTaxdownloadHeader";
import HoldingTaxdownloadTable from "./HoldingTaxdownloadTable";

const HoldingTaxdownloadMain = ({ data }) => {
    return (
        <>
            <HoldingTaxdownloadHeader data={data}/>
            <HoldingTaxdownloadTable data={data}/>
        </>
    )

}
export default HoldingTaxdownloadMain;