export function enFormatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export function bnFormatDate(utcDateString) {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'Asia/Dhaka',
  };

  const utcDate = new Date(utcDateString);
  const banglaDate = new Intl.DateTimeFormat('bn', options).format(utcDate);

  return banglaDate;
}
