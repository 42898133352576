import React, { useMemo, useState } from "react";
import DashboardLayout from "../../components/layout/DashboardLayout";
import Pagination from "../../components/elements/Pagination";

import { useSelector } from "react-redux";
import GetSpinner from "../../helpers/shared/GetSpinner";
import CreateHoldingTaxModal from "../../components/elements/holdingTax/modal/CreateHoldingTaxModal";
import { useGetAllHoldingTaxesQuery } from "../../app/services/holdingTax";
import SingleHoldingTax from "../../components/elements/holdingTax/SingleHoldingTax";
import EditHoldingTaxModal from "../../components/elements/holdingTax/modal/EditHoldingTaxModal";
import AddHoldingbulkUpload from "../../components/elements/holdingTax/modal/AddHoldingbulkUpload";
import Search from "../../utils/Search";

function VoterThothoo() {
  const contects = {
    title: "নতুন নিবন্ধন",
  };
  const [search, setSearchValue] = useState("");
  const [status, setStatus] = useState({ type: false, data: null });
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [singleUser, setSingleUser] = useState();
  const [bulkUpload, setBulkUpload] = useState(false)
  const { userInfo } = useSelector((state) => state.auth);

  const [holdingTaxModal, setHoldingTaxModal] = useState({
    type: false,
    data: null,
  });
  const pathname = `page=${pageIndex}&limit=${pageSize}&search=${search}&status=${'all'}`;
  const { data, isLoading: loading } = useGetAllHoldingTaxesQuery(pathname);
  const holdingTaxes = useMemo(() => data?.result ? data?.result : [], [loading, data])
  const accessFunction = ["entrepreneur", "secretary"];

  return (
    <DashboardLayout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">ভোটার তথ্য</h2>
          </div>
        </div>
        <div className="card mb-4">
          <header className="card-header">
            <div className="row align-items-center">
              <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                <Search setSearchValue={setSearchValue} placeholder="সনদ নাম্বার" />

              </div>
              <div className="text-end col-md-5 col-12 ms-auto mb-md-0 mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <p
                    onClick={() =>
                      setBulkUpload(!bulkUpload)
                    }
                    className=" btn btn-primary btn-sm rounded"
                  >
                    আপলোড করুন
                  </p>
                  <p
                    onClick={() =>
                      setHoldingTaxModal({ type: !holdingTaxModal.type })
                    }
                    className=" btn btn-primary btn-sm rounded"
                  >
                    নতুন তৈরি করুন
                  </p>
                </div>
              </div>
            </div>
          </header>
          <div className="col-md-12 px-4 ">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr className="">
                    <th>নাম</th>
                    <th>হোল্ডিং</th>
                    <th>গ্রাম</th>
                    <th>ওয়ার্ড</th>
                    <th>মোট পরিমাণ </th>
                    <th>ট্যাক্স গ্রহন</th>
                    {accessFunction?.includes(userInfo?.users?.role) && (
                      <th className="text-end">পদক্ষেপ</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {loading && <GetSpinner />}
                  {holdingTaxes?.map((user) => (
                    <SingleHoldingTax
                      user={user}
                      setStatus={setStatus}
                      singleUser={singleUser}
                      setSingleUser={setSingleUser}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Pagination
          totalPages={data?.totalPages}
          setPagination={setPagination}
          pageIndex={pageIndex}
          pageSize={pageSize}
        />
      </section>

      <EditHoldingTaxModal
        singleUser={singleUser}
        modal={status}
        setOpen={setStatus}
      />
      <CreateHoldingTaxModal
        modal={holdingTaxModal}
        setOpen={setHoldingTaxModal}
        contects={contects}
      />
      {
        bulkUpload && <AddHoldingbulkUpload
          bulkUpload={bulkUpload}
          contects={contects}
          setBulkUpload={setBulkUpload}
        />
      }

    </DashboardLayout>
  );
}

export default VoterThothoo;
