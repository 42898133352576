import { useForm } from "react-hook-form";
import EnglishToBanglaNumber from "../../../utils/EnglishToBanglaNumber";
import { useUpdateHoldingTaxMutation } from "../../../app/services/holdingTax";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const AcceptHoldingEdit = ({ data, _id, setSearchValue }) => {

    const [previousFinancial, setPreviousFinancial] = useState(0)

    const [receiveHoldingTax, { isSuccess, error, isLoading }] = useUpdateHoldingTaxMutation();
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const editHoldingTax = async (value) => {
        const data = {
            id: _id,
            data: {
                ...value,
            },
        };
        await receiveHoldingTax(data);
    };
    useEffect(() => {
        if (isSuccess) {
            toast.success("জমা সফল হয়েছে");
            setSearchValue('')
            reset()
        }
        if (error) {
            toast.error(error?.data?.error);
        }
    }, [isSuccess, error]);

    return <form onSubmit={handleSubmit(editHoldingTax)}>
        <div className="card ">
            <div className="card-body">
                <div className="row g-3">
                    <div className="col-lg-4">
                        <label className="form-label">
                            নাম: (বাংলা) <span className="from_required">*</span>{" "}
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            defaultValue={data?.name_bangla}
                            {...register("name_bangla", {
                                required: "খালি রাখা যাবে না",
                                pattern: {
                                    value: /^[ঀ-৾\s,:-]+$/i,
                                    message: "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত",
                                },
                            })}
                        />
                        {errors?.name_bangla && (
                            <span className="form__error">
                                {errors?.name_bangla.message}
                            </span>
                        )}
                    </div>

                    <div className="col-lg-4 ">
                        <label className="form-label">
                            পিতা / স্বামীর নাম: (বাংলা){" "}
                            <span className="from_required">*</span>
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            defaultValue={data?.father_husband_bangla}
                            {...register("father_husband_bangla", {
                                required: "খালি রাখা যাবে না",
                                pattern: {
                                    value: /^[ঀ-৾\s,:-]+$/i,
                                    message: "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত",
                                },
                            })}
                        />
                        {errors?.father_husband_bangla && (
                            <span className="form__error">
                                {errors?.father_husband_bangla.message}
                            </span>
                        )}
                    </div>

                    <div className="col-lg-4 ">
                        <label className="form-label">
                            মাতার নাম: (বাংলা){" "}
                            <span className="from_required">*</span>
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            defaultValue={data?.mother_name_bangla}
                            {...register("mother_name_bangla", {
                                pattern: {
                                    value: /^[ঀ-৾\s,:-]+$/i,
                                    message: "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত",
                                },
                            })}
                        />
                        {errors?.mother_name_bangla && (
                            <span className="form__error">
                                {errors?.mother_name_bangla.message}
                            </span>
                        )}
                    </div>

                    <div className="col-lg-4">
                        <label className="form-label">
                            মোবাইল নম্বর <span className="from_required">*</span>
                        </label>
                        <input
                            className="form-control"
                            type="number"
                            defaultValue={data?.number}
                            {...register("number", {
                                pattern: {
                                    value: /^[0-9]{11}$/,
                                    message: "মোবাইল নম্বরটি ১১ টি সংখ্যা হতে হবে",
                                },
                            })}
                        />
                        {errors?.number && (
                            <span className="form__error">
                                {errors?.number.message}
                            </span>
                        )}
                    </div>
                    <div className="col-lg-4 ">
                        <label className="form-label">
                            জাতীয় পরিচয়পত্র নং / জন্মনিবন্ধন নং{" "}
                            <span className="from_required">*</span>
                        </label>
                        <input
                            className="form-control"
                            type="number"
                            defaultValue={data?.nid}
                            {...register("nid", {
                                pattern: {
                                    value: /^[0-9]{10}$|^[0-9]{17}$/,
                                    message:
                                        "জাতীয় পরিচয়পত্র নম্বরটি ১০ বা ১৭ টি সংখ্যা হতে হবে",
                                },
                            })}
                        />
                        {errors?.nid && (
                            <span className="form__error">
                                {errors?.nid.message}
                            </span>
                        )}
                    </div>

                    <div className="col-lg-4 ">
                        <label htmlFor="ward_no" className="form-label">
                            ওয়ার্ড নং <span className="from_required">*</span>{" "}
                        </label>
                        <input
                            id="ward_no"
                            name="ward_no"
                            className="form-control"
                            type="number"
                            defaultValue={data?.ward_no}
                            {...register("ward_no", {
                            })}
                            onChange={(e) => {
                                const value = e.target.value;
                            }}
                        />
                        {errors?.ward_no && (
                            <span className="form__error">
                                {errors?.ward_no.message}
                            </span>
                        )}
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label htmlFor="village_bn_name" className="form-label">
                            গ্রাম (বাংলা)
                            <span className="from_required">*</span>{" "}
                        </label>
                        <input
                            id="village_bn_name"
                            name="village_bn_name"
                            className="form-control"
                            type="text"
                            defaultValue={data?.village_bn_name}
                            {...register("village_bn_name", {
                                required: "খালি রাখা যাবে না",
                                pattern: {
                                    value: /^[ঀ-৾\s,:-]+$/i,
                                    message: "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত",
                                },
                            })}
                            onChange={(e) => {
                                const value = e.target.value;
                            }}
                        />
                        {errors?.village_bn_name && (
                            <span className="form__error">
                                {errors?.village_bn_name.message}
                            </span>
                        )}
                    </div>

                    <div className="col-lg-4 mb-2">
                        <label htmlFor="prRoad" className="form-label">
                            রোড / ব্লক / সেক্টর{" "}
                        </label>
                        <input
                            id="road"
                            name="road"
                            className="form-control"
                            type="text"
                            defaultValue={data?.road}
                            {...register("road", {
                            })}
                            onChange={(e) => {
                                const value = e.target.value;
                            }}
                        />
                        {errors?.road && (
                            <span className="form__error">
                                {errors?.road.message}
                            </span>
                        )}
                    </div>
                    <div className="col-lg-4 mb-2">
                        <label htmlFor="home_type" className="form-label">
                            বাড়ীর ধরন
                        </label>
                        <input
                            id="home_type"
                            name="home_type"
                            className="form-control"
                            defaultValue={data?.home_type}
                            type="text"
                            {...register("home_type", {
                                required: "খালি রাখা যাবে না",
                                pattern: {
                                    value: /^[ঀ-৾\s:-]+$/i,
                                    message: "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত",
                                },
                            })}
                            onChange={(e) => {
                                const value = e.target.value;
                            }}
                        />
                        {errors?.home_type && (
                            <span className="form__error">
                                {errors?.home_type.message}
                            </span>
                        )}
                    </div>


                    <div className="col-lg-4 mb-2">
                        <label htmlFor="prHolding_no" className="form-label">
                            তফসিল অনুযায়ী পরিমান
                        </label>
                        <input
                            id="amount_as_schedule"
                            name="amount_as_schedule"
                            className="form-control"
                            type="number"
                            defaultValue={data?.amount_as_schedule}
                            disabled
                        />

                    </div>
                    <div className="col-lg-4 mb-2">
                        <label htmlFor="prHolding_no" className="form-label">
                            প্রকৃত ধার্যকৃত করের পরিমান
                        </label>
                        <input
                            id="actual_tax_amount"
                            name="actual_tax_amount"
                            className="form-control"
                            type="number"
                            defaultValue={data?.total_amount}
                            disabled
                        />
                    </div>
                    <div className="col-lg-4 mb-2">
                        <label htmlFor="prHolding_no" className="form-label">
                            বিগত অর্থবছরের বকেয়া পরিমান
                        </label>
                        <input
                            className="form-control"
                            type="number"
                            defaultValue={previousFinancial}
                            onChange={(e) => setPreviousFinancial(e.target.value)}

                        />
                    </div>
                    <div className="col-lg-12 mb-2 me-0">
                        <p className="fs-5 text-end ">
                            মোট টাকার পরিমান {EnglishToBanglaNumber((Number(data?.total_amount)+Number(previousFinancial))?.toString())}{" "}
                            {" "}৳
                        </p>
                    </div>

                </div>
                <div className="d-flex justify-content-end gap-3">
                    <input
                        style={{ cursor: isLoading ? "no-drop" : "pointer" }}
                        type="submit"
                        className="btn btn-md rounded font-sm  h-25"
                        value="আদায় করুন"
                    />
                </div>

            </div>

        </div>


    </form>
}
export default AcceptHoldingEdit;