import React, { useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useCreteCitizenMutation } from '../../../../app/services/citizen';
import { toast } from 'react-toastify';
import { useGetDistrictsQuery, useGetDivisionsQuery, useGetUnionsQuery, useGetUpazilasQuery } from '../../../../app/services/location';
import { useGetProfileUserQuery } from '../../../../app/services/authUser';
import { useSelector } from 'react-redux';

function CreateCitizenModal({ contects, modal, setOpen }) {

  const { type } = modal;
  const { data: profileInfo } = useGetProfileUserQuery()
  const { data: divisions } = useGetDivisionsQuery()
  const { data: districts } = useGetDistrictsQuery()
  const { data: upazilas } = useGetUpazilasQuery()
  const { data: unions } = useGetUnionsQuery()

  const { userInfo } = useSelector((state) => state.auth)
  const accountType = userInfo?.users?.account
  /* Address */
  const [currentAddressChecked, setCurrentAddressChecked] = useState(false)
  /* hook  */
  const [selectStoreDistricts, setSelectStoreDistricts] = useState([])
  const [selectStoreUpazilas, setSelectStoreUpazilas] = useState([])
  const [selectStoreUnions, setSelectStoreUnions] = useState([])
  const [selectPermanentStoreDistricts, setSelectPermanentStoreDistricts] = useState([])
  const [selectPermanentStoreUpazilas, setSelectPermanentStoreUpazilas] = useState([])
  const [selectPermanentStoreUnions, setSelectPermanentStoreUnions] = useState([])


  const selectDistrict = async (e) => {
    e.preventDefault()
    const id = e.target.value;
    const result = await districts?.district?.filter(district => id === district?.division_id)
    setSelectStoreDistricts(result)
  }
  const selectUpdzilas = async (e) => {
    e.preventDefault()
    const id = e.target.value;
    const result = await upazilas?.upazila?.filter(upazila => id === upazila?.district_id)
    setSelectStoreUpazilas(result)
  }
  const selectUnion = async (e) => {
    e.preventDefault()
    const id = e.target.value;
    const result = await unions?.union?.filter(union => id === union?.upazilla_id)
    setSelectStoreUnions(result)
  }
  /* permanent address  */
  const selectPermanentDistrict = async (e) => {
    e.preventDefault()
    const id = e.target.value;
    const result = await districts?.district?.filter(district => id === district?.division_id)
    setSelectPermanentStoreDistricts(result)
  }
  const selectPermanentUpdzilas = async (e) => {
    e.preventDefault()
    const id = e.target.value;
    const result = await upazilas?.upazila?.filter(upazila => id === upazila?.district_id)
    setSelectPermanentStoreUpazilas(result)
  }
  const selectPermanentUnion = async (e) => {
    e.preventDefault()
    const id = e.target.value;
    console.log(id)
    const result = await unions?.union?.filter(union => id === union?.upazilla_id)
    console.log(unions?.union)
    setSelectPermanentStoreUnions(result)
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [createCitizen, { isLoading, isSuccess, isError, error }] = useCreteCitizenMutation();
  const createOnSubmit = async (data) => {
    console.log('data', data)

    if (currentAddressChecked) {
      const division_permanent = await divisions?.division?.find(value => value?.id === data?.permanent_division)
      const district_permanent = await districts?.district?.find(value => value?.id === data?.permanent_district)
      const upazila_permanent = await upazilas?.upazila?.find(value => value?.id === data?.permanent_upazila)
      const union_permanent = await unions?.union?.find(value => value?.id === data?.permanent_union);
      const citizenInfo = {
        ...data,
        presentStatus: true,
        division_permanent: division_permanent?._id,
        district_permanent: district_permanent?._id,
        upazila_permanent: upazila_permanent?._id,
        union_permanent: union_permanent?._id
      }
      await createCitizen(citizenInfo);
    } else {
      /* present */
      const division = await divisions?.division?.find(value => value?.id === data?.present_division)
      const district = await districts?.district?.find(value => value?.id === data?.present_district)
      const upazila = await upazilas?.upazila?.find(value => value?.id === data?.present_upazila)
      const union = await unions?.union?.find(value => value?.id === data?.present_union);
      /* permanent */
      const division_permanent = await divisions?.division?.find(value => value?.id === data?.permanent_division)
      const district_permanent = await districts?.district?.find(value => value?.id === data?.permanent_district)
      const upazila_permanent = await upazilas?.upazila?.find(value => value?.id === data?.permanent_upazila)
      const union_permanent = await unions?.union?.find(value => value?.id === data?.permanent_union);
      const citizenInfo = {
        ...data,
        presentStatus: false,
        /* permanent */
        division_permanent: division_permanent?._id,
        district_permanent: district_permanent?._id,
        upazila_permanent: upazila_permanent?._id,
        union_permanent: union_permanent?._id,
        /* present */
        present_division: division?._id,
        present_district: district?._id,
        present_upazila: upazila?._id,
        present_union: union?._id
      }
      await createCitizen(citizenInfo);
    }
  }




  const [parmanentAddress, setParmanentAddress] = useState({
    prPost_office_bn: '',
    prPost_office_en: '',
    prWard_no: '',
    prVillage_bn_name: '',
    prVillage_en_name: '',
    prRoad: '',
    prHolding_no: '',
  });
  useEffect(() => {
    if (isSuccess) {
      toast.success('সঠিকভাবে নাগরিক এড করা হয়েছ');
      setOpen({ type: false })
      reset();
    }
    if (error) {
      console.log(error)
      toast.error(error.data.error);
    }
  }, [isSuccess, error, reset]);

  return (
    <>
      {type && (
        <div className="modal_product p-6">
          <section className="content-mainDash " >
            <div className="row">
              <div className="col-12">
                <div className="content-header">
                  <h2 className="content-title">{contects?.title}</h2>
                  <button onClick={() => setOpen({ type: false }, reset())}>
                    X
                  </button>
                </div>
              </div>
              <form onSubmit={handleSubmit(createOnSubmit)}
              >
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="card ">
                        <div className="card-body"
                        >
                          <div className="row gx-3">
                            <div className="col-lg-6">
                              <label className="form-label">
                                নাম: (বাংলা){' '}
                                <span className="from_required">*</span>{' '}
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                {...register('name_bangla', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[ঀ-৾\s:-]+$/i,
                                    message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}

                              />
                              {errors?.name_bangla && (
                                <span className="form__error">
                                  {errors?.name_bangla.message}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-6 mb-2">
                              <label className="form-label">
                                নাম: (ইংরেজিতে){' '}
                                <span className="from_required">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                {...register('name_english', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[A-Za-z\s]+$/,
                                    message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}

                              />
                              {errors?.name_english && (
                                <span className="form__error">
                                  {errors?.name_english.message}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-6 my-2">
                              <label className="form-label">
                                পিতা / স্বামীর নাম: (বাংলা){' '}
                                <span className="from_required">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                {...register('father_husband_bangla', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[ঀ-৾\s:-]+$/i,
                                    message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}
                              />
                              {errors?.father_husband_bangla && (
                                <span className="form__error">
                                  {errors?.father_husband_bangla.message}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-6 my-2">
                              <label className="form-label">
                                পিতা / স্বামীর নাম: (ইংরেজিতে){' '}
                                <span className="from_required">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                {...register('father_husband_english', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[A-Za-z\s]+$/,
                                    message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}
                              />
                              {errors?.father_husband_english && (
                                <span className="form__error">
                                  {errors?.father_husband_english.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row gx-3 ">
                            <div className="col-lg-6 mb-2">
                              <label className="form-label">
                                মাতার নাম: (বাংলা){' '}
                                <span className="from_required">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                {...register('mother_name_bangla', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[ঀ-৾\s:-]+$/i,
                                    message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}
                              />
                              {errors?.mother_name_bangla && (
                                <span className="form__error">
                                  {errors?.mother_name_bangla.message}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-6 mb-2">
                              <label className="form-label">
                                মাতার নাম: (ইংরেজিতে){' '}
                                <span className="from_required">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                {...register('mother_name_english', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[A-Za-z\s]+$/,
                                    message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}
                              />
                              {errors?.mother_name_english && (
                                <span className="form__error">
                                  {errors?.mother_name_english.message}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-6 my-2">
                              <label className="form-label">
                                মোবাইল নম্বর{' '}
                                <span className="from_required">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                {...register('number', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[0-9]{11}$/,
                                    message: 'মোবাইল নম্বরটি ১১ টি সংখ্যা হতে হবে',
                                  },
                                })}
                              />
                              {errors?.number && (
                                <span className="form__error">
                                  {errors?.number.message}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-6 my-2">
                              <label className="form-label">
                                জাতীয় পরিচয় পত্র/জন্মনিবন্ধন নং{' '}
                                <span className="from_required">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                {...register('nid', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[0-9]{10}$|^[0-9]{17}$/,
                                    message: 'জাতীয় পরিচয়পত্র নম্বরটি ১০ বা ১৭ টি সংখ্যা হতে হবে',
                                  },
                                })}
                              />
                              {errors?.nid && (
                                <span className="form__error">
                                  {errors?.nid.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row gx-3">
                            <div className="col-lg-4 mb-2">
                              <label className="form-label">
                                জন্ম তারিখ <span className="from_required">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="date"
                                {...register('date_of_birth', {
                                  required: 'খালি রাখা যাবে না',
                                })}
                              />
                              {errors?.date_of_birth && (
                                <span className="form__error">
                                  {errors?.date_of_birth.message}
                                </span>
                              )}
                            </div>
                            <div className="col-md-4 mb-2">
                              <label htmlFor="Promo_amount" className="form-label">
                                লিঙ্গ <span className="from_required">*</span>
                              </label>
                              <select
                                className="form-select"
                                {...register('gender', {
                                  required: 'খালি রাখা যাবে না',
                                })}
                              >
                                <option selected value="">
                                  লিঙ্গ নির্বাচন করুন
                                </option>
                                <option value="male">পুরুষ</option>
                                <option value="woman">মহিলা</option>
                                <option value="ananya">অনন্যা</option>
                              </select>
                              {errors?.gender && (
                                <span className="form__error">
                                  {errors?.gender.message}
                                </span>
                              )}
                            </div>

                            <div className="col-lg-4 mb-2">
                              <label className="form-label">রক্তের গ্রুপ</label>
                              <select
                                className="form-select"
                                {...register('blood_group')}
                              >
                                <option selected value="">
                                  রক্তের গ্রুপ নির্বাচন করুন
                                </option>
                                <option value={'O+'}>ও+</option>
                                <option value={'O-'}>ও−</option>
                                <option value={'A-'}>এ−</option>
                                <option value={'A+'}>এ+</option>
                                <option value={'B+_'}>বি−</option>
                                <option value={'B+'}>বি+</option>
                                <option value={'AB-'}>এবি−</option>
                                <option value={'AB+'}>এবি+</option>
                              </select>
                              {errors?.blood_group && (
                                <span className="form__error">
                                  {errors?.blood_group.message}
                                </span>
                              )}
                            </div>
                          </div>

                          {/* Permanent address */}
                          <div className='mb-2'>
                            <h6>স্থায়ী ঠিকানা</h6>
                          </div>
                          <div className="row gx-3">
                            <div className="col-lg-4">
                              <label className="form-label">
                                বিভাগ
                                <span className="from_required">*</span>
                              </label>
                              <select className="form-control"
                                {...register('permanent_division', {
                                  required: 'খালি রাখা যাবে না',
                                })}
                                onChange={(e) => selectPermanentDistrict(e)}
                              >
                                <option value={''}>পছন্দ করুন বিভাগ</option>
                                {
                                  divisions?.division?.map(divisio =>
                                    <option
                                      className="form-control"
                                      key={divisio?._id} value={divisio?.id} >{divisio?.bn_name}</option>)
                                }
                              </select>
                              {errors?.permanent_division && (
                                <span className="form__error">
                                  {errors?.permanent_division.message}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-4">
                              <label htmlFor='prDistrict' className="form-label">
                                জেলা
                                <span className="from_required">*</span>
                              </label>
                              <select className="form-control"
                                {...register('permanent_district', {
                                  required: 'খালি রাখা যাবে না',
                                })}

                                onChange={(e) => selectPermanentUpdzilas(e)}
                              >
                                <option value=''>পছন্দ করুন জেলা</option>
                                {
                                  selectPermanentStoreDistricts?.map(district => <option
                                    className="form-control"
                                    key={district?._id} value={district?.id}>{district?.bn_name}</option>)
                                }
                              </select>
                              {errors?.permanent_district && (
                                <span className="form__error">
                                  {errors?.permanent_district.message}
                                </span>
                              )}

                            </div>
                            <div className="col-lg-4">
                              <label htmlFor='prUpazila' className="form-label">
                                থানা / উপজেলা
                                <span className="from_required">*</span>
                              </label>
                              <select className="form-control"
                                {...register('permanent_upazila', {
                                  required: 'খালি রাখা যাবে না',
                                })}
                                onChange={(e) => selectPermanentUnion(e)}
                              >
                                <option value=''>পছন্দ করুন থানা / উপজেলা</option>
                                {
                                  selectPermanentStoreUpazilas?.map(upazila => <option
                                    className="form-control"
                                    key={upazila?._id} value={upazila?.id}>{upazila?.bn_name}</option>)
                                }
                              </select>
                              {errors?.permanent_upazila && (
                                <span className="form__error">
                                  {errors?.permanent_upazila.message}
                                </span>
                              )}

                            </div>

                            {/*  {
                              accountType === "municipality" ?
                                <>
                                  <div className="col-lg-4 mt-3">
                                    <label className="form-label">
                                      পৌরসভা  (বাংলা)
                                      <span className="from_required">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      value={profileInfo?.municipality_bn_name}
                                    />
                                  </div>
                                  <div className="col-lg-4 mt-3">
                                    <label className="form-label">
                                      পৌরসভা (ইংরেজিতে)
                                      <span className="from_required">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      value={profileInfo?.municipality_en_name
                                      }
                                    />
                                  </div>
                                </>
                                :
                                <div className="col-lg-4 mt-3">
                                  <label className="form-label">
                                    ইউনিয়ন
                                    <span className="from_required">*</span>
                                  </label>
                                  <select className="form-control"
                                    {...register('permanent_union', {
                                      required: 'খালি রাখা যাবে না',
                                    })}
                                  >
                                    <option value=''>পছন্দ করুন ইউনিয়ন</option>
                                    {
                                      selectPermanentStoreUnions?.map(union => <option
                                        className="form-control"
                                        key={union?._id} value={union?.id} >{union?.bn_name}</option>)
                                    }
                                  </select>
                                  {errors?.permanent_union && (
                                    <span className="form__error">
                                      {errors?.permanent_union.message}
                                    </span>
                                  )}
                                </div>
                            } */}
                            <div className="col-lg-4 mt-3">
                              <label className="form-label">
                                ইউনিয়ন
                                <span className="from_required">*</span>
                              </label>
                              <select className="form-control"
                                {...register('permanent_union', {
                                  required: 'খালি রাখা যাবে না',
                                })}
                              >
                                <option value=''>পছন্দ করুন ইউনিয়ন</option>
                                {
                                  selectPermanentStoreUnions?.map(union => <option
                                    className="form-control"
                                    key={union?._id} value={union?.id} >{union?.bn_name}</option>)
                                }
                              </select>
                              {errors?.permanent_union && (
                                <span className="form__error">
                                  {errors?.permanent_union.message}
                                </span>
                              )}
                            </div>

                            <div className="col-lg-4 mt-3 ">
                              <label htmlFor='prPost_office_bn' className="form-label">
                                ডাকঘর (বাংলা)
                                <span className="from_required">*</span>{' '}
                              </label>
                              <input
                                id='prPost_office_bn'
                                name='prPost_office_bn'
                                className="form-control"
                                type="text"
                                {...register('post_office_bn', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[ঀ-৾\s:-]+$/i,
                                    message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setParmanentAddress({
                                    ...parmanentAddress,
                                    prPost_office_bn: value,
                                  });
                                }}
                              />
                              {errors?.post_office_bn && (
                                <span className="form__error">
                                  {errors?.post_office_bn.message}
                                </span>
                              )}
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="card ">
                        <div className="card-body">
                          <div className="row gx-3">
                            <div className="col-lg-4 mb-3">
                              <label htmlFor='prPost_office_en' className="form-label">
                                ডাকঘর (ইংরেজিতে){' '}
                                <span className="from_required">*</span>{' '}
                              </label>
                              <input
                                id='prPost_office_en'
                                name='prPost_office_en'
                                className="form-control"
                                type="text"

                                {...register('post_office_en', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[A-Za-z\s]+$/,
                                    message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setParmanentAddress({
                                    ...parmanentAddress,
                                    prPost_office_en: value,
                                  });
                                }}
                              />
                              {errors?.post_office_en && (
                                <span className="form__error">
                                  {errors?.post_office_en.message}
                                </span>
                              )}
                            </div>

                            <div className="col-lg-4 mb-3">
                              <label htmlFor='prWard_no' className="form-label">
                                ওয়ার্ড নং <span className="from_required">*</span>{' '}
                              </label>
                              <input
                                id='prWard_no'
                                name='prWard_no'
                                className="form-control"
                                type="number"
                                {...register('ward_no', {
                                  required: 'খালি রাখা যাবে না',
                                })}


                                onChange={(e) => {
                                  const value = e.target.value;
                                  setParmanentAddress({
                                    ...parmanentAddress,
                                    prWard_no: value,
                                  });
                                }}
                              />
                              {errors?.ward_no && (
                                <span className="form__error">
                                  {errors?.ward_no.message}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-4 mb-3">
                              <label htmlFor='prVillage_bn_name' className="form-label">
                                গ্রাম (বাংলা)
                                <span className="from_required">*</span>{' '}
                              </label>
                              <input
                                id='prVillage_bn_name'
                                name='prVillage_bn_name'
                                className="form-control"
                                type="text"
                                {...register('village_bn_name', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[ঀ-৾\s:-]+$/i,
                                    message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}

                                onChange={(e) => {
                                  const value = e.target.value;
                                  setParmanentAddress({
                                    ...parmanentAddress,
                                    prVillage_bn_name: value,
                                  });
                                }}
                              />
                              {errors?.village_bn_name && (
                                <span className="form__error">
                                  {errors?.village_bn_name.message}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-4 mb-2">
                              <label htmlFor='prVillage_en_name' className="form-label">
                                গ্রাম (ইংরেজিতে){' '}
                                <span className="from_required">*</span>{' '}
                              </label>

                              <input
                                id='prVillage_en_name'
                                name='prVillage_en_name'
                                className="form-control"
                                type="text"
                                {...register('village_en_name', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[A-Za-z\s]+$/,
                                    message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setParmanentAddress({
                                    ...parmanentAddress,
                                    prVillage_en_name: value,
                                  });
                                }}
                              />
                              {errors?.village_en_name && (
                                <span className="form__error">
                                  {errors?.village_en_name.message}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-4 mb-2">
                              <label htmlFor='prRoad' className="form-label">
                                রোড / ব্লক / সেক্টর{' '}
                              </label>
                              <input
                                id='prRoad'
                                name='prRoad'
                                className="form-control"
                                type="text"
                                {...register('road')}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setParmanentAddress({
                                    ...parmanentAddress,
                                    prRoad: value,
                                  });
                                }}
                              />
                              {errors?.road && (
                                <span className="form__error">
                                  {errors?.road.message}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-4 mb-2">
                              <label htmlFor='prHolding_no' className="form-label">হোল্ডিং নং</label>
                              <input
                                id='prHolding_no'
                                name='prHolding_no'
                                className="form-control"
                                type="text"
                                {...register('holding_no')}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setParmanentAddress({
                                    ...parmanentAddress,
                                    prHolding_no: value,
                                  });
                                }}
                              />
                              {errors?.holding_no && (
                                <span className="form__error">
                                  {errors?.holding_no.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='mb-2'>
                            <div className='d-flex justify-content-start align-items-center'>
                              {
                                <input onClick={() => setCurrentAddressChecked(!currentAddressChecked)} type="checkbox"
                                />
                              }
                              <h6 className={`px-3`}> স্থায়ী ঠিকানা আর  বর্তমান ঠিকানা একই হলে টিক দিন</h6>
                            </div>
                          </div>
                          {
                            currentAddressChecked ? <div></div> : <div className="row gx-3">
                              <div className="col-lg-4 mb-3">
                                <label className="form-label">
                                  বিভাগ
                                  <span className="from_required">*</span>
                                </label>
                                <select className="form-control" name="division"
                                  {...register('present_division', {
                                    required: 'খালি রাখা যাবে না',
                                  })}

                                  onChange={(e) => selectDistrict(e)}>
                                  <option value=''>পছন্দ করুন বিভাগ</option>
                                  {
                                    divisions?.division?.map(divisio => <option
                                      className="form-control"
                                      key={divisio?._id} value={divisio?.id}>{divisio?.bn_name}</option>)
                                  }
                                </select>

                              </div>
                              <div className="col-lg-4 mb-3">
                                <label className="form-label">
                                  জেলা
                                  <span className="from_required">*</span>
                                </label>
                                <select className="form-control"
                                  {...register('present_district', {
                                    required: 'খালি রাখা যাবে না',
                                  })}
                                  onChange={(e) => selectUpdzilas(e)}>
                                  <option value=''>পছন্দ করুন জেলা</option>
                                  {selectStoreDistricts?.map(district => <option key={district?._id} value={district?.id}>{district?.bn_name}</option>
                                  )
                                  }
                                </select>
                              </div>
                              <div className="col-lg-4 mb-3">
                                <label className="form-label">
                                  থানা / উপজেলা
                                  <span className="from_required">*</span>
                                </label>
                                <select className="form-control" name="division"
                                  {...register('present_upazila', {
                                    required: 'খালি রাখা যাবে না',
                                  })}
                                  onChange={(e) => selectUnion(e)} >
                                  <option value=''>পছন্দ করুন থানা / উপজেলা</option>
                                  {
                                    selectStoreUpazilas?.map(upazila => <option key={upazila?._id} value={upazila?.id}> {upazila?.bn_name}</option>
                                    )
                                  }
                                </select>
                              </div>


                              {/*   {
                                accountType === "municipality" ?

                                  <>
                                    <div className=" mb-3 col-lg-4">
                                      <label className="form-label">
                                        পৌরসভা  (বাংলা)
                                        <span className="from_required">*</span>
                                      </label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        value={profileInfo?.municipality_bn_name}
                                      />
                                    </div>
                                    <div className=" mb-3 col-lg-4">
                                      <label className="form-label">
                                        পৌরসভা (ইংরেজিতে)
                                        <span className="from_required">*</span>
                                      </label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        value={profileInfo?.municipality_en_name
                                        }
                                      />
                                    </div>
                                  </>
                                  :
                                  <div className="col-lg-4 mb-3">
                                    <label className="form-label">
                                      ইউনিয়ন
                                      <span className="from_required">*</span>
                                    </label>
                                    <select className="form-control"
                                      {...register('present_union', {
                                        required: 'খালি রাখা যাবে না',
                                      })}
                                    >
                                      <option value=''>পছন্দ করুন ইউনিয়ন</option>
                                      {
                                        selectStoreUnions?.map(union => <option key={union?._id} value={union?.id}> {union?.bn_name}</option>
                                        )
                                      }
                                    </select>

                                  </div>
                              } */}
                              <div className="col-lg-4 mb-3">
                                <label className="form-label">
                                  ইউনিয়ন
                                  <span className="from_required">*</span>
                                </label>
                                <select className="form-control"
                                  {...register('present_union', {
                                    required: 'খালি রাখা যাবে না',
                                  })}
                                >
                                  <option value=''>পছন্দ করুন ইউনিয়ন</option>
                                  {
                                    selectStoreUnions?.map(union => <option key={union?._id} value={union?.id}> {union?.bn_name}</option>
                                    )
                                  }
                                </select>

                              </div>

                              <div className="col-lg-4 mb-3">


                                <label className="form-label">
                                  ডাকঘর (বাংলা)
                                  <span className="from_required">*</span>{' '}
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  {...register('presentPost_office_bn', {
                                    required: 'খালি রাখা যাবে না',
                                    pattern: {
                                      value: /^[ঀ-৾\s:-]+$/i,
                                      message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                    },
                                  })}
                                />
                                {errors?.post_office && (
                                  <span className="form__error">
                                    {errors?.post_office.message}
                                  </span>
                                )}
                              </div>
                              <div className="col-lg-4 mb-3">
                                <label className="form-label">
                                  ডাকঘর (ইংরেজিতে){' '}
                                  <span className="from_required">*</span>{' '}
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  {...register('presentPost_office_en', {
                                    required: 'খালি রাখা যাবে না',
                                    pattern: {
                                      value: /^[A-Za-z\s]+$/,
                                      message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                    },
                                  })}
                                />
                              </div>

                              <div className="col-lg-4 mb-3">
                                <label className="form-label">
                                  ওয়ার্ড নং <span className="from_required">*</span>{' '}
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  {...register('presentWard_no', {
                                    required: 'খালি রাখা যাবে না',
                                  })}
                                />

                              </div>
                              <div className="col-lg-4 mb-3">
                                <label className="form-label">
                                  গ্রাম (বাংলা)
                                  <span className="from_required">*</span>{' '}
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  {...register('presentVillage_bn_name', {
                                    required: 'খালি রাখা যাবে না',
                                    pattern: {
                                      value: /^[ঀ-৾\s:-]+$/i,
                                      message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                    },
                                  })}
                                />
                              </div>
                              <div className="col-lg-4 mb-2">
                                <label className="form-label">
                                  গ্রাম (ইংরেজিতে){' '}
                                  <span className="from_required">*</span>{' '}
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  {...register('presentVillage_en_name', {
                                    required: 'খালি রাখা যাবে না',
                                    pattern: {
                                      value: /^[A-Za-z\s]+$/,
                                      message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                    },
                                  })}
                                />
                              </div>
                              <div className="col-lg-4">
                                <label className="form-label">
                                  রোড / ব্লক / সেক্টর{' '}
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  {...register('presentRoad')}
                                />

                              </div>
                              <div className="col-lg-4 ">
                                <label htmlFor='presentHolding_no' className="form-label">হোল্ডিং নং</label>
                                <input
                                  id='presentHolding_no'
                                  className="form-control"
                                  type="text"
                                  {...register('presentHolding_no')}
                                />
                              </div>
                            </div>
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end gap-3">
                  <p
                    onClick={() => setOpen({ type: false }, reset())}
                    style={{ cursor: isLoading ? 'no-drop' : 'pointer' }}
                    className="btn btn-md rounded btn-warning bg-warning font-sm hover-up"
                  >
                    বাতিল করুন
                  </p>
                  <>
                    <input
                      style={{ cursor: isLoading ? 'no-drop' : 'pointer' }}
                      type="submit"
                      className="btn btn-md rounded font-sm hover-up h-25"
                      value="সংরক্ষণ করুন"
                    />
                  </>
                </div>
              </form>
            </div>
          </section >
        </div >
      )
      }
    </>
  );
}

export default CreateCitizenModal;
