import { useForm } from "react-hook-form";
import { usePostRegisterMutation } from "../../app/services/register";
import { useEffect } from "react";
import { toast } from "react-toastify";

const SentEmailCode = ({ showModal, setShowModal, sentEmailCodeInfo }) => {
    const [createAccount, { isLoading, isSuccess, error }] = usePostRegisterMutation()

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const onSubmit = async (value) => {
        const entrepreneurEmailcode = Number(value?.entrepreneurEmailcode)
        const secretaryEmailcode = Number(value?.secretaryEmailcode)
        if (entrepreneurEmailcode === sentEmailCodeInfo?.entrepreneurEmailcode && secretaryEmailcode === sentEmailCodeInfo?.secretaryEmailcode) {
            const sentResult = showModal?.data;
            await createAccount(sentResult)
        } else {
            toast.error('দয়া করে আপনার সঠিক কোড দেন')
            reset()
        }
    }
    useEffect(() => {
        if (isSuccess) {
            toast.success('আপনার আবেদনটি চেক করা হবে অনুগ্রহ করে  অপেক্ষা করুন')
            reset()
            setShowModal({ open: false })
        }
        if (error) {
            toast.error(error?.data?.error)
        }

    }, [error, isLoading, isSuccess])

    return (
        <>
            {showModal?.open && (
                <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block', display: 'flex', height: '100vh', justifyItems: 'center', alignItems: 'center' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <p style={{ fontSize: '15px', }} >অনুগ্রহ করে সচিবের  ই-মেইল <span style={{ color: 'green' }}>{showModal?.data?.secretaryEmail}</span> এবং উদ্যোক্তার ই-মেইল <span style={{ color: 'green' }}>{showModal?.data.entrepreneurEmail}</span> চেক করুন এতে দুটি কোড পাঠানো হয়েছে । নিচের ইনপুট ফিল্ড <br></br> এ কোড গুলো বসান। </p>
                                <button type="button" className="btn-close" onClick={() => setShowModal({ open: false })}></button>
                            </div>
                            <div className="col-lg-12">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="card mb-8">
                                        <div className="card-body">
                                            <div className=" gx-2">
                                                <div className="col-12  mb-3">
                                                    <label style={{ fontSize: '20px' }} className="form-label">
                                                        সচিবের ই-মেইল কোড {" "}
                                                        <span className="from_required">*</span>{" "}
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="Number"
                                                        placeholder="কোড"
                                                        {...register("secretaryEmailcode", { required: true })}

                                                    />
                                                </div>
                                                <div className="col-lg-12 mb-3">
                                                    <label style={{ fontSize: '20px' }} className="form-label">
                                                        উদ্যোক্তার ই-মেইল কোড{" "}
                                                        <span className="from_required">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="Number"
                                                        placeholder="কোড"
                                                        {...register("entrepreneurEmailcode", { required: true })} 

                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <input
                                                    style={{ cursor: isLoading ? 'no-drop' : 'pointer' }}
                                                    className="btn btn-primary bg-fuchsia-700 text-white mt-4" type="submit" value="সাবমিট"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showModal?.open && <div className="modal-backdrop fade show"></div>}
        </>
    )
}
export default SentEmailCode;