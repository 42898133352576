import React, { useEffect } from 'react';
import { auth } from '../../firebase/Firebase.config';
import { useSendEmailVerification, useSignOut } from 'react-firebase-hooks/auth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function Verified() {
    const navigate = useNavigate();
    const [signOut, loading] = useSignOut(auth);
    const [sendEmailVerification, sending, error] = useSendEmailVerification(auth);

    const handleVerification = async () => {
        try {
            await sendEmailVerification();
            toast.success('আপনার ইমেইল চেক করুন');
        } catch (error) {
            console.error('Email verification error:', error);
            toast.error('ইমেইল চেক করার সময় একটি ত্রুটি হয়েছে');
        }
    };

    const handleSignOut = async () => {
        try {
            await signOut();
            navigate('/');
        } catch (error) {
            console.error('Sign out error:', error);
            toast.error('লগআউট করার সময় একটি ত্রুটি হয়েছে');
        }
    };

    useEffect(() => {
        if (loading) {
            toast.success('User Logout And Try Logout');
            navigate('/');
            window.location.reload();
        }
    }, [loading]);

    return (
        <section className="content-main">
            <div className="row mt-60">
                <div className="col-sm-12">
                    <div className="w-50 mx-auto text-center">
                        <h3 className="mt-40 mb-15">দয়াকরে আপনার  ই-মেইল  একাউন্টি ভেরিফাই করুন</h3>
                        <p>প্রথমে ভেরিফাই বাটুনে ক্লিক করুন, তারপর আপনার  ই-মেইলে পাঠানো  ভেরিফাই লিংকে ক্লিক করুন,তার পরে লগআউট করে,আবার লগইন করুন।</p>
                        <a onClick={handleVerification} className="btn btn-primary mt-4">ভেরিফাই</a>
                        <a onClick={handleSignOut} className="btn btn-primary mt-4 ml-5">লগআউট</a>
                    </div>
                </div>
            </div>
        </section>
    );
}
