import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useUpdateCitizenMutation } from '../../../../app/services/citizen';

function ApproveCitizenModal({ modal, setOpen }) {
  const { type, data } = modal;

  const [UpdateAccountHandel, { isSuccess, isLoading, error }] =
    useUpdateCitizenMutation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const onSubmit = (updateData) => {
    const userHandel = { data: { ...updateData, approve: true }, id: data?._id };
    UpdateAccountHandel(userHandel);
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success('নাগরিক টি কে অনুমোদন করা হইল');
      reset();
      setOpen({ type: false });
    }
    if (error) {
      toast.error(error?.data?.error);
    }
  }, [isSuccess, isLoading, error, reset, setOpen]);
  const accountHandel = [
    {
      value: 'true',
      type: true,
    },
    {
      value: 'false',
      type: false,
    },
  ];
  const statusHandel = [
    {
      value: 'pending',
      type: 'pending',
    },
    {
      value: 'approved',
      type: 'approved',
    },
  ];

  return (
    <>
      {type && (
        <div className="modal_product">
          <section className="content-main">
            <div className="row">
              <div className="col-12">
                <div className="content-header">
                  <h2 className="content-title">নাগরিক</h2>
                  <button onClick={() => setOpen({ type: false }, reset())}>
                    X
                  </button>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="col-lg-12">
                  <div className="card ">
                    <div className="card-body">
                      <div className="row gx-2">
                        <div className="col-12 ">
                          <label className="form-label" style={{
                            width: '250px'
                          }}>নাগরিকের অবস্থান</label>
                          <select
                            className="form-control"
                            {...register('status')}
                          >
                            {statusHandel?.map((statu) => (
                              <option
                                key={statu?.value}
                                value={statu?.type}
                                selected={data?.status == statu?.type}
                              >
                                {statu?.value}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    style={{ cursor: isLoading ? 'no-drop' : 'pointer' }}
                    type="submit"
                    className="btn btn-md rounded font-sm hover-up"
                  >
                    সংরক্ষণ করুন
                  </button>
                </div>
              </form>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default ApproveCitizenModal;
