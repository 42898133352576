import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQueryHandel";
export const RegisterApi = createApi({
    reducerPath: "Register",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        postRegister: builder.mutation({
            query: (data) => ({
                url: "v1/register",
                method: "POST",
                body: data,
            }),
        }),
        emailVerify: builder.mutation({
            query: (data) => ({
                url: "v1/emailverifier",
                method: "POST",
                body: data,
            }),
        }),
    }),
});
export const { usePostRegisterMutation, useEmailVerifyMutation } = RegisterApi;

