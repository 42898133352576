import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import { useBulkPostHoldingTaxMutation } from "../../../../app/services/holdingTax";
const link_csv = 'https://docs.google.com/spreadsheets/d/15DuiCDkNQ9v-UvOtPEb8dc__FCCmBJuM-KUGzEVdjsI/edit?usp=sharing'


const AddHoldingbulkUpload = ({ bulkUpload, contects, setBulkUpload }) => {
    const [files, setFiles] = useState(null);
    const handleChange = (file) => {
        setFiles(file);
    };

    const [
        createBulk,
        { isLoading, isError, error, isSuccess, data },
    ] = useBulkPostHoldingTaxMutation();



    /* rtk */
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (files) {
            const formData = new FormData();
            formData.append("holding_tax_csv", files);
            await createBulk(formData);
        } else {
            toast.error('ফাইল পছন্দ করুন')
        }
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success("তথ্য আপডেট সফল হয়েছে");
            setBulkUpload(false)
        }
        if (isError) {
            toast.error(error.data.error);
            setBulkUpload(false)
        }

    }, [isLoading, isError, error, data])


    return (
        <>
            {bulkUpload && (
                <div className="modal_product">
                    <section style={{ background: 'white', padding: '20px', borderRadius: "10px" }}>
                        <div className="row">
                            <div className="col-12">
                                <div className="content-header">
                                    <h2 className="content-title">{contects?.title}</h2>
                                    <button
                                        onClick={() =>
                                            setBulkUpload(!bulkUpload)
                                        }
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div >
                                    <FileUploader
                                        handleChange={handleChange}
                                        name="holding_tax_csv"
                                        multiple={false}
                                        types={["CSV"]}
                                    />
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-10">
                                    <button type="submit" className=" btn btn-primary btn-sm rounded" > {isLoading ? "অপেক্ষা করুন" : "সাবমিট  করুন"}     </button>
                                    <a href={link_csv}  
                                    className=" btn btn-primary btn-sm rounded" 
                                    target="_blank"
                                    >ডাউনলোড   করুন</a>
                                </div>

                            </form>
                        </div>
                    </section>
                </div>
            )}
        </>
    )
}
export default AddHoldingbulkUpload;