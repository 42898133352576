import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQueryHandel';
export const CertificateApi = createApi({
  reducerPath: 'Certificate',
  tagTypes: ['Certificate', 'Death'],
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getAllSonod: builder.query({
      query: (pathname) => ({
        url: `v1/certificate?${pathname}`,
        method: 'GET',
      }),
      providesTags: ['Certificate'],
    }),
    getSingleCertificate: builder.query({
      query: (id) => ({
        url: `v1/certificate/${id}`,
        method: 'GET',
      }),
      providesTags: ['Certificate'],
    }),
    getRoleAllSonod: builder.query({
      query: (pathname) => ({
        url: `v1/certificate/roleAll?${pathname}`,
        method: 'GET',
      }),
      providesTags: ['Certificate'],
    }),
    postCertificate: builder.mutation({
      query: (data) => ({
        url: `v1/certificate/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Certificate'],
    }),
    updateCertificate: builder.mutation({
      query: ({ id, data }) => ({
        url: `/v1/certificate/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Certificate'],
    }),
    deleteCertificate: builder.mutation({
      query: (id) => ({
        url: `v1/certificate/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Certificate'],
    }),
    /* free sonod  pull  */
    getSonodFreeCertificate: builder.mutation({
      query: ({ id, data }) => ({
        url: `/v1/certificate/free?_id=${id}`,
        method: 'PUT',
        body: data,
      }),
    }),
    putCertificateRoleReport: builder.mutation({
      query: (data) => ({
        url: `/v1/certificate/report`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Certificate'],
    }),


  }),
});
export const {
  useGetAllSonodQuery,
  usePostCertificateMutation,
  useUpdateCertificateMutation,
  useDeleteCertificateMutation,
  useGetRoleAllSonodQuery,
  useGetSingleCertificateQuery,
  useGetSonodFreeCertificateMutation,
  usePutCertificateRoleReportMutation

} = CertificateApi;

