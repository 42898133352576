import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const app = initializeApp({
    apiKey: "AIzaSyBoQjCLKZrWeH1bhwyL2ci4wIBYYzAezWc",
    authDomain: "smartunion-6b039.firebaseapp.com",
    projectId: "smartunion-6b039",
    storageBucket: "smartunion-6b039.appspot.com",
    messagingSenderId: "98463267229",
    appId: "1:98463267229:web:a33da222f50326bd94d774",
    measurementId: "G-FPYJPRX75D"
});
getAnalytics(app);
export const auth = getAuth(app);
export default app;

