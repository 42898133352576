import React from "react";
import { handelClick } from "../../../utils/ConfirmDelete";
import { Link } from "react-router-dom";
import { useDeleteCitizenMutation } from "../../../app/services/citizen";
import { useSelector } from "react-redux";
import EnglishToBanglaNumber from "../../../utils/EnglishToBanglaNumber";
function SingleHoldingTax({ user, setStatus, singleUser, setSingleUser }) {



  const { userInfo } = useSelector((state) => state.auth);
  const [deleteCitizen, { isLoading }] = useDeleteCitizenMutation();
  const accessFunction = ["secretary"];

  return (
    <tr>
      <td>
        <p>
          <Link to={`/user/citizen/${user._id}`}>{user?.name_bangla}</Link>{" "}
        </p>
      </td>
      <td>
        <p>{EnglishToBanglaNumber(user?.holding_no)}</p>
      </td>
      <td>
        <p>{user?.village_bn_name}</p>
      </td>
      <td>
        <p>{EnglishToBanglaNumber(user?.ward_no)}</p>
      </td>
      <td>
        <p>{EnglishToBanglaNumber(user?.total_amount.toString())}</p>
      </td>

      <td>
        <p
          className={`badge ${
            !user?.status
              ? "badge-soft-danger"
              : user?.status
              ? "badge-soft-success"
              : ""
          }`}
        >
          {user?.status ? "জমা হয়েছে" : "বাকী"}
        </p>
      </td>
      {userInfo?.users?.role !== "admin" && (
        <td className="text-end">
          <div className="text-end">
            {accessFunction?.includes(userInfo?.users?.role) && (
              <>
                <p
                  onClick={() => (
                    setStatus({
                      type: true,
                      data: user,
                    }),
                    setSingleUser(user)
                  )}
                  className="btn btn-sm font-sm rounded btn-brand m-1"
                >
                  {" "}
                  <i className="material-icons md-edit"></i> সম্পাদনা{" "}
                </p>
              </>
            )}
          </div>
        </td>
      )}
    </tr>
  );
}

export default SingleHoldingTax;
