import React from 'react';
import { TbUsers, TbLayoutBoard } from "react-icons/tb";
import { RiUserShared2Line } from "react-icons/ri";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Import the CSS file

export default function Header() {
    const userInfo = useSelector(state => state?.auth?.userInfo?.users)
    return (
        <div className='header'>
            <div className='px-2'>
                <div className='py-2'>
                    <div className='d-flex justify-content-between align-items-center px-lg-4'>
                        <h3 className='h3'>
                            <Link to='/'>ষ্মার্ট ইউনিয়ন পরিষদ ম্যানেজমেন্ট সিস্টেম।</Link>
                        </h3>
                        <div className='navigation-links d-none d-sm-flex'>
                            {
                                userInfo?.role && <Link to="/dashboard" className=" homeBtnstyle">
                                    <TbLayoutBoard className='icon' />
                                    ড্যাশবোর্ড
                                </Link>
                            }
                            <Link to="/login" className="homeBtnstyle">
                                <RiUserShared2Line className='icon' />
                                লগইন
                            </Link>
                            <button className="homeBtnstyle d-none d-lg-block">
                                <TbUsers className='icon' />
                                নাগরিক কর্ণার
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
