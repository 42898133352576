
const GenderNameformat = (value) => {
    let ganderName;
    if (value == 'male') {
        ganderName = 'পুরুষ'
    }
    else if (value == 'woman') {
        ganderName = 'মহিলা'
    }
    else if (value == 'other') {
        ganderName = 'অন্যান্য'
    }
    return ganderName;
};

export default GenderNameformat;