import FiscalYear from "../../../utils/DateDisplay/FiscalYear";
import EnglishToBanglaNumber from "../../../utils/EnglishToBanglaNumber";
import CartificateDownloadTable from "./CartificateDownloadTable";

const CertificatedownloadFormat = ({ dateIssue, area_information, data }) => {

  let totalAmount = 0;
  return (
    <section>
      <nav className="text-center py-2">
        <h3>বাণিজ্যিক কর আদায়ের রেজিষ্টার</h3>
        <h6>{area_information?.union_bn_name},{area_information?.district?.bn_name}, {area_information?.upazila?.bn_name}</h6>
        <h6>আদায়কৃত বাণিজ্যিক করের তালিকা অর্থ বছর <FiscalYear type='bangla' /></h6>
        <h6>{EnglishToBanglaNumber(`${dateIssue?.start_date}`)} হইতে  {EnglishToBanglaNumber(`${dateIssue?.end_date}`)} ইং পর্যন্ত</h6>
      </nav>
      <table id="customers">
        <thead>
          <tr>
            <th>ক্রমিক নং</th>
            <th>প্রতিষ্ঠানের নাম</th>
            <th>স্বত্ত্বাধিকারীর নাম</th>
            <th>প্রতিষ্ঠানের ধরণ</th>
            <th>প্রতিষ্ঠানের ঠিকানার</th>
            <th>আদায়কৃত করের পরিমান</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((dt, index) => {
            const amount = Number(dt?.trade_license_infomation[0]?.license_fee_infomation[0]?.totalAmmount);
            totalAmount += amount;
            return (
              <CartificateDownloadTable key={index} index={index} info={dt?.trade_license_infomation[0]} dt={dt} />
            );
          })}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>মোট পরিমান</td>
            <td>{EnglishToBanglaNumber(`${totalAmount}`)}</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default CertificatedownloadFormat;
