import React, { useEffect } from 'react'
import { auth } from '../../firebase/Firebase.config';
import { useSignOut } from 'react-firebase-hooks/auth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
export default function Verified() {
    const navigate = useNavigate()
    const [signOut, loading, error] = useSignOut(auth);
    useEffect(() => {
        if (loading) {
            toast.success('User Logout And Try Logout')
            navigate('/')
        }
    }, [loading])
    return (
        <section className="content-main">
            <div className="row mt-60">
                <div className="col-sm-12">
                    <div className="w-50 mx-auto text-center">
                        <h3 className="mt-40 mb-15">Your Account Not Approve</h3>
                        <p>Pleass Contact Admn</p>
                        <a onClick={async () => { await signOut() }} className="btn btn-primary mt-4">LogOut</a>
                    </div>
                </div>
            </div>
        </section>
    )
}
