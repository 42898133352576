export default function BnFormatDate(utcDateString) {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'Asia/Dhaka',
    };

    const utcDate = new Date(utcDateString);
    const banglaDate = new Intl.DateTimeFormat('bn', options).format(utcDate);

    return banglaDate;
}
