import React from "react";
import { useSelector } from "react-redux";
import EnglishToBanglaNumber from "../../utils/EnglishToBanglaNumber";
function RechargeTable({ data }) {
    const { userInfo } = useSelector((state) => state.auth);
    const accessFunction = ["secretary"];
    console.log(userInfo?.users?.role)
    return (
        <>
            <tbody>
                {data?.map((payment) => (
                    <tr>
                        <td>
                            <p>
                                {accessFunction.includes(userInfo?.users?.role) ? payment?.user?.secretaryBanglaName : payment?.user?.entrepreneurBanglaName}
                            </p>
                        </td>
                        <td>
                            <p>
                                {EnglishToBanglaNumber(`${payment?.date}`)}
                            </p>
                        </td>
                        <td>
                            <p>
                                {payment?.trxID}
                            </p>
                        </td>
                        <td>
                            <p>
                                {EnglishToBanglaNumber(`${payment?.amount}`)}৳
                            </p>
                        </td>
                        <td>
                            <p>
                                {EnglishToBanglaNumber(`${payment?.sent_number}`)}
                            </p>
                        </td>
                        <td>
                            <p>
                                <i className="material-icons md-print fs-6"></i>
                            </p>
                        </td>

                        {/*  <td>
                            <p>
                                <Link to={`/user/citizen/${user._id}`}>{payment?.name_bangla}</Link>{" "}
                            </p>
                        </td>
                        <td>
                            <p>{EnglishToBanglaNumber(user?.holding_no)}</p>
                        </td>
                        <td>
                            <p>{user?.village_bn_name}</p>
                        </td>
                        <td>
                            <p>{EnglishToBanglaNumber(user?.ward_no)}</p>
                        </td>
                        <td>
                            <p>{EnglishToBanglaNumber(user?.total_amount.toString())}</p>
                        </td>

                        <td>
                            <p
                                className={`badge ${!user?.status
                                    ? "badge-soft-danger"
                                    : user?.status
                                        ? "badge-soft-success"
                                        : ""
                                    }`}
                            >
                                {user?.status ? "জমা হয়েছে" : "বাকী"}
                            </p>
                        </td>
                        {userInfo?.users?.role !== "admin" && (
                            <td className="text-end">
                                <div className="text-end">
                                    {accessFunction?.includes(userInfo?.users?.role) && (
                                        <>
                                            <p
                                                onClick={() => (
                                                    setStatus({
                                                        type: true,
                                                        data: user,
                                                    }),
                                                    setSingleUser(user)
                                                )}
                                                className="btn btn-sm font-sm rounded btn-brand m-1"
                                            >
                                                {" "}
                                                <i className="material-icons md-edit"></i> পরিবর্তন করুন{" "}
                                            </p>
                                        </>
                                    )}
                                </div>
                            </td>
                        )} */}
                    </tr>
                ))}
            </tbody>

        </>
















    );
}

export default RechargeTable;
