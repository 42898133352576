import * as yup from "yup";
export const LoginSchema = yup.object().shape({
  email: yup.string()
    .required("ইমেল ক্ষেত্র খালি করা যাবে না।")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "একটি বৈধ ইমেল ঠিকানা টাইপ করুন।"
    ),
  password: yup.string()
    .required("ইমেল ক্ষেত্র খালি করা যাবে না।")
    .min(6, "পাসওয়ার্ডে কমপক্ষে ৬টি অক্ষর থাকতে হবে।"),
})
export const forgotSchema = yup.object().shape({
  email: yup.string()
    .required("ইমেল ক্ষেত্র খালি করা যাবে না।")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "একটি বৈধ ইমেল ঠিকানা টাইপ করুন।"
    ),
})
export const updateEmailSchema = yup.object().shape({
  email: yup.string()
    .required("ইমেল ক্ষেত্র খালি করা যাবে না।")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "একটি বৈধ ইমেল ঠিকানা টাইপ করুন।"
    ),
  newEmail: yup.string()
    .required("ইমেল ক্ষেত্র খালি করা যাবে না।")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "একটি বৈধ ইমেল ঠিকানা টাইপ করুন।"
    ),
})
