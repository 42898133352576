import React, { } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Spinner from '../shared/Spinner';

function ProtectedRoutes({ isLoading }) {
  const { userInfo } = useSelector(state => state.auth)
  const location = useLocation();
  if (isLoading || userInfo == null) {
    return <Spinner />;
  }
  return userInfo?.user?.emailVerified && userInfo?.users?.approve ? (
    <Outlet />
  ) : !userInfo?.user?.emailVerified ? (
    <Navigate to="verifie" state={{ from: location }} replace />
  ) : !userInfo?.users?.approve ? (
    <Navigate to="notApprove" state={{ from: location }} replace />
  ) : ""

}

export default ProtectedRoutes;
