import React from 'react'
import item from '../assets/imgs/theme/404.png'
import { Link } from 'react-router-dom'
function Forbidden() {
    return (
        <section className="content-main">
            <div className="row mt-60">
                <div className="col-sm-12">
                    <div className="w-50 mx-auto text-center">
                        <img src={item} width="350" alt="Page Not Found" />
                        <h3 className="mt-40 mb-15"></h3>
                        <p></p>
                        <h3 className="mt-40 mb-15"></h3>
                        <Link to="/login" className=" btn btn-primary mt-4"><i className="material-icons md-keyboard_return"></i>আবার লগইন করার চেষ্টা করো</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Forbidden