import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import styles from "./EnmunicipalityTradeliense.module.css";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { useGetsinglemunicipalitycertificateQuery } from "../../../../app/services/certificatemunicipality";
import { RefetchData } from "../../../../components/elements/RefetchData";
import DashboardLayout from "../../../../components/layout/DashboardLayout";
import GetSpinner from "../../../../helpers/shared/GetSpinner";
import FiscalYear from "../../../../utils/DateDisplay/FiscalYear";
import DateDisplay from "../../../../utils/DateDisplay/DateDisplay";
import FiscalSingleYear from "../../../../utils/DateDisplay/FiscalSingleYear";
import { CertificateFooterEn } from "../../../../components/elements/applicationCertificate/CertificateFooter/CertificateFooter";
import CertificatePagesLogo from "../../../../components/elements/applicationCertificate/CertificatePagesLogo/CertificatePagesLogo";
const EnmunicipalityTradeliense = ({ userInfo }) => {
  const maxRetries = 3;
  const { id } = useParams();
  const componentRef = useRef();
  const [retryCount, setRetryCount] = useState(0);
  const { data, isLoading, isError, refetch } = useGetsinglemunicipalitycertificateQuery(id);
  const certificate = data?.certificate;
  useEffect(() => {
    RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
  }, [isError, retryCount, refetch]);
  return (
    <DashboardLayout>
      {
        isLoading ? <GetSpinner/> : <>
          <div>
            <div className="download_pdf d-flex justify-content-end">
              <ReactToPrint
                trigger={() => (
                  <button className="btn btn-md justify-content-center">
                    প্রিন্ট করুন
                  </button>
                )}
                content={() => componentRef.current}
              />
            </div>
            <div className={styles.body} ref={componentRef}>
              <div className={styles.container}>
                <div className={styles.content_position} >
                  <div>
                    <header className={styles.pdfHeader}>
                      <div className={styles.userImageDiv}>
                        {
                          certificate?.citizen_id?.profilePicture ? <div>
                            <img
                              className={styles.userImage}
                              src={certificate?.citizen_id?.profilePicture}
                              alt="sonod_images"
                            />
                          </div>
                            : <div className={styles.userImage_feram} >
                            </div>
                        }

                      </div>

                      <div className={styles.title}>
                        <img src={certificate?.headLogo} alt="sonod_images" className={styles.gotLogo} />
                        <h2 className="fs-5">
                          {
                            certificate?.union_or_municipality
                              ?.municipality_union_en_name
                          }{" "}
                        </h2>
                        <p>
                          {certificate?.union_or_municipality?.district?.name},{" "}
                          {certificate?.union_or_municipality?.upazila?.name}{" "}
                        </p>
                        <h2 className="fs-5">Trade License</h2>
                        <p className="fs-6">Trade No -{certificate?.sonodId}</p>
                      </div>
                      <div className={styles.blankDiv}></div>
                    </header>
                    <div className={styles.timeline}>
                      <sm style={{ fontWeight: "bold" }}>
                      </sm>
                      <h2>
                        <span className="fs-6">Fiscal Year:- <FiscalYear type='' /></span>{" "}
                      </h2>
                      <sm style={{ textAlign: "end",  fontWeight: "bold" }}>
                        <div className="d-flex align-items-center  my-auto">
                          <span className=" ">Date of Issue:-{" "}</span>
                          <span>
                            <DateDisplay />
                          </span>
                        </div>
                      </sm>
                    </div>
                    <div>
                      <p
                        style={{
                          textAlign: "center",
                          fontWeight: 500,
                        }}
                      >
                        [ According to Union Parishad Model Tax Schedule 2013 ]
                      </p>
                    </div>
                    <div className={styles.main}>
                      <div className={styles.userInfo}>
                        <>
                          <h5 className="font-sm">Organization Name</h5>
                          <p
                            style={{
                              fontSize: '16px',
                              fontWeight: 'bold'
                            }}
                          >
                            :{" "}
                            {
                              certificate?.trade_license_infomation[0]
                                ?.institution_en_name
                            }
                          </p>
                        </>
                        <>
                          <h5 className="font-sm">Organization Address </h5>
                          <p>
                            :{" "}
                            {
                              certificate?.trade_license_infomation[0]?.institution_address_en
                            }
                          </p>
                        </>
                        <>
                          <h5 className="font-sm"> Organization Type</h5>
                          <p>
                            {" "}
                            :{" "}
                            {
                              certificate?.trade_license_infomation[0]
                                ?.institution_en_type
                            }
                          </p>
                        </>

                        <>
                          <h5 className="font-sm">Proprietary Name </h5>
                          <p>: {certificate?.citizen_id ?.name_english},{certificate?.trade_license_infomation[0]?.institution_nature[0]?.joint_name_en}</p>
                        </>
                        <>
                          <h5 className="font-sm">NID/Birth Reg No </h5>
                          <p>: {certificate?.citizen_id?.nid},{certificate?.trade_license_infomation[0]?.institution_nature[0]?.joint_nid}</p>
                        </>
                        <>
                          <h5 className="font-sm">Organization  Nature</h5>
                          <p>: {certificate?.trade_license_infomation[0]?.institution_nature[0]?.en_name}</p>
                        </>
                        
                        
                        <>
                          <h5 className="font-sm">TIN Number</h5>
                          <p>: {certificate?.trade_license_infomation[0]?.tin}</p>
                        </>

                        <>
                          <h5 className="font-sm">Father / Husband Name</h5>
                          <p>: {certificate?.citizen_id?.father_husband_english}</p>
                        </>
                        <>
                          <h5 className="font-sm">Mother Name</h5>
                          <p>: {certificate?.citizen_id?.mother_name_english}</p>
                        </>
                        <>
                          <h5 className="font-sm">Present Address</h5>
                          <p>
                            :
                            {" "} {certificate?.citizen_id?.present_address[0]?.village_en_name},
                            {
                              certificate?.citizen_id?.present_address[0]?.holding_no ? `{" "}${certificate?.citizen_id?.present_address[0]?.holding_no},` : ''}
                            {" "}
                            {certificate?.citizen_id?.present_address[0]?.post_office_en},{" "}
                            {certificate?.citizen_id?.present_address[0]?.ward_no},{" "}
                            {certificate?.citizen_id?.present_address[0]?.union?.name},{" "}
                            {certificate?.citizen_id?.present_address[0]?.upazila?.name},{" "}
                            {certificate?.citizen_id?.present_address[0]?.district?.name}
                          </p>
                        </>
                        <>
                          <h5 className="font-sm">Permanent Address </h5>
                          <p>
                            :{" "}
                            {certificate?.citizen_id?.permanent_address[0]?.village_en_name},
                            {
                              certificate?.citizen_id?.permanent_address[0]?.holding_no ? `{" "}
                         {" "}${certificate?.citizen_id?.permanent_address[0]?.holding_no
                                },
                          `: ''
                            }{" "}
                            {certificate?.citizen_id?.permanent_address[0]?.post_office_en},{" "}
                            {certificate?.citizen_id?.permanent_address[0]?.ward_no},{" "}
                            {certificate?.citizen_id?.permanent_address[0]?.union?.name}, {" "}
                            {certificate?.citizen_id?.permanent_address[0]?.upazila?.name},{" "}
                            {certificate?.citizen_id?.permanent_address[0]?.district?.name}
                          </p>
                        </>
                      </div>
                      <div>
                        <h5 style={{ fontWeight: "bold" }} className="mt-0">
                          License / Renew Fee{" "}
                        </h5>

                        <div className={styles.infoTable}>
                          <div className={styles.grid_container}>
                            <h6 className="font-sm fw-bold">License Fee</h6>
                            <h6 className="font-sm fw-bold">Vat </h6>
                            <h3 className={`${styles.span_2} fw-bold`}>
                              Taxes on Profession and Trades
                            </h3>
                            <h3 className={`${styles.span_2} fw-bold`}>
                              Signboard / Advertisement{" "}
                            </h3>
                            <h6 className="font-sm fw-bold">Due Amount </h6>
                            <h6 className="font-sm fw-bold">Service Charge</h6>
                            <h6 className="font-sm fw-bold">Total</h6>
                          </div>

                          {certificate?.trade_license_infomation?.map(
                            (trade_license_info) => (
                              <>
                                <div key={data._id} className={styles.grid_container}>
                                  <p>
                                    {
                                      trade_license_info?.license_fee_infomation[0]
                                        ?.license_fee
                                    }{" "}
                                  </p>
                                  <p>
                                    {" "}
                                    {
                                      trade_license_info?.license_fee_infomation[0]?.vat
                                    }{" "}
                                  </p>
                                  <p className={styles.span_2}>
                                    {" "}
                                    {
                                      trade_license_info?.license_fee_infomation[0]
                                        ?.taxes_on_profession_trades
                                    }{" "}
                                  </p>
                                  <p className={styles.span_2}>
                                    {" "}
                                    {
                                      trade_license_info?.license_fee_infomation[0]
                                        ?.signboard
                                    }{" "}
                                  </p>
                                  <p>
                                    {" "}
                                    {
                                      trade_license_info?.license_fee_infomation[0]?.due
                                    }{" "}
                                  </p>
                                  <p>
                                    {" "}
                                    {
                                      trade_license_info?.license_fee_infomation[0]
                                        ?.service_charge
                                    }{" "}
                                  </p>
                                  <p>
                                    {
                                      trade_license_info?.license_fee_infomation[0]
                                        ?.totalAmmount
                                    }{" "}
                                  </p>
                                </div>
                              </>
                            )
                          )}
                        </div>
                        <div>
                          <p>
                            The business / profession mentioned in this License must be
                            governed by the terms of the Consumer Rights Act 2009 and
                            the License may not be transferred to any other person or
                            organization under any circumstances.
                          </p>
                          <br />
                          <h4 style={{
                            textAlign: 'center'
                          }}>
                            Validity of this trade license 1st July {FiscalSingleYear('', 0)} to 30th June {FiscalSingleYear('', 1)} Up to
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CertificateFooterEn certificate={certificate} accountType={userInfo?.account}
                    sigbature={true}
                    sigbatureConecnt="Secretary Signature"
                  />
                </div>
                <CertificatePagesLogo
                  certificate={certificate}
                  toValue={'150px'}
                />
              </div>
            </div>

          </div>
        </>
      }
    </DashboardLayout>
  );
};

function mapStateToProps(state) {
  return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(EnmunicipalityTradeliense)