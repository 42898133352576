import React from 'react';
import { FaHandPointRight } from "react-icons/fa";

const ImportantLink = () => {
    return (
        <div>
            <div className="ms-4 mt-2">
                <div xs={12} md={6} lg={4} style={{ marginBottom: "10px" }}>
                    <a className='d-flex align-items-center gap-2' href='/'>
                        <FaHandPointRight className='text-sm text-purple-700' />
                        <span className='text-sm text-purple-500'>প্রধানমন্ত্রী কার্যালয়</span>
                    </a>
                </div>
                <div xs={12} md={6} lg={4} style={{ margin: "10px 0" }}>
                    <a className='d-flex align-items-center gap-2' href='/'>
                        <FaHandPointRight className='text-sm text-purple-700' />
                        <span className='text-sm text-purple-500'>বাংলাদেশ জাতীয় তথ্য বাতায়ন</span>
                    </a>
                </div>
                <div xs={12} md={6} lg={4} style={{ margin: "10px 0" }}>
                    <a className='d-flex align-items-center gap-2' href='/'>
                        <FaHandPointRight className='text-sm text-purple-700' />
                        <span className='text-sm text-violet-500'> মন্ত্রিপরিষদ বিভাগ</span>
                    </a>
                </div>
                <div xs={12} md={6} lg={4} style={{ margin: "10px 0" }}>
                    <a className='d-flex align-items-center gap-2' href='/'>
                        <FaHandPointRight className='text-sm text-purple-700' />
                        <span className='text-sm text-violet-500'> স্বাস্থ্য ও পরিবার কল্যাণ মন্ত্রণালয়</span>
                    </a>
                </div>
                <div xs={12} md={6} lg={4} style={{ margin: "10px 0" }}>
                    <a className='d-flex align-items-center gap-2' href='/'>
                        <FaHandPointRight className='text-sm text-purple-700' />
                        <span className='text-sm text-violet-500'>  শিক্ষা মন্ত্রণালয়</span>
                    </a>
                </div>
                <div xs={12} md={6} lg={4} style={{ margin: "10px 0" }}>
                    <a className='d-flex align-items-center gap-2' href='/'>
                        <FaHandPointRight className='text-sm text-purple-700' />
                        <span className='text-sm text-violet-500'>  তথ্য মন্ত্রণালয়</span>
                    </a>
                </div>
                <div xs={12} md={6} lg={4} style={{ margin: "10px 0" }}>
                    <a className='d-flex align-items-center gap-2' href='/'>
                        <FaHandPointRight className='text-sm text-purple-700' />
                        <span className='text-sm text-violet-500'> সমাজকল্যাণ মন্ত্রণালয়</span>
                    </a>
                </div>
                <div xs={12} md={6} lg={4} style={{ margin: "10px 0" }}>
                    <a className='d-flex align-items-center gap-2' href='/'>
                        <FaHandPointRight className='text-sm text-purple-700' />
                        <span className='text-sm text-violet-500'>  বাংলাদেশ ফরম</span>
                    </a>
                </div>
                <div xs={12} md={6} lg={4} style={{ margin: "10px 0" }}>
                    <a className='d-flex align-items-center gap-2' href='/'>
                        <FaHandPointRight className='text-sm text-purple-700' />
                        <span className='text-sm text-violet-500'>  বাংলাদেশ সংসদ</span>
                    </a>
                </div>
                <div xs={12} md={6} lg={4} style={{ margin: "10px 0" }}>
                    <a className='d-flex align-items-center gap-2' href='/'>
                        <FaHandPointRight className='text-sm text-purple-700' />
                        <span className='text-sm text-violet-500'>  ই-নামজারি সিস্টেমে উত্তরাধিকার সনদ</span>
                    </a>
                </div>
                <div xs={12} md={6} lg={4} style={{ margin: "10px 0" }}>
                    <a className='d-flex align-items-center gap-2' href='/'>
                        <FaHandPointRight className='text-sm text-purple-700' />
                        <span className='text-sm text-violet-500'>  জন্ম নিবন্ধন তথ্য যাচাই</span>
                    </a>
                </div>
                <div xs={12} md={6} lg={4} style={{ margin: "10px 0" }}>
                    <a className='d-flex align-items-center gap-2' href='/'>
                        <FaHandPointRight className='text-sm text-purple-700' />
                        <span className='text-sm text-violet-500'>  জাতীয় পরিচয় পত্র তথ্য যাচাই</span>
                    </a>
                </div>
            </div>
        </div >
    );
};

export default ImportantLink;