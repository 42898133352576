import React, { useEffect } from 'react';
import { useSignOut } from 'react-firebase-hooks/auth';
import { Outlet, useNavigate } from 'react-router-dom';
import { auth } from '../../firebase/Firebase.config';

function TabAndTimeRoutes() {
    const navigate = useNavigate()
    const [signOut] = useSignOut(auth);
    const handleWindowClose = () => {
        signOut();
        navigate('/')
    };
/*     useEffect(() => {
        window.addEventListener('beforeunload', function (e) {
            console.log('hello')
            handleWindowClose()
        });

    }, []); */

    return (
        <Outlet />
    );
}

export default TabAndTimeRoutes;
