import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginSchema } from '../../helpers/validation/LoginSchema';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase/Firebase.config';
import { useState } from 'react';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
export default function Login() {
  const naviger = useNavigate('')
  const [showPassword, setShowPassword] = useState(false)
  const [signInWithEmailAndPassword, user, loading, error] = useSignInWithEmailAndPassword(auth);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(LoginSchema) });
  const onSubmit = async (data) => {
    const email = data?.email;
    const password = data?.password;
    await signInWithEmailAndPassword(email, password);
  };

  useEffect(() => {
    if (user) {
      naviger('/dashboard')
    }
    if (error) {
      toast.error('আপনার একাউন্ট টি তৈরি করা হয় নেই। আপনি এডমিন এর সাথে যোগাযোগ করুন')
    }
  }, [user, loading])



  return (
    <div>
      <section
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'

        }}
      >
        <div className="card" style={{ width: '300px' }}>
          <div className="card-body">
            <h4 className="card-title mb-4">লগইন করুন</h4>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <label >ইমেইল <span style={{color:'red'}}>*</span></label>
                <input
                  className="form-control"
                  placeholder="ইমেইল"
                  type="email"
                  {...register('email')}
                />
                {errors?.email && (
                  <span className="form__error">{errors?.email?.message}</span>
                )}
              </div>
              <div className="mb-3">
                <label>পাসওয়ার্ড <span style={{color:'red'}}>*</span></label>
                <input
                  className="form-control"
                  placeholder="পাসওয়ার্ড"
                  type={showPassword ? 'text' : 'password'}
                  {...register('password')}
                />
                {errors?.password && (
                  <span className="form__error">
                    {errors?.password?.message}
                  </span>
                )}
              </div>
              <div className="mb-3">
                <Link to="/forgot" className="float-end font-sm text-muted">
                পাসওয়ার্ড ভুলে গেছেন
                </Link>
                <label onClick={() => setShowPassword(!showPassword)} className="form-check">
                  <input type="checkbox" className="form-check-input" />
                  <span  className="form-check-label">পাসওয়ার্ড দেখাও</span>
                </label>
              </div>
              <div className="mb-4">
                <button type="submit" className="btn-primary w-100">
                  {
                    loading ? 'অপেক্ষা করুন...' : 'প্রবেশ করুন'
                  }
                </button>
              </div>
            </form>
            <p className="text-center mb-4">
            অ্যাকাউন্ট নেই? <Link to="/union-signUp">নিবন্ধন করুন</Link>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
