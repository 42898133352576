import React, { useEffect, useMemo, useState } from "react";
import DashboardLayout from "../../components/layout/DashboardLayout";
import Pagination from "../../components/elements/Pagination";
import ApproveCitizenModal from "../../components/elements/citizen/modal/ApproveCitizenModal";
import EditCitizenModal from "../../components/elements/citizen/modal/EditCitizenModal";
import { useAllUserGetQuery } from "../../app/services/authUser";

import { useSelector } from "react-redux";
import GetSpinner from "../../helpers/shared/GetSpinner";
import CreateHoldingTaxModal from "../../components/elements/holdingTax/modal/CreateHoldingTaxModal";
import { useGetAllHoldingTaxesQuery } from "../../app/services/holdingTax";
import SingleCitizen from "../../components/elements/citizen/SingleCitizen";
import SingleHoldingTax from "../../components/elements/holdingTax/SingleHoldingTax";
import EditHoldingTaxModal from "../../components/elements/holdingTax/modal/EditHoldingTaxModal";
import SingleHoldingTaxReport from "../../components/elements/holdingTax/SingleHoldingTaxReport";
import Search from "../../utils/Search";

function HoldingTaxReport() {
  const contects = {
    title: "নতুন নিবন্ধন",
  };
  const [filterStatus, setFilterStatus] = useState("all");
  const [search, setSearchValue] = useState("");
  const [status, setStatus] = useState({ type: false, data: null });
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [singleUser, setSingleUser] = useState();
  const [bulkUpload, setBulkUpload] = useState(false)
  const { userInfo } = useSelector((state) => state.auth);

  const [holdingTaxModal, setHoldingTaxModal] = useState({
    type: false,
    data: null,
  });
  const pathname = `page=${pageIndex}&limit=${pageSize}&search=${search}&status=${filterStatus}`;
  const { data, isLoading: loading } = useGetAllHoldingTaxesQuery(pathname);
  const holdingTaxes = useMemo(() => data?.result ? data?.result : [], [loading, data])
  const accessFunction = ["entrepreneur", "secretary"];

  return (
    <DashboardLayout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">
              হোল্ডিং ট্যাক্স প্রতিবেদন
            </h2>
          </div>
        </div>
        <div className="card mb-4">
          <header className="card-header">
            <div className="row gx-3 align-items-center">
              <div className="col-md-3 col-12 ms-auto mb-md-0 mb-3">
                <p
                  onClick={() => setFilterStatus("all")}
                  className={`btn  btn-sm rounded w-100 ${filterStatus === "all" ? "btn-primary" : "btn-secondary"
                    }`}
                >
                  সকল হোল্ডিং ট্যাক্স
                </p>
              </div>
              <div className="col-md-3 col-12 ms-auto mb-md-0 mb-3">
                <p
                  onClick={() => setFilterStatus("completed")}
                  className={`btn  btn-sm rounded w-100 ${filterStatus === "completed"
                    ? "btn-primary"
                    : "btn-secondary"
                    }`}
                >
                  আদায়কৃত হোল্ডিং ট্যাক্স
                </p>
              </div>
              <div className="col-md-3 col-12 ms-auto mb-md-0 mb-3">
                <p
                  onClick={() => setFilterStatus("uncompleted")}
                  className={`btn  btn-sm rounded w-100 ${filterStatus === "uncompleted"
                    ? "btn-primary"
                    : "btn-secondary"
                    }`}
                >
                  বাকী হোল্ডিং ট্যাক্স সমূহ
                </p>
              </div>
              <div className="col-md-3  col-12 me-auto mb-md-0 mb-3">
              <Search setSearchValue={setSearchValue} placeholder="অনুসন্ধান করুন"/>

              </div>
            </div>
          </header>
          <div className="col-md-12 px-4 ">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr className="">
                    <th>নাম</th>
                    <th>হোল্ডিং</th>
                    <th>গ্রাম</th>
                    <th>ওয়ার্ড</th>
                    <th> প্রকৃত ধার্যকৃত কর</th>
                    <th> তফসিল অনুযায়ী </th>
                    <th>মোট পরিমাণ </th>
                    <th>ট্যাক্স গ্রহন</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && <GetSpinner />}
                  {holdingTaxes?.map((user) => (
                    <SingleHoldingTaxReport
                      user={user}
                      setStatus={setStatus}
                      singleUser={singleUser}
                      setSingleUser={setSingleUser}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Pagination
          totalPages={data?.totalPages}
          setPagination={setPagination}
          pageIndex={pageIndex}
          pageSize={pageSize}
        />
      </section>

      <EditHoldingTaxModal
        singleUser={singleUser}
        modal={status}
        setOpen={setStatus}
      />

      <CreateHoldingTaxModal
        modal={holdingTaxModal}
        setOpen={setHoldingTaxModal}
        contects={contects}
      />
    </DashboardLayout>
  );
}

export default HoldingTaxReport;
