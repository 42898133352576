import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQueryHandel';
export const CertificatemunicipalityApi = createApi({
    reducerPath: 'municipality',
    tagTypes: ['municipality'],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getallmunicipalitycertificate: builder.query({
            query: (pathname) => ({
                url: `v1/certificate/municipality?${pathname}`,
                method: 'GET',
            }),
            providesTags: ['municipality'],
        }),
        getsinglemunicipalitycertificate: builder.query({
            query: (id) => ({
                url: `v1/certificate/municipality/${id}`,
                method: 'GET',
            }),
            providesTags: ['municipality'],
        }),
        getrolemunicipalitycertificate: builder.query({
            query: (pathname) => ({
                url: `v1/certificate/municipality/roleAll?${pathname}`,
                method: 'GET',
            }),
            providesTags: ['municipality'],
        }),
        createmunicipalitycertificate: builder.mutation({
            query: (data) => ({
                url: `v1/certificate/municipality`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['municipality'],
        }),
        updatemunicipalitycertificate: builder.mutation({
            query: ({ id, data }) => ({
                url: `/v1/certificate/municipality/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['municipality'],
        }),
        deletemunicipalitycertificate: builder.mutation({
            query: (id) => ({
                url: `v1/certificate/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['municipality'],
        }),
        /* free sonod  pull  */
        getsonodfreemunicipalitycertificate: builder.mutation({
            query: ({ id, data }) => ({
                url: `/v1/certificate/municipality/free?_id=${id}`,
                method: 'PUT',
                body: data,
            }),
        }),
        putrolerepostmunicipalitycertificate: builder.mutation({
            query: (data) => ({
                url: `/v1/certificate/municipality/report`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['municipality'],
        }),


    }),
});
export const {
    useCreatemunicipalitycertificateMutation,useDeletemunicipalitycertificateMutation,useGetallmunicipalitycertificateQuery,useGetsinglemunicipalitycertificateQuery,useGetrolemunicipalitycertificateQuery,useUpdatemunicipalitycertificateMutation,usePutrolerepostmunicipalitycertificateMutation, useGetsonodfreemunicipalitycertificateMutation
} = CertificatemunicipalityApi;

