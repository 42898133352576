import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/main.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './app/store';


import { Toaster } from 'react-hot-toast';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <Toaster />
        <App />
      </React.StrictMode>
    </BrowserRouter>
  </Provider>
);
