const SingleDonation = ({ subscription, subscriptionsHandelstatus, updateLoading }) => {

    const timestamp = subscription?.updatedAt;
    const date = new Date(timestamp);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    const dateformate = `${day}-${month}-${year}`
    return (
        <>
            <tr>
                <td>
                    {dateformate}
                </td>
                <td>
                    {subscription?.month}
                </td>
                <td>
                    {subscription?.total_amount}
                </td>
                <td>
                    {subscription?.sent_number}
                </td>
                <td>
                    {subscription?.transaction_id}
                </td>
                <td>
                    {subscription?.mobile_banking}
                </td>
                <td
                    className='status_ponter'
                    style={{ cursor: updateLoading ? 'no-drop' : '' }}
                >
                    {subscription?.status ? <span className='approve_color'>গ্রহণ করা হয়েছে</span> : <span
                        className='pandding_color'
                        onClick={() => subscriptionsHandelstatus(subscription)}
                    >গ্রহণ করা হয়নি</span>}
                </td>
            </tr>
        </>
    )
}
export default SingleDonation;