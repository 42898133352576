import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../../components/layout/DashboardLayout'
import { SubscriptionSchema } from '../../../helpers/validation/Subscription'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import { usePostSubscriptionMutation } from '../../../app/services/subscription';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Subscription() {
    const [addSubscription, { isLoading, isSuccess }] = usePostSubscriptionMutation()
    const [selectmonth, setSelectmonth] = useState()
    const [ammount, setAmmount] = useState()
    const [vetShowAmmount, setVetShowAmmount] = useState()
    const navigate = useNavigate()

    const totalamountmoney = (e) => {
        const month = e.target.value;
        setSelectmonth(month)
        const vet = 0.015;
        const taotalAmmount = (month * 300)
        const vatShowTotal = (taotalAmmount * vet) + taotalAmmount
        setAmmount(taotalAmmount)
        setVetShowAmmount(vatShowTotal)
    }
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(SubscriptionSchema) });
    const handelSubscription = async (data) => {
        const subscriptionValue = {
            ...data, month: selectmonth, amount: ammount, total_amount: vetShowAmmount
        }
        await addSubscription(subscriptionValue)
    }
    useEffect(() => {
        if (isSuccess) {
            toast.success('আপনার পেমেন্টটি চেক করা হচ্ছে দয়া করে অপেক্ষা করুন')
            reset()
            navigate("/donation")
        }
    }, [isSuccess, isLoading])



    return (
        <DashboardLayout>
            <div>
                <h4 className='text-center underline'>প্রথমে মাস সিলেক্ট করুন, যেই নাম্বার থেকে টাকা পাঠিয়েছেন সেটা উল্লেখ করুন, ট্রানজেকশন নাম্বার দিন,  তার পরে টাকা পাঠানোর মাধ্যম নির্বাচন করুন, তার পরে সাবমিট করুন।</h4>
            </div>
            <section className="content-main">
                <p>প্রতি মাস 300 করে ভ্যাট 1.5 % (01837266474)</p>
                <div className="row">
                    <form onSubmit={handleSubmit(handelSubscription)}>
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="row gx-2">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="product_cost" className="form-label">চুক্তির সময় (মাস) </label>
                                            <select className="form-select"
                                                onChange={totalamountmoney}
                                            >
                                                <option value={0} selected>Select Month</option>
                                                <option value={1} >1</option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                                <option value={4}>4</option>
                                                <option value={5}>5</option>
                                                <option value={6}>6</option>
                                                <option value={7}>7</option>
                                                <option value={8}>8</option>
                                                <option value={9}>9</option>
                                                <option value={10}>10</option>
                                                <option value={11}>11</option>
                                                <option value={12}>12</option>
                                            </select>

                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="price" className="form-label">টাকার পরিমাণ </label>
                                            <input type="number" placeholder="টাকার পরিমাণ" className="form-control" id="price"
                                                value={vetShowAmmount}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="row gx-2">

                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="নাম্বার" className="form-label">টাকা পাঠানোর নাম্বার</label>
                                            <input type="number" placeholder="নাম্বার" className="form-control" id="নাম্বার"
                                                {...register("sent_number")}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="ট্রানজেকশন" className="form-label">ট্রানজেকশন আইডি</label>
                                            <input type="text" placeholder="ট্রানজেকশন" className="form-control" id="ট্রানজেকশন"
                                                {...register("transaction_id")}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div className="mb-3">
                                                <label className="form-label">টাকা পাঠানোর ধরন</label>
                                                <select className="form-select"
                                                    {...register("mobile_banking")}
                                                >
                                                    <option value='বিকাশ' selected>বিকাশ</option>
                                                    <option value='নগদ'>নগদ</option>
                                                    <option value='রকেট'>রকেট</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="btn btn-md rounded font-sm hover-up">সাবমিট</button>
                    </form>
                </div>
            </section>
        </DashboardLayout >
    )
}

export default Subscription