import React, { useEffect } from "react";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useUpdateLogoMutation } from "../../../../app/services/authUser";

export default function EditLogoModal({ modal, setModal }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { type, data, field } = modal;
  const [updateUser, { isSuccess, isLoading }] = useUpdateLogoMutation();

  const onSubmit = async (value) => {
    let file = value[field][0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("field", field);
      await updateUser({ id: data?._id, value: formData });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success("তথ্য আপডেট সফল হয়েছে");
      reset();
      setModal({ type: false });
    }
  }, [isSuccess]);
  return (
    <>
      {type && (
        <div className="modal_product row">
          <section className="content-main col-7">
            <div className="row">
              <div className="col-12">
                <div className="content-header">
                  <h2 className="content-title">সার্টিফিকেট লোগো আপডেট</h2>
                  <button onClick={() => setModal({ type: false }, reset())}>
                    X
                  </button>
                </div>
              </div>
              <form
                className="col-12 card p-4"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className=" row col-span-2 ">
                  {field === "headLogo" && (
                    <div className="col-6  mb-4">
                      <h5 className="">হেড লোগো</h5>
                      <input type="file" {...register("headLogo")} />
                    </div>
                  )}
                  {field === "pageLogo" && (
                    <div className="col-6  mb-4">
                      <h5 className="my-3">পেজ লোগো</h5>
                      <input type="file" {...register("pageLogo")} />
                    </div>
                  )}
                </div>

                <div>
                  <input
                  style={{ cursor: isLoading ? "no-drop" : "pointer" }}
                    type="submit"
                    value="সংরক্ষণ করুন"
                    className="btn btn-primary btn-md"
                  />
                </div>
              </form>
            </div>
          </section>
        </div>
      )}
    </>
  );
}
