import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import styles from './BnOtherCertificate.module.css';
import DashboardLayout from '../../../../layout/DashboardLayout';
import { useParams } from 'react-router-dom';
import { useGetSingleCertificateQuery } from '../../../../../app/services/certificate';
import GetSpinner from '../../../../../helpers/shared/GetSpinner';
import { connect } from 'react-redux';
import EnglishToBanglaNumber from '../../../../../utils/EnglishToBanglaNumber';
import { CertificateFooterBn } from '../../CertificateFooter/CertificateFooter';
import { CertificateHeaderBn } from '../../CertificateHeader/CertificateHeader';
import { useState } from 'react';
import { useEffect } from 'react';
import { RefetchData } from '../../../RefetchData';
import CertificatePagesLogo from '../../CertificatePagesLogo/CertificatePagesLogo';


const BnOtherCertificate = ({ userInfo }) => {
  const maxRetries = 3;
  const { id } = useParams();
  const componentRef = useRef();
  const [retryCount, setRetryCount] = useState(0);
  const { data, isLoading, isError, refetch } = useGetSingleCertificateQuery(id);
  const accountType = userInfo?.account
  const certificate = data?.certificate;
  useEffect(() => {
    RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
  }, [isError, retryCount, refetch]);

  return (
    <DashboardLayout>
      {
        isLoading ? <GetSpinner /> : <>
          <div>
            <div className="download_pdf d-flex justify-content-end">
              <ReactToPrint
                trigger={() => (
                  <button className="btn btn-md justify-content-center">
                    প্রিন্ট করুন
                  </button>
                )}
                content={() => componentRef.current}
              />
            </div>

            <div className={styles.body} ref={componentRef}>
              <div className={styles.container}>
                <div className={styles.content_position}>
                  <div>
                    <CertificateHeaderBn certificate={certificate} sonodName={certificate?.other_info?.title_bn} />
                    <div dangerouslySetInnerHTML={{ __html: certificate?.other_info?.detail_bn }} />
                  </div>
                  <CertificateFooterBn certificate={certificate} accountType={accountType} marginHandel={true} />
                </div>

                <CertificatePagesLogo
                  certificate={certificate}
                  toValue={'200px'}
                />
              </div>
            </div>
          </div>

        </>
      }
    </DashboardLayout>
  );
};


function mapStateToProps(state) {
  return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(BnOtherCertificate)