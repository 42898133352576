import React from "react";
import { handelClick } from "../../../utils/ConfirmDelete";
import { Link } from "react-router-dom";
import { useDeleteCitizenMutation } from "../../../app/services/citizen";
import { useSelector } from "react-redux";
import EnglishToBanglaNumber from "../../../utils/EnglishToBanglaNumber";
function SingleHoldingTaxReport({
  user,
  setStatus,
  singleUser,
  setSingleUser,
}) {
  const { userInfo } = useSelector((state) => state.auth);
  const [deleteCitizen, { isLoading }] = useDeleteCitizenMutation();
  const accessFunction = ["secretary"];

  return (
    <tr>
      <td>
        <p>
          <Link to={`/user/citizen/${user._id}`}>{user?.name_bangla}</Link>{" "}
        </p>
      </td>
      <td>
        <p>{EnglishToBanglaNumber(user?.holding_no)}</p>
      </td>
      <td>
        <p>{EnglishToBanglaNumber(user?.village_bn_name)}</p>
      </td>
      <td>
        <p>{EnglishToBanglaNumber(user?.ward_no)}</p>
      </td>
      <td>
        <p>{EnglishToBanglaNumber(user?.actual_tax_amount?.toString())}</p>
      </td>
      <td>
        <p>{EnglishToBanglaNumber(user?.amount_as_schedule?.toString())}</p>
      </td>

      <td>
        <p>{EnglishToBanglaNumber(user?.total_amount?.toString())}</p>
      </td>

      <td>
        <p
          className={`badge ${!user?.status
              ? "badge-soft-danger"
              : user?.status
                ? "badge-soft-success"
                : ""
            }`}
        >
          {user?.status ? "জমা হয়েছে" : "বাকী"}
        </p>
      </td>
    </tr>
  );
}

export default SingleHoldingTaxReport;
