import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQueryHandel';
export const DashboardApi = createApi({
    reducerPath: 'Dashboard',
    tagTypes: ['Dashboard'],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        dashboard: builder.query({
            query: (dateQuery) => ({
                url: `v1/dashboard?${dateQuery}`,
                method: 'GET',
            }),
            providesTags: ['Dashboard'],
        }),
    }),
});
export const {
    useDashboardQuery
} = DashboardApi;
