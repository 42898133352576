import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import styles from "./EnMrittoSonod.module.css";
import DashboardLayout from "../../../../layout/DashboardLayout";
import { useParams } from "react-router-dom";
import { useGetSingleCertificateQuery } from "../../../../../app/services/certificate";
import { CertificateFooterEn } from "../../CertificateFooter/CertificateFooter";
import { connect } from "react-redux";
import { CertificateHeaderEn } from "../../CertificateHeader/CertificateHeader";
import { RefetchData } from "../../../RefetchData";
import { useEffect } from "react";
import { useState } from "react";
import GetSpinner from "../../../../../helpers/shared/GetSpinner";
import CertificatePagesLogo from "../../CertificatePagesLogo/CertificatePagesLogo";
import EnFormatDateDynamics from "../../../../../utils/DateDisplay/EnFormatDateDynamics";
const EnMrittoSonod = ({ userInfo }) => {
  const maxRetries = 3;
  const { id } = useParams();
  const componentRef = useRef();
  const [retryCount, setRetryCount] = useState(0);
  const { data, isLoading, isError, refetch } = useGetSingleCertificateQuery(id);
  const certificate = data?.certificate;
  useEffect(() => {
    RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
  }, [isError, retryCount, refetch]);
  return (
    <DashboardLayout>
      {
        isLoading ? <GetSpinner /> : <div>
          <div className="download_pdf d-flex justify-content-end">
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-md justify-content-center">
                  প্রিন্ট করুন
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div className={styles.body} ref={componentRef}>
            <div className={styles.container}>
              <div className={styles.content_position}>
                <div>
                  <CertificateHeaderEn certificate={certificate} sonodName={'Death Certificate'} />
                  <div>
                    <p className={styles.mainText}>
                      This is to certify that,{" "}
                      <b> {certificate?.death_information[0]?.death_person_en}</b>,
                      Father / Husband: <b>{certificate?.death_information[0]?.death_father_husband_en},{" "}Mother Name : {certificate?.death_information[0]?.date_mother_en}, Village:{" "}
                      {certificate?.citizen_id?.present_address[0]?.village_en_name}, Holding:{" "}
                      {certificate?.citizen_id?.present_address[0]?.holding_no}, Post Office:{" "}
                      {certificate?.citizen_id?.present_address[0]?.post_office_en}, Ward:{" "}
                      {certificate?.citizen_id?.present_address[0]?.ward_no},{" "}
                      {certificate?.citizen_id?.present_address[0]?.municipality_en_name} Thana/
                      Upazila: {certificate?.citizen_id?.permanent_address[0]?.upazila?.name},{" "}
                      District: {certificate?.citizen_id?.permanent_address[0]?.district?.name},{" "}
                      Division: {certificate?.citizen_id?.permanent_address[0]?.division?.name} .{" "}</b>
                      was a resident of that area. The applicant -{" "}
                      {certificate?.citizen_id?.name_english}, Village:{" "}
                      {certificate?.citizen_id?.present_address[0]?.village_en_name}, Holding:{" "}
                      {certificate?.citizen_id?.present_address[0]?.holding_no}, Post Office:{" "}
                      {certificate?.citizen_id?.present_address[0]?.post_office_en}, Ward:{" "}
                      {certificate?.citizen_id?.present_address[0]?.ward_no},{" "}
                      {certificate?.citizen_id?.present_address[0]?.municipality_en_name} Thana/
                      Upazila: {certificate?.citizen_id?.permanent_address[0]?.upazila?.name},{" "}
                      District: {certificate?.citizen_id?.permanent_address[0]?.district?.name},{" "}
                      Division: {certificate?.citizen_id?.permanent_address[0]?.division?.name} .
                      According to applicant information and my knowledge, the person
                      mentioned has been died on{" "}
                      {EnFormatDateDynamics(certificate?.death_information[0].date_of_birth)} and
                      death registered is number {certificate?.death_information[0]?.death_register_no}.
                    </p>
                    <br />

                    <div className={styles.mainText}>
                      <p className="fs-6">I wish the peace of the soul of late. </p>
                    </div>
                  </div>
                </div>
                <CertificateFooterEn certificate={certificate} accountType={userInfo?.account} marginHandel={false} />
              </div>
              <CertificatePagesLogo
                certificate={certificate}
                toValue={'200px'}
              />
            </div>
          </div>


        </div>
      }

    </DashboardLayout>
  );
};

function mapStateToProps(state) {
  return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(EnMrittoSonod)
