import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQueryHandel";
// users service
export const BirthregistrationApi = createApi({
    reducerPath: "Birthregistration",
    tagTypes: ["Birthregistration"],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        createbirthregistration: builder.mutation({
            query: (data) => ({
                url: `v1/birthregistration`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["birthregistration"],
        }),
        savebirthregistration: builder.mutation({
            query: (data) => ({
                url: `v1/birthregistration/save`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["birthregistration"],
        }),
        getbirthregistration: builder.query({
            query: (pathname) => ({
                url: `v1/birthregistration?${pathname}`,
                method: "GET",
            }),
            providesTags: ["birthregistration"],
        }),
    }),
});
export const { useCreatebirthregistrationMutation, useGetbirthregistrationQuery,useSavebirthregistrationMutation } = BirthregistrationApi;
