import ReactToPrint from "react-to-print";
import HoldingTaxdownloadMain from "../../components/elements/holdingTaxdownload/HoldingTaxdownloadMain";
import DashboardLayout from "../../components/layout/DashboardLayout";
import { useMemo, useRef, useState } from "react";
import { useDownloadHoldingTaxMutation } from "../../app/services/holdingTax";
import EnglishToBanglaNumber from "../../utils/EnglishToBanglaNumber";
function HoldingTaxdownload() {
    const [taxDoron, setTaxDoron] = useState('all')
    const [wardNo, setWardNo] = useState()
    const componentRef = useRef();

    /* rtk */
    const [getData, { isError, isLoading, isSuccess, data }] = useDownloadHoldingTaxMutation()
    const dataInfo = useMemo(() => data ? data : [], [isError, data, isLoading, isSuccess])

    const submitData = async () => {
        const data = {
            status: taxDoron,
            ward_no: wardNo,
        }
        await getData(data)
    }



    return (
        <DashboardLayout>
            <section className="content-main">
                <div className="content-header">
                    <div>
                        <h2 className="content-title card-title">
                            হোল্ডিং ট্যাক্স ডাউনলোড
                        </h2>
                    </div>
                    <ReactToPrint
                        trigger={() => (
                            <button className="btn btn-md justify-content-center">
                                প্রিন্ট করুন
                            </button>
                        )}
                        content={() => componentRef.current}
                    />
                </div>
                {/* search handel  */}
                <div className="card ">
                    <div className="card-body">
                        <div className="row g-3">
                            <div className="col-lg-6">
                                <label className="form-label">
                                    হোল্ডিং ট্যাক্স ধরন
                                </label>
                                <select className="form-select form-select-sm" onClick={(e) => setTaxDoron(e.target.value)}>
                                    <option selected value={'all'}>সকল তালিকা</option>
                                    <option selected value={true}>আদায়কৃত হোল্ডিং ট্যাক্সের তালিকা</option>
                                    <option value={false}>বকেয়া হোল্ডিং ট্যাক্সের তালিকা</option>
                                </select>
                            </div>
                            <div className="col-lg-6">
                                <label className="form-label">
                                    ওয়ার্ড নং
                                </label>
                                <select className="form-select form-select-sm" onChange={(e) => setWardNo(e.target.value)}>
                                    <option  value={''} selected>সকল ওয়ার্ড</option>
                                    <option  value={1}>১</option>
                                    <option  value={2}>২</option>
                                    <option  value={3}>৩</option>
                                    <option  value={4}>৪</option>
                                    <option  value={5}>৫</option>
                                    <option  value={6}>৬</option>
                                    <option  value={7}>৭</option>
                                    <option  value={8}>৮</option>
                                    <option  value={9}>৯</option>
                                </select>
                            </div>

                        </div>
                        <div className="d-flex justify-content-end gap-3">
                            <>
                                <input
                                    onClick={() => submitData()}
                                    type="submit"
                                    className="btn btn-md rounded font-sm hover-up h-25"
                                    value={isLoading ? "অপেক্ষা করুন" : "সাবমিট করুন"}
                                />
                            </>
                        </div>
                        {
                            dataInfo.length > 0 && <h5>{EnglishToBanglaNumber(`${dataInfo?.length}`)} জন ব্যক্তির তথ্য পাওয়া গিয়েছে দয়া করে প্রিন্ট বাটনে ক্লিক করে প্রিন্ট করুন</h5>
                        }
                    </div>
                </div>
            </section>
            <section ref={componentRef} className={`holdingTaxdownloadMain_styles`}>
                <HoldingTaxdownloadMain data={dataInfo} />
            </section>

        </DashboardLayout>
    );
}

export default HoldingTaxdownload;
