import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import styles from './BnNationlitySonod.module.css';
import DashboardLayout from '../../../../layout/DashboardLayout';
import { useParams } from 'react-router-dom';
import { useGetSingleCertificateQuery } from '../../../../../app/services/certificate';
import GetSpinner from '../../../../../helpers/shared/GetSpinner';
import { connect } from 'react-redux';
import EnglishToBanglaNumber from '../../../../../utils/EnglishToBanglaNumber';
import { CertificateFooterBn } from '../../CertificateFooter/CertificateFooter';
import { CertificateHeaderBn } from '../../CertificateHeader/CertificateHeader';
import { useState } from 'react';
import { useEffect } from 'react';
import { RefetchData } from '../../../RefetchData';
import CertificatePagesLogo from '../../CertificatePagesLogo/CertificatePagesLogo';


const BnNationlitySonod = ({ userInfo }) => {
  const maxRetries = 3;
  const { id } = useParams();
  const componentRef = useRef();
  const [retryCount, setRetryCount] = useState(0);
  const { data, isLoading, isError, refetch } = useGetSingleCertificateQuery(id);
  const accountType = userInfo?.account
  const certificate = data?.certificate;
  useEffect(() => {
    RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch)
  }, [isError, retryCount, refetch]);

  return (
    <DashboardLayout>
      {
        isLoading ? <GetSpinner /> : <>
          <div>
            <div className="download_pdf d-flex justify-content-end">
              <ReactToPrint
                trigger={() => (
                  <button className="btn btn-md justify-content-center">
                    প্রিন্ট করুন
                  </button>
                )}
                content={() => componentRef.current}
              />
            </div>

            <div className={styles.body} ref={componentRef}>
              <div className={styles.container}>
                <div className={styles.content_position}>
                  <div>
                    <CertificateHeaderBn certificate={certificate} sonodName={'জাতীয়তা সনদ'} />
                    <div>
                      <p className={styles.mainText}>
                        এই মর্মে প্রত্যয়ন করা যাইতেছে যে,{' '}
                        <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.name_bangla}</b>, জাতীয় পরিচয়পত্র/জন্মনিবন্ধন নম্বরঃ
                        <b style={{ fontWeight: 'bold' }}> {EnglishToBanglaNumber(`${certificate?.citizen_id?.nid}`)}</b>, পিতা/স্বামী {' '}
                        <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.father_husband_bangla}</b>, মাতাঃ {' '}
                        <b style={{ fontWeight: 'bold' }}>{certificate?.citizen_id?.mother_name_bangla}</b>, তাহার{' '}
                        <b style={{ fontWeight: 'bold' }}>স্থায়ী ঠিকানাঃ- {' '}</b>
                        গ্রাম/বাড়ীঃ- {' '}{certificate?.citizen_id?.permanent_address[0]?.village_bn_name}, রোড/ব্লক/সেক্টরঃ  {EnglishToBanglaNumber(`${certificate?.citizen_id?.permanent_address[0]?.road}`)}, ডাকঘরঃ{' '}
                        {certificate?.citizen_id?.permanent_address[0]?.post_office_bn}, হোল্ডিং নংঃ {EnglishToBanglaNumber(`${certificate?.citizen_id?.permanent_address[0]?.holding_no}`)}, ওয়ার্ড নম্বরঃ {EnglishToBanglaNumber(`${certificate?.citizen_id?.permanent_address[0]?.ward_no}`)},{' '}
                        {
                          accountType === 'municipality' ? `পৌরসভাঃ- ` : 'ইউনিয়নঃ-'
                        }
                        {' '}{certificate?.union_or_municipality?.municipality_union_bn_name}

                        , থানা/উপজেলাঃ {certificate?.citizen_id?.permanent_address[0]?.upazila?.bn_name}, জেলাঃ{' '}
                        {certificate?.citizen_id?.permanent_address[0]?.district?.bn_name}। {' '}

                        {/* Present Address */}
                        <b style={{ fontWeight: 'bold' }}>বর্তমান ঠিকানাঃ-</b> {' '}
                        গ্রাম/বাড়ীঃ-{' '}{certificate?.citizen_id?.present_address[0]?.village_bn_name}, রোড/ব্লক/সেক্টরঃ {EnglishToBanglaNumber(`${certificate?.citizen_id?.present_address[0]?.road}`)}, ডাকঘরঃ{' '}
                        {certificate?.citizen_id?.present_address[0]?.post_office_bn}, হোল্ডিং নংঃ {EnglishToBanglaNumber(`${certificate?.citizen_id?.present_address[0]?.holding_no}`)}, ওয়ার্ড নম্বরঃ {EnglishToBanglaNumber(`${certificate?.citizen_id?.present_address[0]?.ward_no}`)}{' '}
                        {
                          accountType === 'municipality' && `পৌরসভাঃ- ${certificate?.union_or_municipality?.municipality_union_bn_name} `
                        }
                        , থানা/উপজেলাঃ {certificate?.citizen_id?.present_address[0]?.upazila?.bn_name}, জেলাঃ{' '}
                        {certificate?.citizen_id?.present_address[0]?.district?.bn_name}। {' '}

                        তিনি অত্র এলাকার একজন স্থায়ী
                        বাসিন্দা এবং জন্মসূত্রে বাংলাদেশি নাগরিক।
                      </p>
                      <br />

                      <div >
                        <p className={styles.mainText}>আমি তাহার উজ্জ্বল ভবিষ্যৎ ও সর্বাঙ্গীণ মঙ্গল কামনা করছি। </p>
                      </div>
                    </div>
                  </div>
                  <CertificateFooterBn certificate={certificate} accountType={accountType} marginHandel={true} />
                </div>

                <CertificatePagesLogo
                  certificate={certificate}
                  toValue={'200px'}
                />
              </div>
            </div>
          </div>

        </>
      }
    </DashboardLayout>
  );
};


function mapStateToProps(state) {
  return { userInfo: state.auth?.userInfo?.users }
}
export default connect(mapStateToProps, null)(BnNationlitySonod)