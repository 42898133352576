import React from 'react';
import { handelClick } from '../../../utils/ConfirmDelete';
import { useUserDeleteMutation } from '../../../app/services/authUser';
import { Link } from 'react-router-dom';
function SingleEmployee({ user, setStatus }) {
  const [deleteContent, { isLoading }] = useUserDeleteMutation();

  return (
    <tr>
      <td>
        <Link to={`/user/${user?.role}/${user._id}`}>{user?.union_bn_name ? user?.union_bn_name : user?.municipality_bn_name}</Link>
      </td>
      <td>{user?.division?.bn_name}</td>
      <td>{user?.district?.bn_name}</td>
      <td>{user?.upazila?.bn_name}</td>
      <td
        className={`${user?.status === 'pending'
          ? 'badge-soft-danger'
          : user?.status === 'approved'
            ? 'badge-soft-success'
            : ''
          }`}
      >
        {user?.status}
      </td>
      <td className="text-end">
        <div className="text-end">
          <a
            onClick={() =>
              setStatus({
                type: true,
                data: user,
              })
            }
            className="btn btn-sm font-sm rounded btn-brand m-1"
          >
            {' '}
            <i className="material-icons md-edit"></i>সম্পাদনা{' '}
          </a>
          <a
            onClick={() => handelClick(user?._id, deleteContent)}
            style={{ cursor: isLoading ? 'no-drop' : 'pointer' }}
            className="btn btn-sm font-sm rounded btn-brand m-1"
          >
            {' '}
            <i className="material-icons md-delete_forever"></i> মুছে ফেলুন{' '}
          </a>
        </div>
      </td>
    </tr>
  );
}

export default SingleEmployee;
