import React from "react";
import { useReactToPrint } from "react-to-print";
import MunicipalityFamilySonod from "./MunicipalityFamilySonod";
import GetSpinner from "../../../helpers/shared/GetSpinner";
import MunicipalityWarishSonod from "./MunicipalityWarishSonod";

const MunicipalityWarishAndNationality = ({ certificate, isLoading }) => {
  let content;

  if (isLoading) {
    content = <GetSpinner/>;
  } else if (certificate) {
    const sonodChaker = ["family"];
    const sonodResult = sonodChaker.includes(certificate?.sonod_type);
    content = sonodResult ? (
      <MunicipalityFamilySonod certificate={certificate} />
    ) : (
      <MunicipalityWarishSonod  certificate={certificate}/>
    );
  }
  
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <div ref={componentRef}>{content}</div>
      <div
        onClick={handlePrint}
        className="container"
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <div
          className="col-lg-1 text-center"
          style={{
            backgroundColor: "#29A56C",
            cursor: "pointer",
            borderRadius: "3px",
            color: "white",
            padding: "10px",
            fontSize: "20px",
          }}
        >
          প্রিন্ট
        </div>
      </div>
    </div>
  );
};

export default MunicipalityWarishAndNationality;
