import { useContext, useEffect, useRef, useState } from "react";
import userProfile from "../../assets/imgs/people/avatar-2.png";
import { useSignOut } from "react-firebase-hooks/auth";
import { ThemeContext } from "../../utils/Context";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/Firebase.config";
import { useSelector } from "react-redux";
import { FaRegUserCircle } from "react-icons/fa";
import { roleNameBangla } from "../../utils/roleNameBangla";
import SubscriptionEndDate from "../../utils/SubscriptionEndDate";
import { useGetProfileUserQuery } from "../../app/services/authUser";
import Roles from "../../utils/RolesHandel";


const Header = ({ userData }) => {
  let content;
  /* react hook  */
  const navigate = useNavigate();
  const [profileOpen, setProfileOpen] = useState(false);
  /* profile rtk  */
  const { data } = useGetProfileUserQuery()

  /* redux handel */
  const { userInfo } = useSelector((state) => state.auth);
  const [signOut] = useSignOut(auth);


  /* filter ammount   */
  const ammount = userInfo?.users?.role == Roles.SECRETARY ? parseInt(data?.secretary_amount):parseInt(data?.entrepreneur_amount)





  /* signOut funtion */

  const signOutHandel = async () => {
    await signOut();
    navigate("/");
    window.location.reload();
  }

  
  if (userData && userInfo) {
    const userRole = userInfo?.users?.role;
    content = `${userRole == "secretary"
      ? userData?.secretaryBanglaName
      : userRole == "entrepreneur"
        ? userData?.entrepreneurBanglaName : userRole == "admin" ? "এডমিন" : ""
      }
    `;
  }

  /* toggleProfile */
  const toggleProfile = () => {
    setProfileOpen(!profileOpen);
  };

  /* useEffect */

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdownMenu = document.getElementById('dropdownAccountMenu');
      const profileImage = document.getElementById('profileImage');

      if (
        profileOpen &&
        !dropdownMenu.contains(event.target) &&
        !profileImage.contains(event.target)
      ) {
        setProfileOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [profileOpen]);



  return (
    <header className="main-header navbar">
      <div className="col-brand">

        <h1
          style={{
            color: "black",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          {content}

        </h1>
      </div>
      {/* 
       */}



      <div className="col-nav">
        <ul className="nav">
          {userInfo?.users?.role !== 'admin' && (
            <div>
              <p>{SubscriptionEndDate(userInfo?.users?.endDate,ammount)}</p>


            </div>




          )}
          <li className="dropdown nav-item">
            <p
              onClick={toggleProfile}
              className="dropdown-toggle"
              data-bs-toggle="dropdown"
              id="dropdownAccount"
              aria-expanded="false"
              aria-haspopup="false"
            >
              <img
                id="profileImage"
                className="img-xs rounded-circle"
                src={userProfile}
                alt="User"
              />
            </p>
            <div
              id="dropdownAccountMenu"
              className={`dropdown-menu dropdown-menu-end shadow ${profileOpen ? 'show' : ''
                }`}
              aria-labelledby="dropdownAccount"
            >
              {userInfo && (
                <p className="my-0 d-flex align-items-center">
                  <span className="capitalize mt-1 fs-5 mx-auto">
                    {roleNameBangla(userInfo?.users?.role)}
                  </span>
                </p>
              )}
              <div className="dropdown-divider "></div>
              <p
                onClick={() => navigate('/user/view-profile')}
                className="dropdown-item fs-6"
              >
                <FaRegUserCircle className="text-secondary me-2 fs-6" />
                প্রোফাইল দেখুন
              </p>
              <p
                className="dropdown-item text-danger"
                onClick={() => signOutHandel()}
              >
                <i className="material-icons md-exit_to_app fs-6 text-secondary"></i>
                <span className="fs-6"> লগ আউট</span>
              </p>
            </div>
          </li>
        </ul>
      </div>
      {/*   <marquee>২৮/০১/২০২৪ ইং তারিখে ইস্যুকৃত সনদ, ডাটা মাইগ্রেশনের কারনে  ত্রুটি দেখা দিয়েছে, উক্ত সনদ গুলো নতুন করে তৈরী করার জন্য বিশেষ ভাবে অনুরোধ করা গেল। </marquee> */}
    </header>
  );
};

export default Header;
