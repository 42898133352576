import React from "react";
import EnglishToBanglaNumber from "../../../utils/EnglishToBanglaNumber";
function JonmoNibondhonSingle({ jonmoNibondhon }) {
    let village;
    const str = jonmoNibondhon?.birthPlace;
    const commaIndex = str?.indexOf(',');
    if (commaIndex !== 1) {
        village = str?.slice(0, commaIndex);
    }
    return (
        <tr>
            <td>{EnglishToBanglaNumber(jonmoNibondhon?.registrationDate)}</td>
            <td>{jonmoNibondhon?.name}</td>
            <td>{jonmoNibondhon?.fatherName}</td>
            <td>{jonmoNibondhon?.motherName}</td>
            <td>{EnglishToBanglaNumber(`${jonmoNibondhon?.birthCirtificateNo}`)}</td>
            <td>{EnglishToBanglaNumber(`${jonmoNibondhon?.birthDate}`)}</td>
            <td>{village}</td>
            <td>{EnglishToBanglaNumber(`${jonmoNibondhon?.book_no}`)}</td>
            <td>{EnglishToBanglaNumber(`${jonmoNibondhon?.leaf}`)}</td>
        </tr>
    );
}

export default JonmoNibondhonSingle;
