import { useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';
import { useGetProfileUserQuery } from '../../app/services/authUser';
import GetSpinner from '../../helpers/shared/GetSpinner';

const DashboardLayout = ({ children }) => {
  const { data } = useGetProfileUserQuery()
  const [sideOpen, setSideOpen] = useState(false);
  const [sideOpenMobile, setSideOpenMobille] = useState(false);


  return (
    <>
      {
        <div>
          <div className="screen-overlay"></div>
          <Sidebar
            sideOpen={sideOpen}
            setSideOpen={setSideOpen}
            sideOpenMobile={sideOpenMobile}
            setSideOpenMobille={setSideOpenMobille}
            userData={data}
          />
          <div className={`${sideOpen ? 'aside-mini' : ''}`}>
            <main className="main-wrap">
              <Header
                userData={data}
                sideOpen={sideOpenMobile}
                setSideOpen={setSideOpenMobille}
              />
              <section className="content-main">{children}</section>
           {/*    <Footer /> */}
            </main>
          </div>
        </div>
      }

    </>

  );
};
export default DashboardLayout;
