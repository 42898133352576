import { FaFacebook, FaPhone, FaTicketAlt } from "react-icons/fa";
import Header from "../../components/Home/Header";
import { HiOutlineMail } from "react-icons/hi";

const Communication = () => {
  return (
    <div>
      <Header></Header>

      <div style={{ textAlign: "center", marginTop: "20pt" }}>
        <h1>সার্বিক সহযোগিতার জন্য যোগাযোগ করুন </h1>
        <p style={{ fontSize: "15pt" }}>
          <FaPhone /> মোবাইল:{" "}
          <span style={{ color: "" }}>+880 1837-266474</span>{" "}
        </p>
        <p style={{ fontSize: "15pt" }}>
          <HiOutlineMail /> ই-মেইল :
          <span style={{ color: "" }}>smartunion.support@gmail.com</span>{" "}
        </p>

        <p style={{ fontSize: "15pt" }}>
          <FaFacebook /> হেল্পলাইন :{" "}
          <a
            href="https://www.facebook.com/groups/722535906491506"
            style={{ color: "black" }}
          >
            ষ্মার্ট ইউনিয়ন /পৌরসভা ম্যানেজমেন্ট সিস্টেম।
          </a>{" "}
        </p>
        <p style={{ fontSize: "15pt" }}>
          <FaTicketAlt /> ই-টিকেট : <span>খুব শীঘ্রই আপডেট করা হবে।</span>{" "}
        </p>
      </div>
    </div>
  );
};

export default Communication;
