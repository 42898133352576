import { IoHomeOutline } from "react-icons/io5";
import { PiCurrencyCircleDollarBold, PiFolderUserThin, PiUsersFourThin } from "react-icons/pi";
import { MdOutlineMedicalServices, MdOutlineSettingsApplications, MdPersonSearch, } from "react-icons/md";
import { AiOutlineUserAdd } from "react-icons/ai";
import { FaSearchDollar } from "react-icons/fa";
import { TbDeviceIpadDollar } from "react-icons/tb";
import { MdOutlineEditLocation } from "react-icons/md";

export const SideMenu = [
  {
    id: 1,
    to: "/dashboard",
    link: "ড্যাশবোর্ড",
    icon: <IoHomeOutline />,
    role: ["admin", "secretary", "entrepreneur", "union", "municipality"],
  },
  {
    id: 2,
    link: "আবেদন",
    icon: <MdOutlineSettingsApplications />,
    role: ["admin"],
    children: [
      {
        id: 2,
        _id: 1,
        to: "/user-application/union-secretary",
        link: "ইউনিয়ন/পৌরসভা",
        role: ["admin"],
      },
    ],
  },
  {
    id: 3,
    link: "সাবস্ক্রিপশন কিনুন",
    icon: <PiCurrencyCircleDollarBold />,
    role: ["admin", "secretary", "entrepreneur", "union", "municipality"],
    children: [
      {
        id: 3,
        _id: 1,
        to: "/donation/subscription",
        link: "টাকা  জমা করুন ",
        role: ["secretary", "entrepreneur", "union", "municipality"],
      },
      {
        id: 3,
        _id: 2,
        to: "/donation",
        link: "আবেদন",
        role: ["admin", "secretary", "entrepreneur", "union", "municipality"],
      },
      {
        id: 3,
        _id: 3,
        to: "/donation/report",
        link: "রিপোর্ট",
        role: ["admin"],
      },
    ],
  },
  {
    id: 7,
    link: "নাগরিক প্রোফাইল তৈরি করুন",
    icon: <AiOutlineUserAdd />,
    role: ["admin", "secretary", "entrepreneur", "union", "municipality"],
    children: [
      {
        id: 7,
        _id: 1,
        to: "/user/union-secretary",
        link: "ইউনিয়ন/পৌরসভা",
        role: ["admin"],
      },
      {
        id: 7,
        _id: 2,
        to: "/user/citizen",
        link: "নাগরিক অফলাইন",
        role: ["admin", "entrepreneur", "secretary", "union", "municipality"],
      },
      {
        id: 7,
        _id: 3,
        to: "/user/onlineCitizen",
        link: "নাগরিক EKYC",
        role: ["admin", "entrepreneur", "secretary", "union", "municipality"],
      },
    ],
  },
  {
    id: 4,
    link: "সকল সনদ",
    icon: <MdOutlineMedicalServices />,
    role: ["secretary", "entrepreneur", "union"],
    children: [
      {
        id: 4,
        _id: 1,
        to: "/application-certificate/trade-license",
        link: "ট্রেড লাইসেন্স ",
        role: ["secretary", "entrepreneur", "union"],
      },
      {
        id: 4,
        _id: 2,
        to: "/application-certificate/family",
        link: "পারিবারিক সনদ",
        role: ["secretary", "entrepreneur", "union"],
      },
      {
        id: 4,
        _id: 3,
        to: "/application-certificate/warish",
        link: "ওয়ারিশ সনদ",
        role: ["secretary", "entrepreneur", "union"],
      },
      {
        id: 4,
        _id: 4,
        to: "/application-certificate/death",
        link: "মৃত্যু সনদ",
        role: ["secretary", "entrepreneur", "union"],
      },
      {
        id: 4,
        _id: 5,
        to: "/application-certificate/nationality",
        link: "জাতীয়তা সনদ",
        role: ["secretary", "entrepreneur", "union"],
      },
      {
        id: 4,
        _id: 6,
        to: "/application-certificate/citizenship",
        link: "নাগরিকত্ব সনদ",
        role: ["secretary", "entrepreneur", "union"],
      },
      {
        id: 4,
        _id: 7,
        to: "/application-certificate/new-voter-attestation",
        link: "নতুন ভোটার প্রত্যয়ন",
        role: ["secretary", "entrepreneur", "union"],
      },
      {
        id: 4,
        _id: 8,
        to: "/application-certificate/remarriage",
        link: "পুনঃবিবাহ-সনদ",
        role: ["secretary", "entrepreneur", "union"],
      },
      {
        id: 4,
        _id: 9,
        to: "/application-certificate/agricultural",
        link: "কৃষি প্রত্যয়ন",
        role: ["secretary", "entrepreneur", "union"],
      },
      {
        id: 4,
        _id: 10,
        to: "/application-certificate/annual-income",
        link: "বার্ষিক আয়ের সনদ",
        role: ["secretary", "entrepreneur", "union"],
      },
      {
        id: 4,
        _id: 11,
        to: "/application-certificate/monthly-income",
        link: "মাসিক আয়ের সনদ",
        role: ["secretary", "entrepreneur", "union"],
      },
      {
        id: 4,
        _id: 12,
        to: "/application-certificate/characteristic",
        link: "চারিত্রিক সনদ",
        role: ["secretary", "entrepreneur", "union"],
      },
      {
        id: 4,
        _id: 13,
        to: "/application-certificate/transfer-of-constituency",
        link: "ভোটার এলাকা স্থানান্তর প্রত্যয়ন",
        role: ["secretary", "entrepreneur", "union"],
      },
      /*  {
         id: 4,
         _id: 14,
         to: "/application-certificate/amendment-of-national-identity-information",
         link: "জাতীয় পরিচয় তথ্য সংশোধন সনদ",
         role: ["secretary", "entrepreneur"],
       }, */
      {
        id: 4,
        _id: 15,
        to: "/application-certificate/attestation-of-the-same-person",
        link: "একই ব্যক্তির প্রত্যয়ন",
        role: ["secretary", "entrepreneur", "union"],
      },
      {
        id: 4,
        _id: 16,
        to: "/application-certificate/unmarried",
        link: "অবিবাহিত সনদ",
        role: ["secretary", "entrepreneur", "union"],
      },
      {
        id: 4,
        _id: 17,
        to: "/application-certificate/other",
        link: "অন্যান্য সনদ",
        role: ["secretary", "entrepreneur", "union"],
      },
      /* municipality section */
      {
        id: 4,
        _id: 18,
        to: "/application-certificate/municipality/trade-license",
        link: "ট্রেড লাইসেন্স ",
        role: ["secretary", "entrepreneur", "municipality"],
      },
      

    ],
  },

  {
    id: 8,
    link: "হোল্ডিং ট্যাক্স প্রতিবেদন",
    icon: <FaSearchDollar />,
    role: ["entrepreneur", "secretary", "union", "municipality"],
    children: [
      {
        id: 8,
        _id: 1,
        to: "/holding-tax/all",
        link: "নতুন হোল্ডিং নিবন্ধন",
        role: ["entrepreneur", "secretary", "union", "municipality"],
      },
      {
        id: 8,
        _id: 2,
        to: "/holding-tax/accept",
        link: "হোল্ডিং ট্যাক্স আদায় করুন",
        role: ["entrepreneur", "secretary", "union", "municipality"],
      },
      {
        id: 8,
        _id: 4,
        to: "/holding-tax/download",
        link: "প্রতিবেদন দেখুন",
        role: ["entrepreneur", "secretary", "union", "municipality"],
      },
      /* {
        id: 8,
        _id: 5,
        to: "/user/citizen",
        link: "হোল্ডিং স্ট্যাটাস",
        role: ["secretary"],
      }, */
    ],
  },
  {
    id: 9,
    link: "বিভিন্ন তালিকা",
    icon: <PiFolderUserThin />,
    role: ["entrepreneur", "secretary", "union", "municipality"],
    children: [
      /* {
        id: 9,
        _id: 1,
        to: "/voter/all",
        link: "ভোটার তথ্য",
        role: ["entrepreneur", "secretary","union", "municipality"],
      }, */
      {
        id: 9,
        _id: 2,
        to: "/nibondhon/all",
        link: "জন্ম নিবন্ধন",
        role: ["entrepreneur", "secretary", "union", "municipality"],
      },

    ],
  },
  {
    id: 11,
    link: "এন আইডির তথ্য যাচাই",
    icon: <MdPersonSearch />,
    role: ["entrepreneur", "secretary", "union", "municipality"],
    children: [
      {
        id: 11,
        _id: 1,
        to: "/verify-info",
        link: "তথ্য যাচাই করুন",
        role: ["entrepreneur", "secretary", "union", "municipality"],
      },

    ],
  },

  {
    id: 12,
    link: "টাকা রিচার্জ করুন",
    icon: <TbDeviceIpadDollar />,
    role: ["entrepreneur", "secretary", "union", "municipality"],
    children: [
      {
        id: 12,
        _id: 1,
        to: "/money/recharge",
        link: "টাকা রিচার্জ",
        role: ["entrepreneur", "secretary", "union", "municipality"],
      },

    ],
  },
  {
    id: 13,
    link: "অন্যান্য তথ্য",
    icon: <TbDeviceIpadDollar />,
    role: ["admin"],
    children: [
      {
        id: 13,
        _id: 1,
        to: "/otherinformation/email-update",
        link: "ইমেইল আপডেট করুন",
        role: ["admin"],
      },

    ],
  },
  {
    id: 14,
    link: "অবস্থান যোগ করুন",
    icon: <MdOutlineEditLocation />,
    role: ["admin"],
    children: [
      {
        id: 14,
        _id: 1,
        to: "/location/union-add",
        link: "ইউনিয়ন তথ্য করুন",
        role: ["admin"],
      },

    ],
  },


];
