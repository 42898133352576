export default function EnFormatDateDynamics(utcDateString) {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'Asia/Dhaka',
    };
  
    const utcDate = new Date(utcDateString);
    return new Intl.DateTimeFormat("en-US", options).format(utcDate);
  }
  