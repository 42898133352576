import { useForm } from "react-hook-form";
import { IoCloseSharp } from "react-icons/io5";
import { useBkashCreateMutation, useEkpayCreateMutation } from "../../app/services/recharge";
import { useState } from "react";
import { toast } from "react-toastify";

const RechargeModal = ({ contects, setOpen, open }) => {
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [ekpayCreate, { isError, isLoading, isSuccess }] = useEkpayCreateMutation()
    const [BkashCreate, { isError: bkashIsError, isLoading: BkashIsLoading, isSuccess: BkashIsSuccess }] = useBkashCreateMutation()
    const [selectedOption, setSelectedOption] = useState('');

    const handleCheckboxChange = (event) => {
        setSelectedOption(event.target.value);
    };


    const createOnSubmit = async (value) => {
        if (selectedOption) {
            if (selectedOption === 'ekpay') {
                const result = await ekpayCreate(value)
                if (result?.data) {
                    const url = result.data.url;
                    window.location.href = url;
                }
            }
            if (selectedOption === 'bkash') {
                const result = await BkashCreate(value)
                if (result?.data) {
                    const url = result.data.url;
                    window.location.href = url;
                }
            }

        } else {
            toast.error('পেমেন্ট এর ধরণ সিলেক্ট করুন')
        }
    }

    return (
        <>
            {
                open && <div className="modal_product">
                    <section style={{ background: 'white', padding: '20px', borderRadius: "10px" }}>
                        <div className="row">
                            <div className="col-12">
                                <div className="content-header">
                                    <h2 className="content-title">{contects?.title}</h2>
                                    <span
                                        onClick={() =>
                                            setOpen(false)
                                        }
                                    >
                                        <IoCloseSharp />
                                    </span>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit(createOnSubmit)}>
                                <div>
                                    <label className="form-label">
                                        টাকা পরিমাণ <span className="from_required">*</span>{" "}
                                    </label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder="সর্বনিম্ন আপনাকে ১০০ রিচার্জ টাকা করতে হবে"

                                        {...register("amount", {
                                            required: "খালি রাখা যাবে না",
                                            min: {
                                                value: 100,
                                                message: "সর্বনিম্ন ১০০ টাকা অনুমোদন করা হয়"
                                            }
                                        })}

                                    />
                                    {errors?.amount && (
                                        <span className="form__error">
                                            {errors?.amount.message}
                                        </span>
                                    )}
                                </div>
                                <div className="d-flex justify-content-start py-2 gap-4">
                                    <div className="d-flex justify-content-start align-items-center gap-1">
                                        <input
                                            type="checkbox"
                                            id="vehicle1"
                                            name="paymentOption"
                                            value="bkash"
                                            checked={selectedOption === 'bkash'}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label htmlFor="vehicle1">Bkash</label>
                                    </div>
                                    <div className="d-flex justify-content-start align-items-center gap-1">
                                        <input
                                            type="checkbox"
                                            id="vehicle2"
                                            name="paymentOption"
                                            value="ekpay"
                                            checked={selectedOption === 'ekpay'}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label htmlFor="vehicle2">Ekpay</label>
                                    </div>
                                </div>


                                <div className="d-flex justify-content-end align-items-center mt-10">
                                    <button type="submit" className=" btn btn-primary btn-sm rounded" > {isLoading || BkashIsLoading ? "অপেক্ষা করুন" : "সাবমিট  করুন"} </button>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            }







        </>
    )

}
export default RechargeModal;