import React from "react";
import { useReactToPrint } from "react-to-print";
import styles from "./NidVerification.module.css";
import poricy from "../../assets/verifyNid/poricy.png";
import logo from "../../assets/verifyNid/logo.png";
import a2i from "../../assets/verifyNid/a2i.png";
import election from "../../assets/verifyNid/election.png";
import QRCode from "react-qr-code";

const NidVerification = ({ data }) => {
  const {
    photoUrl,
    oldNationalIdNumber,
    nationalIdNumber,
    fullNameBN,
    fullNameEN,
    dateOfBirth,
    fathersNameBN,
    fathersNameEN,
    mothersNameBN,
    mothersNameEN,
    spouseNameEN,
    profession,
    gender,
    presentAddressBN,
    permanentAddressBN,
  } = data?.response?.nid;
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const QRCodeData = `
    Name Bangla : ${fullNameBN},
    NID No : ${nationalIdNumber},
    Date Of Birth : ${dateOfBirth},
  `;

  return (
    <section>
      <div onClick={handlePrint} className="">
        <div
          className="col-lg-1 text-center"
          style={{
            backgroundColor: "#29A56C",
            cursor: "pointer",
            borderRadius: "3px",
            color: "white",
            padding: "10px",
            fontSize: "20px",
          }}
        >
          প্রিন্ট
        </div>
      </div>
      <div className={styles.body} ref={componentRef}>
        <div className={styles.container}>
          <div>
            {/* header section handel  */}
            <div className={styles.header_section}>
              <img className={styles.header_election} src={election} />
              <h1 className={styles.header_title}>
                National ID Verification Copy
              </h1>
              <p className={styles.header_sub}>
                <b>Photo of Voter</b>
              </p>
              <div className={styles.header_img_qrCode}>
                <QRCode
                  style={{
                    width: 100,
                    height: 100,
                  }}
                  value={QRCodeData}
                  viewBox={`0 0 256 256`}
                />
                <img src={photoUrl} />
              </div>
            </div>
            {/* body */}
            <div className={styles.bodyTable}>
              <table>
                <tr>
                  <td>NID No</td>
                  <td>{oldNationalIdNumber}</td>
                </tr>
                <tr>
                  <td>Pin</td>
                  <td>{nationalIdNumber}</td>
                </tr>
                <tr>
                  <td>Name Bangla</td>
                  <td>{fullNameBN}</td>
                </tr>
                <tr>
                  <td>Name (English)</td>
                  <td>{fullNameEN}</td>
                </tr>
                <tr>
                  <td>Date Of Birth</td>
                  <td>{dateOfBirth}</td>
                </tr>
                <tr>
                  <td>Father Name</td>
                  <td>{fathersNameBN}</td>
                </tr>
                <tr>
                  <td>Father's Name (English)</td>
                  <td>{fathersNameEN}</td>
                </tr>
                <tr>
                  <td>Mother Name</td>
                  <td>{mothersNameBN}</td>
                </tr>
                <tr>
                  <td>Mother's Name (English)</td>
                  <td>{mothersNameEN}</td>
                </tr>
                <tr>
                  <td>Spouse Name</td>
                  <td>{spouseNameEN}</td>
                </tr>
                <tr>
                  <td>Occupation</td>
                  <td>{profession}</td>
                </tr>
                <tr>
                  <td>লিঙ্গ</td>
                  <td>{gender}</td>
                </tr>
                <tr>
                  <td>বর্তমান ঠিকানা </td>
                  <td>{presentAddressBN}</td>
                </tr>
                <tr>
                  <td>স্থায়ী ঠিকানা</td>
                  <td>{permanentAddressBN}</td>
                </tr>
              </table>
            </div>
            <p className={styles.newTest}>
              উপরের প্রদর্শিত তথ্যগুলি জাতীয় পরিচয়পত্র সংশ্লিষ্ট, ভোটার তলিকার
              সাথে সরাসরি সম্পর্ক নেই{" "}
            </p>
            <p className={styles.signatureText}>
              This is Software Generated Report From Bangladesh Election
              Commission, Signature & Seal Aren't Requried
            </p>
          </div>
          <div className={styles.footer_image}>
            <img src={logo} />
            <img src={poricy} />
            <img src={a2i} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NidVerification;
