import React from 'react';
import Header from './Header';
import Footer from './Footer';

export default function HomeLayout({ children }) {
    return (
        <div>
            <Header />
            {/* <marquee
            style={
                {
                    color:'red',
                    fontSize: '30px'
                }
            }
            >সার্ভার রক্ষণাবেক্ষণের কাজ চলছে, সাময়িক অসুবিধা জন্য আমরা আন্তরিক ভাবে দুঃক্ষিত, খুব শিঘ্রই সার্ভার সচল হবে।
            </marquee> */}
            {children}
            <Footer />
        </div>
    );
}
