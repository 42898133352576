import { useEffect, useMemo, useState } from "react";
import DashboardLayout from "../../components/layout/DashboardLayout";
import { useGetRoleAllSonodQuery, usePostCertificateMutation } from "../../app/services/certificate";
import CertificatePrintModal from "../../components/elements/applicationCertificate/modal/CertificatePrintModal";
import ApproveSonod from "../../components/elements/applicationCertificate/modal/ApproveSonod";
import Search from "../../utils/Search";
import TradeLicenseSingleSonod from "../../components/elements/applicationCertificate/singleSonod/TradeLicenseSingleSonod";
import Pagination from "../../components/elements/Pagination";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RefetchData } from "../../components/elements/RefetchData";
import GetSpinner from "../../helpers/shared/GetSpinner";
import OtherSonodCreateModal from "../../components/other/OtherSonodCreateModal";
import OtherSonodTable from "../../components/other/OtherSonodTable";

const OtherSonodPage = () => {
  const maxRetries = 3;
  const [retryCount, setRetryCount] = useState(0);
  const contects = {
    title: "অন্যান্য সনদ",
  };
  const { userInfo } = useSelector((state) => state.auth);
  const [Loading, setLoading] = useState(false);
  const [search, setSearchValue] = useState("");
  const [printModalOpen, setPrintModalOpen] = useState({
    type: false,
    data: null,
  });
  const [status, setStatus] = useState({ type: false, data: null });

  const [sonodCreateModal, setSonodCreateModal] = useState({
    type: false,
    data: null,
  });

  const [sonodEditModal, setSonodEditModal] = useState({
    type: false,
    data: null,
  });
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const pathname = `role=other&page=${pageIndex}&limit=${pageSize}&search=${search}`;

  const { data, isLoading, isError, refetch } = useGetRoleAllSonodQuery(pathname);

  const [
    addCertificate,
    { isLoading: addisLoading, isSuccess: addisSuccess, reset },
  ] = usePostCertificateMutation();

  const certificate = useMemo(
    () => (data ? data?.certificate : []),
    [data, search]
  );
  const CreateonSubmit = async (data) => {
    await addCertificate(data);
  };

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    if (addisSuccess) {
      toast.success("Certificate Add");
      reset();
    }
    RefetchData(isError, retryCount, maxRetries, setRetryCount, refetch);
  }, [isLoading, addisSuccess, reset, isError, retryCount, refetch]);






  return (
    <DashboardLayout>
      <section className="content-main">
        <div className="content-header ">
          <div>
            <h2 className="content-title card-title">{contects?.title}</h2>
          </div>


          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="">

            <p style={{ marginRight: '20px ', }}
              onClick={() =>
                setSonodCreateModal({ type: !sonodCreateModal.type })
              }
              className="btn btn-primary btn-sm rounded"
            >
              নতুন তৈরি করুন
            </p>




          </div>

        </div>
        {Loading ? (
          <GetSpinner />
        ) : (
          <>
            <div className="card mb-4">
              <header className="card-header">
                <div className="row align-items-center">
                  <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                    <Search setSearchValue={setSearchValue} placeholder={"সনদ নাম্বার"} />
                  </div>
                </div>
              </header>
              <div className="col-md-12 px-4 ">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr className="">
                        <th>নাম</th>
                        <th>জাতীয় পরিচয়পত্র</th>
                        <th>মোবাইল নম্বর</th>
                        <th> শিরোনাম	</th>
                        <th>অবস্থা</th>
                        <th className="text-end">পদক্ষেপ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {certificate?.map((data) => (
                        <OtherSonodTable
                          setSonodCreateModal={setSonodCreateModal}
                          setSonodEditModal={setSonodEditModal}
                          data={data}
                          key={data._id}
                          setStatus={setStatus}
                          setPrintModalOpen={setPrintModalOpen}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Pagination
              totalPages={data?.totalPages}
              setPagination={setPagination}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
          </>
        )}
      </section>


      {userInfo?.users?.role === "secretary" ? (
        <ApproveSonod modal={status} setOpen={setStatus} />
      ) : (
        ""
      )}
      <CertificatePrintModal
        title={contects?.title}
        modal={printModalOpen}
        setOpen={setPrintModalOpen}
      />
      <OtherSonodCreateModal
        contects={contects}
        CreateonSubmit={CreateonSubmit}
        modal={sonodCreateModal}
        setOpen={setSonodCreateModal}
        addisLoading={addisLoading}
      />
    </DashboardLayout>)
};
export default OtherSonodPage;
