import { useParams } from "react-router-dom";
import { useGetSingleCertificateQuery } from "../../app/services/certificate";
import DashboardLayout from "../../components/layout/DashboardLayout";
import OtherSonodEdit from "../../components/other/OtherSonodEdit";

const OtherSonodEditPage = () => {
  let content;
  const { id } = useParams();
  const {
    data: certificate,
    isLoading,
    isError,
    refetch,
  } = useGetSingleCertificateQuery(id);

  if (!certificate || isLoading || isError) {
    content = <di>অপেক্ষা করুন....</di>;
  } else {
    content = <OtherSonodEdit certificate={certificate?.certificate} />;
  }

  return <DashboardLayout>{content}</DashboardLayout>;
};
export default OtherSonodEditPage;
