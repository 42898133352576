import React from 'react'

function Error({ message }) {
    return (
        <div style={{ width: "100%", height: "40vh", display: 'flex', justifyContent: "center", alignItems: "center" }}>
            <div className="text-warning" role="status">
                <p>{message}</p>
            </div>
        </div>
    )
}

export default Error