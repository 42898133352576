import React, { useEffect, useMemo, useState } from 'react';
import DashboardLayout from '../../../components/layout/DashboardLayout';
import { useGetProfileUserQuery, usePorichoyCitizanPostMutation } from '../../../app/services/authUser';
import { useCreteCitizenMutation, useGetAllCitizenQuery } from '../../../app/services/citizen';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useGetDistrictsQuery, useGetDivisionsQuery, useGetUnionsQuery, useGetUpazilasQuery } from '../../../app/services/location';
import { fetchGender } from '../../../utils/Fetch';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';


function OnlineCitizen() {
  const navigate = useNavigate('')
  const { userInfo } = useSelector((state) => state.auth);
  const accountType = userInfo?.users?.account;
  const [parmanentAddress, setParmanentAddress] = useState({
    prPost_office_bn: '',
    prPost_office_en: '',
    prWard_no: '',
    prVillage_bn_name: '',
    prVillage_en_name: '',
    prRoad: '',
    prHolding_no: '',
  });
  const [findPorichoy, { isError, error, isLoading, isSuccess, data }] = usePorichoyCitizanPostMutation()
  const [createCitizen, { isSuccess: CitizenisSuccess, error: Citizenerror }] = useCreteCitizenMutation();

  const { data: profileInfo } = useGetProfileUserQuery()
  const { data: divisions } = useGetDivisionsQuery()
  const { data: districts } = useGetDistrictsQuery()
  const { data: upazilas } = useGetUpazilasQuery()
  const { data: unions } = useGetUnionsQuery()
  /* Address */
  const [currentAddressChecked, setCurrentAddressChecked] = useState(false)
  /* hook  */
  const [selectStoreDistricts, setSelectStoreDistricts] = useState([])
  const [selectStoreUpazilas, setSelectStoreUpazilas] = useState([])
  const [selectStoreUnions, setSelectStoreUnions] = useState([])
  const [selectPermanentStoreDistricts, setSelectPermanentStoreDistricts] = useState([])
  const [selectPermanentStoreUpazilas, setSelectPermanentStoreUpazilas] = useState([])
  const [selectPermanentStoreUnions, setSelectPermanentStoreUnions] = useState([])

  const selectDistrict = async (e) => {
    e.preventDefault()
    const id = e.target.value;
    const result = await districts?.district?.filter(district => id === district?.division_id)
    setSelectStoreDistricts(result)
  }
  const selectUpdzilas = async (e) => {
    e.preventDefault()
    const id = e.target.value;
    const result = await upazilas?.upazila?.filter(upazila => id === upazila?.district_id)
    setSelectStoreUpazilas(result)
  }
  const selectUnion = async (e) => {
    e.preventDefault()
    const id = e.target.value;
    const result = await unions?.union?.filter(union => id === union?.upazilla_id)
    setSelectStoreUnions(result)
  }
  /* permanent address  */
  const selectPermanentDistrict = async (e) => {
    e.preventDefault()
    const id = e.target.value;
    const result = await districts?.district?.filter(district => id === district?.division_id)
    setSelectPermanentStoreDistricts(result)
  }
  const selectPermanentUpdzilas = async (e) => {
    e.preventDefault()
    const id = e.target.value;
    const result = await upazilas?.upazila?.filter(upazila => id === upazila?.district_id)
    setSelectPermanentStoreUpazilas(result)
  }
  const selectPermanentUnion = async (e) => {
    e.preventDefault()
    const id = e.target.value;
    const result = await unions?.union?.filter(union => id === union?.upazilla_id)
    setSelectPermanentStoreUnions(result)
  }

  /* find citizan user  */


  const { register, handleSubmit, reset, formState: { errors }, } = useForm();

  const Seacrh = async (searchValue) => {
    try {
      await findPorichoy(searchValue)
    } catch (error) {
      toast.error("Error:", error.message);
    }

  }







  const foundOnlineData = useMemo(() => data?.data ? data?.data : false, [isLoading, isSuccess, data]);

  const createOnSubmit = async (data) => {

    if (currentAddressChecked) {
      const division_permanent = await divisions?.division?.find(value => value?.id === data?.permanent_division)
      const district_permanent = await districts?.district?.find(value => value?.id === data?.permanent_district)
      const upazila_permanent = await upazilas?.upazila?.find(value => value?.id === data?.permanent_upazila)
      const union_permanent = await unions?.union?.find(value => value?.id === data?.permanent_union);
      const citizenInfo = {
        ...data,
        presentStatus: true,
        division_permanent: division_permanent?._id,
        district_permanent: district_permanent?._id,
        upazila_permanent: upazila_permanent?._id,
        union_permanent: union_permanent?._id
      }
      await createCitizen(citizenInfo);
    } else {
      /* present */
      const division = await divisions?.division?.find(value => value?.id === data?.present_division)
      const district = await districts?.district?.find(value => value?.id === data?.present_district)
      const upazila = await upazilas?.upazila?.find(value => value?.id === data?.present_upazila)
      const union = await unions?.union?.find(value => value?.id === data?.present_union);
      /* permanent */
      const division_permanent = await divisions?.division?.find(value => value?.id === data?.permanent_division)
      const district_permanent = await districts?.district?.find(value => value?.id === data?.permanent_district)
      const upazila_permanent = await upazilas?.upazila?.find(value => value?.id === data?.permanent_upazila)
      const union_permanent = await unions?.union?.find(value => value?.id === data?.permanent_union);
      const citizenInfo = {
        ...data,
        presentStatus: false,
        /* permanent */
        division_permanent: division_permanent?._id,
        district_permanent: district_permanent?._id,
        upazila_permanent: upazila_permanent?._id,
        union_permanent: union_permanent?._id,
        /* present */
        present_division: division?._id,
        present_district: district?._id,
        present_upazila: upazila?._id,
        present_union: union?._id
      }
      await createCitizen(citizenInfo);
    }
  }


  useEffect(() => {
    if (CitizenisSuccess) {
      toast.success('সঠিকভাবে নাগরিক এড করা হয়েছ');
      navigate('/user/citizen')
      reset();
    }
    if (Citizenerror) {
      toast.error(Citizenerror.data.error);
    }
  }, [CitizenisSuccess, Citizenerror, reset]);









  return (
    <DashboardLayout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">অনলাইন নাগরিক</h2>
          </div>
        </div>
        <div className="card mb-4">
          <header className="card-header">
            <div className="row align-items-center">
              <form onSubmit={handleSubmit(Seacrh)} style={{ display: 'flex' }}>
                <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                  <div>
                    <p>এনআইডি</p>
                  </div>
                  <input
                    id='nidNumber'
                    type="number"
                    placeholder="অনুসন্ধান এনআইডি"
                    className="form-control"
                    {...register('nidNumber', {
                      required: 'খালি রাখা যাবে না',
                      pattern: {
                        value: /^[0-9]{10}$|^[0-9]{17}$/,
                        message: 'জাতীয় পরিচয়পত্র নম্বরটি ১০ বা ১৭ টি সংখ্যা হতে হবে',
                      },
                    })}
                  />
                  {errors?.nidNumber && (
                    <span className="form__error">
                      {errors?.nidNumber.message}
                    </span>
                  )}
                </div>
                <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                  <div>
                    <p>জন্ম তারিখ</p>
                  </div>
                  <input
                    id='dateOfBirth'
                    type="date"
                    className="form-control"
                    {...register('dateOfBirth', {
                      required: 'খালি রাখা যাবে না',

                    })}
                  />
                  {errors?.dateOfBirth && (
                    <span className="form__error">
                      {errors?.dateOfBirth.message}
                    </span>
                  )}
                </div>
                {
                  <div className="col-md-3 col-12 ms-auto mb-md-0 mb-3  text-end">
                    <input
                      type='submit'
                      value={`${isLoading ? 'অপেক্ষা করুন' : 'সাবমিট করুন'}`}
                      style={{ marginTop: '25px' }}
                      className="btn btn-primary btn-sm rounded"
                    />
                  </div>

                }
              </form>
            </div>
          </header>
          {
            isError && <h6 className='p-2'>{error?.data?.error}</h6>
          }
          {
            foundOnlineData && <>
              <form onSubmit={handleSubmit(createOnSubmit)}
              >
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="card ">
                        <div className="card-body"
                        >
                          <div className="row gx-3">

                            <div className="col-lg-6">
                              <label className="form-label">
                                নাম: (বাংলা){' '}
                                <span className="from_required">*</span>{' '}
                              </label>
                              <input
                                defaultValue={foundOnlineData?.name_bangla}
                                className="form-control"
                                type="text"
                                {...register('name_bangla', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[ঀ-৾\s:-]+$/i,
                                    message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}

                              />
                              {errors?.name_bangla && (
                                <span className="form__error">
                                  {errors?.name_bangla.message}
                                </span>
                              )}
                            </div>

                            <div className="col-lg-6 mb-2">
                              <label className="form-label">
                                নাম: (ইংরেজিতে){' '}
                                <span className="from_required">*</span>
                              </label>
                              <input
                                value={foundOnlineData?.name_english}
                                readOnly
                                className="form-control"
                                type="text"
                                {...register('name_english', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[A-Za-z\s]+$/,
                                    message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}

                              />
                              {errors?.name_english && (
                                <span className="form__error">
                                  {errors?.name_english.message}
                                </span>
                              )}
                            </div>

                            <div className="col-lg-6 my-2">
                              <label className="form-label">
                                পিতা / স্বামীর নাম: (বাংলা){' '}
                                <span className="from_required">*</span>
                              </label>
                              <input
                                defaultValue={foundOnlineData?.father_husband_bangla}
                                className="form-control"
                                type="text"
                                {...register('father_husband_bangla', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[ঀ-৾\s:-]+$/i,
                                    message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}
                              />
                              {errors?.father_husband_bangla && (
                                <span className="form__error">
                                  {errors?.father_husband_bangla.message}
                                </span>
                              )}
                            </div>

                            <div className="col-lg-6 my-2">
                              <label className="form-label">
                                পিতা / স্বামীর নাম: (ইংরেজিতে){' '}
                                <span className="from_required">*</span>
                              </label>
                              <input
                                defaultValue={foundOnlineData?.father_husband_english}
                                readOnly
                                className="form-control"
                                type="text"
                                {...register('father_husband_english', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[A-Za-z\s]+$/,
                                    message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}
                              />
                              {errors?.father_husband_english && (
                                <span className="form__error">
                                  {errors?.father_husband_english.message}
                                </span>
                              )}
                            </div>

                          </div>

                          <div className="row gx-3 ">


                            <div className="col-lg-6 mb-2">
                              <label className="form-label">
                                মাতার নাম: (বাংলা){' '}
                                <span className="from_required">*</span>
                              </label>
                              <input
                                defaultValue={foundOnlineData?.mother_name_bangla}
                                readOnly
                                className="form-control"
                                type="text"
                                {...register('mother_name_bangla', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[ঀ-৾\s:-]+$/i,
                                    message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}
                              />
                              {errors?.mother_name_bangla && (
                                <span className="form__error">
                                  {errors?.mother_name_bangla.message}
                                </span>
                              )}
                            </div>

                            <div className="col-lg-6 mb-2">
                              <label className="form-label">
                                মাতার নাম: (ইংরেজিতে){' '}
                                <span className="from_required">*</span>
                              </label>
                              <input
                                defaultValue={foundOnlineData?.mother_name_english}
                                readOnly
                                className="form-control"
                                type="text"
                                {...register('mother_name_english', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[A-Za-z\s]+$/,
                                    message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}
                              />
                              {errors?.mother_name_english && (
                                <span className="form__error">
                                  {errors?.mother_name_english.message}
                                </span>
                              )}
                            </div>


                            <div className="col-lg-6 my-2">
                              <label className="form-label">
                                মোবাইল নম্বর{' '}
                                <span className="from_required">*</span>
                              </label>
                              <input
                                className="form-control"
                                defaultValue={foundOnlineData?.number}
                                type="number"
                                {...register('number', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[0-9]{11}$/,
                                    message: 'মোবাইল নম্বরটি ১১ টি সংখ্যা হতে হবে',
                                  },
                                })}
                              />
                              {errors?.number && (
                                <span className="form__error">
                                  {errors?.number.message}
                                </span>
                              )}
                            </div>

                            <div className="col-lg-6 my-2">
                              <label className="form-label">
                                জাতীয় পরিচয় পত্র/জন্মনিবন্ধন নং{' '}
                                <span className="from_required">*</span>
                              </label>
                              <input
                                defaultValue={foundOnlineData?.nid}
                                className="form-control"
                                type="number"
                                {...register('nid', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[0-9]{10}$|^[0-9]{17}$/,
                                    message: 'জাতীয় পরিচয়পত্র নম্বরটি ১০ বা ১৭ টি সংখ্যা হতে হবে',
                                  },
                                })}
                              />
                              {errors?.nid && (
                                <span className="form__error">
                                  {errors?.nid.message}
                                </span>
                              )}
                            </div>
                          </div>


                          <div className="row gx-3">

                            <div className="col-lg-4 mb-2">
                              <label className="form-label">
                                জন্ম তারিখ <span className="from_required">*</span>
                              </label>
                              <input
                                defaultValue={foundOnlineData?.date_of_birth}
                                readOnly
                                className="form-control"
                                type="date"
                                {...register('date_of_birth', {
                                  required: 'খালি রাখা যাবে না',
                                })}
                              />
                              {errors?.date_of_birth && (
                                <span className="form__error">
                                  {errors?.date_of_birth.message}
                                </span>
                              )}
                            </div>

                            <div className="col-md-4 mb-2">
                              <label htmlFor="Promo_amount" className="form-label">
                                লিঙ্গ <span className="from_required">*</span>
                              </label>
                              <select
                                readOnly
                                className="form-select"
                                {...register('gender', {
                                  required: 'খালি রাখা যাবে না',
                                })}
                              >
                                {fetchGender?.map((gender) => (
                                  <option
                                    key={gender?.id}
                                    value={gender?.en}
                                    selected={foundOnlineData?.gender === gender?.en}
                                  >
                                    {gender?.bd}
                                  </option>
                                ))}
                              </select>
                              {errors?.gender && (
                                <span className="form__error">
                                  {errors?.gender.message}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-4 mb-2">
                              <label className="form-label">রক্তের গ্রুপ</label>
                              <select
                                className="form-select"
                                {...register('blood_group', {
                                  required: 'খালি রাখা যাবে না',
                                })}
                              >
                                <option selected value="">
                                  রক্তের গ্রুপ নির্বাচন করুন
                                </option>
                                <option value={'O+'}>ও+</option>
                                <option value={'O-'}>ও−</option>
                                <option value={'A-'}>এ−</option>
                                <option value={'A+'}>এ+</option>
                                <option value={'B+_'}>বি−</option>
                                <option value={'B+'}>বি+</option>
                                <option value={'AB-'}>এবি−</option>
                                <option value={'AB+'}>এবি+</option>
                              </select>
                              {errors?.blood_group && (
                                <span className="form__error">
                                  {errors?.blood_group.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='mb-2'>
                            <h6>স্থায়ী ঠিকানা</h6>

                          </div>
                          <div className="row gx-3">
                            <div className="col-lg-4">
                              <label className="form-label">
                                বিভাগ
                                <span className="from_required">*</span>
                              </label>
                              <select className="form-control"
                                {...register('permanent_division', {
                                  required: 'খালি রাখা যাবে না',
                                })}
                                onChange={(e) => selectPermanentDistrict(e)}
                              >
                                <option value='' selected>পছন্দ করুন বিভাগ</option>
                                {
                                  divisions?.division?.map(divisio => <option
                                    className="form-control"
                                    key={divisio?._id} value={divisio?.id} >{divisio?.bn_name}</option>)
                                }
                              </select>
                            </div>
                            <div className="col-lg-4">
                              <label htmlFor='prDistrict' className="form-label">
                                জেলা
                                <span className="from_required">*</span>
                              </label>
                              <select className="form-control"
                                {...register('permanent_district', {
                                  required: 'খালি রাখা যাবে না',
                                })}

                                onChange={(e) => selectPermanentUpdzilas(e)}
                              >
                                <option value='' selected>পছন্দ করুন জেলা</option>
                                {
                                  selectPermanentStoreDistricts?.map(district => <option
                                    className="form-control"
                                    key={district?._id} value={district?.id} >{district?.bn_name}</option>)
                                }
                              </select>
                            </div>
                            <div className="col-lg-4">
                              <label htmlFor='prUpazila' className="form-label">
                                থানা / উপজেলা
                                <span className="from_required">*</span>
                              </label>
                              <select className="form-control"
                                {...register('permanent_upazila', {
                                  required: 'খালি রাখা যাবে না',
                                })}
                                onChange={(e) => selectPermanentUnion(e)}
                              >
                                <option value='' selected>পছন্দ করুন থানা / উপজেলা</option>
                                {
                                  selectPermanentStoreUpazilas?.map(upazila => <option
                                    className="form-control"
                                    key={upazila?._id} value={upazila?.id} >{upazila?.bn_name}</option>)
                                }
                              </select>

                            </div>

                            {
                              accountType === "municipality" ?
                                <>
                                  <div className="col-lg-4 mt-3">
                                    <label className="form-label">
                                      পৌরসভা  (বাংলা)
                                      <span className="from_required">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      value={profileInfo?.municipality_bn_name}
                                    />
                                  </div>
                                  <div className="col-lg-4 mt-3">
                                    <label className="form-label">
                                      পৌরসভা (ইংরেজিতে)
                                      <span className="from_required">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      value={profileInfo?.municipality_en_name
                                      }
                                    />
                                  </div>
                                </>
                                :
                                <div className="col-lg-4 mt-3">
                                  <label className="form-label">
                                    ইউনিয়ন
                                    <span className="from_required">*</span>
                                  </label>
                                  <select className="form-control"
                                    {...register('permanent_union', {
                                      required: 'খালি রাখা যাবে না',
                                    })}
                                  >
                                    <option value='' selected>পছন্দ করুন ইউনিয়ন</option>
                                    {
                                      selectPermanentStoreUnions?.map(union => <option
                                        className="form-control"
                                        key={union?._id} value={union?.id} >{union?.bn_name}</option>)
                                    }
                                  </select>
                                </div>
                            }

                            <div className="col-lg-4 mt-3 ">
                              <label htmlFor='prPost_office_bn' className="form-label">
                                ডাকঘর (বাংলা)
                                <span className="from_required">*</span>{' '}
                              </label>
                              <input
                                id='prPost_office_bn'
                                name='prPost_office_bn'
                                className="form-control"
                                type="text"
                                {...register('post_office_bn', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[ঀ-৾\s:-]+$/i,
                                    message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setParmanentAddress({
                                    ...parmanentAddress,
                                    prPost_office_bn: value,
                                  });
                                }}
                              />
                              {errors?.post_office_bn && (
                                <span className="form__error">
                                  {errors?.post_office_bn.message}
                                </span>
                              )}
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="card ">
                        <div className="card-body">
                          <div className="row gx-3">
                            <div className="col-lg-4 mb-3">
                              <label htmlFor='prPost_office_en' className="form-label">
                                ডাকঘর (ইংরেজিতে){' '}
                                <span className="from_required">*</span>{' '}
                              </label>
                              <input
                                id='prPost_office_en'
                                name='prPost_office_en'
                                className="form-control"
                                type="text"
                                {...register('post_office_en', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[A-Za-z\s]+$/,
                                    message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setParmanentAddress({
                                    ...parmanentAddress,
                                    prPost_office_en: value,
                                  });
                                }}
                              />
                              {errors?.post_office_en && (
                                <span className="form__error">
                                  {errors?.post_office_en.message}
                                </span>
                              )}
                            </div>

                            <div className="col-lg-4 mb-3">
                              <label htmlFor='prWard_no' className="form-label">
                                ওয়ার্ড নং <span className="from_required">*</span>{' '}
                              </label>
                              <input
                                id='prWard_no'
                                name='prWard_no'
                                className="form-control"
                                type="number"
                                {...register('ward_no', {
                                  required: 'খালি রাখা যাবে না',
                                })}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setParmanentAddress({
                                    ...parmanentAddress,
                                    prWard_no: value,
                                  });
                                }}
                              />
                              {errors?.ward_no && (
                                <span className="form__error">
                                  {errors?.ward_no.message}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-4 mb-3">
                              <label htmlFor='prVillage_bn_name' className="form-label">
                                গ্রাম (বাংলা)
                                <span className="from_required">*</span>{' '}
                              </label>
                              <input
                                id='prVillage_bn_name'
                                name='prVillage_bn_name'
                                className="form-control"
                                type="text"
                                {...register('village_bn_name', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[ঀ-৾\s:-]+$/i,
                                    message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}

                                onChange={(e) => {
                                  const value = e.target.value;
                                  setParmanentAddress({
                                    ...parmanentAddress,
                                    prVillage_bn_name: value,
                                  });
                                }}
                              />
                              {errors?.village_bn_name && (
                                <span className="form__error">
                                  {errors?.village_bn_name.message}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-4 mb-2">
                              <label htmlFor='prVillage_en_name' className="form-label">
                                গ্রাম (ইংরেজিতে){' '}
                                <span className="from_required">*</span>{' '}
                              </label>

                              <input
                                id='prVillage_en_name'
                                name='prVillage_en_name'
                                className="form-control"
                                type="text"
                                {...register('village_en_name', {
                                  required: 'খালি রাখা যাবে না',
                                  pattern: {
                                    value: /^[A-Za-z\s]+$/,
                                    message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                  },
                                })}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setParmanentAddress({
                                    ...parmanentAddress,
                                    prVillage_en_name: value,
                                  });
                                }}
                              />
                              {errors?.village_en_name && (
                                <span className="form__error">
                                  {errors?.village_en_name.message}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-4 mb-2">
                              <label htmlFor='prRoad' className="form-label">
                                রোড / ব্লক / সেক্টর{' '}
                              </label>
                              <input
                                id='prRoad'
                                name='prRoad'
                                className="form-control"
                                type="text"
                                {...register('road')}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setParmanentAddress({
                                    ...parmanentAddress,
                                    prRoad: value,
                                  });
                                }}
                              />
                              {errors?.road && (
                                <span className="form__error">
                                  {errors?.road.message}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-4 mb-2">
                              <label htmlFor='prHolding_no' className="form-label">হোল্ডিং নং</label>
                              <input
                                id='prHolding_no'
                                name='prHolding_no'
                                className="form-control"
                                type="text"
                                {...register('holding_no')}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setParmanentAddress({
                                    ...parmanentAddress,
                                    prHolding_no: value,
                                  });
                                }}
                              />
                              {errors?.holding_no && (
                                <span className="form__error">
                                  {errors?.holding_no.message}
                                </span>
                              )}
                            </div>
                          </div>


                          <div className='mb-2'>
                            <div className='d-flex justify-content-start align-items-center'>
                              {
                                <input onClick={() => setCurrentAddressChecked(!currentAddressChecked)} type="checkbox"
                                />
                              }
                              <h6 className={`px-3`}> স্থায়ী ঠিকানা আর  বর্তমান ঠিকানা একই হলে টিক দিন</h6>
                            </div>
                          </div>


                          {
                            currentAddressChecked ? <div></div> : <div className="row gx-3">
                              <div className="col-lg-4 mb-3">
                                <label className="form-label">
                                  বিভাগ
                                  <span className="from_required">*</span>
                                </label>
                                <select className="form-control" name="division"
                                  {...register('present_division', {
                                    required: 'খালি রাখা যাবে না',
                                  })}

                                  onChange={(e) => selectDistrict(e)}>
                                  <option value='' selected>পছন্দ করুন বিভাগ</option>
                                  {
                                    divisions?.division?.map(divisio => <option
                                      className="form-control"
                                      key={divisio?._id} value={divisio?.id}>{divisio?.bn_name}</option>)
                                  }
                                </select>

                              </div>
                              <div className="col-lg-4 mb-3">
                                <label className="form-label">
                                  জেলা
                                  <span className="from_required">*</span>
                                </label>
                                <select className="form-control"
                                  {...register('present_district', {
                                    required: 'খালি রাখা যাবে না',
                                  })}
                                  onChange={(e) => selectUpdzilas(e)}>
                                  <option value='' selected>পছন্দ করুন জেলা</option>
                                  {selectStoreDistricts?.map(district => <option key={district?._id} value={district?.id}>{district?.bn_name}</option>
                                  )
                                  }
                                </select>
                              </div>
                              <div className="col-lg-4 mb-3">
                                <label className="form-label">
                                  থানা / উপজেলা
                                  <span className="from_required">*</span>
                                </label>
                                <select className="form-control" name="division"
                                  {...register('present_upazila', {
                                    required: 'খালি রাখা যাবে না',
                                  })}
                                  onChange={(e) => selectUnion(e)} >
                                  <option value='' selected>পছন্দ করুন থানা / উপজেলা</option>
                                  {
                                    selectStoreUpazilas?.map(upazila => <option key={upazila?._id} value={upazila?.id}> {upazila?.bn_name}</option>
                                    )
                                  }
                                </select>
                              </div>


                              {
                                accountType === "municipality" ?

                                  <>
                                    <div className=" mb-3 col-lg-4">
                                      <label className="form-label">
                                        পৌরসভা  (বাংলা)
                                        <span className="from_required">*</span>
                                      </label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        value={profileInfo?.municipality_bn_name}
                                      />
                                    </div>
                                    <div className=" mb-3 col-lg-4">
                                      <label className="form-label">
                                        পৌরসভা (ইংরেজিতে)
                                        <span className="from_required">*</span>
                                      </label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        value={profileInfo?.municipality_en_name
                                        }
                                      />
                                    </div>
                                  </>
                                  :
                                  <div className="col-lg-4 mb-3">
                                    <label className="form-label">
                                      ইউনিয়ন
                                      <span className="from_required">*</span>
                                    </label>
                                    <select className="form-control"
                                      {...register('present_union', {
                                        required: 'খালি রাখা যাবে না',
                                      })}
                                    >
                                      <option value='' selected>পছন্দ করুন থানা / উপজেলা</option>
                                      {
                                        selectStoreUnions?.map(union => <option key={union?._id} value={union?.id}> {union?.bn_name}</option>
                                        )
                                      }
                                    </select>

                                  </div>
                              }

                              <div className="col-lg-4 mb-3">


                                <label className="form-label">
                                  ডাকঘর (বাংলা)
                                  <span className="from_required">*</span>{' '}
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  {...register('presentPost_office_bn', {
                                    required: 'খালি রাখা যাবে না',
                                    pattern: {
                                      value: /^[ঀ-৾\s:-]+$/i,
                                      message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                    },
                                  })}
                                />
                                {errors?.post_office && (
                                  <span className="form__error">
                                    {errors?.post_office.message}
                                  </span>
                                )}
                              </div>
                              <div className="col-lg-4 mb-3">
                                <label className="form-label">
                                  ডাকঘর (ইংরেজিতে){' '}
                                  <span className="from_required">*</span>{' '}
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  {...register('presentPost_office_en', {
                                    required: 'খালি রাখা যাবে না',
                                    pattern: {
                                      value: /^[A-Za-z\s]+$/,
                                      message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                    },
                                  })}
                                />
                              </div>

                              <div className="col-lg-4 mb-3">
                                <label className="form-label">
                                  ওয়ার্ড নং <span className="from_required">*</span>{' '}
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  {...register('presentWard_no', {
                                    required: 'খালি রাখা যাবে না',
                                  })}
                                />

                              </div>
                              <div className="col-lg-4 mb-3">
                                <label className="form-label">
                                  গ্রাম (বাংলা)
                                  <span className="from_required">*</span>{' '}
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  {...register('presentVillage_bn_name', {
                                    required: 'খালি রাখা যাবে না',
                                    pattern: {
                                      value: /^[ঀ-৾\s:-]+$/i,
                                      message: 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত',
                                    },
                                  })}
                                />
                              </div>
                              <div className="col-lg-4 mb-2">
                                <label className="form-label">
                                  গ্রাম (ইংরেজিতে){' '}
                                  <span className="from_required">*</span>{' '}
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  {...register('presentVillage_en_name', {
                                    required: 'খালি রাখা যাবে না',
                                    pattern: {
                                      value: /^[A-Za-z\s]+$/,
                                      message: 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত',
                                    },
                                  })}
                                />
                              </div>
                              <div className="col-lg-4">
                                <label className="form-label">
                                  রোড / ব্লক / সেক্টর{' '}
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  {...register('presentRoad')}
                                />

                              </div>
                              <div className="col-lg-4 ">
                                <label htmlFor='presentHolding_no' className="form-label">হোল্ডিং নং</label>
                                <input
                                  id='presentHolding_no'
                                  className="form-control"
                                  type="text"
                                  {...register('presentHolding_no')}
                                />
                              </div>
                            </div>
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end gap-3">
                  <p

                    // style={{ cursor: isLoading ? 'no-drop' : 'pointer' }}
                    className="btn btn-md rounded btn-warning bg-warning font-sm hover-up"
                  >
                    বাতিল করুন

                  </p>
                  <>
                    <input
                      // style={{ cursor: isLoading ? 'no-drop' : 'pointer' }}
                      type="submit"
                      className="btn btn-md rounded font-sm hover-up h-25"
                      value="জমা দিন"
                    />
                  </>
                </div>
              </form>




            </>
          }



        </div>
      </section>


    </DashboardLayout>
  );
}

export default OnlineCitizen;