import React, { } from "react";
import { useForm } from "react-hook-form";


function SearchInputModal({ contects, modal, setSearchValue, setOpen, isLoading }) {
  const { type } = modal;
  const { register, handleSubmit, reset, formState: { errors } } = useForm();


  const createOnSubmit = async (data) => {
    let queryParameters;
    const { registrationDate, birthDate } = data
    const formattedRegistrationDate = new Date(registrationDate).toLocaleDateString('bn-BD', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      minimumIntegerDigits: 2 
    });
    // Format birth date
    const formattedBirthDate = new Date(birthDate).toLocaleDateString('bn-BD', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      minimumIntegerDigits: 2 // Ensure day and month have two digits
    });

    if (registrationDate && birthDate) {
      queryParameters = `registrationDate=${formattedRegistrationDate}&name=${data?.name}&fatherName=${data?.fatherName}&motherName=${data?.motherName}&birthCirtificateNo=${data?.birthCirtificateNo}&birthDate=${formattedBirthDate}&gender=${data?.gender}&permanentAddress=${data?.permanentAddress}`
    } else if (registrationDate) {
      queryParameters = `registrationDate=${formattedRegistrationDate}&name=${data?.name}&fatherName=${data?.fatherName}&motherName=${data?.motherName}&birthCirtificateNo=${data?.birthCirtificateNo}&birthDate=${data?.birthDate}&gender=${data?.gender}&permanentAddress=${data?.permanentAddress}`
    } else if (birthDate) {
      queryParameters = `registrationDate=${data?.registrationDate}&name=${data?.name}&fatherName=${data?.fatherName}&motherName=${data?.motherName}&birthCirtificateNo=${data?.birthCirtificateNo}&birthDate=${formattedBirthDate}&gender=${data?.gender}&permanentAddress=${data?.permanentAddress}`
    }else{
      queryParameters = `registrationDate=${data?.registrationDate}&name=${data?.name}&fatherName=${data?.fatherName}&motherName=${data?.motherName}&birthCirtificateNo=${data?.birthCirtificateNo}&birthDate=${data?.birthDate}&gender=${data?.gender}&permanentAddress=${data?.permanentAddress}`
    }
    setSearchValue(queryParameters)
    setOpen({ tyep: false, data: null })

  };

  return (
    <>
      {type && (
        <div className="modal_product p-6">
          <section className="content-jonmonNibondhon">
            <div className="row">
              <div className="col-12">
                <div className="content-header">
                  <h2 className="content-title">{contects?.title}</h2>
                  <button
                    onClick={() =>
                      setOpen({ type: false })
                    }
                  >
                    X
                  </button>
                </div>
              </div>
              <form onSubmit={handleSubmit(createOnSubmit)}>
                <div className="card ">
                  <div className="card-body">
                    <div className="row g-3">

                      <div className="col-lg-4">
                        <label className="form-label">
                          রেজিস্টার তারিখ
                        </label>
                        <input
                          id="registrationDate"
                          className="form-control"
                          type="date"
                          {...register("registrationDate")}
                        />
                      </div>
                      <div className="col-lg-4">
                        <label className="form-label">
                          নাম (বাংলা)
                        </label>
                        <input
                          id="birth registration"
                          className="form-control"
                          type="text"
                          {...register("name", {
                            pattern: {
                              value: /^[ঀ-৾\s:-]+$/i,
                              message: "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত",
                            },
                          })}
                        />
                        {errors?.name && (
                          <span className="form__error">
                            {errors?.name.message}
                          </span>
                        )}
                      </div>

                      <div className="col-lg-4 ">
                        <label className="form-label">
                          বাবার নাম	(বাংলা){" "}
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          {...register("fatherName", {
                            pattern: {
                              value: /^[ঀ-৾\s:-]+$/i,
                              message: "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত",
                            },
                          })}
                        />
                        {errors?.fatherName && (
                          <span className="form__error">
                            {errors?.fatherName.message}
                          </span>
                        )}
                      </div>

                      <div className="col-lg-4 ">
                        <label className="form-label">
                          মায়ের নাম	(বাংলা){" "}
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          {...register("motherName", {
                            pattern: {
                              value: /^[ঀ-৾\s:-]+$/i,
                              message: "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত",
                            },
                          })}
                        />
                        {errors?.motherName && (
                          <span className="form__error">
                            {errors?.motherName.message}
                          </span>
                        )}
                      </div>
                      <div className="col-lg-4 ">
                        <label htmlFor="ward" className="form-label">
                          জন্ম নিবন্ধন নং
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          {...register("birthCirtificateNo", {
                            pattern: {
                              value: /^[0-9]{10}$|^[0-9]{17}$/,
                              message:
                                "জাতীয় পরিচয়পত্র নম্বরটি ১০ বা ১৭ টি সংখ্যা হতে হবে",
                            },
                          })}

                        />
                        {errors?.birthCirtificateNo && (
                          <span className="form__error">
                            {errors?.birthCirtificateNo.message}
                          </span>
                        )}
                      </div>
                      <div className="col-lg-4 ">
                        <label className="form-label">
                          জন্ম তারিখ{" "}
                        </label>
                        <input
                          className="form-control"
                          type="date"
                          {...register("birthDate")}
                        />
                        {errors?.birthDate && (
                          <span className="form__error">
                            {errors?.birthDate.message}
                          </span>
                        )}
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="Promo_amount" className="form-label">
                          লিঙ্গ
                        </label>
                        <select
                          className="form-select"
                          {...register('gender')}
                        >
                          <option selected value="">
                            লিঙ্গ নির্বাচন করুন
                          </option>

                          <option value="MALE">পুরুষ</option>
                          <option value="FEMALE">মহিলা</option>
                        </select>
                        {errors?.gender && (
                          <span className="form__error">
                            {errors?.gender.message}
                          </span>
                        )}
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label htmlFor="village_bn_name" className="form-label">
                          গ্রাম (বাংলা)
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          {...register("permanentAddress", {
                            pattern: {
                              value: /^[ঀ-৾\s:-]+$/i,
                              message: "কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত",
                            },
                          })}
                        />
                        {errors?.permanentAddress && (
                          <span className="form__error">
                            {errors?.permanentAddress.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end gap-3">
                  <p
                    onClick={() =>
                      setOpen({ type: false }, reset())
                    }
                    className="btn btn-md rounded btn-danger bg-danger font-sm hover-up"
                  >
                   ডাটা মুছে ফেলুন
                  </p>
                  <>
                    <input
                      style={{ cursor: isLoading ? "no-drop" : "pointer" }}
                      type="submit"
                      className="btn btn-md rounded font-sm hover-up h-25"
                      value="অনুসন্ধান করুন"
                    />
                  </>
                </div>
              </form>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default SearchInputModal;

