import EnglishToBanglaNumber from "../../../utils/EnglishToBanglaNumber";

const CartificateDownloadTable = ({ dt, info, index }) => {
    const licenseFee = info?.license_fee_infomation[0]?.totalAmmount
    return (
        <tr>
            <td>{EnglishToBanglaNumber(`${index + 1}`)}</td>
            <td>{info?.institution_bn_name}</td>
            <td>{dt?.name_bangla}</td>
            <td>{info?.institution_bn_type}</td>
            <td>{info?.institution_address_bn}</td>
            <td>{EnglishToBanglaNumber(`${licenseFee}`)}</td>
        </tr>
    )

}
export default CartificateDownloadTable;