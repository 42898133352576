import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/authSlice";
import { AuthApi } from "./services/authUser";
import { CertificateApi } from "./services/certificate";
import { SubscriptionApi } from "./services/subscription";
import { CitizenApi } from "./services/citizen";
import { LocationApi } from "./services/location";
import { RegisterApi } from "./services/register";
import { DashboardApi } from "./services/dashboard";
import { CertificateDeathApi } from "./services/certificateDeath";
import { HoldingTaxApi } from "./services/holdingTax";
import { BirthregistrationApi } from "./services/birthregistration";
import { RechargeApi } from "./services/recharge";
import { CertificatemunicipalityApi } from "./services/certificatemunicipality";
export const store = configureStore({
  reducer: {
    [BirthregistrationApi.reducerPath]: BirthregistrationApi.reducer,

    [AuthApi.reducerPath]: AuthApi.reducer,
    [CertificateApi.reducerPath]: CertificateApi.reducer,
    [CertificatemunicipalityApi.reducerPath]: CertificatemunicipalityApi.reducer,
    [SubscriptionApi.reducerPath]: SubscriptionApi.reducer,
    [CitizenApi.reducerPath]: CitizenApi.reducer,
    [LocationApi.reducerPath]: LocationApi.reducer,
    [RegisterApi.reducerPath]: RegisterApi.reducer,
    [DashboardApi.reducerPath]: DashboardApi.reducer,
    [HoldingTaxApi.reducerPath]: HoldingTaxApi.reducer,
    [CertificateDeathApi.reducerPath]: CertificateDeathApi.reducer,
    [RechargeApi.reducerPath]: RechargeApi.reducer,
    auth: authSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      BirthregistrationApi.middleware,
      AuthApi.middleware,
      LocationApi.middleware,
      CertificatemunicipalityApi.middleware,
      CertificateApi.middleware,
      SubscriptionApi.middleware,
      CitizenApi.middleware,
      RegisterApi.middleware,
      DashboardApi.middleware,
      CertificateDeathApi.middleware,
      HoldingTaxApi.middleware,
      RechargeApi.middleware,
    ]),
});
