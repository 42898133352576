import React, { useState, useEffect, useMemo } from 'react';
import DashboardLayout from '../../layout/DashboardLayout';
import Pagination from '../Pagination';
import GetSpinner from '../../../helpers/shared/GetSpinner';
import ALlSonodCreate from './modal/ALlSonodCreate';
import { toast } from 'react-toastify';
import {
  useGetAllSonodQuery,
  usePostCertificateMutation,
} from '../../../app/services/certificate';
import SingleSonod from './singleSonod/SingleSonod';
import { useSelector } from 'react-redux';
import ApproveSonod from './modal/ApproveSonod';
import CertificatePrintModal from './modal/CertificatePrintModal';
import Search from '../../../utils/Search';

function AllSonod() {
  const { userInfo } = useSelector((state) => state.auth);
  const [printModalOpen, setPrintModalOpen] = useState({
    type: false,
    data: null,
  });
  const [status, setStatus] = useState({ type: false, data: null });
  const contects = {
    title: 'সকল সনদ',
    sonod_type: 'nationality'
  };
  const [Loading, setLoading] = useState(false);
  const [search, setSearchValue] = useState('');
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const pathname = `page=${pageIndex}&limit=${pageSize}&search=${search}`;

  const { data, isLoading } = useGetAllSonodQuery(pathname);

  const [
    addCertificate,
    { isLoading: addisLoading, isSuccess: addisSuccess, reset },
  ] = usePostCertificateMutation();
  const certificate = useMemo(
    () => (data ? data?.certificate : []),
    [data, search]
  );

  const CreateonSubmit = async (data) => {
    await addCertificate(data);
  };

  useEffect(() => {
    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    if (addisSuccess) {
      toast.success('Certificate Add');
      reset();
    }
  }, [isLoading, addisSuccess, reset]);

  /* add modal section ==============  */
  const [sonodCreateModal, setSonodCreateModal] = useState({
    type: false,
    data: null,
  });
  const [sonodEditModal, setSonodEditModal] = useState({
    type: false,
    data: null,
  });

  return (
    <DashboardLayout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">{contects?.title}</h2>
          </div>
          <div>
            <p
              onClick={() =>
                setSonodCreateModal({ type: !sonodCreateModal.type })
              }
              className="btn btn-primary btn-sm rounded"
            >
              নতুন তৈরি করুন
            </p>
          </div>
        </div>
        <div className="card mb-4">
          <header className="card-header">
            <div className="row align-items-center">
              <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
              <Search
               setSearchValue={setSearchValue} placeholder="সনদ নাম্বার"/>
              </div>
            </div>
          </header>
          <div className="col-md-12 px-4 ">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr className="">
                    <th>নাম</th>
                    <th>জাতীয় পরিচয়পত্র</th>
                    <th>মোবাইল নম্বর</th>
                    <th>ইউনিয়ন</th>
                    <th>সনদপত্র প্রকার</th>
                    <th>অবস্থা</th>
                    <th className="text-end">পদক্ষেপ</th>
                  </tr>
                </thead>
                <tbody>
                  {Loading && <GetSpinner />}
                  {certificate?.map((data) => (
                    <SingleSonod
                      setSonodCreateModal={setSonodCreateModal}
                      setSonodEditModal={setSonodEditModal}
                      data={data}
                      key={data._id}
                      setStatus={setStatus}
                      setPrintModalOpen={setPrintModalOpen}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Pagination
          totalPages={data?.totalPages}
          setPagination={setPagination}
          pageIndex={pageIndex}
          pageSize={pageSize}
        />
      </section>
      {userInfo?.users?.role === 'secretary' ? (
        <ApproveSonod modal={status} setOpen={setStatus} />
      ) : (
        ''
      )}

      <CertificatePrintModal
        modal={printModalOpen}
        setOpen={setPrintModalOpen}
      />

      <ALlSonodCreate
        contects={contects}
        CreateonSubmit={CreateonSubmit}
        modal={sonodCreateModal}
        setOpen={setSonodCreateModal}
        addisLoading={addisLoading}
      />


      
    </DashboardLayout>
  );
}

export default AllSonod;
