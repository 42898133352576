const SonodTypeEnToBn = (value) => {

    let banglaName;
    if (value === 'nationality') {
        banglaName = 'জাতীয়তা সনদ'
    }
    else if (value === 'family') {
        banglaName = 'পারিবারিক সনদ'
    }
    else if (value === 'theheir') {
        banglaName = 'ওয়ারিশ সনদ'
    }
    else if (value === 'citizenship') {
        banglaName = 'নাগরিকত্ব সনদ'
    }
    else if (value === 'newvoterattestation') {
        banglaName = 'নতুন ভোটার প্রত্যয়ন'
    }
    else if (value === 'remarriage') {
        banglaName = 'পুনঃবিবাহ-সনদ'
    }
    else if (value === 'agricultural') {
        banglaName = 'কৃষি প্রত্যয়ন'
    }
    else if (value === 'annualincome') {
        banglaName = 'বার্ষিক আয়ের সনদ'
    }
    else if (value === 'monthlyincome') {
        banglaName = 'মাসিক আয়ের সনদ'
    }
    else if (value === 'characteristic') {
        banglaName = 'চারিত্রিক সনদ'
    }
    else if (value === 'transferofconstituency') {
        banglaName = 'ভোটার এলাকা স্থানান্তর প্রত্যয়ন'
    }
    else if (value === 'amendmentofnationalidentityinformation') {
        banglaName = 'জাতীয় পরিচয় তথ্য সংশোধন সনদ'
    }
    else if (value === 'attestationofthesameperson') {
        banglaName = 'একই ব্যক্তির প্রত্যয়ন'
    }
    else if (value === 'miscellaneous') {
        banglaName = 'সহোদর ভাই'
    }
    else if (value === 'death') {
        banglaName = 'মৃত্যু সনদ'
    }
    else if (value === 'tradelicense') {
        banglaName = 'ট্রেড লাইসেন্স'
    }
    else if (value === 'unmarried') {
        banglaName = 'অবিবাহিত সনদ'
    }
    return banglaName;
};
export default SonodTypeEnToBn;


