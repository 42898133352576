import React from 'react';
import { handelClick } from '../../../utils/ConfirmDelete';
import { Link, useNavigate } from 'react-router-dom';
import { useDeleteCitizenMutation } from '../../../app/services/citizen';
import { useSelector } from 'react-redux';
import EnglishToBanglaNumber from '../../../utils/EnglishToBanglaNumber';
import GenderNameformat from '../../../utils/GenderNameformat';
function SingleCitizen({ user, setStatus }) {
  const { userInfo } = useSelector((state) => state.auth);
  const [deleteCitizen, { isLoading }] = useDeleteCitizenMutation();
  const accessFunction = ['secretary']

  const nagigate=useNavigate()
  const nagigatePages =()=>{
    nagigate(`/user/citizen/${user._id}`)
  }


  return (
    <tr>
      <td>
        <p><Link to={`/user/citizen/${user._id}`}>{user?.name_bangla}</Link> </p>
      </td>
      <td>
        <p>{user?.nid}</p>
      </td>
      <td>
        <p>{user?.number}</p>
      </td>
      <td>
        <p>{GenderNameformat(`${user?.gender}`)}</p>
      </td>

      <td>
        <p
          className={`badge ${user?.status === 'pending'
            ? 'badge-soft-danger'
            : user?.status === 'approved'
              ? 'badge-soft-success'
              : ''
            }`}
        >
          {user?.status}
        </p>
      </td>
      {
        userInfo?.users?.role !== 'admin' && <td className="text-end">
          <div className="text-end">

            {
              accessFunction?.includes(userInfo?.users?.role) && <>
                <p
                onClick={()=>nagigatePages()}
                 /*  onClick={() =>
                    setStatus({
                      type: true,
                      data: user,
                    })
                  } */
                  className="btn btn-sm font-sm rounded btn-brand m-1"
                >
                  {' '}
                  <i className="material-icons md-edit"></i> সম্পাদনা
                
                </p>

                <p
                  onClick={() => handelClick(user?._id, deleteCitizen)}
                  className="btn btn-sm font-sm rounded btn-brand m-1"
                >
                  {' '}
                  <i className="material-icons md-delete_forever"></i> মুছে ফেলুন {' '}
                </p>
              </>
            }

          </div>
        </td>
      }

    </tr>
  );
}

export default SingleCitizen;
