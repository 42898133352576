import DashboardLayout from "../../components/layout/DashboardLayout";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { usePorichoyCitizanVerifiedMutation } from "../../app/services/authUser";
import NidVerification from "../../components/nidVerification/NidVerification";
const NidVerifyInfo = () => {
  /* react hook */
  /* react js hook from  */
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  /* redut rtk handel */
  const [VerifiedPorichoy, { isError, error, isLoading, isSuccess, data }] =
    usePorichoyCitizanVerifiedMutation();

  /* extra funtion handel  */
  const SeacrhSubmit = async (searchValue) => {
    try {
      await VerifiedPorichoy(searchValue);
    } catch (error) {
      toast.error(error?.data?.error);
    }
  };
  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.error);
    }
  }, [isError, error, isLoading, isSuccess, data]);

  /* facek data  */

  const datas = {
    response: {
      nid: {
        photoUrl:'',
        oldNationalIdNumber:'123123',
        nationalIdNumber:'fasf',
        fullNameBN:'afas',
        fullNameEN:'sdfasdf',
        dateOfBirth:'asdf',
        fathersNameBN:'asdf',
        fathersNameEN:'asdfsad',
        mothersNameBN:'asdf',
        mothersNameEN:'asdfs',
        spouseNameEN:'sadfs',
        profession:"sdf",
        gender:'famalee',
        presentAddressBN:'sdfa',
        permanentAddressBN:'asdf',
      }
    }
  }



  return (
    <DashboardLayout>
      {!isSuccess && !data && (
        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">
                এন আইডির তথ্য যাচাই করুন
              </h2>
              <p style={{
                color: 'red'
              }}>মৃত ব্যক্তির/ ভুল তথ্য দিয়ে অনুসন্ধান করলেও চার্জ কাটা হবে সুতরাং সঠিক তথ্য দিয়ে অনুসন্ধান করুন অথবা প্রয়োজনে সরাসরি visit করে <a href="https://services.nidw.gov.bd/nid-pub/">https://services.nidw.gov.bd/nid-pub/</a> যাচাই করুন। </p>
            </div>
          </div>
          <div className="card mb-4">
            <header className="card-header">
              <div className="row align-items-center">
                <form
                  onSubmit={handleSubmit(SeacrhSubmit)}
                  style={{ display: "flex" }}
                >
                  <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                    <div>
                      <p>এনআইডি</p>
                    </div>
                    <input
                      id="nidNumber"
                      type="number"
                      placeholder="অনুসন্ধান এনআইডি"
                      className="form-control"
                      {...register("nidNumber", {
                        required: "খালি রাখা যাবে না",
                        pattern: {
                          value: /^[0-9]{10}$|^[0-9]{17}$/,
                          message:
                            "জাতীয় পরিচয়পত্র নম্বরটি ১০ বা ১৭ টি সংখ্যা হতে হবে",
                        },
                      })}
                    />
                    {errors?.nidNumber && (
                      <span className="form__error">
                        {errors?.nidNumber.message}
                      </span>
                    )}
                  </div>
                  <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                    <div>
                      <p>জন্ম তারিখ</p>
                    </div>
                    <input
                      id="dateOfBirth"
                      type="date"
                      className="form-control"
                      {...register("dateOfBirth", {
                        required: "খালি রাখা যাবে না",
                      })}
                    />
                    {errors?.dateOfBirth && (
                      <span className="form__error">
                        {errors?.dateOfBirth.message}
                      </span>
                    )}
                  </div>
                  {
                    <div className="col-md-3 col-12 ms-auto mb-md-0 mb-3  text-end">
                      <input
                        type="submit"
                        value={`${isLoading ? "অপেক্ষা করুন" : "সাবমিট করুন"}`}
                        style={{ marginTop: "25px" }}
                        className="btn btn-primary btn-sm rounded"
                      />
                    </div>
                  }
                </form>
              </div>
            </header>
            {isError && <h6 className="p-2">{error?.data?.error}</h6>}
          </div>
        </section>
      )}

      {data && <NidVerification data={data} />}
    </DashboardLayout>
  );
};
export default NidVerifyInfo;
