import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useUpdateCertificateMutation } from '../../../../app/services/certificate';
import AccountRoles from '../../../../utils/AccountRoles';
import { useUpdatemunicipalitycertificateMutation } from '../../../../app/services/certificatemunicipality';

function ApproveSonod({ modal, setOpen, userInfo }) {
  const title = userInfo?.users?.account === AccountRoles.MUNICIPALITY ? 'পৌরসভার মেয়র' : 'ইউনিয়ন সচিব'
  const { type, data } = modal; 
  const [updateCertificate, { isSuccess, isLoading }] = useUpdateCertificateMutation();
  const [updateCertificateMunicipality, { isSuccess: isSuccessmui, isLoading: isLoadingmui }] = useUpdatemunicipalitycertificateMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (updateData) => {
    let updatedData = { data: { ...updateData }, id: data?._id };
    if (userInfo?.users?.account === AccountRoles.MUNICIPALITY) {
      updateCertificateMunicipality(updatedData);
    } else {
      updateCertificate(updatedData);
    }
  };
  useEffect(() => {
    if (isSuccess || isSuccessmui) {
      toast.success('সঠিক ভাবে আপডেট করা হয়েছ');
      reset();
      setOpen({ type: false });
    }
  }, [isSuccess, isLoading, reset, isSuccessmui, isLoadingmui, setOpen]);

  const statusHandel = [
    {
      value: 'pending',
      type: 'pending',
      bn: "বিচারাধীন",
    },
    {
      value: 'approved',
      type: 'approved',
      bn: "অনুমোদন দেন",
    },
  ];

  return (
    <>
      {type && (
        <div className="modal_product">
          <section className="content-main">
            <div className="row">
              <div className="col-12">
                <div className="content-header">
                  <h2 className="content-title">{title}</h2>
                  <button onClick={() => setOpen({ type: false }, reset())}>
                    X
                  </button>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row gx-2">
                        <div className="col-12 ">
                          <label className="form-label">অবস্থা</label>
                          <select
                            className="form-control"
                            {...register('status')}
                          >
                            {statusHandel?.map((statu) => (
                              <option
                                key={statu?.value}
                                value={statu?.type}
                                selected={data?.status == statu?.type}
                              >
                                {statu?.bn}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    style={{ cursor: isLoading || isLoadingmui ? 'no-drop' : 'pointer' }}
                    type="submit"
                    className="btn btn-md rounded font-sm hover-up"
                  >
                    জমা করুন
                  </button>
                </div>
              </form>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default ApproveSonod;
