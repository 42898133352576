import React from "react";
import { Link } from "react-router-dom";
import { handelClick } from "../../../../utils/ConfirmDelete";
import { useSelector } from "react-redux";
import EnglishToBanglaNumber from "../../../../utils/EnglishToBanglaNumber";
import { useDeleteCertificateMutation } from "../../../../app/services/certificate";
import { useDeletemunicipalitycertificateMutation } from "../../../../app/services/certificatemunicipality";
import AccountRoles from "../../../../utils/AccountRoles";
function TradeLicenseSingleSonod({ data, setStatus, setPrintModalOpen }) {
    const { userInfo } = useSelector((state) => state.auth);
    const [deleteContent, { isLoading }] = useDeleteCertificateMutation();
    const [Deletemunicipalitycertificate, { isLoading: isLoadingmunicipalitycertificate }] = useDeletemunicipalitycertificateMutation();
    const accessFunction = ["secretary"];
    return (
        <tr>
            {
                userInfo?.users?.account === AccountRoles.MUNICIPALITY && <td>
                    <Link to={`/application-certificate/${userInfo?.users?.account}/${data?.sonod_type}/${data?._id}`}>
                        {data?.trade_license_infomation[0]?.institution_bn_name}
                    </Link>
                </td>

            }
            {
                userInfo?.users?.account === AccountRoles.UNION && <td>
                    <Link to={`/application-certificate/${data?.sonod_type}/${data?._id}`}>
                        {data?.trade_license_infomation[0]?.institution_bn_name}
                    </Link>
                </td>

            }

            <td>{data?.citizen_id?.name_bangla}</td>
            <td>{EnglishToBanglaNumber(`${data?.citizen_id?.nid}`)}</td>
            <td>{EnglishToBanglaNumber(`${data?.citizen_id?.number}`)}</td>
            <td>
                <p
                    className={`badge ${data?.status === "pending"
                        ? "badge-soft-danger"
                        : data?.status === "approved"
                            ? "badge-soft-success"
                            : ""
                        }`}
                >
                    {data?.status === "approved" ? "গ্রহণ করা হয়েছে" : "বিচারাধীন"}
                </p>
            </td>
            <td className="text-end">
                <div className="text-end">
                    {accessFunction?.includes(userInfo?.users?.role) && (
                        <>
                            {data?.status !== "approved" && (
                                <button
                                    onClick={() =>
                                        setStatus({
                                            type: true,
                                            data: data,
                                        })
                                    }
                                    style={{ cursor: isLoading ? "no-drop" : "pointer" }}
                                    disabled={data?.status === "approved"}
                                    className="btn btn-sm font-sm btn-light rounded m-1 border"
                                >
                                    {" "}
                                    <i className="material-icons md-edit fs-6"></i>{" "}
                                </button>
                            )}
                            {
                                userInfo?.user?.account === AccountRoles.MUNICIPALITY && <button
                                    onClick={() => handelClick(data?._id, Deletemunicipalitycertificate)}
                                    style={{ cursor: isLoading || isLoadingmunicipalitycertificate ? "no-drop" : "pointer" }}
                                    className="btn btn-sm font-sm btn-light rounded m-1"
                                >
                                    {" "}
                                    <i className="material-icons md-delete_forever fs-6"></i>{" "}
                                </button>
                            }
                            {
                                userInfo?.user?.account === AccountRoles.UNION && <button
                                    onClick={() => handelClick(data?._id, deleteContent)}
                                    style={{ cursor: isLoading || isLoadingmunicipalitycertificate ? "no-drop" : "pointer" }}
                                    className="btn btn-sm font-sm btn-light rounded m-1"
                                >
                                    {" "}
                                    <i className="material-icons md-delete_forever fs-6"></i>{" "}
                                </button>
                            }

                        </>
                    )}
                    <button
                        onClick={() =>
                            setPrintModalOpen({
                                type: true,
                                data: data,
                            })
                        }
                        disabled={data?.status === "pending"}
                        style={{ cursor: isLoading ? "no-drop" : "pointer" }}
                        className="btn btn-sm font-sm btn-light rounded border m-1"
                    >
                        <i className="material-icons md-print fs-6"></i>{" "}
                    </button>
                </div>
            </td>
        </tr>
    );
}

export default TradeLicenseSingleSonod;


