import { yupResolver } from "@hookform/resolvers/yup";
import DashboardLayout from "../../components/layout/DashboardLayout";
import { updateEmailSchema } from "../../helpers/validation/LoginSchema";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useUpdateEmalOnlyAdminMutation } from "../../app/services/authUser";
import { toast } from "react-toastify";

const EmailUpdate = () => {
    const [updateEmail, { isLoading, isError, isSuccess }] = useUpdateEmalOnlyAdminMutation()
    const { register, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(updateEmailSchema) });
    const onSubmit = async (data) => {
        await updateEmail(data)
    };
    useEffect(() => {
        if (isSuccess) {
            toast.success('ইমেইল টা আপডেট করা হয়েছে')
            reset()
        }
        if (isError) {
            toast.error('ইমেইল টি সঠিক না')
        }

    }, [isLoading, isError, isSuccess])
    return (
        <DashboardLayout>

            <div>
                <section style={{
                    width: '100%', height: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center'

                }}
                >
                    <div className="card" style={{ width: '300px' }}>
                        <div className="card-body">
                            <h4 className="card-title mb-4">ইমেইল আপডেট করুন</h4>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mb-3">
                                    <label >ইমেইল <span style={{ color: 'red' }}>*</span></label>
                                    <input
                                        className="form-control"
                                        placeholder="ইমেইল"
                                        type="email"
                                        {...register('email')}
                                    />
                                    {errors?.email && (
                                        <span className="form__error">{errors?.email?.message}</span>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label>নতুন ইমেইল <span style={{ color: 'red' }}>*</span></label>
                                    <input
                                        className="form-control"
                                        placeholder="নতুন ইমেইল"
                                        type="email"
                                        {...register('newEmail')}
                                    />
                                    {errors?.newEmail && (
                                        <span className="form__error">
                                            {errors?.newEmail?.message}
                                        </span>
                                    )}
                                </div>

                                <div className="mb-4">
                                    <button type="submit" className="btn-primary w-100">
                                        {
                                            isLoading ? 'অপেক্ষা করুন...' : 'প্রবেশ করুন'
                                        }
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>



        </DashboardLayout>
    )
}
export default EmailUpdate;