import React, { useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import JoditEditor from "jodit-react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useGetSingleCitizenMutation } from "../../app/services/citizen";
import { OtherCertificateSchema } from "../../helpers/validation/AllSonodCertificateSchema";
import { usePostCertificateMutation } from "../../app/services/certificate";
import FindcitizensSearch from "../findcitizens/FindcitizensSearch";
function OtherSonodCreateModal({
  contects,
  modal,
  setOpen,
  addisLoading,
  userInfo,
}) {
  const { type } = modal;
  const bodyData = useRef(null);
  const [joditdetails, setJoditdetails] = useState({
    detail_bn: null,
    detail_en: null,
  });
  const [userData,setUserData]=useState(false)
  const [nidOrNumber, setNidOrNumber] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: {  },
  } = useForm({ resolver: yupResolver(OtherCertificateSchema) });

  const [getCitizen, { data, isLoading }] = useGetSingleCitizenMutation();

  const handleSearchCitizen = async () => {
    if (nidOrNumber) {
      const searchData = { nidOrNumber };
      await getCitizen(searchData);
    }
  };
  const [
    createCertificate,
    { isSuccess: isSucc, isLoading: loading, isError: isErr, error: err },
  ] = usePostCertificateMutation();

  const createOnSubmit = async (certificateData) => {
    if (!data) {
      toast.error("আপনার নাগরিক কে খুজে বের করুন");
    } else {
      const user_infoData = { ...data?.data };
      if (
        user_infoData &&
        user_infoData._id &&
        Object.getOwnPropertyDescriptor(user_infoData, "_id").configurable
      ) {
        delete user_infoData._id;
      } else {
      }
      const otherInfo = {
        ...certificateData,
        ...joditdetails,
      };

      const certificateDataInfo = {
        ...user_infoData,
        status: userInfo?.role == "secretary" ? "approved" : "pending",
        citizen_id: userData?._id,
        other_info: otherInfo,
        sonod_type: "other",
      };

      await createCertificate(certificateDataInfo);
    }
  };

  const contentFieldChanagedbn = (data) => {
    setJoditdetails({
      detail_bn: data,
      detail_en: joditdetails?.detail_en,
    });
  };
  const contentFieldChanageden = (data) => {
    setJoditdetails({
      detail_bn: joditdetails?.detail_bn,
      detail_en: data,
    });
  };

  useEffect(() => {
    if (isSucc) {
      toast.success("সঠিকভাবে  এড করা হয়েছ");
      setOpen({ type: false });
      reset();
    }
    if (isErr) {
      toast.error("errror", { id: "create" });
    }
  }, [isSucc, isErr, err]);

  return (
    <>
      {type && (
        <div className="modal_product">
          <section className="content-mainDash">
            <div className="row">
              <div className="col-12">
                <div className="content-header">
                  <h2 className="content-title">{contects?.title}</h2>
                  <button onClick={() => setOpen({ type: false }, reset())}>
                    X
                  </button>
                </div>
                <FindcitizensSearch
                  onChangeGetValue={setNidOrNumber}
                  onClickSubmitButton={handleSearchCitizen}
                  isLoading={isLoading}
                  setInputFildData={setUserData}
                  data={data?.data}
                />
              </div>
              <form onSubmit={handleSubmit(createOnSubmit)}>
                <div className="col-lg-12">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row gx-2">
                        <div className="col-6 mb-3">
                          <label className="form-label">
                            নাম: (বাংলা){" "}
                            <span className="from_required">*</span>{" "}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="নাম বাংলা"
                            value={userData?.name_bangla}
                            disabled
                          />
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label className="form-label">
                            নাম: (ইংরেজিতে){" "}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="নাম ইংরেজিতে"
                            value={userData?.name_english}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row gx-2">
                        <div className="col-lg-6 mb-3">
                          <label className="form-label">
                            মোবাইল নম্বর{" "}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            placeholder="মোবাইল নম্বর"
                            value={userData?.number}
                            disabled
                          />
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label className="form-label">
                            জাতীয় পরিচয়পত্র নং{" "}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            placeholder="জাতীয় পরিচয়পত্র নং"
                            value={userData?.nid}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row gx-2">
                        <div className="col-lg-6 mb-3">
                          <label className="form-label">
                            শিরোনাম নাম: (বাংলা){" "}
                            <span className="from_required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder=" শিরোনাম নাম"
                            {...register("title_bn")}
                          />
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label className="form-label">
                            শিরোনাম নাম: (ইংরেজিতে){" "}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder=" শিরোনাম নাম"
                            {...register("title_en")}
                          />
                        </div>
                      </div>
                      <div className="mb-4">
                        <label htmlFor="description" className="form-label">
                          বিস্তারিত লিখুন : (বাংলা){" "}
                          <span className="from_required">*</span>
                        </label>
                        <JoditEditor
                          ref={bodyData}
                          onChange={(newContent) =>
                            contentFieldChanagedbn(newContent)
                          }
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="description" className="form-label">
                          বিস্তারিত লিখুন : (ইংরেজিতে){" "}
                        </label>
                        <JoditEditor
                          ref={bodyData}
                          onChange={(newContent) =>
                            contentFieldChanageden(newContent)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  style={{ cursor: addisLoading ? "no-drop" : "pointer" }}
                  className="btn btn-md rounded font-sm hover-up"
                >
                  জমা করুন
                </button>
              </form>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return { userInfo: state.auth?.userInfo?.users };
}

export default connect(mapStateToProps, null)(OtherSonodCreateModal);
