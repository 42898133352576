import * as yup from "yup";
export const SingUpUnionSchema = yup.object().shape({
    chairmanBanglaName: yup.string().required("চেয়ারম্যান বাংলা নাম ফিল্ড খালি রাখা যাবে না।")
        .matches(/^[ঀ-৾\s:,-:.']+$/i, 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত'),
    chairmanEnglishName: yup.string().required("চেয়ারম্যান ইংরেজি নাম ফিল্ডখালি রাখা যাবে না।")
        .matches(/^[A-Za-z\s:.,-]+$/, 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত'),
    chairmanNID: yup.string().required("এনআইডি কমপক্ষে ১০ এবং ১৭ অক্ষরের হতে হবে")
        .matches(/^[0-9]{10}$|^[0-9]{17}$/, 'জাতীয় পরিচয়পত্র নম্বরটি ১০ বা ১৭ টি সংখ্যা হতে হবে'),
    chairmanMobileNumber: yup.string().required("মোবাইল নাম্বার কমপক্ষে ১১ অক্ষরের হতে হবে")
        .matches(/^[0-9]{11}$/, 'মোবাইল নম্বরটি ১১ টি সংখ্যা হতে হবে'),
    union_bn_name: yup.string().required(" নাম ফিল্ড খালি রাখা যাবে না।")
        .matches(/^[ঀ-৾\s:,-:.'"]+$/i, 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত'),
    union_en_name: yup.string().required(" নাম ফিল্ড খালি রাখা যাবে না।")
        .matches(/^[A-Za-z\s0-9]+$/, 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত'),
    //Secretary
    secretaryBanglaName: yup.string().required("সচিব বাংলা নাম ফিল্ড খালি রাখা যাবে না।")
        .matches(/^[ঀ-৾\s:,-:.'"]+$/i, 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত'),
    secretaryEnglishName: yup.string().required("সচিব ইংরেজি নাম ফিল্ড খালি রাখা যাবে না।")
        .matches(/^[A-Za-z\s:.,-]+$/, 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত'),
    secretaryNID: yup.string().required("এন আইডি কমপক্ষে ১০  এবং ১৭ অক্ষরের হতে হবে")
        .matches(/^[0-9]{10}$|^[0-9]{17}$/, 'জাতীয় পরিচয়পত্র নম্বরটি ১০ বা ১৭ টি সংখ্যা হতে হবে'),
    secretaryEmail: yup.string(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/).required(""),
    secretaryPassword: yup.string().required("পাসওয়ার্ড সর্ব নিম্ন আটটা সর্বোচ্চ ৩২ টা হতে হবে ").min(8).max(32),
    secretaryMobileNumber: yup.string().required("মোবাইল নাম্বার মকপক্ষে ১১ অক্ষরের হতে হবে")
        .matches(/^[0-9]{11}$/, 'মোবাইল নম্বরটি ১১ টি সংখ্যা হতে হবে'),
    //Entrepreneur
    entrepreneurBanglaName: yup.string().required("উদ্যোক্তার বাংলা নাম ফিল্ড খালি রাখা যাবে না।")
        .matches(/^[ঀ-৾\s:,-:.'"]+$/i, 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত'),
    entrepreneurEnglishName: yup.string().required("উদ্যোক্তার ইংরেজি নাম ফিল্ড খালি রাখা যাবে না।")
        .matches(/^[A-Za-z\s:.,-]+$/, 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত'),
    entrepreneurNID: yup.string().required("এন আইডি কমপক্ষে ১০  এবং ১৭ অক্ষরের হতে হবে")
        .matches(/^[0-9]{10}$|^[0-9]{17}$/, 'জাতীয় পরিচয়পত্র নম্বরটি ১০ বা ১৭ টি সংখ্যা হতে হবে'),
    entrepreneurEmail: yup.string(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/).required(""),
    entrepreneurPassword: yup.string().required("পাসওয়ার্ড সর্ব নিম্ন আটটা সর্বোচ্চ ৩২ টা হতে হবে ").min(8).max(32),
    entrepreneurMobileNumber: yup.string().required("মোবাইল নাম্বার মকপক্ষে ১১ অক্ষরের হতে হবে")
        .matches(/^[0-9]{11}$/, 'মোবাইল নম্বরটি ১১ টি সংখ্যা হতে হবে'),
    /* Area information */
    division: yup.string().required("ফিল্ড খালি রাখা যাবে না।"),
    district: yup.string().required("নাম ফিল্ড খালি রাখা যাবে না।"),
    upazila: yup.string().required(" নাম ফিল্ড খালি রাখা যাবে না।"),
    union: yup.string().required(" নাম ফিল্ড খালি রাখা যাবে না।")
})
export const SingUpIncludesmunicipalitiesSchema = yup.object().shape({
    chairmanBanglaName: yup.string()
        .required("চেয়ারম্যান বাংলা নাম ফিল্ড খালি রাখা যাবে না।")
        .matches(/^[ঀ-৾\s:,-:.'"]+$/i, 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত'),

    chairmanEnglishName: yup.string().required("চেয়ারম্যান ইংরেজি নাম ফিল্ডখালি রাখা যাবে না।")
        .matches(/^[A-Za-z\s:.,-]+$/, 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত'),
    chairmanNID: yup.string().required("এন আইডি কমপক্ষে ১০  এবং ১৭ অক্ষরের হতে হবে")
        .matches(/^[0-9]{10}$|^[0-9]{17}$/, 'জাতীয় পরিচয়পত্র নম্বরটি ১০ বা ১৭ টি সংখ্যা হতে হবে'),
    chairmanMobileNumber: yup.string().required("মোবাইল নাম্বার মকপক্ষে ১১ অক্ষরের হতে হবে")
        .matches(/^[0-9]{11}$/, 'মোবাইল নম্বরটি ১১ টি সংখ্যা হতে হবে'),
    secretaryBanglaName: yup.string().required("সচিব বাংলা নাম ফিল্ড খালি রাখা যাবে না।")
        .matches(/^[ঀ-৾\s:,-:.'"]+$/i, 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত'),

    secretaryEnglishName: yup.string().required("সচিব ইংরেজি নাম ফিল্ড খালি রাখা যাবে না।")
        .matches(/^[A-Za-z\s:.,-]+$/, 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত'),
    secretaryNID: yup.string().required("এন আইডি কমপক্ষে ১০  এবং ১৭ অক্ষরের হতে হবে")
        .matches(/^[0-9]{10}$|^[0-9]{17}$/, 'জাতীয় পরিচয়পত্র নম্বরটি ১০ বা ১৭ টি সংখ্যা হতে হবে'),
    secretaryEmail: yup.string(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/).required("নাম ফিল্ড খালি রাখা যাবে না।"),
    secretaryPassword: yup.string().required("পাসওয়ার্ড সর্ব নিম্ন আটটা সর্বোচ্চ ৩২ টা হতে হবে ").min(8).max(32),
    secretaryMobileNumber: yup.string().required("মোবাইল নাম্বার মকপক্ষে ১১ অক্ষরের হতে হবে")
        .matches(/^[0-9]{11}$/, 'মোবাইল নম্বরটি ১১ টি সংখ্যা হতে হবে'),
    //Entrepreneur
    entrepreneurBanglaName: yup.string().required("উদ্যোক্তার বাংলা নাম ফিল্ড খালি রাখা যাবে না।")
        .matches(/^[ঀ-৾\s:,-:.'"]+$/i, 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত'),
    entrepreneurEnglishName: yup.string().required("উদ্যোক্তার ইংরেজি নাম ফিল্ড খালি রাখা যাবে না।")
        .matches(/^[A-Za-z\s:.,-]+$/, 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত'),
    entrepreneurNID: yup.string().required("এন আইডি কমপক্ষে ১০  এবং ১৭ অক্ষরের হতে হবে")
        .matches(/^[0-9]{10}$|^[0-9]{17}$/, 'জাতীয় পরিচয়পত্র নম্বরটি ১০ বা ১৭ টি সংখ্যা হতে হবে'),
    entrepreneurEmail: yup.string(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/).required("নাম ফিল্ড খালি রাখা যাবে না।"),
    entrepreneurPassword: yup.string().required("পাসওয়ার্ড সর্ব নিম্ন আটটা সর্বোচ্চ ৩২ টা হতে হবে ").min(8).max(32),
    entrepreneurMobileNumber: yup.string().required("মোবাইল নাম্বার মকপক্ষে ১১ অক্ষরের হতে হবে")
        .matches(/^[0-9]{11}$/, 'মোবাইল নম্বরটি ১১ টি সংখ্যা হতে হবে'),
    /* Area information */
    division: yup.string().required("ফিল্ড খালি রাখা যাবে না।"),
    district: yup.string().required("নাম ফিল্ড খালি রাখা যাবে না।"),
    upazila: yup.string().required(" নাম ফিল্ড খালি রাখা যাবে না।"),
    municipality_bn_name: yup.string().required(" নাম ফিল্ড খালি রাখা যাবে না।")
        .matches(/^[ঀ-৾\s:,-:.'"]+$/i, 'কেবল বাংলা অক্ষর এবং স্পেস অনুমোদিত'),
    municipality_en_name: yup.string().required(" নাম ফিল্ড খালি রাখা যাবে না।")
        .matches(/^[A-Za-z\s0-9]+$/, 'কেবল ইংরেজিতে অক্ষর এবং স্পেস অনুমোদিত'),
})